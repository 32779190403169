/* eslint-disable */
/* prettier-ignore */
export type Json = unknown;
export type DateTimeLocal = {
  year: number;
  month: number;
  date: number;
  hours: number;
  minutes: number;
  seconds: number;
};
export type TemplatePrediction = { [key: string]: string[] | TemplatePrediction };

import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  ContentSequence: { input: Json; output: Json };
  Date: { input: Date; output: Date };
  DateTime: { input: Date; output: Date };
  /**
   * Represents a local date time value in an unknown timezone.
   *
   * Useful when representing date time values that we don't have a timezone for (e.g. SeriesDate and SeriesTime).
   * In these cases we don't want to use a Date which may give the impression the value is in the user's local timezone.
   *
   * Instead we use this structure which the client can format as necessary.
   *
   * The values match the ones used in JS Date instantiation.
   */
  DateTimeLocal: { input: DateTimeLocalScalarConfig; output: DateTimeLocalScalarConfig };
  FrameScalar: { input: FrameDataFragment; output: FrameDataFragment };
  JSON: { input: JSON; output: JSON };
  JSONObject: { input: JSON; output: JSON };
  Matrix3x3: {
    input: [number, number, number, number, number, number, number, number, number];
    output: [number, number, number, number, number, number, number, number, number];
  };
  ModalityLutDescriptor: { input: [number, number, number]; output: [number, number, number] };
  StringOrBooleanOrDate: { input: string | boolean | Date; output: string | boolean | Date };
  TemplatePrediction: {
    input: TemplatePredictionScalarConfig;
    output: TemplatePredictionScalarConfig;
  };
  TransformMatrix: {
    input: [
      [number, number, number, number],
      [number, number, number, number],
      [number, number, number, number],
      [number, number, number, number],
    ];
    output: [
      [number, number, number, number],
      [number, number, number, number],
      [number, number, number, number],
      [number, number, number, number],
    ];
  };
  Vector2: { input: [number, number]; output: [number, number] };
  Vector3: { input: [number, number, number]; output: [number, number, number] };
  Vector4: { input: [number, number, number, number]; output: [number, number, number, number] };
  Vector6: {
    input: [number, number, number, number, number, number];
    output: [number, number, number, number, number, number];
  };
  VoiLutDescriptor: { input: [number, number, number]; output: [number, number, number] };
};

export type AiModeInput = {
  readonly findingsMapperEnabled: Scalars['Boolean']['input'];
  readonly formatImpressionGenerationAsList: Scalars['Boolean']['input'];
  readonly onDemandImpressionGeneration: Scalars['Boolean']['input'];
};

export type AiModeSettings = {
  readonly __typename?: 'AIModeSettings';
  readonly findingsMapperEnabled: Scalars['Boolean']['output'];
  readonly formatImpressionGenerationAsList: Scalars['Boolean']['output'];
  readonly onDemandImpressionGeneration: Scalars['Boolean']['output'];
};

export type AbsoluteScrollingResponse = {
  readonly __typename?: 'AbsoluteScrollingResponse';
  readonly absoluteScroll: Scalars['Boolean']['output'];
};

export type AcceptAiAnnotation = {
  readonly annotations: ReadonlyArray<UserAnnotation>;
  readonly id: Scalars['String']['input'];
};

export type AcceptAiAnnotationResponse = {
  readonly __typename?: 'AcceptAiAnnotationResponse';
  readonly annotations: ReadonlyArray<AnnotationLogResponse>;
  readonly logIds: ReadonlyArray<Scalars['ID']['output']>;
};

export type Addendum = {
  readonly __typename?: 'Addendum';
  readonly content: AddendumContent;
  readonly created: Scalars['DateTime']['output'];
  readonly owner?: Maybe<Owner>;
  readonly sentAt?: Maybe<Scalars['DateTime']['output']>;
  readonly smid: Scalars['ID']['output'];
  readonly submittedAt?: Maybe<Scalars['DateTime']['output']>;
  readonly updated: Scalars['DateTime']['output'];
  readonly workstationID?: Maybe<Scalars['String']['output']>;
};

export type AddendumContent = AddendumSironaSlate | AddendumTextBlob;

export type AddendumPayload = {
  readonly content: ReadonlyArray<Scalars['JSON']['input']>;
  readonly workstationID?: InputMaybe<Scalars['String']['input']>;
};

export type AddendumSironaSlate = {
  readonly __typename?: 'AddendumSironaSlate';
  readonly sironaSlate: ReadonlyArray<Scalars['JSON']['output']>;
};

export type AddendumTextBlob = {
  readonly __typename?: 'AddendumTextBlob';
  readonly textBlob: Scalars['String']['output'];
};

export type AiAnnotation = {
  readonly __typename?: 'AiAnnotation';
  readonly annotations: ReadonlyArray<Scalars['JSON']['output']>;
  readonly data?: Maybe<Scalars['JSON']['output']>;
  readonly frameOfReferenceUID?: Maybe<Scalars['String']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly modelName: Scalars['String']['output'];
  readonly seriesSmid: Scalars['ID']['output'];
  readonly status: AiAnnotationGroupStatus;
  readonly type: AiAnnotationTypes;
};

export enum AiAnnotationGroupStatus {
  Accepted = 'ACCEPTED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
}

export enum AiAnnotationTypes {
  FastAnnotation = 'FAST_ANNOTATION',
  SegmentationMask = 'SEGMENTATION_MASK',
  SegMaskLabel = 'SEG_MASK_LABEL',
  VertebraeLabel = 'VERTEBRAE_LABEL',
}

export type AiUgcAnnotation = Annotation & {
  readonly __typename?: 'AiUgcAnnotation';
  readonly aiAnnotationSmid?: Maybe<Scalars['ID']['output']>;
  readonly customLogic?: Maybe<CustomAnnotationLogic>;
  readonly data: Scalars['JSON']['output'];
  readonly pixel_space_representation?: Maybe<Scalars['JSON']['output']>;
  readonly seriesSmid: Scalars['ID']['output'];
  readonly smid: Scalars['ID']['output'];
  readonly source: AnnotationSource;
  readonly type: AnnotationTypes;
};

export type AnatomicNavigatorResponse = {
  readonly __typename?: 'AnatomicNavigatorResponse';
  readonly anatomicNavigatorEnabled: Scalars['Boolean']['output'];
};

export enum AnatomicalDirection {
  A = 'A',
  F = 'F',
  H = 'H',
  L = 'L',
  P = 'P',
  R = 'R',
}

export type Annotation = {
  readonly aiAnnotationSmid?: Maybe<Scalars['ID']['output']>;
  readonly customLogic?: Maybe<CustomAnnotationLogic>;
  readonly data: Scalars['JSON']['output'];
  readonly pixel_space_representation?: Maybe<Scalars['JSON']['output']>;
  readonly seriesSmid: Scalars['ID']['output'];
  readonly smid: Scalars['ID']['output'];
  readonly source: AnnotationSource;
  readonly type: AnnotationTypes;
};

export type AnnotationEditableOptions = {
  readonly __typename?: 'AnnotationEditableOptions';
  readonly delete?: Maybe<Scalars['Boolean']['output']>;
  readonly move?: Maybe<Scalars['Boolean']['output']>;
  readonly transform?: Maybe<Scalars['Boolean']['output']>;
};

export type AnnotationInstance = {
  readonly groupId?: InputMaybe<Scalars['ID']['input']>;
  readonly id: Scalars['ID']['input'];
  readonly label?: InputMaybe<Scalars['String']['input']>;
  readonly segments: ReadonlyArray<IAnnotationSegment>;
  readonly type: AnnotationTypes;
};

export type AnnotationLog = {
  readonly aiAnnotationSmid: Scalars['ID']['input'];
  readonly smid: Scalars['String']['input'];
};

export type AnnotationLogResponse = {
  readonly __typename?: 'AnnotationLogResponse';
  readonly annotationSmid: Scalars['String']['output'];
};

export type AnnotationSegment = {
  readonly __typename?: 'AnnotationSegment';
  readonly end: ExtendedCoordinates;
  readonly start: ExtendedCoordinates;
};

export enum AnnotationSource {
  Ai = 'AI',
  Cad = 'CAD',
  Gsps = 'GSPS',
  Ugc = 'UGC',
}

export type AnnotationStyle = {
  readonly __typename?: 'AnnotationStyle';
  readonly color?: Maybe<Scalars['String']['output']>;
  readonly opacity?: Maybe<Scalars['Float']['output']>;
};

export enum AnnotationTypes {
  Angle = 'ANGLE',
  Arrow = 'ARROW',
  Circle = 'CIRCLE',
  CobbAngle = 'COBB_ANGLE',
  Contour = 'CONTOUR',
  Custom = 'CUSTOM',
  Ellipse = 'ELLIPSE',
  FastAnnotation = 'FAST_ANNOTATION',
  Landmark = 'LANDMARK',
  Length = 'LENGTH',
  MultiPoint = 'MULTI_POINT',
  Point = 'POINT',
  Rect = 'RECT',
  SegmentationMask = 'SEGMENTATION_MASK',
  SegMaskLabel = 'SEG_MASK_LABEL',
  Text = 'TEXT',
  VertebraeLabel = 'VERTEBRAE_LABEL',
}

export type AssignDefaultAutoloadTemplateInput = {
  readonly id?: InputMaybe<Scalars['ID']['input']>;
};

export type AssignDefaultAutoloadTemplatePayload = {
  readonly __typename?: 'AssignDefaultAutoloadTemplatePayload';
  readonly me: User;
};

export type AssignExamCountInput = {
  readonly count: Scalars['Int']['input'];
  readonly smid: Scalars['ID']['input'];
};

export type AssignExamsToUserResponse = {
  readonly __typename?: 'AssignExamsToUserResponse';
  readonly assignedExams: ReadonlyArray<WorklistItem>;
  readonly failedExams: ReadonlyArray<FailedAssignExamSmid>;
};

export enum AuthRole {
  Unknown = 'UNKNOWN',
  User = 'USER',
}

export type AuthenticateResponse = MfaRequired | User;

export type AutoCorrectEntry = {
  readonly __typename?: 'AutoCorrectEntry';
  readonly id: Scalars['ID']['output'];
  readonly key: Scalars['String']['output'];
  readonly value: Scalars['String']['output'];
};

export type AutoFillComparison = {
  readonly __typename?: 'AutoFillComparison';
  readonly enabled: Scalars['Boolean']['output'];
};

export type AutoFillComparisonInput = {
  readonly enabled: Scalars['Boolean']['input'];
};

export type BarPreferences = {
  readonly __typename?: 'BarPreferences';
  readonly toolIds: ReadonlyArray<Scalars['ID']['output']>;
};

export type BaseStack = {
  readonly smid: Scalars['ID']['output'];
  readonly study: Study;
  readonly type: Scalars['String']['output'];
};

export type BatchClaimInput = {
  readonly resetReport?: InputMaybe<Scalars['Boolean']['input']>;
  readonly smid: Scalars['ID']['input'];
};

export type BatchClaimResult = {
  readonly __typename?: 'BatchClaimResult';
  readonly accessionNumber?: Maybe<Scalars['String']['output']>;
  readonly claimedBy?: Maybe<User>;
  readonly errorReason?: Maybe<Scalars['String']['output']>;
  readonly smid: Scalars['ID']['output'];
  readonly success: Scalars['Boolean']['output'];
};

export type BatchUnclaimInput = {
  readonly draftedAddendumSmid?: InputMaybe<Scalars['ID']['input']>;
  readonly resetReport?: InputMaybe<Scalars['Boolean']['input']>;
  readonly smid: Scalars['ID']['input'];
};

export type BatchUnclaimResult = {
  readonly __typename?: 'BatchUnclaimResult';
  readonly accessionNumber?: Maybe<Scalars['String']['output']>;
  readonly errorReason?: Maybe<Scalars['String']['output']>;
  readonly smid: Scalars['ID']['output'];
  readonly success: Scalars['Boolean']['output'];
};

export type BodyPart = {
  readonly __typename?: 'BodyPart';
  readonly name: Scalars['String']['output'];
};

export type CadAnnotation = Annotation & {
  readonly __typename?: 'CadAnnotation';
  readonly aiAnnotationSmid?: Maybe<Scalars['ID']['output']>;
  readonly customLogic?: Maybe<CustomAnnotationLogic>;
  readonly data: Scalars['JSON']['output'];
  readonly pixel_space_representation?: Maybe<Scalars['JSON']['output']>;
  readonly seriesSmid: Scalars['ID']['output'];
  readonly smid: Scalars['ID']['output'];
  readonly source: AnnotationSource;
  readonly type: AnnotationTypes;
};

export type CineModule = {
  readonly __typename?: 'CineModule';
  readonly frameTimeVector: ReadonlyArray<Scalars['Int']['output']>;
  readonly preferredPlaybackSequencing: Scalars['Int']['output'];
};

export type ClassificationItem = {
  readonly __typename?: 'ClassificationItem';
  readonly category: Scalars['String']['output'];
  readonly classifications: ReadonlyArray<Scalars['String']['output']>;
  readonly codeVersion?: Maybe<Scalars['String']['output']>;
  readonly createdAt: Scalars['Date']['output'];
  readonly modelName?: Maybe<Scalars['String']['output']>;
  readonly modelVersion?: Maybe<Scalars['String']['output']>;
  readonly smid: Scalars['ID']['output'];
};

export type ClassificationSummary = {
  readonly __typename?: 'ClassificationSummary';
  readonly display_name?: Maybe<Scalars['String']['output']>;
  readonly ontology_id?: Maybe<Scalars['String']['output']>;
};

export type Classifications = {
  readonly __typename?: 'Classifications';
  readonly cursor?: Maybe<Scalars['ID']['output']>;
  readonly items: ReadonlyArray<ClassificationItem>;
  readonly next?: Maybe<Scalars['ID']['output']>;
  readonly previous?: Maybe<Scalars['ID']['output']>;
  readonly totalCount: Scalars['Int']['output'];
};

export type Clinic = {
  readonly __typename?: 'Clinic';
  readonly isRbacEnabled: Scalars['Boolean']['output'];
  readonly name: Scalars['String']['output'];
  readonly smid: Scalars['ID']['output'];
  readonly worklistLockDuration: Scalars['Int']['output'];
};

export enum CompletionFlag {
  Complete = 'COMPLETE',
  Partial = 'PARTIAL',
}

export type ConfirmDeleteWorkspacePreset = {
  readonly __typename?: 'ConfirmDeleteWorkspacePreset';
  readonly smid: Scalars['ID']['output'];
};

export type ConfirmResponse = {
  readonly __typename?: 'ConfirmResponse';
  readonly confirmed: Scalars['Boolean']['output'];
};

export type Counter = {
  readonly __typename?: 'Counter';
  readonly count: Scalars['Int']['output'];
  readonly countStr?: Maybe<Scalars['String']['output']>;
};

export type CreateAutoCorrectEntryInput = {
  readonly key: Scalars['String']['input'];
  readonly value: Scalars['String']['input'];
};

export type CreateAutoCorrectEntryResponse = {
  readonly __typename?: 'CreateAutoCorrectEntryResponse';
  readonly entry?: Maybe<AutoCorrectEntry>;
};

export type CreateVocabEntryInput = {
  readonly phrase: Scalars['String']['input'];
};

export type CreateVocabEntryResponse = {
  readonly __typename?: 'CreateVocabEntryResponse';
  readonly entry?: Maybe<VocabEntry>;
};

export type CursorStyle = {
  readonly __typename?: 'CursorStyle';
  readonly color: Scalars['String']['output'];
};

export type CursorStyleInput = {
  readonly color: Scalars['String']['input'];
};

export type CustomAnnotationLogic = {
  readonly __typename?: 'CustomAnnotationLogic';
  readonly id: Scalars['ID']['output'];
  readonly logic: Scalars['String']['output'];
  readonly toolId: Scalars['ID']['output'];
};

export type CustomDicomTagsResponse = {
  readonly __typename?: 'CustomDicomTagsResponse';
  readonly modality: Scalars['String']['output'];
  readonly modalityDicomTags: ReadonlyArray<DicomTagRenderInfo>;
};

export type CustomMergeField = {
  readonly __typename?: 'CustomMergeField';
  readonly key: Scalars['String']['output'];
  readonly value: Scalars['String']['output'];
};

export type DefaultCustomWorklist = {
  readonly __typename?: 'DefaultCustomWorklist';
  readonly surface: Scalars['String']['output'];
  readonly worklistViewID?: Maybe<Scalars['ID']['output']>;
};

export type DefaultCustomWorklistInput = {
  readonly surface: Scalars['String']['input'];
  readonly worklistViewID?: InputMaybe<Scalars['ID']['input']>;
};

export type DefaultCustomWorklistsSetting = {
  readonly __typename?: 'DefaultCustomWorklistsSetting';
  readonly defaultCustomWorklists: ReadonlyArray<Maybe<DefaultCustomWorklist>>;
  readonly isEnabled: Scalars['Boolean']['output'];
};

export type DefaultCustomWorklistsSettingInput = {
  readonly defaultCustomWorklists?: InputMaybe<
    ReadonlyArray<InputMaybe<DefaultCustomWorklistInput>>
  >;
  readonly isEnabled: Scalars['Boolean']['input'];
};

export type DefaultThumbnailSizeResponse = {
  readonly __typename?: 'DefaultThumbnailSizeResponse';
  readonly thumbnailSizePx?: Maybe<Scalars['Int']['output']>;
};

export type DehydratedSeries = {
  readonly __typename?: 'DehydratedSeries';
  readonly smid: Scalars['ID']['output'];
};

export type DehydratedStack = {
  readonly __typename?: 'DehydratedStack';
  readonly smid: Scalars['ID']['output'];
};

export type DehydratedStudy = {
  readonly __typename?: 'DehydratedStudy';
  readonly smid: Scalars['ID']['output'];
};

export type DeleteAnnotationsItem = {
  readonly __typename?: 'DeleteAnnotationsItem';
  readonly smid: Scalars['ID']['output'];
  readonly status: DeleteAnnotationsStatus;
};

export type DeleteAnnotationsResponse = {
  readonly __typename?: 'DeleteAnnotationsResponse';
  readonly results: ReadonlyArray<DeleteAnnotationsItem>;
};

export enum DeleteAnnotationsStatus {
  Fulfilled = 'fulfilled',
  Rejected = 'rejected',
}

export type DeleteAutoCorrectEntryInput = {
  readonly key: Scalars['String']['input'];
  readonly value: Scalars['String']['input'];
};

export type DeleteAutoCorrectEntryResponse = {
  readonly __typename?: 'DeleteAutoCorrectEntryResponse';
  readonly id: Scalars['ID']['output'];
};

export type DeleteMacroResponse = {
  readonly __typename?: 'DeleteMacroResponse';
  readonly smid: Scalars['ID']['output'];
};

export type DeleteTemplateResponse = {
  readonly __typename?: 'DeleteTemplateResponse';
  readonly smid: Scalars['ID']['output'];
};

export type DeleteVocabEntryInput = {
  readonly id: Scalars['ID']['input'];
};

export type DeleteVocabEntryResponse = {
  readonly __typename?: 'DeleteVocabEntryResponse';
  readonly id: Scalars['ID']['output'];
};

export type DicomInstance = {
  readonly __typename?: 'DicomInstance';
  readonly dicomTags: ReadonlyArray<DicomTag>;
  readonly smid: Scalars['ID']['output'];
};

export type DicomInstanceDicomTagsArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

export type DicomTag = {
  readonly __typename?: 'DicomTag';
  readonly id: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
  readonly values: ReadonlyArray<DicomTagValue>;
  readonly vr: Scalars['String']['output'];
};

export type DicomTagRenderInfo = {
  readonly __typename?: 'DicomTagRenderInfo';
  readonly enabled: Scalars['Boolean']['output'];
  readonly id: Scalars['ID']['output'];
  readonly label: Scalars['String']['output'];
  readonly tagId: Scalars['String']['output'];
};

export type DicomTagRenderInfoInput = {
  readonly enabled: Scalars['Boolean']['input'];
  readonly id: Scalars['ID']['input'];
  readonly label: Scalars['String']['input'];
  readonly tagId: Scalars['String']['input'];
};

export type DicomTagValue = {
  readonly __typename?: 'DicomTagValue';
  readonly key?: Maybe<Scalars['String']['output']>;
  readonly value?: Maybe<Scalars['JSON']['output']>;
};

export type DictaphoneKey = {
  readonly __typename?: 'DictaphoneKey';
  readonly id: GamepadActionId;
  readonly key: Scalars['String']['output'];
};

export type DictaphonePreferences = {
  readonly __typename?: 'DictaphonePreferences';
  readonly deviceLabel: Scalars['String']['output'];
  readonly keyMapping: ReadonlyArray<DictaphoneKey>;
  readonly recordWhilePressed: Scalars['Boolean']['output'];
  readonly showPicklistOptionsInEditor: Scalars['Boolean']['output'];
};

export type DisplayConfiguration = {
  readonly isHorizontallyFlipped: Scalars['Boolean']['input'];
  readonly isInverted: Scalars['Boolean']['input'];
  readonly isVerticallyFlipped: Scalars['Boolean']['input'];
  readonly params2d?: InputMaybe<DisplayConfigurationParams2d>;
};

export type DisplayConfigurationParams2d = {
  readonly focalPoint?: InputMaybe<Scalars['Vector3']['input']>;
  readonly imageNumber?: InputMaybe<Scalars['Int']['input']>;
  readonly level?: InputMaybe<Scalars['Float']['input']>;
  readonly offsetX?: InputMaybe<Scalars['Float']['input']>;
  readonly offsetY?: InputMaybe<Scalars['Float']['input']>;
  readonly position?: InputMaybe<Scalars['Vector3']['input']>;
  readonly rotate?: InputMaybe<Scalars['Float']['input']>;
  readonly window?: InputMaybe<Scalars['Float']['input']>;
  readonly zoom?: InputMaybe<Scalars['Float']['input']>;
};

export enum EraseAllToolScope {
  Study = 'study',
  Viewport = 'viewport',
}

export type EraseAllToolScopeResponse = {
  readonly __typename?: 'EraseAllToolScopeResponse';
  readonly eraseAllToolScope?: Maybe<EraseAllToolScope>;
};

export type ExtendedCoordinates = IndexCoordinates | WorldCoordinates;

export type FailedAssignExamSmid = {
  readonly __typename?: 'FailedAssignExamSmid';
  readonly errorReason: Scalars['String']['output'];
  readonly smid: Scalars['ID']['output'];
};

export type FastScrollPreferences = {
  readonly __typename?: 'FastScrollPreferences';
  readonly sensitivity: Scalars['Float']['output'];
};

export type FineScrollPreferences = {
  readonly __typename?: 'FineScrollPreferences';
  readonly enabled: Scalars['Boolean']['output'];
};

export type FocusMode = {
  readonly __typename?: 'FocusMode';
  readonly enabled: Scalars['Boolean']['output'];
};

export type FocusModeInput = {
  readonly enabled: Scalars['Boolean']['input'];
};

export type Frame = {
  readonly __typename?: 'Frame';
  readonly _groupType: Scalars['String']['output'];
  readonly colorChannels: Scalars['Int']['output'];
  readonly dicomInstance: DicomInstance;
  readonly direction: FrameDirection;
  readonly hasPixels: Scalars['Boolean']['output'];
  readonly imageNumber: Scalars['Int']['output'];
  readonly modules: Modules;
  readonly needsInversion: Scalars['Boolean']['output'];
  readonly origin: Scalars['Vector3']['output'];
  readonly series: Series;
  readonly size: ReadonlyArray<Scalars['Float']['output']>;
  readonly smid: Scalars['ID']['output'];
  readonly sopClassUID: Scalars['String']['output'];
  readonly sopInstanceUID: Scalars['String']['output'];
  readonly spacing: Scalars['Vector3']['output'];
  readonly stack: FrameStack;
  readonly supportedRenderers: SupportedRenderers;
  readonly validSpacing: Scalars['Boolean']['output'];
};

export type FrameDicomInstanceArgs = {
  dicomTagIds?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

export type FrameDirection = {
  readonly __typename?: 'FrameDirection';
  readonly columns: Scalars['Int']['output'];
  readonly data: Scalars['Matrix3x3']['output'];
  readonly rows: Scalars['Int']['output'];
  readonly validDirection: Scalars['Boolean']['output'];
};

export enum FrameGroupByType {
  Auto = 'AUTO',
  Disabled = 'DISABLED',
  Enabled = 'ENABLED',
}

export type FrameStack = LayeredStack | SingleLayerStack;

export type FrameToLoad = {
  readonly frameSmid: Scalars['ID']['input'];
  readonly seriesSmid: Scalars['ID']['input'];
};

export enum GamepadActionId {
  Back = 'back',
  Forward = 'forward',
  Record = 'record',
  SubmitReport = 'submitReport',
  ToggleFocusMode = 'toggleFocusMode',
}

export type GeneralImageModule = {
  readonly __typename?: 'GeneralImageModule';
  readonly imageType?: Maybe<ReadonlyArray<Scalars['String']['output']>>;
  readonly instanceNumber?: Maybe<Scalars['Int']['output']>;
  readonly patientOrientation?: Maybe<PatientOrientation>;
};

export type GeneralReferenceModule = {
  readonly __typename?: 'GeneralReferenceModule';
  readonly sourceImageSequence?: Maybe<ReadonlyArray<SourceImage>>;
};

export type GenerateImpressionInput = {
  readonly caseSmid: Scalars['ID']['input'];
  readonly reportSections: Scalars['JSON']['input'];
};

export type GenerateImpressionResponse = {
  readonly __typename?: 'GenerateImpressionResponse';
  readonly impression: Scalars['JSON']['output'];
};

export type GeneratePriorsSummaryResponse = {
  readonly __typename?: 'GeneratePriorsSummaryResponse';
  readonly priorsSummary: Scalars['JSON']['output'];
};

export type GspsAnnotation = Annotation & {
  readonly __typename?: 'GspsAnnotation';
  readonly aiAnnotationSmid?: Maybe<Scalars['ID']['output']>;
  readonly created?: Maybe<Scalars['DateTimeLocal']['output']>;
  readonly customLogic?: Maybe<CustomAnnotationLogic>;
  readonly data: Scalars['JSON']['output'];
  readonly owner: GspsOwner;
  readonly pixel_space_representation?: Maybe<Scalars['JSON']['output']>;
  readonly presentationState: GspsPresentationState;
  readonly seriesInstanceUID: Scalars['String']['output'];
  readonly seriesSmid: Scalars['ID']['output'];
  readonly smid: Scalars['ID']['output'];
  readonly source: AnnotationSource;
  readonly study: Study;
  readonly studyInstanceUID: Scalars['String']['output'];
  readonly type: AnnotationTypes;
};

export type GspsOwner = {
  readonly __typename?: 'GspsOwner';
  readonly firstName?: Maybe<Scalars['String']['output']>;
  readonly lastName?: Maybe<Scalars['String']['output']>;
};

export type GspsPresentationState = {
  readonly __typename?: 'GspsPresentationState';
  readonly annotations: ReadonlyArray<GspsAnnotation>;
  readonly created?: Maybe<Scalars['DateTimeLocal']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly owner?: Maybe<GspsOwner>;
  readonly smid: Scalars['ID']['output'];
  readonly study: Study;
};

export type GspsPresentationStateAnnotationsArgs = {
  query?: InputMaybe<Scalars['JSON']['input']>;
};

export type GspsPresentationStateError = {
  readonly __typename?: 'GspsPresentationStateError';
  readonly message: Scalars['String']['output'];
};

export type GspsPresentationStateResponse = GspsPresentationState | GspsPresentationStateError;

export type HangingProtocol = {
  readonly __typename?: 'HangingProtocol';
  readonly clinic: Clinic;
  readonly created: Scalars['DateTime']['output'];
  readonly data: HangingProtocolData;
  readonly name: Scalars['String']['output'];
  readonly numberOfPriors: Scalars['Int']['output'];
  readonly owner: User;
  readonly smid: Scalars['ID']['output'];
  readonly studyCriteria?: Maybe<Scalars['JSON']['output']>;
  readonly studyDescriptions: ReadonlyArray<Scalars['String']['output']>;
  readonly updated: Scalars['DateTime']['output'];
  readonly viewportsConfigurations?: Maybe<ReadonlyArray<ViewportConfiguration>>;
};

export type HangingProtocolData = {
  readonly __typename?: 'HangingProtocolData';
  readonly layouts: ReadonlyArray<HangingProtocolLayout>;
  readonly viewportsConfigurations: Scalars['JSON']['output'];
};

export type HangingProtocolDeleted = {
  readonly __typename?: 'HangingProtocolDeleted';
  readonly smid: Scalars['ID']['output'];
};

export type HangingProtocolLayout = {
  readonly __typename?: 'HangingProtocolLayout';
  readonly id: Scalars['ID']['output'];
  readonly layout: ReadonlyArray<Scalars['Int']['output']>;
};

export type HangingProtocolPayload = {
  readonly caseId?: InputMaybe<Scalars['String']['input']>;
  readonly data: IHangingProtocolData;
  readonly groupedStudies?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  readonly name: Scalars['String']['input'];
  readonly numberOfPriors: Scalars['Int']['input'];
  readonly studyDescriptions: ReadonlyArray<Scalars['String']['input']>;
};

export enum HangingProtocolSortBy {
  NumberOfPriors = 'NUMBER_OF_PRIORS',
}

export type HangingProtocols = {
  readonly __typename?: 'HangingProtocols';
  readonly items: ReadonlyArray<HangingProtocol>;
  readonly next?: Maybe<Scalars['ID']['output']>;
  readonly previous?: Maybe<Scalars['ID']['output']>;
  readonly totalCount: Scalars['Int']['output'];
};

export type HeadingKeywords = {
  readonly __typename?: 'HeadingKeywords';
  readonly comparison: ReadonlyArray<Scalars['String']['output']>;
  readonly exam: ReadonlyArray<Scalars['String']['output']>;
  readonly findings: ReadonlyArray<Scalars['String']['output']>;
  readonly history: ReadonlyArray<Scalars['String']['output']>;
  readonly impressions: ReadonlyArray<Scalars['String']['output']>;
  readonly technique: ReadonlyArray<Scalars['String']['output']>;
};

export enum HeadingLevel {
  H1 = 'H1',
  H2 = 'H2',
}

export type HeadingStyle = {
  readonly __typename?: 'HeadingStyle';
  readonly level: HeadingLevel;
  readonly textStyle: TextStyle;
};

export type HeadingStyleInput = {
  readonly level: HeadingLevel;
  readonly textStyle: TextStyleInput;
};

export type IAddendum = {
  readonly content: ReadonlyArray<Scalars['JSON']['input']>;
  readonly created?: InputMaybe<Scalars['DateTime']['input']>;
  readonly owner?: InputMaybe<Scalars['JSON']['input']>;
  readonly smid: Scalars['ID']['input'];
  readonly submittedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type IAnnotationSegment = {
  readonly end: IExtendedCoordinates;
  readonly start: IExtendedCoordinates;
};

export type IDictaphoneKey = {
  readonly id: GamepadActionId;
  readonly key: Scalars['String']['input'];
};

export type IExtendedCoordinates = {
  readonly indexSpace?: InputMaybe<Scalars['Vector3']['input']>;
  readonly worldSpace?: InputMaybe<Scalars['Vector3']['input']>;
};

export type IHangingProtocolData = {
  readonly layouts: ReadonlyArray<IHangingProtocolLayout>;
  readonly viewportsConfigurations: ReadonlyArray<IViewportConfiguration>;
};

export type IHangingProtocolLayout = {
  readonly id: Scalars['ID']['input'];
  readonly layout: ReadonlyArray<Scalars['Int']['input']>;
};

export type IProcedureReference = {
  readonly autoload: Scalars['Boolean']['input'];
  readonly smid: Scalars['ID']['input'];
};

export type IReportPicklist = {
  readonly id: Scalars['ID']['input'];
  readonly options: ReadonlyArray<IReportPicklistOption>;
};

export type IReportPicklistOption = {
  readonly default?: InputMaybe<Scalars['Boolean']['input']>;
  readonly id: Scalars['ID']['input'];
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly text: ReadonlyArray<Scalars['JSON']['input']>;
};

export type IReportTemplatePayload = {
  readonly content?: InputMaybe<Scalars['JSON']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly picklists?: InputMaybe<ReadonlyArray<IReportPicklist>>;
  readonly procedureReferences?: InputMaybe<ReadonlyArray<IProcedureReference>>;
  readonly sections?: InputMaybe<ReadonlyArray<Scalars['JSON']['input']>>;
  readonly triggerPhrase?: InputMaybe<Scalars['String']['input']>;
};

export type IViewportConfiguration = {
  readonly comparisonIndex?: InputMaybe<Scalars['Int']['input']>;
  readonly groupType?: InputMaybe<Scalars['String']['input']>;
  readonly id: Scalars['ID']['input'];
  readonly seriesDescriptions?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly seriesId?: InputMaybe<Scalars['ID']['input']>;
  readonly viewType?: InputMaybe<ViewType>;
};

export type ImageParams = {
  readonly __typename?: 'ImageParams';
  readonly colorLevel?: Maybe<Scalars['Float']['output']>;
  readonly colorWindow?: Maybe<Scalars['Float']['output']>;
  readonly dimensions: Scalars['Vector3']['output'];
  readonly direction: Scalars['Matrix3x3']['output'];
  readonly extent: Scalars['Vector6']['output'];
  readonly isMultiAxes: Scalars['Boolean']['output'];
  readonly origin: Scalars['Vector3']['output'];
  readonly spacing: Scalars['Vector3']['output'];
};

export type ImageRegistration = {
  readonly __typename?: 'ImageRegistration';
  readonly clinic: Scalars['ID']['output'];
  readonly codeVersion: Scalars['String']['output'];
  readonly created: Scalars['DateTime']['output'];
  readonly dataType: Scalars['String']['output'];
  readonly fixedFrameOfReferenceUid: Scalars['ID']['output'];
  readonly modelName: Scalars['String']['output'];
  readonly modelVersion: Scalars['String']['output'];
  readonly movingFrameOfReferenceUid: Scalars['ID']['output'];
  readonly seriesFixed: Scalars['ID']['output'];
  readonly seriesMoving: Scalars['ID']['output'];
  readonly smid: Scalars['ID']['output'];
  readonly transformMatrix: Scalars['TransformMatrix']['output'];
};

export type ImpressionGenerator = {
  readonly __typename?: 'ImpressionGenerator';
  readonly automatic: Scalars['Boolean']['output'];
  readonly enabled: Scalars['Boolean']['output'];
  readonly modalities: ReadonlyArray<Scalars['String']['output']>;
};

export type ImpressionGeneratorInput = {
  readonly automatic: Scalars['Boolean']['input'];
  readonly enabled: Scalars['Boolean']['input'];
  readonly modalities: ReadonlyArray<Scalars['String']['input']>;
};

export type IndexCoordinates = {
  readonly __typename?: 'IndexCoordinates';
  readonly indexSpace: Scalars['Vector3']['output'];
};

export type KeyboardPreferences = {
  readonly __typename?: 'KeyboardPreferences';
  readonly shortcuts: ReadonlyArray<Shortcut>;
};

export type Layer = {
  readonly __typename?: 'Layer';
  readonly index: Scalars['Int']['output'];
  readonly stackSmid: Scalars['ID']['output'];
};

export type LayeredStack = BaseStack & {
  readonly __typename?: 'LayeredStack';
  readonly smid: Scalars['ID']['output'];
  readonly stackLayers: ReadonlyArray<Layer>;
  readonly study: Study;
  readonly type: Scalars['String']['output'];
};

export type LimitedUser = {
  readonly __typename?: 'LimitedUser';
  readonly email: Scalars['String']['output'];
  readonly firstName: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
  readonly lastName: Scalars['String']['output'];
};

export type LineIndicator = {
  readonly __typename?: 'LineIndicator';
  readonly enabled: Scalars['Boolean']['output'];
  readonly placement: LineIndicatorPlacement;
  readonly variant: LineIndicatorVariant;
};

export type LineIndicatorInput = {
  readonly enabled: Scalars['Boolean']['input'];
  readonly placement: LineIndicatorPlacement;
  readonly variant: LineIndicatorVariant;
};

export enum LineIndicatorPlacement {
  Left = 'left',
  Right = 'right',
}

export enum LineIndicatorVariant {
  Arrow = 'arrow',
  Dot = 'dot',
}

export type LinkAuthenticatorResponse = {
  readonly __typename?: 'LinkAuthenticatorResponse';
  readonly linked: Scalars['Boolean']['output'];
};

export type List = {
  readonly cursor?: Maybe<Scalars['ID']['output']>;
  readonly totalCount: Scalars['Int']['output'];
};

export type MfaRequired = {
  readonly __typename?: 'MFARequired';
  readonly mfaSessionId: Scalars['String']['output'];
};

export type Macro = {
  readonly __typename?: 'Macro';
  readonly created: Scalars['DateTime']['output'];
  readonly name: Scalars['String']['output'];
  readonly nestedMacros: ReadonlyArray<Macro>;
  readonly owner?: Maybe<SlimUser>;
  readonly picklists: ReadonlyArray<ReportPicklist>;
  readonly procedures?: Maybe<ReadonlyArray<Procedure>>;
  readonly smid: Scalars['ID']['output'];
  readonly text: ReadonlyArray<Scalars['JSON']['output']>;
  readonly triggerPhrase: Scalars['String']['output'];
  readonly updated?: Maybe<Scalars['DateTime']['output']>;
};

export type MacroPayload = {
  readonly name: Scalars['String']['input'];
  readonly picklists: ReadonlyArray<IReportPicklist>;
  readonly procedures?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  readonly text: ReadonlyArray<Scalars['JSON']['input']>;
  readonly triggerPhrase: Scalars['String']['input'];
};

export type Macros = {
  readonly __typename?: 'Macros';
  readonly cursor?: Maybe<Scalars['ID']['output']>;
  readonly items: ReadonlyArray<Macro>;
  readonly next?: Maybe<Scalars['ID']['output']>;
  readonly previous?: Maybe<Scalars['ID']['output']>;
  readonly totalCount: Scalars['Int']['output'];
};

export type ManualLocalizerLinesResponse = {
  readonly __typename?: 'ManualLocalizerLinesResponse';
  readonly showManualLocalizerLines: Scalars['Boolean']['output'];
};

export enum MatchType {
  Mrn = 'MRN',
  PatientAttributes = 'PATIENT_ATTRIBUTES',
  PatientAttributesPartial = 'PATIENT_ATTRIBUTES_PARTIAL',
}

export type MergeFieldInstance = {
  readonly id: Scalars['ID']['input'];
  readonly resolved: Scalars['Boolean']['input'];
  readonly type: Scalars['ID']['input'];
};

export type MergeFieldsSettings = {
  readonly __typename?: 'MergeFieldsSettings';
  readonly ignoreDefaultSelection: Scalars['Boolean']['output'];
  readonly ignoreNavigation: Scalars['Boolean']['output'];
};

export type MergeFieldsSettingsInput = {
  readonly ignoreDefaultSelection: Scalars['Boolean']['input'];
  readonly ignoreNavigation: Scalars['Boolean']['input'];
};

export enum MessageDisplay {
  Alert = 'ALERT',
  Toast = 'TOAST',
}

export enum MessageSeverity {
  Error = 'ERROR',
  Info = 'INFO',
  Success = 'SUCCESS',
}

export type MipPreferences = {
  readonly __typename?: 'MipPreferences';
  readonly defaultSlabThickness: Scalars['Int']['output'];
  readonly slabThicknessInterval: Scalars['Int']['output'];
};

export type MirrorViewerLayoutsPreferences = {
  readonly __typename?: 'MirrorViewerLayoutsPreferences';
  readonly enabled: Scalars['Boolean']['output'];
};

export type Modality = {
  readonly __typename?: 'Modality';
  readonly name: Scalars['String']['output'];
};

export type ModalityDicomTags = {
  readonly __typename?: 'ModalityDicomTags';
  readonly modality: Scalars['String']['output'];
  readonly modalityDicomTags: ReadonlyArray<DicomTagRenderInfo>;
};

export type ModalityLutModule = {
  readonly __typename?: 'ModalityLutModule';
  readonly modalityLutSequence?: Maybe<ReadonlyArray<ModalityLutSequence>>;
  readonly rescaleIntercept?: Maybe<Scalars['String']['output']>;
  readonly rescaleSlope?: Maybe<Scalars['String']['output']>;
  readonly rescaleType?: Maybe<Scalars['String']['output']>;
};

export type ModalityLutSequence = {
  readonly __typename?: 'ModalityLutSequence';
  readonly lutData: ReadonlyArray<Scalars['Int']['output']>;
  readonly lutDescriptor: Scalars['ModalityLutDescriptor']['output'];
  readonly lutExplanation?: Maybe<Scalars['String']['output']>;
  readonly modalityLutType: Scalars['String']['output'];
};

export type Modules = {
  readonly __typename?: 'Modules';
  readonly cine?: Maybe<CineModule>;
  readonly generalImage?: Maybe<GeneralImageModule>;
  readonly generalReference?: Maybe<GeneralReferenceModule>;
  readonly modalityLut?: Maybe<ModalityLutModule>;
  readonly multiFrame?: Maybe<MultiFrameModule>;
  readonly patientStudy?: Maybe<PatientStudyModule>;
  readonly petImage?: Maybe<PetImageModule>;
  readonly regionCalibration?: Maybe<RegionCalibrationModule>;
  readonly voiLut?: Maybe<VoiLutModule>;
};

export enum MouseAction {
  Click = 'CLICK',
  DoubleClick = 'DOUBLE_CLICK',
}

export enum MouseButton {
  Left = 'LEFT',
  Middle = 'MIDDLE',
  Right = 'RIGHT',
}

export type MousePreferences = {
  readonly __typename?: 'MousePreferences';
  readonly left: Scalars['ID']['output'];
  readonly middle: Scalars['ID']['output'];
  readonly right: Scalars['ID']['output'];
};

export type MultiFactorAuth = {
  readonly __typename?: 'MultiFactorAuth';
  readonly enabled: Scalars['Boolean']['output'];
  readonly linked: Scalars['Boolean']['output'];
};

export type MultiFactorAuthResponse = {
  readonly __typename?: 'MultiFactorAuthResponse';
  readonly authenticatorToken?: Maybe<Scalars['String']['output']>;
  readonly multiFactorAuthEnabled: Scalars['Boolean']['output'];
};

export type MultiFactorLinkResponse = {
  readonly __typename?: 'MultiFactorLinkResponse';
  readonly multiFactorAuthLinked: Scalars['Boolean']['output'];
};

export type MultiFrameModule = {
  readonly __typename?: 'MultiFrameModule';
  readonly frameIncrementPointer: ReadonlyArray<Scalars['String']['output']>;
  readonly numberOfFrames: Scalars['Int']['output'];
};

export type Mutation = {
  readonly __typename?: 'Mutation';
  readonly acceptAiAnnotations: AcceptAiAnnotationResponse;
  readonly assignDefaultAutoloadTemplate: AssignDefaultAutoloadTemplatePayload;
  readonly assignExamCount?: Maybe<WorklistItem>;
  readonly assignExamsToUser: AssignExamsToUserResponse;
  readonly authenticate?: Maybe<AuthenticateResponse>;
  readonly authenticateMFA?: Maybe<User>;
  readonly batchClaimWorklistItems: ReadonlyArray<BatchClaimResult>;
  readonly batchUnclaimWorklistItems: ReadonlyArray<BatchUnclaimResult>;
  readonly cancelAddendum: Addendum;
  readonly cancelReport: Report;
  readonly changeWorklistItemCustomStatus?: Maybe<WorklistItem>;
  readonly changeWorklistItemPriority?: Maybe<WorklistItem>;
  readonly changeWorklistItemQualityControlState?: Maybe<WorklistItem>;
  readonly changeWorklistItemStatus?: Maybe<WorklistItem>;
  readonly clearKeyboardPreferences: KeyboardPreferences;
  readonly confirmAccount: ConfirmResponse;
  readonly createAddendum: Addendum;
  readonly createAnnotation?: Maybe<UgcAnnotation>;
  readonly createAutoCorrectEntry: CreateAutoCorrectEntryResponse;
  readonly createHangingProtocol?: Maybe<HangingProtocol>;
  readonly createMacro: Macro;
  readonly createPresentationState?: Maybe<PresentationState>;
  readonly createTemplate: ReportTemplate;
  readonly createVocabEntry: CreateVocabEntryResponse;
  readonly createWorklistItemActivity?: Maybe<Scalars['ID']['output']>;
  readonly createWorklistItemGroup: Scalars['ID']['output'];
  readonly createWorkspacePreset: WorkspacePreset;
  readonly createWwwcToolPreset?: Maybe<WwwcPreset>;
  readonly deleteAddendum: ConfirmResponse;
  readonly deleteAllAnnotationsInStudy?: Maybe<Response>;
  readonly deleteAnnotation?: Maybe<Response>;
  readonly deleteAnnotations: DeleteAnnotationsResponse;
  readonly deleteAutoCorrectEntry: DeleteAutoCorrectEntryResponse;
  readonly deleteHangingProtocol?: Maybe<HangingProtocolDeleted>;
  readonly deleteMacro: DeleteMacroResponse;
  readonly deleteTemplate: DeleteTemplateResponse;
  readonly deleteUserLinkedAiAnnotations?: Maybe<Response>;
  readonly deleteVocabEntry: DeleteVocabEntryResponse;
  readonly deleteWorklistItemGroup?: Maybe<Response>;
  readonly deleteWorkspacePreset: ConfirmDeleteWorkspacePreset;
  readonly deleteWwwcToolPreset: WwcPresetDeleteResponse;
  readonly empty?: Maybe<Scalars['Boolean']['output']>;
  readonly generateImpression?: Maybe<GenerateImpressionResponse>;
  readonly linkAuthenticator: LinkAuthenticatorResponse;
  readonly rejectAiAnnotations: RejectAiAnnotationLog;
  readonly requestPasswordReset: ConfirmResponse;
  readonly resetAndReleaseWorklistItem?: Maybe<WorklistItem>;
  readonly resetPassword: ConfirmResponse;
  readonly rhinoFeedback?: Maybe<RhinoFeedbackResponse>;
  readonly rhinoMedCheck?: Maybe<RhinoMedCheckResponse>;
  readonly rhinoQuery?: Maybe<RhinoQueryResponse>;
  readonly saveHangingProtocol?: Maybe<User>;
  readonly setDefaultThumbnailSize?: Maybe<DefaultThumbnailSizeResponse>;
  readonly setWorkspaceAutoApply: WorkspacePreferences;
  readonly setWwwcDragDirection: SetWwwcDragDirectionResponse;
  readonly submitAddendum: Addendum;
  readonly submitReport: SubmitReportResult;
  readonly toggleAbsoluteScroll: AbsoluteScrollingResponse;
  readonly toggleAnatomicNavigator: AnatomicNavigatorResponse;
  readonly toggleEraseAllToolScope?: Maybe<EraseAllToolScopeResponse>;
  readonly toggleManualLocalizerLines: ManualLocalizerLinesResponse;
  readonly toggleMultiFactorAuth: MultiFactorAuthResponse;
  readonly toggleMultiFactorLink: MultiFactorLinkResponse;
  readonly toggleScreenShare: ScreenShareSettingResponse;
  readonly toggleStackedScrolling: StackedScrollingResponse;
  readonly toggleVirtualMonitorSplit?: Maybe<VirtualMonitorSplitResponse>;
  readonly triggerToolInteraction: ReadonlyArray<ToolInteractionResponseUnion>;
  readonly unauthenticate: Scalars['Boolean']['output'];
  readonly undoChangeWorklistItemCustomStatus?: Maybe<WorklistItem>;
  readonly updateAddendum: Addendum;
  readonly updateAnnotation?: Maybe<UgcAnnotation>;
  readonly updateAutoCorrectEntry: UpdateAutoCorrectEntryResponse;
  readonly updateBarPreferences: BarPreferences;
  readonly updateCustomDicomTags: CustomDicomTagsResponse;
  readonly updateDictaphonePreferences: DictaphonePreferences;
  readonly updateHangingProtocol?: Maybe<HangingProtocol>;
  readonly updateKeyboardPreferences: KeyboardPreferences;
  readonly updateLastViewedWorklists?: Maybe<User>;
  readonly updateMacro: Macro;
  readonly updateMe: User;
  readonly updateMipPreferences: MipPreferences;
  readonly updateMirrorViewerLayoutsPreferences: MirrorViewerLayoutsPreferences;
  readonly updateMousePreferences: MousePreferences;
  readonly updateMouseScrollDirection: ToolPreferences;
  readonly updateMouseSensitivityPreferences: ToolPreferences;
  readonly updatePassword: ConfirmResponse;
  readonly updateReport: Report;
  readonly updateReporterSettings: ReporterSettings;
  readonly updateResetToolOnScrollPreferences: ResetToolOnScrollPreferences;
  readonly updateSeriesSkipSettings: SeriesSkipSettingsResponse;
  readonly updateShowIconLabelPreferences: ShowIconLabelPreferences;
  readonly updateStackedModalities: StackedScrollingModalitiesResponse;
  readonly updateTemplate: ReportTemplate;
  readonly updateWheelPreferences: WheelPreferences;
  readonly updateWorklistItemGroup: ReadonlyArray<Scalars['ID']['output']>;
  readonly updateWorklistSettings: WorklistSettings;
  readonly updateWorkspacePreset: WorkspacePreset;
  readonly updateWwwcToolPreset?: Maybe<WwwcPreset>;
};

export type MutationAcceptAiAnnotationsArgs = {
  payload: ReadonlyArray<AcceptAiAnnotation>;
};

export type MutationAssignDefaultAutoloadTemplateArgs = {
  input: AssignDefaultAutoloadTemplateInput;
};

export type MutationAssignExamCountArgs = {
  input: AssignExamCountInput;
};

export type MutationAssignExamsToUserArgs = {
  userSmid: Scalars['ID']['input'];
  worklistItemSmids: ReadonlyArray<Scalars['ID']['input']>;
};

export type MutationAuthenticateArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type MutationAuthenticateMfaArgs = {
  email: Scalars['String']['input'];
  mfaSessionId: Scalars['String']['input'];
  totp: Scalars['String']['input'];
};

export type MutationBatchClaimWorklistItemsArgs = {
  worklistItems: ReadonlyArray<BatchClaimInput>;
};

export type MutationBatchUnclaimWorklistItemsArgs = {
  isLockClaimAddendumEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  worklistItems: ReadonlyArray<BatchUnclaimInput>;
};

export type MutationCancelAddendumArgs = {
  smid: Scalars['ID']['input'];
};

export type MutationCancelReportArgs = {
  smid: Scalars['ID']['input'];
};

export type MutationChangeWorklistItemCustomStatusArgs = {
  customStatusID: Scalars['ID']['input'];
  smid: Scalars['ID']['input'];
};

export type MutationChangeWorklistItemPriorityArgs = {
  name: Scalars['String']['input'];
  smid: Scalars['ID']['input'];
};

export type MutationChangeWorklistItemQualityControlStateArgs = {
  comment?: InputMaybe<Scalars['String']['input']>;
  qc: Scalars['Boolean']['input'];
  smid: Scalars['ID']['input'];
};

export type MutationChangeWorklistItemStatusArgs = {
  claim?: InputMaybe<Scalars['Boolean']['input']>;
  resetReport?: InputMaybe<Scalars['Boolean']['input']>;
  smid: Scalars['ID']['input'];
};

export type MutationClearKeyboardPreferencesArgs = {
  resetToDefault: Scalars['Boolean']['input'];
};

export type MutationConfirmAccountArgs = {
  password: Scalars['String']['input'];
  token: Scalars['ID']['input'];
  userId: Scalars['String']['input'];
};

export type MutationCreateAddendumArgs = {
  reportSmid: Scalars['ID']['input'];
};

export type MutationCreateAnnotationArgs = {
  aiAnnotationSmid?: InputMaybe<Scalars['ID']['input']>;
  data: Scalars['JSON']['input'];
  pixel_space_representation?: InputMaybe<Scalars['JSON']['input']>;
  seriesSmid: Scalars['ID']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreateAutoCorrectEntryArgs = {
  input: CreateAutoCorrectEntryInput;
};

export type MutationCreateHangingProtocolArgs = {
  payload: HangingProtocolPayload;
};

export type MutationCreateMacroArgs = {
  payload: MacroPayload;
};

export type MutationCreatePresentationStateArgs = {
  annotationSmids: ReadonlyArray<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
};

export type MutationCreateTemplateArgs = {
  payload: IReportTemplatePayload;
};

export type MutationCreateVocabEntryArgs = {
  input: CreateVocabEntryInput;
};

export type MutationCreateWorklistItemActivityArgs = {
  smid: Scalars['ID']['input'];
};

export type MutationCreateWorklistItemGroupArgs = {
  worklistItemSmids: ReadonlyArray<Scalars['ID']['input']>;
};

export type MutationCreateWorkspacePresetArgs = {
  payload: WorkspacePresetPayload;
};

export type MutationCreateWwwcToolPresetArgs = {
  payload: WwwcPresetPayload;
};

export type MutationDeleteAddendumArgs = {
  smid: Scalars['ID']['input'];
};

export type MutationDeleteAllAnnotationsInStudyArgs = {
  studyInstanceUID: Scalars['String']['input'];
};

export type MutationDeleteAnnotationArgs = {
  smid: Scalars['ID']['input'];
};

export type MutationDeleteAnnotationsArgs = {
  smids: ReadonlyArray<Scalars['ID']['input']>;
};

export type MutationDeleteAutoCorrectEntryArgs = {
  input: DeleteAutoCorrectEntryInput;
};

export type MutationDeleteHangingProtocolArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteMacroArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteTemplateArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteUserLinkedAiAnnotationsArgs = {
  payload: ReadonlyArray<Scalars['String']['input']>;
};

export type MutationDeleteVocabEntryArgs = {
  input: DeleteVocabEntryInput;
};

export type MutationDeleteWorklistItemGroupArgs = {
  groupId: Scalars['ID']['input'];
};

export type MutationDeleteWorkspacePresetArgs = {
  smid: Scalars['ID']['input'];
};

export type MutationDeleteWwwcToolPresetArgs = {
  id: Scalars['ID']['input'];
};

export type MutationGenerateImpressionArgs = {
  input: GenerateImpressionInput;
};

export type MutationLinkAuthenticatorArgs = {
  password: Scalars['String']['input'];
  totp: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationRejectAiAnnotationsArgs = {
  ids: ReadonlyArray<Scalars['ID']['input']>;
};

export type MutationRequestPasswordResetArgs = {
  email: Scalars['String']['input'];
};

export type MutationResetAndReleaseWorklistItemArgs = {
  smid: Scalars['ID']['input'];
};

export type MutationResetPasswordArgs = {
  password: Scalars['String']['input'];
  token: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationRhinoFeedbackArgs = {
  input: RhinoFeedbackInput;
};

export type MutationRhinoMedCheckArgs = {
  input: RhinoMedCheckInput;
};

export type MutationRhinoQueryArgs = {
  input: RhinoQueryInput;
};

export type MutationSaveHangingProtocolArgs = {
  hangingProtocolId: Scalars['ID']['input'];
  payload: Scalars['JSON']['input'];
  userId: Scalars['ID']['input'];
};

export type MutationSetDefaultThumbnailSizeArgs = {
  thumbnailSizePx: Scalars['Int']['input'];
};

export type MutationSetWorkspaceAutoApplyArgs = {
  value: Scalars['Boolean']['input'];
};

export type MutationSetWwwcDragDirectionArgs = {
  value: Scalars['Boolean']['input'];
};

export type MutationSubmitAddendumArgs = {
  immediate_submit?: InputMaybe<Scalars['Boolean']['input']>;
  smid: Scalars['ID']['input'];
};

export type MutationSubmitReportArgs = {
  immediate_submit?: InputMaybe<Scalars['Boolean']['input']>;
  smid: Scalars['ID']['input'];
};

export type MutationToggleAbsoluteScrollArgs = {
  absoluteScroll: Scalars['Boolean']['input'];
};

export type MutationToggleAnatomicNavigatorArgs = {
  anatomicNavigatorEnabled: Scalars['Boolean']['input'];
};

export type MutationToggleEraseAllToolScopeArgs = {
  eraseAllToolScope?: InputMaybe<EraseAllToolScope>;
};

export type MutationToggleManualLocalizerLinesArgs = {
  showManualLocalizerLines: Scalars['Boolean']['input'];
};

export type MutationToggleMultiFactorAuthArgs = {
  multiFactorAuthEnabled: Scalars['Boolean']['input'];
  password: Scalars['String']['input'];
  totp?: InputMaybe<Scalars['String']['input']>;
};

export type MutationToggleMultiFactorLinkArgs = {
  multiFactorAuthLinked: Scalars['Boolean']['input'];
};

export type MutationToggleScreenShareArgs = {
  screenShareEnabled: Scalars['Boolean']['input'];
};

export type MutationToggleStackedScrollingArgs = {
  stackInstancesByType: Scalars['Boolean']['input'];
};

export type MutationToggleVirtualMonitorSplitArgs = {
  virtualMonitorSplit?: InputMaybe<VirtualMonitorSplit>;
};

export type MutationTriggerToolInteractionArgs = {
  payload: ToolInteractionPayload;
  toolId: Scalars['ID']['input'];
  toolInteractionId: Scalars['ID']['input'];
  toolInteractionType: ToolInteractionType;
};

export type MutationUndoChangeWorklistItemCustomStatusArgs = {
  customStatusID: Scalars['ID']['input'];
  smid: Scalars['ID']['input'];
};

export type MutationUpdateAddendumArgs = {
  payload: AddendumPayload;
  smid: Scalars['ID']['input'];
};

export type MutationUpdateAnnotationArgs = {
  data: Scalars['JSON']['input'];
  pixel_space_representation?: InputMaybe<Scalars['JSON']['input']>;
  smid: Scalars['ID']['input'];
};

export type MutationUpdateAutoCorrectEntryArgs = {
  input: UpdateAutoCorrectEntryInput;
};

export type MutationUpdateBarPreferencesArgs = {
  ids: ReadonlyArray<Scalars['ID']['input']>;
};

export type MutationUpdateCustomDicomTagsArgs = {
  modality: Scalars['String']['input'];
  modalityDicomTags: ReadonlyArray<DicomTagRenderInfoInput>;
};

export type MutationUpdateDictaphonePreferencesArgs = {
  deviceLabel?: InputMaybe<Scalars['String']['input']>;
  keyMapping?: InputMaybe<ReadonlyArray<IDictaphoneKey>>;
  recordWhilePressed?: InputMaybe<Scalars['Boolean']['input']>;
  showPicklistOptionsInEditor?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationUpdateHangingProtocolArgs = {
  id: Scalars['ID']['input'];
  payload: HangingProtocolPayload;
};

export type MutationUpdateKeyboardPreferencesArgs = {
  id: Scalars['ID']['input'];
  interactionId?: InputMaybe<Scalars['String']['input']>;
  key: Scalars['String']['input'];
};

export type MutationUpdateLastViewedWorklistsArgs = {
  smids: ReadonlyArray<Scalars['ID']['input']>;
};

export type MutationUpdateMacroArgs = {
  id: Scalars['ID']['input'];
  payload: MacroPayload;
};

export type MutationUpdateMeArgs = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  physicianId?: InputMaybe<Scalars['String']['input']>;
  preferredWorkLocation?: InputMaybe<PreferredWorkLocationPayload>;
};

export type MutationUpdateMipPreferencesArgs = {
  id: Scalars['ID']['input'];
  value: Scalars['Int']['input'];
};

export type MutationUpdateMirrorViewerLayoutsPreferencesArgs = {
  enabled: Scalars['Boolean']['input'];
};

export type MutationUpdateMousePreferencesArgs = {
  button: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};

export type MutationUpdateMouseScrollDirectionArgs = {
  invertScroll: Scalars['Boolean']['input'];
};

export type MutationUpdateMouseSensitivityPreferencesArgs = {
  fastScroll?: InputMaybe<Scalars['Float']['input']>;
  fineScroll?: InputMaybe<Scalars['Boolean']['input']>;
  regularScroll?: InputMaybe<Scalars['Float']['input']>;
};

export type MutationUpdatePasswordArgs = {
  newPassword: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
  totp?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateReportArgs = {
  payload: ReportPayload;
  smid: Scalars['ID']['input'];
  worklistItemSmid: Scalars['ID']['input'];
};

export type MutationUpdateReporterSettingsArgs = {
  aiMode?: InputMaybe<AiModeInput>;
  autoFillComparison?: InputMaybe<AutoFillComparisonInput>;
  cursorStyle?: InputMaybe<CursorStyleInput>;
  focusMode?: InputMaybe<FocusModeInput>;
  impressionGenerator?: InputMaybe<ImpressionGeneratorInput>;
  lineIndicator?: InputMaybe<LineIndicatorInput>;
  mergeFieldsSettings?: InputMaybe<MergeFieldsSettingsInput>;
  styles?: InputMaybe<StylesInput>;
  triggerWord?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateResetToolOnScrollPreferencesArgs = {
  enabled: Scalars['Boolean']['input'];
};

export type MutationUpdateSeriesSkipSettingsArgs = {
  seriesSkipSettings?: InputMaybe<SeriesSkipSettingsPayload>;
};

export type MutationUpdateShowIconLabelPreferencesArgs = {
  enabled: Scalars['Boolean']['input'];
};

export type MutationUpdateStackedModalitiesArgs = {
  stackedModalities: ReadonlyArray<Scalars['String']['input']>;
};

export type MutationUpdateTemplateArgs = {
  id: Scalars['ID']['input'];
  payload: IReportTemplatePayload;
};

export type MutationUpdateWheelPreferencesArgs = {
  ids: ReadonlyArray<Scalars['ID']['input']>;
};

export type MutationUpdateWorklistItemGroupArgs = {
  groupId: Scalars['ID']['input'];
  worklistItemSmids: ReadonlyArray<Scalars['ID']['input']>;
};

export type MutationUpdateWorklistSettingsArgs = {
  columns?: InputMaybe<ReadonlyArray<WorklistColumnInput>>;
  columnsBySurface?: InputMaybe<ReadonlyArray<WorklistColumnsBySurfaceInput>>;
  defaultCustomWorklistsSettings?: InputMaybe<DefaultCustomWorklistsSettingInput>;
  favoritedWorklistViews?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  isPatientJacketDocked?: InputMaybe<Scalars['Boolean']['input']>;
  lastViewedCustomWorklists?: InputMaybe<ReadonlyArray<DefaultCustomWorklistInput>>;
  openPatientJacketOnRead?: InputMaybe<Scalars['Boolean']['input']>;
  patientJacketLayout?: InputMaybe<Scalars['JSON']['input']>;
  savedSortingPreferences?: InputMaybe<ReadonlyArray<SavedSortingPreferenceInput>>;
  savedWorklistViews?: InputMaybe<ReadonlyArray<SavedWorklistViewInput>>;
  worklistAutoLoad?: InputMaybe<WorklistAutoLoadInput>;
};

export type MutationUpdateWorkspacePresetArgs = {
  payload: WorkspacePresetPayload;
  smid: Scalars['ID']['input'];
};

export type MutationUpdateWwwcToolPresetArgs = {
  id: Scalars['ID']['input'];
  payload: WwwcPresetPayload;
};

export type NvoqCredentials = {
  readonly __typename?: 'NvoqCredentials';
  readonly password: Scalars['String']['output'];
  readonly username: Scalars['String']['output'];
};

export type Owner = {
  readonly __typename?: 'Owner';
  readonly email: Scalars['String']['output'];
  readonly firstName: Scalars['String']['output'];
  readonly id: Scalars['String']['output'];
  readonly lastName: Scalars['String']['output'];
};

export type PatientOrientation = {
  readonly __typename?: 'PatientOrientation';
  readonly columns: AnatomicalDirection;
  readonly rows: AnatomicalDirection;
};

export type PatientStudyModule = {
  readonly __typename?: 'PatientStudyModule';
  readonly patientWeight?: Maybe<Scalars['Float']['output']>;
};

export type Permission = {
  readonly __typename?: 'Permission';
  readonly component: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
};

export type PetImageModule = {
  readonly __typename?: 'PetImageModule';
  readonly correctedImage: ReadonlyArray<Scalars['String']['output']>;
  readonly decayCorrection: Scalars['String']['output'];
  readonly frameReferenceTime?: Maybe<Scalars['Float']['output']>;
  readonly preCalculatedSuvBwFactor?: Maybe<Scalars['Float']['output']>;
  readonly radiopharmaceuticalInformation: RadioPharmaceuticalInformation;
  readonly scanDateTime: Scalars['DateTime']['output'];
  readonly startDateTime: Scalars['DateTime']['output'];
  readonly suvScaleFactor?: Maybe<Scalars['Int']['output']>;
  readonly units: Scalars['String']['output'];
};

export type PixelUrl = {
  readonly __typename?: 'PixelURL';
  readonly fallbackUrl: Scalars['String']['output'];
  readonly frameSmid: Scalars['ID']['output'];
  readonly signedUrl: SignedUrl;
};

export type PredictTemplateResponse = {
  readonly __typename?: 'PredictTemplateResponse';
  readonly findings: Scalars['TemplatePrediction']['output'];
};

export type PreferredWorkLocation = {
  readonly __typename?: 'PreferredWorkLocation';
  readonly home: Scalars['Boolean']['output'];
  readonly hospital: Scalars['Boolean']['output'];
};

export type PreferredWorkLocationPayload = {
  readonly home: Scalars['Boolean']['input'];
  readonly hospital: Scalars['Boolean']['input'];
};

export enum PreliminaryFlag {
  Final = 'FINAL',
  Preliminary = 'PRELIMINARY',
}

export type PresentationState = {
  readonly __typename?: 'PresentationState';
  readonly annotationSmids: ReadonlyArray<Scalars['ID']['output']>;
  readonly name: Scalars['String']['output'];
};

export type Priority = {
  readonly __typename?: 'Priority';
  readonly dueDuration: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
  readonly value: Scalars['Int']['output'];
};

export type Procedure = {
  readonly __typename?: 'Procedure';
  readonly autoload?: Maybe<Scalars['Boolean']['output']>;
  readonly autoloadTemplate?: Maybe<ReportTemplate>;
  readonly code: Scalars['String']['output'];
  readonly description: Scalars['String']['output'];
  readonly smid: Scalars['ID']['output'];
};

export type ProcedureReference = {
  readonly __typename?: 'ProcedureReference';
  readonly autoload: Scalars['Boolean']['output'];
  readonly code?: Maybe<Scalars['String']['output']>;
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly smid: Scalars['ID']['output'];
};

export type Procedures = {
  readonly __typename?: 'Procedures';
  readonly cursor?: Maybe<Scalars['ID']['output']>;
  readonly items: ReadonlyArray<Procedure>;
  readonly next?: Maybe<Scalars['ID']['output']>;
  readonly previous?: Maybe<Scalars['ID']['output']>;
  readonly totalCount: Scalars['Int']['output'];
};

export type ProvenanceData = {
  readonly __typename?: 'ProvenanceData';
  readonly matchType?: Maybe<MatchType>;
  readonly relevant?: Maybe<Scalars['Boolean']['output']>;
};

export type QualityControlComment = {
  readonly __typename?: 'QualityControlComment';
  readonly authorName: Scalars['String']['output'];
  readonly authorSmid: Scalars['ID']['output'];
  readonly comment: Scalars['String']['output'];
  readonly createdAt: Scalars['Float']['output'];
};

export type Query = {
  readonly __typename?: 'Query';
  readonly aiAnnotations?: Maybe<ReadonlyArray<AiAnnotation>>;
  readonly annotations?: Maybe<ReadonlyArray<Annotation>>;
  readonly annotationsByStudyInstanceUIDs?: Maybe<ReadonlyArray<Annotation>>;
  readonly authenticatorToken: Scalars['String']['output'];
  readonly bodyParts: ReadonlyArray<BodyPart>;
  readonly caseClassification?: Maybe<ReadonlyArray<Maybe<StudyClassificationSummary>>>;
  readonly clinic?: Maybe<Clinic>;
  readonly customAnnotationLogic?: Maybe<CustomAnnotationLogic>;
  readonly customAnnotationsLogic: ReadonlyArray<CustomAnnotationLogic>;
  readonly dicomInstance?: Maybe<DicomInstance>;
  readonly empty?: Maybe<Scalars['Boolean']['output']>;
  readonly generatePriorsSummary: GeneratePriorsSummaryResponse;
  readonly groupableWorklistItems: ReadonlyArray<WorklistItem>;
  readonly groupedWorklistItems: ReadonlyArray<WorklistItem>;
  readonly hangingProtocol?: Maybe<HangingProtocol>;
  readonly hangingProtocols: HangingProtocols;
  readonly headingKeywords: HeadingKeywords;
  readonly macro: Macro;
  readonly macros: Macros;
  readonly matchHangingProtocol?: Maybe<HangingProtocol>;
  readonly matchHangingProtocolByWorklistsAndStudies?: Maybe<HangingProtocol>;
  readonly me: User;
  readonly modalities: ReadonlyArray<Modality>;
  readonly pixelURLs: ReadonlyArray<PixelUrl>;
  readonly predictTemplate: PredictTemplateResponse;
  readonly presentationStates: ReadonlyArray<GspsPresentationStateResponse>;
  readonly priorities: ReadonlyArray<Priority>;
  readonly procedure: Procedure;
  readonly procedures: Procedures;
  readonly proceduresBySmid: ReadonlyArray<Procedure>;
  readonly qualityControlComments: ReadonlyArray<Maybe<QualityControlComment>>;
  readonly report: Report;
  readonly reports: ReadonlyArray<Report>;
  readonly rhinoModels: ReadonlyArray<RhinoModel>;
  readonly rhinoPrompt?: Maybe<RhinoPrompt>;
  readonly rhinoPrompts: ReadonlyArray<RhinoPrompt>;
  readonly seriesBySmid: ReadonlyArray<Series>;
  readonly seriesClassifications?: Maybe<ReadonlyArray<Maybe<SeriesClassification>>>;
  readonly structuredReport: StructuredReport;
  readonly studies: StudyItems;
  readonly studiesBySmid: ReadonlyArray<Study>;
  readonly study: Study;
  readonly studyDescriptions: StudyDescriptions;
  readonly template?: Maybe<ReportTemplate>;
  readonly templates: ReportTemplates;
  readonly toolPreferences: ToolPreferences;
  readonly tools: ReadonlyArray<Tool>;
  readonly user?: Maybe<User>;
  readonly users: Users;
  readonly vocabEntries: ReadonlyArray<VocabEntry>;
  readonly worklistCustomStatuses: ReadonlyArray<WorklistCustomStatus>;
  readonly worklistFilterDropdownItems?: Maybe<WorklistFilterDropdownItems>;
  readonly worklistItem?: Maybe<WorklistItem>;
  readonly worklistItemAuditLogs: ReadonlyArray<WorklistItemAuditLogItem>;
  readonly worklistItemByStudySmid?: Maybe<WorklistItem>;
  readonly worklistItems: WorklistItems;
  readonly worklistItemsClassifications?: Maybe<Scalars['JSON']['output']>;
  readonly worklistView?: Maybe<WorklistViewDetails>;
  readonly worklistViews: ReadonlyArray<WorklistView>;
};

export type QueryAiAnnotationsArgs = {
  seriesSmid?: InputMaybe<Scalars['ID']['input']>;
  studySmids: ReadonlyArray<Scalars['ID']['input']>;
};

export type QueryAnnotationsArgs = {
  query?: InputMaybe<Scalars['JSON']['input']>;
  studyIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  worklistIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
};

export type QueryAnnotationsByStudyInstanceUiDsArgs = {
  studyInstanceUIDs: ReadonlyArray<Scalars['String']['input']>;
  studySmids: ReadonlyArray<Scalars['ID']['input']>;
};

export type QueryAuthenticatorTokenArgs = {
  password: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryCaseClassificationArgs = {
  studySmids: ReadonlyArray<Scalars['ID']['input']>;
};

export type QueryClinicArgs = {
  id: Scalars['ID']['input'];
};

export type QueryCustomAnnotationLogicArgs = {
  toolId: Scalars['ID']['input'];
  type: Scalars['String']['input'];
};

export type QueryCustomAnnotationsLogicArgs = {
  toolId: Scalars['ID']['input'];
};

export type QueryDicomInstanceArgs = {
  instanceSmid: Scalars['ID']['input'];
  seriesSmid: Scalars['ID']['input'];
};

export type QueryGeneratePriorsSummaryArgs = {
  caseSmid: Scalars['ID']['input'];
};

export type QueryGroupableWorklistItemsArgs = {
  mrn: Scalars['String']['input'];
  patientName: Scalars['String']['input'];
};

export type QueryGroupedWorklistItemsArgs = {
  groupId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryHangingProtocolArgs = {
  cases?: InputMaybe<ReadonlyArray<ReadonlyArray<Scalars['ID']['input']>>>;
  id: Scalars['ID']['input'];
};

export type QueryHangingProtocolsArgs = {
  cases?: InputMaybe<ReadonlyArray<ReadonlyArray<Scalars['ID']['input']>>>;
  cursor?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  minimumNumberOfPriors?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<HangingProtocolSortBy>;
  studyDescriptions?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

export type QueryMacroArgs = {
  id: Scalars['ID']['input'];
};

export type QueryMacrosArgs = {
  cursor?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  owners?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  procedures?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type QueryMatchHangingProtocolArgs = {
  cases: ReadonlyArray<ReadonlyArray<Scalars['ID']['input']>>;
};

export type QueryMatchHangingProtocolByWorklistsAndStudiesArgs = {
  includeAutoloadedPriors?: InputMaybe<Scalars['Boolean']['input']>;
  skippedStudyIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  studyIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  worklistIds: ReadonlyArray<Scalars['ID']['input']>;
};

export type QueryPixelUrLsArgs = {
  frames: ReadonlyArray<FrameToLoad>;
  isMultiFrame: Scalars['Boolean']['input'];
  stackSmid: Scalars['ID']['input'];
};

export type QueryPredictTemplateArgs = {
  headers: ReadonlyArray<ReadonlyArray<Scalars['String']['input']>>;
  report: Scalars['String']['input'];
  reportSmid: Scalars['String']['input'];
  storeLog?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryPresentationStatesArgs = {
  includeAutoloadedPriors?: InputMaybe<Scalars['Boolean']['input']>;
  skippedStudyIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  studyIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  worklistIds: ReadonlyArray<Scalars['ID']['input']>;
};

export type QueryProcedureArgs = {
  id: Scalars['ID']['input'];
};

export type QueryProceduresArgs = {
  cursor?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type QueryProceduresBySmidArgs = {
  smids: ReadonlyArray<Scalars['ID']['input']>;
};

export type QueryQualityControlCommentsArgs = {
  smid: Scalars['ID']['input'];
};

export type QueryReportArgs = {
  smid: Scalars['ID']['input'];
};

export type QueryReportsArgs = {
  accessionNumbers: ReadonlyArray<Scalars['ID']['input']>;
};

export type QueryRhinoModelsArgs = {
  modelIdentifier?: InputMaybe<Scalars['String']['input']>;
  vendor?: InputMaybe<Scalars['String']['input']>;
};

export type QueryRhinoPromptArgs = {
  id: Scalars['ID']['input'];
};

export type QueryRhinoPromptsArgs = {
  featureName?: InputMaybe<Scalars['String']['input']>;
  modelIdentifier?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
};

export type QuerySeriesBySmidArgs = {
  smids: ReadonlyArray<Scalars['ID']['input']>;
};

export type QuerySeriesClassificationsArgs = {
  seriesSmids: ReadonlyArray<Scalars['ID']['input']>;
};

export type QueryStructuredReportArgs = {
  frameSmid: Scalars['ID']['input'];
  seriesSmid: Scalars['ID']['input'];
};

export type QueryStudiesArgs = {
  bodyPart?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  cursor?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  matchTypes?: InputMaybe<ReadonlyArray<MatchType>>;
  modality?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  mrn?: InputMaybe<Scalars['String']['input']>;
  overrideShowRelevantPriorsOnly?: InputMaybe<Scalars['Boolean']['input']>;
  searchSmid?: InputMaybe<Scalars['ID']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortColumn?: InputMaybe<SortColumn>;
  sortOrder?: InputMaybe<SortOrder>;
  studyDate?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['DateTime']['input']>>>;
};

export type QueryStudiesBySmidArgs = {
  smids: ReadonlyArray<Scalars['ID']['input']>;
};

export type QueryStudyArgs = {
  smid: Scalars['ID']['input'];
};

export type QueryStudyDescriptionsArgs = {
  cursor?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type QueryTemplateArgs = {
  id: Scalars['ID']['input'];
};

export type QueryTemplatesArgs = {
  autoload?: InputMaybe<Scalars['Boolean']['input']>;
  cursor?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  owners?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  procedures?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  studyDescriptions?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

export type QueryUserArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryUsersArgs = {
  cursor?: InputMaybe<Scalars['ID']['input']>;
  hasMacros?: InputMaybe<Scalars['Boolean']['input']>;
  hasTemplates?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type QueryWorklistFilterDropdownItemsArgs = {
  accessionNumber?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  accessionNumberPartial?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  activeViews?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  bodyPart?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  claimedBy?: InputMaybe<Scalars['ID']['input']>;
  customStatus?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  fieldName?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  hasDraftReport?: InputMaybe<Scalars['Boolean']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  issuer?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  modality?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  mrn?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  mrnPartial?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  patientDob?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['DateTime']['input']>>>;
  patientName?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  patientSex?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  patientType?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  priority?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  priorityFilter?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  referringPhysician?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  searchValue?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  smids?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  status?: InputMaybe<WorklistItemStatus>;
  studyDate?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['DateTime']['input']>>>;
  studyDescription?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  submittedAt?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['DateTime']['input']>>>;
};

export type QueryWorklistItemArgs = {
  id: Scalars['ID']['input'];
};

export type QueryWorklistItemAuditLogsArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryWorklistItemByStudySmidArgs = {
  smid: Scalars['ID']['input'];
};

export type QueryWorklistItemsArgs = {
  accessionNumber?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  accessionNumberPartial?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  activeViews?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  bodyPart?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  claimedBy?: InputMaybe<Scalars['ID']['input']>;
  cursor?: InputMaybe<Scalars['ID']['input']>;
  customStatus?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  filter?: InputMaybe<Scalars['String']['input']>;
  hasDraftReport?: InputMaybe<Scalars['Boolean']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  issuer?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  modality?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  mrn?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  mrnPartial?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  patientDob?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['DateTime']['input']>>>;
  patientName?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  patientSex?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  patientType?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  priority?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  priorityFilter?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  referringPhysician?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  site?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  smids?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
  sortColumn?: InputMaybe<ReadonlyArray<SortColumn>>;
  sortOrder?: InputMaybe<ReadonlyArray<SortOrder>>;
  statsOnTop?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<WorklistItemStatus>;
  studyDate?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['DateTime']['input']>>>;
  studyDescription?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  submittedAt?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['DateTime']['input']>>>;
};

export type QueryWorklistItemsClassificationsArgs = {
  worklistItemSmids: ReadonlyArray<Scalars['ID']['input']>;
};

export type QueryWorklistViewArgs = {
  smid: Scalars['ID']['input'];
};

export type RadioPharmaceuticalInformation = {
  readonly __typename?: 'RadioPharmaceuticalInformation';
  readonly radionuclideHalfLife?: Maybe<Scalars['Float']['output']>;
  readonly radiopharmaceuticalStartDateTime?: Maybe<Scalars['DateTime']['output']>;
  readonly radiopharmaceuticalStartTime?: Maybe<Scalars['String']['output']>;
  readonly radiopharmaceuticalTotalDose?: Maybe<Scalars['Int']['output']>;
};

export type ReferencedImage = {
  readonly referencedFrameNumber?: Maybe<Scalars['Int']['output']>;
  readonly referencedSOPClassUID: Scalars['String']['output'];
  readonly referencedSOPInstanceUID: Scalars['String']['output'];
};

export type Region = {
  readonly __typename?: 'Region';
  readonly dataType: Scalars['String']['output'];
  readonly flags: RegionFlags;
  readonly location: ReadonlyArray<Scalars['Int']['output']>;
  readonly physicalDelta: ReadonlyArray<Scalars['Float']['output']>;
  readonly physicalUnits: ReadonlyArray<Scalars['String']['output']>;
  readonly spatialFormat: Scalars['String']['output'];
};

export type RegionCalibrationModule = {
  readonly __typename?: 'RegionCalibrationModule';
  readonly regions: ReadonlyArray<Region>;
};

export type RegionFlags = {
  readonly __typename?: 'RegionFlags';
  readonly highPriority: Scalars['Boolean']['output'];
  readonly scalingProtection: Scalars['Boolean']['output'];
};

export type RegularScrollPreferences = {
  readonly __typename?: 'RegularScrollPreferences';
  readonly sensitivity: Scalars['Float']['output'];
};

export type RejectAiAnnotationLog = {
  readonly __typename?: 'RejectAiAnnotationLog';
  readonly logIds: ReadonlyArray<Scalars['ID']['output']>;
};

export enum RendererSupport {
  BetaSupported = 'BETA_SUPPORTED',
  Supported = 'SUPPORTED',
  Unsupported = 'UNSUPPORTED',
}

export type Report = {
  readonly __typename?: 'Report';
  readonly addendums: ReadonlyArray<Addendum>;
  readonly content?: Maybe<Scalars['JSON']['output']>;
  readonly created: Scalars['DateTime']['output'];
  readonly format: ReportFormat;
  readonly macros: ReadonlyArray<Macro>;
  readonly owner?: Maybe<Owner>;
  readonly picklists?: Maybe<ReadonlyArray<ReportPicklist>>;
  readonly sections: Scalars['JSON']['output'];
  readonly sectionsLastUpdated?: Maybe<Scalars['DateTime']['output']>;
  readonly sentAt?: Maybe<Scalars['DateTime']['output']>;
  readonly smid: Scalars['ID']['output'];
  readonly status: ReportStatus;
  readonly submittedAt?: Maybe<Scalars['DateTime']['output']>;
  readonly template?: Maybe<ReportTemplate>;
  readonly updated: Scalars['DateTime']['output'];
  readonly version: Scalars['Int']['output'];
  readonly worklistitem?: Maybe<Scalars['String']['output']>;
  readonly workstationID?: Maybe<Scalars['String']['output']>;
};

export enum ReportFormat {
  SironaSlate = 'SironaSlate',
  TextBlob = 'TextBlob',
}

export type ReportPayload = {
  readonly addendums?: InputMaybe<ReadonlyArray<IAddendum>>;
  readonly picklists: ReadonlyArray<IReportPicklist>;
  readonly plugins?: InputMaybe<Scalars['JSON']['input']>;
  readonly sections: Scalars['JSON']['input'];
  readonly templateId?: InputMaybe<Scalars['ID']['input']>;
  readonly version?: InputMaybe<Scalars['Int']['input']>;
  readonly workstationID?: InputMaybe<Scalars['String']['input']>;
};

export type ReportPicklist = {
  readonly __typename?: 'ReportPicklist';
  readonly id: Scalars['ID']['output'];
  readonly options: ReadonlyArray<ReportPicklistOption>;
};

export type ReportPicklistOption = {
  readonly __typename?: 'ReportPicklistOption';
  readonly default?: Maybe<Scalars['Boolean']['output']>;
  readonly id: Scalars['ID']['output'];
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly text: ReadonlyArray<Scalars['JSON']['output']>;
};

export enum ReportStatus {
  Addendum = 'Addendum',
  Final = 'Final',
  Preliminary = 'Preliminary',
}

export type ReportTemplate = {
  readonly __typename?: 'ReportTemplate';
  readonly content: Scalars['JSON']['output'];
  readonly created: Scalars['DateTime']['output'];
  readonly id: Scalars['ID']['output'];
  readonly macros: ReadonlyArray<Macro>;
  readonly name: Scalars['String']['output'];
  readonly owner?: Maybe<Owner>;
  readonly picklists: ReadonlyArray<ReportPicklist>;
  readonly procedureReferences?: Maybe<ReadonlyArray<ProcedureReference>>;
  readonly sections: ReadonlyArray<Scalars['JSON']['output']>;
  readonly triggerPhrase: Scalars['String']['output'];
  readonly updated: Scalars['DateTime']['output'];
};

export type ReportTemplates = {
  readonly __typename?: 'ReportTemplates';
  readonly cursor?: Maybe<Scalars['ID']['output']>;
  readonly items: ReadonlyArray<ReportTemplate>;
  readonly next?: Maybe<Scalars['ID']['output']>;
  readonly previous?: Maybe<Scalars['ID']['output']>;
  readonly totalCount: Scalars['Int']['output'];
};

export type ReporterSettings = {
  readonly __typename?: 'ReporterSettings';
  readonly aiMode: AiModeSettings;
  readonly autoFillComparison: AutoFillComparison;
  readonly cursorStyle: CursorStyle;
  readonly focusMode: FocusMode;
  readonly impressionGenerator: ImpressionGenerator;
  readonly lineIndicator: LineIndicator;
  readonly mergeFieldsSettings: MergeFieldsSettings;
  readonly styles: Styles;
  readonly triggerWord: Scalars['String']['output'];
};

export type ResetToolOnScrollPreferences = {
  readonly __typename?: 'ResetToolOnScrollPreferences';
  readonly enabled: Scalars['Boolean']['output'];
};

export type Response = {
  readonly __typename?: 'Response';
  readonly status?: Maybe<Scalars['Int']['output']>;
};

export type RhinoFeedbackInput = {
  readonly featureName?: InputMaybe<Scalars['String']['input']>;
  readonly modelIdentifier?: InputMaybe<Scalars['String']['input']>;
  readonly reportMetadata: Scalars['JSON']['input'];
  readonly responseId: Scalars['ID']['input'];
  readonly userFeedback: RhinoUserFeedbackInput;
};

export type RhinoFeedbackResponse = {
  readonly __typename?: 'RhinoFeedbackResponse';
  readonly success: Scalars['Boolean']['output'];
};

export type RhinoMedCheckInput = {
  readonly caseId: Scalars['String']['input'];
  readonly reportId: Scalars['String']['input'];
};

export type RhinoMedCheckResponse = {
  readonly __typename?: 'RhinoMedCheckResponse';
  readonly content?: Maybe<Scalars['String']['output']>;
};

export type RhinoModel = {
  readonly __typename?: 'RhinoModel';
  readonly config: RhinoModelConfig;
  readonly id: Scalars['ID']['output'];
  readonly label: Scalars['String']['output'];
  readonly vendor: Scalars['String']['output'];
};

export type RhinoModelConfig = {
  readonly __typename?: 'RhinoModelConfig';
  readonly modelName: Scalars['String']['output'];
  readonly temperature: Scalars['Float']['output'];
};

export type RhinoPrompt = {
  readonly __typename?: 'RhinoPrompt';
  readonly featureName: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
  readonly label: Scalars['String']['output'];
  readonly modelIdentifier: Scalars['String']['output'];
  readonly prompt: Scalars['String']['output'];
  readonly role: Scalars['String']['output'];
};

export type RhinoQueryInput = {
  readonly featureName: Scalars['String']['input'];
  readonly modelIdentifier: Scalars['String']['input'];
  readonly promptId: Scalars['String']['input'];
  readonly queryMessages: ReadonlyArray<RhinoQueryMessageInput>;
  readonly reportId: Scalars['String']['input'];
  readonly reportMetadata: Scalars['JSON']['input'];
};

export type RhinoQueryMessage = {
  readonly __typename?: 'RhinoQueryMessage';
  readonly content: Scalars['String']['output'];
  readonly role: Scalars['String']['output'];
};

export type RhinoQueryMessageInput = {
  readonly content: Scalars['String']['input'];
  readonly role: Scalars['String']['input'];
};

export type RhinoQueryResponse = {
  readonly __typename?: 'RhinoQueryResponse';
  readonly id: Scalars['ID']['output'];
  readonly modelIdentifier: Scalars['String']['output'];
  readonly queryMessages: ReadonlyArray<RhinoQueryMessage>;
  readonly rawApiResponse: RhinoRawApiResponse;
  readonly response: SironaCustomLlmResponseModel;
};

export type RhinoRawApiResponse = {
  readonly __typename?: 'RhinoRawAPIResponse';
  readonly choices: ReadonlyArray<RhinoRawApiResponseChoice>;
  readonly created: Scalars['Int']['output'];
  readonly id: Scalars['String']['output'];
  readonly model: Scalars['String']['output'];
  readonly object: Scalars['String']['output'];
  readonly usage: RhinoRawApiResponseUsage;
};

export type RhinoRawApiResponseChoice = {
  readonly __typename?: 'RhinoRawAPIResponseChoice';
  readonly finishReason: Scalars['String']['output'];
  readonly index: Scalars['Int']['output'];
  readonly message: SironaCustomLlmResponseMessage;
};

export type RhinoRawApiResponseUsage = {
  readonly __typename?: 'RhinoRawAPIResponseUsage';
  readonly completionTokens: Scalars['Int']['output'];
  readonly promptTokens: Scalars['Int']['output'];
  readonly totalTokens: Scalars['Int']['output'];
};

export type RhinoUserFeedbackInput = {
  readonly customFeedbackText: Scalars['String']['input'];
  readonly queryMessages: ReadonlyArray<RhinoQueryMessageInput>;
  readonly rating: Scalars['Int']['input'];
  readonly response?: InputMaybe<SironaCustomLlmResponseModelInput>;
};

export type Role = {
  readonly __typename?: 'Role';
  readonly name: Scalars['String']['output'];
  readonly permissions: ReadonlyArray<Permission>;
  readonly scope: Scope;
};

export type SrGeneralInfo = {
  readonly __typename?: 'SRGeneralInfo';
  readonly completion_flag?: Maybe<CompletionFlag>;
  readonly completion_flag_description?: Maybe<Scalars['String']['output']>;
  readonly date?: Maybe<Scalars['Date']['output']>;
  readonly instance_number?: Maybe<Scalars['Int']['output']>;
  readonly preliminary_flag?: Maybe<PreliminaryFlag>;
  readonly time?: Maybe<Scalars['String']['output']>;
  readonly verification_status?: Maybe<VerificationStatus>;
};

export type SrInstance = {
  readonly __typename?: 'SRInstance';
  readonly number?: Maybe<Scalars['Int']['output']>;
  readonly uid?: Maybe<Scalars['String']['output']>;
};

export type SrObservation = {
  readonly __typename?: 'SRObservation';
  readonly datetime?: Maybe<Scalars['String']['output']>;
  readonly uid?: Maybe<Scalars['String']['output']>;
};

export type SrPatient = {
  readonly __typename?: 'SRPatient';
  readonly age?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly name?: Maybe<Scalars['String']['output']>;
  readonly sex?: Maybe<Scalars['String']['output']>;
};

export type SrsopClass = {
  readonly __typename?: 'SRSOPClass';
  readonly uid?: Maybe<Scalars['String']['output']>;
};

export type SrSeries = {
  readonly __typename?: 'SRSeries';
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly number?: Maybe<Scalars['Int']['output']>;
  readonly uid?: Maybe<Scalars['String']['output']>;
};

export type SrStudy = {
  readonly __typename?: 'SRStudy';
  readonly date?: Maybe<Scalars['Date']['output']>;
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly id?: Maybe<Scalars['String']['output']>;
  readonly time?: Maybe<Scalars['String']['output']>;
  readonly uid?: Maybe<Scalars['String']['output']>;
};

export type SavedSortingPreference = {
  readonly __typename?: 'SavedSortingPreference';
  readonly searchParams: Scalars['String']['output'];
  readonly surface: Scalars['String']['output'];
  readonly worklistViewID?: Maybe<Scalars['ID']['output']>;
};

export type SavedSortingPreferenceInput = {
  readonly searchParams: Scalars['String']['input'];
  readonly surface: Scalars['String']['input'];
  readonly worklistViewID?: InputMaybe<Scalars['ID']['input']>;
};

export type SavedWorklistView = {
  readonly __typename?: 'SavedWorklistView';
  readonly surface: Scalars['String']['output'];
  readonly views: ReadonlyArray<Scalars['ID']['output']>;
};

export type SavedWorklistViewInput = {
  readonly surface: Scalars['String']['input'];
  readonly views: ReadonlyArray<Scalars['ID']['input']>;
};

export type Scope = {
  readonly __typename?: 'Scope';
  readonly name: Scalars['String']['output'];
  readonly object_id: Scalars['String']['output'];
};

export enum ScreenName {
  PatientJacket = 'PATIENT_JACKET',
  Reporter = 'REPORTER',
  Viewer0 = 'VIEWER0',
  Viewer1 = 'VIEWER1',
  Worklist = 'WORKLIST',
}

export type ScreenShareSettingResponse = {
  readonly __typename?: 'ScreenShareSettingResponse';
  readonly screenShareEnabled: Scalars['Boolean']['output'];
};

export type SecuritySettings = {
  readonly __typename?: 'SecuritySettings';
  readonly multiFactorAuth: MultiFactorAuth;
};

export type Series = {
  readonly __typename?: 'Series';
  readonly SOPClassUID?: Maybe<Scalars['String']['output']>;
  readonly classifications?: Maybe<ReadonlyArray<Maybe<SeriesClassification>>>;
  /**
   * 1. Assume the slice orders will be reversed
   * 2. Compare the z axis component of the dicom reported direction with the "fovia direction" calculated between each slice
   * 3. Find the difference between the points, and the distance
   * 4. Divide the difference by the distance to normalize it, and we negate it to get the fovia-Z component of the direction
   */
  readonly colorLevel?: Maybe<Scalars['Float']['output']>;
  readonly columns?: Maybe<Scalars['Int']['output']>;
  readonly description?: Maybe<Scalars['String']['output']>;
  /**
   * NOTE(@ian): I changed this to a String because the ids are not unique across series, so they were overriding each other
   * within the Apollo Cache.
   * The Apollo Client has been fixed to not cache DicomTags individually (instead tied to the series), so we could change
   * this back to an ID, but since it isn't really being used as a unique identifier, I feel like String is more appropriate here.
   */
  readonly dicomTags: ReadonlyArray<DicomTag>;
  readonly frameOfReferenceUID?: Maybe<Scalars['String']['output']>;
  readonly imageRegistrations: ReadonlyArray<ImageRegistration>;
  readonly instances: ReadonlyArray<DicomInstance>;
  readonly is3Dable: Scalars['Boolean']['output'];
  readonly pixelRepresentation?: Maybe<Scalars['Int']['output']>;
  readonly pixelSpacing?: Maybe<ReadonlyArray<Maybe<Scalars['Float']['output']>>>;
  readonly rescaleIntercept?: Maybe<Scalars['Float']['output']>;
  readonly rescaleSlope?: Maybe<Scalars['Float']['output']>;
  readonly rows?: Maybe<Scalars['Int']['output']>;
  readonly seriesDateTimeLocal?: Maybe<Scalars['DateTimeLocal']['output']>;
  readonly seriesInstanceUID: Scalars['String']['output'];
  readonly seriesNumber?: Maybe<Scalars['Int']['output']>;
  readonly sliceThickness?: Maybe<Scalars['Float']['output']>;
  readonly smid: Scalars['ID']['output'];
  readonly study: Study;
  readonly windowLevel?: Maybe<Scalars['Float']['output']>;
};

export type SeriesDicomTagsArgs = {
  ids?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

export type SeriesClassification = {
  readonly __typename?: 'SeriesClassification';
  readonly seriesCriteria?: Maybe<SeriesCriteria>;
  readonly seriesDate?: Maybe<Scalars['String']['output']>;
  readonly seriesDescription?: Maybe<Scalars['String']['output']>;
  readonly seriesNumber?: Maybe<Scalars['Int']['output']>;
  readonly seriesSmid?: Maybe<Scalars['String']['output']>;
  readonly seriesTime?: Maybe<Scalars['String']['output']>;
  readonly studySmid?: Maybe<Scalars['String']['output']>;
};

export type SeriesCriteria = {
  readonly __typename?: 'SeriesCriteria';
  readonly anatomic_focus?: Maybe<ReadonlyArray<Maybe<ClassificationSummary>>>;
  readonly contrast_status?: Maybe<ReadonlyArray<Maybe<ClassificationSummary>>>;
  readonly ct_kernel?: Maybe<ReadonlyArray<Maybe<ClassificationSummary>>>;
  readonly dimensionality?: Maybe<ReadonlyArray<Maybe<ClassificationSummary>>>;
  readonly document_type?: Maybe<ReadonlyArray<Maybe<ClassificationSummary>>>;
  readonly field_of_view?: Maybe<ReadonlyArray<Maybe<ClassificationSummary>>>;
  readonly localizer?: Maybe<ReadonlyArray<Maybe<ClassificationSummary>>>;
  readonly modality?: Maybe<ReadonlyArray<Maybe<ClassificationSummary>>>;
  readonly mr_relative_phase?: Maybe<ReadonlyArray<Maybe<ClassificationSummary>>>;
  readonly patient_position?: Maybe<ReadonlyArray<Maybe<ClassificationSummary>>>;
  readonly post_processing?: Maybe<ReadonlyArray<Maybe<ClassificationSummary>>>;
  readonly tissue_selection?: Maybe<ReadonlyArray<Maybe<ClassificationSummary>>>;
  readonly view_count?: Maybe<ReadonlyArray<Maybe<ClassificationSummary>>>;
  readonly view_orientation?: Maybe<ReadonlyArray<Maybe<ClassificationSummary>>>;
};

export type SeriesSkipSettings = {
  readonly __typename?: 'SeriesSkipSettings';
  readonly amount?: Maybe<Scalars['Int']['output']>;
  readonly keepPreviousWhenAvailable: Scalars['Boolean']['output'];
};

export type SeriesSkipSettingsPayload = {
  readonly amount?: InputMaybe<Scalars['Int']['input']>;
  readonly keepPreviousWhenAvailable: Scalars['Boolean']['input'];
};

export type SeriesSkipSettingsResponse = {
  readonly __typename?: 'SeriesSkipSettingsResponse';
  readonly seriesSkipSettings?: Maybe<SeriesSkipSettings>;
};

export type SetWwwcDragDirectionResponse = {
  readonly __typename?: 'SetWwwcDragDirectionResponse';
  readonly invertDragDirection: Scalars['Boolean']['output'];
};

export type Shortcut = {
  readonly __typename?: 'Shortcut';
  readonly id: Scalars['ID']['output'];
  readonly interactionId?: Maybe<Scalars['String']['output']>;
  readonly key?: Maybe<Scalars['String']['output']>;
};

export type ShowIconLabelPreferences = {
  readonly __typename?: 'ShowIconLabelPreferences';
  readonly enabled: Scalars['Boolean']['output'];
};

export type SignedUrl = {
  readonly __typename?: 'SignedUrl';
  readonly expirationTime: Scalars['Int']['output'];
  readonly url: Scalars['String']['output'];
};

export type SingleLayerStack = BaseStack & {
  readonly __typename?: 'SingleLayerStack';
  readonly frames: ReadonlyArray<Scalars['FrameScalar']['output']>;
  readonly image: Scalars['String']['output'];
  readonly imageParams: ImageParams;
  readonly is3Dable: Scalars['Boolean']['output'];
  readonly series: Series;
  readonly seriesNumber?: Maybe<Scalars['Int']['output']>;
  readonly smid: Scalars['ID']['output'];
  readonly study: Study;
  readonly supportedRenderers: SupportedRenderers;
  readonly type: Scalars['String']['output'];
};

export type SironaCustomLlmResponseMessage = {
  readonly __typename?: 'SironaCustomLLMResponseMessage';
  readonly content: Scalars['String']['output'];
  readonly role: Scalars['String']['output'];
};

export type SironaCustomLlmResponseMessageInput = {
  readonly content: Scalars['String']['input'];
  readonly role: Scalars['String']['input'];
};

export type SironaCustomLlmResponseModel = {
  readonly __typename?: 'SironaCustomLLMResponseModel';
  readonly message: SironaCustomLlmResponseMessage;
  readonly usage: SironaCustomLlmResponseUsage;
};

export type SironaCustomLlmResponseModelInput = {
  readonly message: SironaCustomLlmResponseMessageInput;
  readonly usage: SironaCustomLlmResponseUsageInput;
};

export type SironaCustomLlmResponseUsage = {
  readonly __typename?: 'SironaCustomLLMResponseUsage';
  readonly completionTokens: Scalars['Int']['output'];
  readonly promptTokens: Scalars['Int']['output'];
  readonly totalTokens: Scalars['Int']['output'];
};

export type SironaCustomLlmResponseUsageInput = {
  readonly completionTokens: Scalars['Int']['input'];
  readonly promptTokens: Scalars['Int']['input'];
  readonly totalTokens: Scalars['Int']['input'];
};

export type Slice = {
  readonly active: Scalars['Boolean']['input'];
  readonly index: Scalars['Int']['input'];
  readonly instanceId?: InputMaybe<Scalars['ID']['input']>;
  readonly seriesId?: InputMaybe<Scalars['ID']['input']>;
  readonly studyId?: InputMaybe<Scalars['ID']['input']>;
};

export type SlimUser = {
  readonly __typename?: 'SlimUser';
  readonly firstName: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
  readonly lastName: Scalars['String']['output'];
};

export enum SortColumn {
  AccessionNumber = 'ACCESSION_NUMBER',
  BodyPart = 'BODY_PART',
  Created = 'CREATED',
  CustomStatusName = 'CUSTOM_STATUS_NAME',
  Date = 'DATE',
  FirstVerifiedStatusDatetime = 'FIRST_VERIFIED_STATUS_DATETIME',
  HasDraftReport = 'HAS_DRAFT_REPORT',
  Modality = 'MODALITY',
  Mrn = 'MRN',
  PatientDob = 'PATIENT_DOB',
  PatientName = 'PATIENT_NAME',
  PatientSex = 'PATIENT_SEX',
  PatientType = 'PATIENT_TYPE',
  Priority = 'PRIORITY',
  ReferringPhysician = 'REFERRING_PHYSICIAN',
  Site = 'SITE',
  Status = 'STATUS',
  StudyDatetime = 'STUDY_DATETIME',
  StudyDescription = 'STUDY_DESCRIPTION',
  SubmittedAt = 'SUBMITTED_AT',
  Updated = 'UPDATED',
}

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type SourceImage = ReferencedImage & {
  readonly __typename?: 'SourceImage';
  readonly patientOrientation?: Maybe<PatientOrientation>;
  readonly referencedFrameNumber?: Maybe<Scalars['Int']['output']>;
  readonly referencedSOPClassUID: Scalars['String']['output'];
  readonly referencedSOPInstanceUID: Scalars['String']['output'];
  readonly spatialLocationsPreserved?: Maybe<SpatialLocationsPreserved>;
};

export enum SpatialLocationsPreserved {
  No = 'NO',
  ReorientedOnly = 'REORIENTED_ONLY',
  Yes = 'YES',
}

export type StackedScrollingModalitiesResponse = {
  readonly __typename?: 'StackedScrollingModalitiesResponse';
  readonly stackedModalities: ReadonlyArray<Scalars['String']['output']>;
};

export type StackedScrollingResponse = {
  readonly __typename?: 'StackedScrollingResponse';
  readonly stackInstancesByType: Scalars['Boolean']['output'];
};

export type StructuredReport = {
  readonly __typename?: 'StructuredReport';
  readonly content: Scalars['ContentSequence']['output'];
  readonly general: SrGeneralInfo;
  readonly id: Scalars['ID']['output'];
  readonly instance: SrInstance;
  readonly modalities: Scalars['String']['output'];
  readonly observation: SrObservation;
  readonly patient: SrPatient;
  readonly series: SrSeries;
  readonly sop_class: SrsopClass;
  readonly study: SrStudy;
};

export type Study = {
  readonly __typename?: 'Study';
  readonly accessionNumber?: Maybe<Scalars['String']['output']>;
  readonly bodyPart?: Maybe<Scalars['String']['output']>;
  readonly classifications: Classifications;
  readonly created: Scalars['DateTime']['output'];
  readonly description?: Maybe<Scalars['String']['output']>;
  readonly hl7Order?: Maybe<Scalars['ID']['output']>;
  readonly instanceUID: Scalars['String']['output'];
  readonly laterality?: Maybe<Scalars['String']['output']>;
  readonly modality: Scalars['String']['output'];
  readonly mrn?: Maybe<Scalars['String']['output']>;
  readonly patientAge?: Maybe<Scalars['String']['output']>;
  readonly patientDob?: Maybe<Scalars['Date']['output']>;
  readonly patientName?: Maybe<Scalars['String']['output']>;
  readonly patientSex?: Maybe<Scalars['String']['output']>;
  readonly patientWeight?: Maybe<Scalars['Float']['output']>;
  readonly provenanceData?: Maybe<ProvenanceData>;
  readonly seriesCount: Scalars['Int']['output'];
  readonly seriesList: ReadonlyArray<Series>;
  readonly site?: Maybe<Scalars['String']['output']>;
  readonly smid: Scalars['ID']['output'];
  readonly stackedFrames: ReadonlyArray<FrameStack>;
  readonly studyDate?: Maybe<Scalars['DateTime']['output']>;
  readonly updated: Scalars['DateTime']['output'];
};

export type StudyClassificationsArgs = {
  cursor?: InputMaybe<Scalars['ID']['input']>;
};

export type StudyStackedFramesArgs = {
  groupByType?: FrameGroupByType;
};

export type StudyClassificationSummary = {
  readonly __typename?: 'StudyClassificationSummary';
  readonly study_classification?: Maybe<Scalars['JSON']['output']>;
  readonly study_description?: Maybe<Scalars['String']['output']>;
  readonly study_smid?: Maybe<Scalars['String']['output']>;
};

export type StudyDescription = {
  readonly __typename?: 'StudyDescription';
  readonly autoloadTemplate?: Maybe<ReportTemplate>;
  readonly description: Scalars['String']['output'];
  readonly smid: Scalars['ID']['output'];
};

export type StudyDescriptions = {
  readonly __typename?: 'StudyDescriptions';
  readonly cursor?: Maybe<Scalars['ID']['output']>;
  readonly items: ReadonlyArray<StudyDescription>;
  readonly next?: Maybe<Scalars['ID']['output']>;
  readonly previous?: Maybe<Scalars['ID']['output']>;
  readonly totalCount: Scalars['Int']['output'];
};

export type StudyItems = List & {
  readonly __typename?: 'StudyItems';
  readonly cursor?: Maybe<Scalars['ID']['output']>;
  readonly items: ReadonlyArray<Study>;
  readonly next?: Maybe<Scalars['ID']['output']>;
  readonly previous?: Maybe<Scalars['ID']['output']>;
  readonly totalCount: Scalars['Int']['output'];
};

export type Styles = {
  readonly __typename?: 'Styles';
  readonly bodyStyle: TextStyle;
  readonly headingStyles: ReadonlyArray<HeadingStyle>;
};

export type StylesInput = {
  readonly bodyStyle: TextStyleInput;
  readonly headingStyles: ReadonlyArray<HeadingStyleInput>;
};

export type SubmitReportInvalidInput = {
  readonly __typename?: 'SubmitReportInvalidInput';
  readonly field: SubmitReportInvalidInputField;
  readonly message: Scalars['String']['output'];
};

export enum SubmitReportInvalidInputField {
  Impressions = 'IMPRESSIONS',
}

export type SubmitReportPayload = {
  readonly __typename?: 'SubmitReportPayload';
  readonly report: Report;
};

export type SubmitReportProblem = {
  readonly __typename?: 'SubmitReportProblem';
  readonly invalidInputs: ReadonlyArray<Maybe<SubmitReportInvalidInput>>;
  readonly unknownProblem?: Maybe<Scalars['String']['output']>;
};

export type SubmitReportResult = SubmitReportPayload | SubmitReportProblem;

export type Subscription = {
  readonly __typename?: 'Subscription';
  readonly counter: Counter;
};

export type SupportedRenderers = {
  readonly __typename?: 'SupportedRenderers';
  readonly vtk: RendererSupport;
};

export type TextDecoration = {
  readonly __typename?: 'TextDecoration';
  readonly isBold?: Maybe<Scalars['Boolean']['output']>;
  readonly isItalic?: Maybe<Scalars['Boolean']['output']>;
  readonly isUnderline?: Maybe<Scalars['Boolean']['output']>;
};

export type TextDecorationInput = {
  readonly isBold: Scalars['Boolean']['input'];
  readonly isItalic: Scalars['Boolean']['input'];
  readonly isUnderline: Scalars['Boolean']['input'];
};

export type TextStyle = {
  readonly __typename?: 'TextStyle';
  readonly fontFamily?: Maybe<Scalars['String']['output']>;
  readonly fontSize?: Maybe<Scalars['Int']['output']>;
  readonly textDecoration?: Maybe<TextDecoration>;
  readonly textTransform?: Maybe<TextTransform>;
};

export type TextStyleInput = {
  readonly fontFamily?: InputMaybe<Scalars['String']['input']>;
  readonly fontSize?: InputMaybe<Scalars['Int']['input']>;
  readonly textDecoration?: InputMaybe<TextDecorationInput>;
  readonly textTransform?: InputMaybe<TextTransform>;
};

export enum TextTransform {
  Capitalize = 'capitalize',
  None = 'none',
  Uppercase = 'uppercase',
}

export type Tool = {
  readonly __typename?: 'Tool';
  readonly category: ToolCategory;
  readonly customAnnotationsLogic: ReadonlyArray<CustomAnnotationLogic>;
  readonly description: Scalars['String']['output'];
  readonly icon: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
  readonly interactions: ReadonlyArray<ToolInteractionUnion>;
  readonly name: Scalars['String']['output'];
  readonly payloadCapabilities: ReadonlyArray<ToolPayloadCapability>;
  readonly splitFlag?: Maybe<Scalars['String']['output']>;
};

export type ToolAnnotationInteraction = ToolInteraction & {
  readonly __typename?: 'ToolAnnotationInteraction';
  readonly action: MouseAction;
  readonly button: MouseButton;
  readonly id: Scalars['ID']['output'];
  readonly maxConcurrentRequests?: Maybe<Scalars['Int']['output']>;
  readonly request: ToolInteractionRequest;
  readonly splitFlag?: Maybe<Scalars['String']['output']>;
  readonly targetAnnotationGroupId?: Maybe<Scalars['ID']['output']>;
  readonly targetAnnotationId?: Maybe<Scalars['ID']['output']>;
  readonly type: ToolInteractionType;
};

export enum ToolCategory {
  Annotations = 'ANNOTATIONS',
  ImageAdjustment = 'IMAGE_ADJUSTMENT',
  ImageNavigation = 'IMAGE_NAVIGATION',
  Reporter = 'REPORTER',
  Workflow = 'WORKFLOW',
}

export type ToolContextMenuOptionInteraction = ToolInteraction & {
  readonly __typename?: 'ToolContextMenuOptionInteraction';
  readonly id: Scalars['ID']['output'];
  readonly label: Scalars['String']['output'];
  readonly maxConcurrentRequests?: Maybe<Scalars['Int']['output']>;
  readonly request: ToolInteractionRequest;
  readonly splitFlag?: Maybe<Scalars['String']['output']>;
  readonly type: ToolInteractionType;
};

export type ToolIconButtonClickInteraction = ToolInteraction & {
  readonly __typename?: 'ToolIconButtonClickInteraction';
  readonly id: Scalars['ID']['output'];
  readonly maxConcurrentRequests?: Maybe<Scalars['Int']['output']>;
  readonly request: ToolInteractionRequest;
  readonly splitFlag?: Maybe<Scalars['String']['output']>;
  readonly type: ToolInteractionType;
};

export type ToolInteraction = {
  readonly id: Scalars['ID']['output'];
  readonly maxConcurrentRequests?: Maybe<Scalars['Int']['output']>;
  readonly request: ToolInteractionRequest;
  readonly splitFlag?: Maybe<Scalars['String']['output']>;
  readonly type: ToolInteractionType;
};

export type ToolInteractionAddInteractionResponse = ToolInteractionResponse & {
  readonly __typename?: 'ToolInteractionAddInteractionResponse';
  readonly interactions: ReadonlyArray<ToolInteraction>;
  readonly type: ToolInteractionResponseType;
};

export type ToolInteractionAnnotationData = {
  readonly __typename?: 'ToolInteractionAnnotationData';
  readonly allowedTransformations?: Maybe<AnnotationEditableOptions>;
  readonly customLogic?: Maybe<CustomAnnotationLogic>;
  readonly groupId?: Maybe<Scalars['ID']['output']>;
  readonly label?: Maybe<Scalars['String']['output']>;
  readonly segments: ReadonlyArray<AnnotationSegment>;
  readonly style?: Maybe<AnnotationStyle>;
  readonly type: AnnotationTypes;
};

export type ToolInteractionAnnotationResponse = ToolInteractionResponse & {
  readonly __typename?: 'ToolInteractionAnnotationResponse';
  readonly annotation: ToolInteractionAnnotationData;
  readonly type: ToolInteractionResponseType;
};

export type ToolInteractionInsertTextResponse = ToolInteractionResponse & {
  readonly __typename?: 'ToolInteractionInsertTextResponse';
  readonly content: Scalars['JSON']['output'];
  readonly type: ToolInteractionResponseType;
};

export type ToolInteractionKeyboardShortcutResponse = ToolInteractionResponse & {
  readonly __typename?: 'ToolInteractionKeyboardShortcutResponse';
  readonly type: ToolInteractionResponseType;
};

export type ToolInteractionMessageResponse = ToolInteractionResponse & {
  readonly __typename?: 'ToolInteractionMessageResponse';
  readonly display: MessageDisplay;
  readonly message: Scalars['String']['output'];
  readonly severity: MessageSeverity;
  readonly type: ToolInteractionResponseType;
};

export type ToolInteractionPayload = {
  readonly annotations?: InputMaybe<ReadonlyArray<AnnotationInstance>>;
  readonly coordinates?: InputMaybe<ReadonlyArray<IExtendedCoordinates>>;
  readonly mergeFields?: InputMaybe<ReadonlyArray<MergeFieldInstance>>;
  readonly reporterTextFragment?: InputMaybe<Scalars['String']['input']>;
  readonly viewports?: InputMaybe<ReadonlyArray<ViewportState>>;
  readonly widgets?: InputMaybe<ReadonlyArray<WidgetState>>;
};

export type ToolInteractionRequest = {
  readonly __typename?: 'ToolInteractionRequest';
  readonly method: ToolInteractionRequestMethod;
  readonly url: Scalars['String']['output'];
};

export enum ToolInteractionRequestMethod {
  Get = 'GET',
  Post = 'POST',
}

export type ToolInteractionResponse = {
  readonly type: ToolInteractionResponseType;
};

export enum ToolInteractionResponseType {
  AddInteraction = 'ADD_INTERACTION',
  Annotation = 'ANNOTATION',
  InsertText = 'INSERT_TEXT',
  KeyboardShortcut = 'KEYBOARD_SHORTCUT',
  Message = 'MESSAGE',
  StartAnnotation = 'START_ANNOTATION',
  UpdateToolbarIcon = 'UPDATE_TOOLBAR_ICON',
  UpdateToolActiveState = 'UPDATE_TOOL_ACTIVE_STATE',
  UpdateViewportPresentationState = 'UPDATE_VIEWPORT_PRESENTATION_STATE',
  UpdateViewportSlice = 'UPDATE_VIEWPORT_SLICE',
  Widget = 'WIDGET',
}

export type ToolInteractionResponseUnion =
  | ToolInteractionAddInteractionResponse
  | ToolInteractionAnnotationResponse
  | ToolInteractionInsertTextResponse
  | ToolInteractionKeyboardShortcutResponse
  | ToolInteractionMessageResponse
  | ToolInteractionStartAnnotationResponse
  | ToolInteractionUpdateToolActiveStateResponse
  | ToolInteractionUpdateToolbarButtonIconResponse
  | ToolInteractionUpdateViewportPresentationStateResponse
  | ToolInteractionUpdateViewportSliceResponse
  | ToolInteractionWidgetResponse;

export type ToolInteractionStartAnnotationResponse = ToolInteractionResponse & {
  readonly __typename?: 'ToolInteractionStartAnnotationResponse';
  readonly annotationType: Scalars['String']['output'];
  readonly type: ToolInteractionResponseType;
};

export enum ToolInteractionType {
  Annotation = 'ANNOTATION',
  ContextMenuOption = 'CONTEXT_MENU_OPTION',
  IconButtonClick = 'ICON_BUTTON_CLICK',
  KeyboardShortcut = 'KEYBOARD_SHORTCUT',
  MergeField = 'MERGE_FIELD',
  ViewportClick = 'VIEWPORT_CLICK',
  VoiceCommand = 'VOICE_COMMAND',
}

export type ToolInteractionUnion =
  | ToolAnnotationInteraction
  | ToolContextMenuOptionInteraction
  | ToolIconButtonClickInteraction
  | ToolKeyboardShortcutInteraction
  | ToolMergeFieldInteraction
  | ToolViewportClickInteraction
  | ToolVoiceCommandInteraction;

export type ToolInteractionUpdateToolActiveStateResponse = ToolInteractionResponse & {
  readonly __typename?: 'ToolInteractionUpdateToolActiveStateResponse';
  readonly active: Scalars['Boolean']['output'];
  readonly toolId?: Maybe<Scalars['ID']['output']>;
  readonly type: ToolInteractionResponseType;
};

export type ToolInteractionUpdateToolbarButtonIconResponse = ToolInteractionResponse & {
  readonly __typename?: 'ToolInteractionUpdateToolbarButtonIconResponse';
  readonly icon: Scalars['String']['output'];
  readonly type: ToolInteractionResponseType;
};

export type ToolInteractionUpdateViewportPresentationStateResponse = ToolInteractionResponse & {
  readonly __typename?: 'ToolInteractionUpdateViewportPresentationStateResponse';
  readonly level?: Maybe<Scalars['Float']['output']>;
  readonly rotate?: Maybe<Scalars['Float']['output']>;
  readonly type: ToolInteractionResponseType;
  readonly viewportId?: Maybe<Scalars['ID']['output']>;
  readonly window?: Maybe<Scalars['Float']['output']>;
  readonly zoom?: Maybe<Scalars['Float']['output']>;
};

export type ToolInteractionUpdateViewportSliceResponse = ToolInteractionResponse & {
  readonly __typename?: 'ToolInteractionUpdateViewportSliceResponse';
  readonly slice: Scalars['Int']['output'];
  readonly type: ToolInteractionResponseType;
  readonly viewportId?: Maybe<Scalars['ID']['output']>;
};

export type ToolInteractionWidgetResponse = ToolInteractionResponse & {
  readonly __typename?: 'ToolInteractionWidgetResponse';
  readonly type: ToolInteractionResponseType;
  readonly widget: WidgetConfiguration;
};

export type ToolKeyboardShortcutInteraction = ToolInteraction & {
  readonly __typename?: 'ToolKeyboardShortcutInteraction';
  readonly id: Scalars['ID']['output'];
  readonly keyboardShortcut: Scalars['String']['output'];
  readonly maxConcurrentRequests?: Maybe<Scalars['Int']['output']>;
  readonly request: ToolInteractionRequest;
  readonly splitFlag?: Maybe<Scalars['String']['output']>;
  readonly type: ToolInteractionType;
};

export type ToolMergeFieldInteraction = ToolInteraction & {
  readonly __typename?: 'ToolMergeFieldInteraction';
  readonly id: Scalars['ID']['output'];
  readonly label: Scalars['String']['output'];
  readonly maxConcurrentRequests?: Maybe<Scalars['Int']['output']>;
  readonly request: ToolInteractionRequest;
  readonly splitFlag?: Maybe<Scalars['String']['output']>;
  readonly type: ToolInteractionType;
};

export enum ToolPayloadCapability {
  ReporterMergeFields = 'REPORTER__MERGE_FIELDS',
  ReporterTextFragment = 'REPORTER__TEXT_FRAGMENT',
  ViewerAnnotations = 'VIEWER__ANNOTATIONS',
  ViewerCoordinates = 'VIEWER__COORDINATES',
  ViewerViewports = 'VIEWER__VIEWPORTS',
  ViewerViewportsDisplayConfig = 'VIEWER__VIEWPORTS__DISPLAY_CONFIG',
  ViewerViewportsSlices = 'VIEWER__VIEWPORTS__SLICES',
  Widgets = 'WIDGETS',
}

export type ToolPreferences = {
  readonly __typename?: 'ToolPreferences';
  readonly bar: BarPreferences;
  readonly dictaphone: DictaphonePreferences;
  readonly fastScroll: FastScrollPreferences;
  readonly fineScroll: FineScrollPreferences;
  readonly invertScroll: Scalars['Boolean']['output'];
  readonly keyboard: KeyboardPreferences;
  readonly mip: MipPreferences;
  readonly mirrorViewerLayouts: MirrorViewerLayoutsPreferences;
  readonly mouse: MousePreferences;
  readonly regularScroll: RegularScrollPreferences;
  readonly resetToolOnScroll: ResetToolOnScrollPreferences;
  readonly showIconLabel: ShowIconLabelPreferences;
  readonly wheel: WheelPreferences;
  readonly workspaces: WorkspacePreferences;
  readonly wwwc: WwwcToolPreferences;
};

export type ToolViewportClickInteraction = ToolInteraction & {
  readonly __typename?: 'ToolViewportClickInteraction';
  readonly button: MouseButton;
  readonly cursorIcon: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
  readonly maxConcurrentRequests?: Maybe<Scalars['Int']['output']>;
  readonly request: ToolInteractionRequest;
  readonly splitFlag?: Maybe<Scalars['String']['output']>;
  readonly type: ToolInteractionType;
};

export type ToolVoiceCommandInteraction = ToolInteraction & {
  readonly __typename?: 'ToolVoiceCommandInteraction';
  readonly id: Scalars['ID']['output'];
  readonly maxConcurrentRequests?: Maybe<Scalars['Int']['output']>;
  readonly request: ToolInteractionRequest;
  readonly splitFlag?: Maybe<Scalars['String']['output']>;
  readonly triggerPhrase: Scalars['String']['output'];
  readonly type: ToolInteractionType;
};

export type UgcAnnotation = Annotation & {
  readonly __typename?: 'UgcAnnotation';
  readonly aiAnnotationSmid?: Maybe<Scalars['ID']['output']>;
  readonly created?: Maybe<Scalars['DateTime']['output']>;
  readonly customLogic?: Maybe<CustomAnnotationLogic>;
  readonly data: Scalars['JSON']['output'];
  readonly owner?: Maybe<User>;
  readonly pixel_space_representation?: Maybe<Scalars['JSON']['output']>;
  readonly seriesSmid: Scalars['ID']['output'];
  readonly smid: Scalars['ID']['output'];
  readonly source: AnnotationSource;
  readonly type: AnnotationTypes;
  readonly updated?: Maybe<Scalars['DateTime']['output']>;
};

export type UpdateAutoCorrectEntryInput = {
  readonly key: Scalars['String']['input'];
  readonly value: Scalars['String']['input'];
};

export type UpdateAutoCorrectEntryResponse = {
  readonly __typename?: 'UpdateAutoCorrectEntryResponse';
  readonly entry?: Maybe<AutoCorrectEntry>;
};

export type User = {
  readonly __typename?: 'User';
  readonly anatomicNavigatorEnabled: Scalars['Boolean']['output'];
  readonly autoCorrect: ReadonlyArray<AutoCorrectEntry>;
  readonly clinic?: Maybe<Clinic>;
  readonly defaultAutoloadTemplate?: Maybe<ReportTemplate>;
  readonly email: Scalars['String']['output'];
  readonly firstName: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
  readonly intercomHash?: Maybe<Scalars['String']['output']>;
  readonly lastName: Scalars['String']['output'];
  readonly lastViewedWorklists: ReadonlyArray<WorklistItem>;
  readonly nvoqCredentials: NvoqCredentials;
  readonly physicianId?: Maybe<Scalars['String']['output']>;
  readonly preferredWorkLocation: PreferredWorkLocation;
  readonly reporterSettings: ReporterSettings;
  readonly roles: ReadonlyArray<Role>;
  readonly screenShareEnabled: Scalars['Boolean']['output'];
  readonly securitySettings: SecuritySettings;
  readonly sessionTimeoutInMinutes: Scalars['Int']['output'];
  readonly stackInstancesByType: Scalars['Boolean']['output'];
  readonly stackedModalities: ReadonlyArray<Scalars['String']['output']>;
  readonly toolPreferences: ToolPreferences;
  readonly viewerSettings: ViewerSettings;
  readonly worklistSettings: WorklistSettings;
};

export type UserAnnotation = {
  readonly aiAnnotationSmid: Scalars['ID']['input'];
  readonly data?: InputMaybe<Scalars['JSON']['input']>;
  readonly pixel_space_representation?: InputMaybe<Scalars['JSON']['input']>;
  readonly seriesSmid: Scalars['ID']['input'];
  readonly type: Scalars['String']['input'];
  readonly userId: Scalars['ID']['input'];
};

export type Users = {
  readonly __typename?: 'Users';
  readonly items: ReadonlyArray<LimitedUser>;
  readonly next?: Maybe<Scalars['ID']['output']>;
};

export enum VerificationStatus {
  Unverified = 'UNVERIFIED',
  Verified = 'VERIFIED',
}

export enum ViewType {
  AxialDre = 'AXIAL_DRE',
  CoronalDre = 'CORONAL_DRE',
  Pdf = 'PDF',
  SagittalDre = 'SAGITTAL_DRE',
  ThreeDDre = 'THREE_D_DRE',
  ThreeDMip = 'THREE_D_MIP',
  TwoDDre = 'TWO_D_DRE',
}

export enum ViewerRenderer {
  Vtk = 'VTK',
}

export type ViewerSettings = {
  readonly __typename?: 'ViewerSettings';
  readonly absoluteScroll: Scalars['Boolean']['output'];
  readonly customDicomTags: ReadonlyArray<ModalityDicomTags>;
  readonly defaultThumbnailSizePx?: Maybe<Scalars['Int']['output']>;
  readonly eraseAllToolScope?: Maybe<EraseAllToolScope>;
  readonly seriesSkipSettings?: Maybe<SeriesSkipSettings>;
  readonly showManualLocalizerLines: Scalars['Boolean']['output'];
  readonly virtualMonitorSplit?: Maybe<VirtualMonitorSplit>;
};

export type ViewportConfiguration = {
  readonly __typename?: 'ViewportConfiguration';
  readonly configuration: ViewportConfigurationData;
  readonly id: Scalars['ID']['output'];
};

export type ViewportConfigurationData = {
  readonly __typename?: 'ViewportConfigurationData';
  readonly comparisonIndex?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use `stack` instead */
  readonly series?: Maybe<DehydratedSeries>;
  readonly seriesCriteria?: Maybe<SeriesCriteria>;
  readonly seriesDescriptions: ReadonlyArray<Scalars['String']['output']>;
  readonly seriesId?: Maybe<Scalars['ID']['output']>;
  readonly stack?: Maybe<DehydratedStack>;
  readonly study?: Maybe<DehydratedStudy>;
  readonly viewType?: Maybe<ViewType>;
};

export type ViewportState = {
  readonly active: Scalars['Boolean']['input'];
  readonly description: Scalars['String']['input'];
  readonly displayConfiguration?: InputMaybe<DisplayConfiguration>;
  readonly id: Scalars['ID']['input'];
  readonly slices?: InputMaybe<ReadonlyArray<Slice>>;
};

export enum VirtualMonitorSplit {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
}

export type VirtualMonitorSplitResponse = {
  readonly __typename?: 'VirtualMonitorSplitResponse';
  readonly virtualMonitorSplit?: Maybe<VirtualMonitorSplit>;
};

export type VocabEntry = {
  readonly __typename?: 'VocabEntry';
  readonly id: Scalars['ID']['output'];
  readonly phrase: Scalars['String']['output'];
};

export type VoiLutModule = {
  readonly __typename?: 'VoiLutModule';
  readonly voiLutFunction?: Maybe<Scalars['String']['output']>;
  readonly voiLutSequence?: Maybe<ReadonlyArray<Maybe<VoiLutSequence>>>;
  readonly windowCenter?: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  readonly windowCenterWidthExplanation?: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  readonly windowWidth?: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
};

export type VoiLutSequence = {
  readonly __typename?: 'VoiLutSequence';
  readonly lutData: ReadonlyArray<Scalars['Int']['output']>;
  readonly lutDescriptor: Scalars['VoiLutDescriptor']['output'];
  readonly lutExplanation?: Maybe<Scalars['String']['output']>;
};

export type WheelPreferences = {
  readonly __typename?: 'WheelPreferences';
  readonly toolIds: ReadonlyArray<Scalars['ID']['output']>;
};

export type WidgetConfiguration = {
  readonly __typename?: 'WidgetConfiguration';
  readonly data?: Maybe<Scalars['JSON']['output']>;
  readonly dismissActions?: Maybe<ReadonlyArray<WidgetDismissAction>>;
  readonly id: Scalars['ID']['output'];
  readonly placement?: Maybe<WidgetPlacement>;
  readonly position?: Maybe<ExtendedCoordinates>;
  readonly title: Scalars['String']['output'];
  readonly type: WidgetType;
  readonly urlPath?: Maybe<Scalars['String']['output']>;
};

export enum WidgetDismissAction {
  ClickOutside = 'CLICK_OUTSIDE',
  ToolButtonClick = 'TOOL_BUTTON_CLICK',
  ToolSwitch = 'TOOL_SWITCH',
}

export type WidgetInstance = {
  readonly id: Scalars['ID']['input'];
  readonly open: Scalars['Boolean']['input'];
};

export enum WidgetPlacement {
  Bottom = 'BOTTOM',
  BottomEnd = 'BOTTOM_END',
  BottomStart = 'BOTTOM_START',
  Left = 'LEFT',
  LeftEnd = 'LEFT_END',
  LeftStart = 'LEFT_START',
  Right = 'RIGHT',
  RightEnd = 'RIGHT_END',
  RightStart = 'RIGHT_START',
  Top = 'TOP',
  TopEnd = 'TOP_END',
  TopStart = 'TOP_START',
}

export type WidgetState = {
  readonly id: Scalars['ID']['input'];
  readonly open: Scalars['Boolean']['input'];
  readonly position: Scalars['Vector3']['input'];
  readonly url: Scalars['String']['input'];
};

export enum WidgetType {
  BackgroundScript = 'BACKGROUND_SCRIPT',
  Popover = 'POPOVER',
}

export type Window = {
  readonly __typename?: 'Window';
  readonly height: Scalars['Int']['output'];
  readonly id: Scalars['ID']['output'];
  readonly left: Scalars['Int']['output'];
  readonly screen: ScreenName;
  readonly state: WindowState;
  readonly top: Scalars['Int']['output'];
  readonly type: WindowType;
  readonly width: Scalars['Int']['output'];
};

export type WindowPayload = {
  readonly height: Scalars['Int']['input'];
  readonly id: Scalars['ID']['input'];
  readonly left: Scalars['Int']['input'];
  readonly screen: ScreenName;
  readonly state: WindowState;
  readonly top: Scalars['Int']['input'];
  readonly type: WindowType;
  readonly width: Scalars['Int']['input'];
};

export enum WindowState {
  Fullscreen = 'fullscreen',
  Maximized = 'maximized',
  Minimized = 'minimized',
  Normal = 'normal',
}

export enum WindowType {
  App = 'app',
  Normal = 'normal',
  Popup = 'popup',
}

export type WorklistAutoLoad = {
  readonly __typename?: 'WorklistAutoLoad';
  readonly surface?: Maybe<Scalars['String']['output']>;
};

export type WorklistAutoLoadInput = {
  readonly surface?: InputMaybe<Scalars['String']['input']>;
};

export type WorklistColumn = {
  readonly __typename?: 'WorklistColumn';
  readonly enabled: Scalars['Boolean']['output'];
  readonly id: WorklistColumnId;
  readonly width: Scalars['Int']['output'];
};

export enum WorklistColumnId {
  AccessionNumber = 'accessionNumber',
  Actions = 'actions',
  Age = 'age',
  Alert = 'alert',
  BodyPart = 'bodyPart',
  Created = 'created',
  CustomStatus = 'customStatus',
  DicomInstanceCount = 'dicomInstanceCount',
  FirstVerifiedStatusDate = 'firstVerifiedStatusDate',
  HasDraftReport = 'hasDraftReport',
  MatchType = 'matchType',
  Modality = 'modality',
  Mrn = 'mrn',
  PatientDob = 'patientDob',
  PatientName = 'patientName',
  PatientSex = 'patientSex',
  PatientType = 'patientType',
  Priority = 'priority',
  Radiologist = 'radiologist',
  ReferringPhysician = 'referringPhysician',
  SelectCheckbox = 'selectCheckbox',
  Site = 'site',
  Status = 'status',
  StudyDate = 'studyDate',
  StudyDescription = 'studyDescription',
  SubmittedAt = 'submittedAt',
  Table = 'table',
}

export type WorklistColumnInput = {
  readonly enabled: Scalars['Boolean']['input'];
  readonly id: WorklistColumnId;
  readonly width: Scalars['Int']['input'];
};

export type WorklistColumnsBySurface = {
  readonly __typename?: 'WorklistColumnsBySurface';
  readonly columns: ReadonlyArray<WorklistColumn>;
  readonly surface: Scalars['String']['output'];
};

export type WorklistColumnsBySurfaceInput = {
  readonly columns: ReadonlyArray<WorklistColumnInput>;
  readonly surface: Scalars['String']['input'];
};

export type WorklistCustomStatus = {
  readonly __typename?: 'WorklistCustomStatus';
  readonly name: Scalars['String']['output'];
  readonly smid: Scalars['ID']['output'];
};

export type WorklistFilter = {
  readonly __typename?: 'WorklistFilter';
  readonly key: Scalars['ID']['output'];
  readonly type: WorklistFilterType;
  readonly values: ReadonlyArray<WorklistFilterValue>;
};

export type WorklistFilterDropdownItem = {
  readonly __typename?: 'WorklistFilterDropdownItem';
  readonly count: Scalars['Int']['output'];
  readonly label: Scalars['String']['output'];
  readonly value: Scalars['String']['output'];
};

export type WorklistFilterDropdownItems = {
  readonly __typename?: 'WorklistFilterDropdownItems';
  readonly items?: Maybe<ReadonlyArray<WorklistFilterDropdownItem>>;
  readonly totalCount: Scalars['Int']['output'];
};

export type WorklistFilterInput = {
  readonly key: Scalars['ID']['input'];
  readonly type: WorklistFilterType;
  readonly values: ReadonlyArray<WorklistFilterValueInput>;
};

export enum WorklistFilterType {
  Checkboxes = 'CHECKBOXES',
  Date = 'DATE',
  Daterange = 'DATERANGE',
  Search = 'SEARCH',
}

export type WorklistFilterValue = {
  readonly __typename?: 'WorklistFilterValue';
  readonly key: Scalars['ID']['output'];
  readonly value: Scalars['StringOrBooleanOrDate']['output'];
};

export type WorklistFilterValueInput = {
  readonly key: Scalars['ID']['input'];
  readonly value: Scalars['StringOrBooleanOrDate']['input'];
};

export type WorklistItem = {
  readonly __typename?: 'WorklistItem';
  readonly accessionNumber?: Maybe<Scalars['String']['output']>;
  readonly accessionNumberPartial?: Maybe<Scalars['String']['output']>;
  readonly allAutoloadedPriors?: Maybe<ReadonlyArray<DehydratedStudy>>;
  readonly autoloadedPriors: ReadonlyArray<Study>;
  readonly bodyPart?: Maybe<Scalars['String']['output']>;
  readonly claimExpiration?: Maybe<Scalars['DateTime']['output']>;
  readonly claimedBy?: Maybe<User>;
  readonly created?: Maybe<Scalars['DateTime']['output']>;
  readonly customMergeFields: ReadonlyArray<CustomMergeField>;
  readonly customStatus?: Maybe<WorklistCustomStatus>;
  readonly date?: Maybe<Scalars['DateTime']['output']>;
  readonly dicomInstanceCount?: Maybe<Scalars['String']['output']>;
  readonly dosage?: Maybe<Scalars['String']['output']>;
  readonly examCount?: Maybe<Scalars['Int']['output']>;
  readonly firstVerifiedStatusDate?: Maybe<Scalars['DateTime']['output']>;
  readonly groupId?: Maybe<Scalars['ID']['output']>;
  readonly hasDraftReport: Scalars['Boolean']['output'];
  readonly hasQcComments?: Maybe<Scalars['Boolean']['output']>;
  readonly isActive: Scalars['Boolean']['output'];
  readonly isStat: Scalars['Boolean']['output'];
  readonly modality?: Maybe<Scalars['String']['output']>;
  readonly mrn?: Maybe<Scalars['String']['output']>;
  readonly mrnPartial?: Maybe<Scalars['String']['output']>;
  readonly patientAge?: Maybe<Scalars['String']['output']>;
  readonly patientDob?: Maybe<Scalars['DateTime']['output']>;
  readonly patientName?: Maybe<Scalars['String']['output']>;
  readonly patientSex?: Maybe<Scalars['String']['output']>;
  readonly patientType?: Maybe<Scalars['String']['output']>;
  readonly priority?: Maybe<Scalars['String']['output']>;
  readonly procedure?: Maybe<Procedure>;
  readonly referringPhysician?: Maybe<Scalars['String']['output']>;
  readonly referringPhysicianFirstName?: Maybe<Scalars['String']['output']>;
  readonly referringPhysicianLastName?: Maybe<Scalars['String']['output']>;
  readonly referringPhysicianMiddleName?: Maybe<Scalars['String']['output']>;
  readonly report?: Maybe<Report>;
  readonly site?: Maybe<Scalars['String']['output']>;
  readonly smid: Scalars['ID']['output'];
  readonly status: WorklistItemStatus;
  readonly studies: ReadonlyArray<Study>;
  readonly studyDate?: Maybe<Scalars['DateTime']['output']>;
  readonly studyDescription?: Maybe<Scalars['String']['output']>;
  readonly studyReason?: Maybe<Scalars['String']['output']>;
  readonly submittedAt?: Maybe<Scalars['DateTime']['output']>;
  readonly summary?: Maybe<WorklistItemSummary>;
  readonly techNotes?: Maybe<Scalars['String']['output']>;
  readonly updated?: Maybe<Scalars['DateTime']['output']>;
};

export type WorklistItemAutoloadedPriorsArgs = {
  skippedStudyIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']>>;
};

export type WorklistItemAuditLogItem = {
  readonly __typename?: 'WorklistItemAuditLogItem';
  readonly actor?: Maybe<WorklistItemAuditLogUser>;
  readonly claimedBy?: Maybe<WorklistItemAuditLogUser>;
  readonly createdAt: Scalars['DateTime']['output'];
  readonly customStatus?: Maybe<Scalars['String']['output']>;
  readonly groupId?: Maybe<Scalars['ID']['output']>;
  readonly status?: Maybe<Scalars['String']['output']>;
};

export type WorklistItemAuditLogUser = {
  readonly __typename?: 'WorklistItemAuditLogUser';
  readonly firstName: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
  readonly lastName: Scalars['String']['output'];
};

export enum WorklistItemStatus {
  Archive = 'ARCHIVE',
  InProgress = 'IN_PROGRESS',
  InProgressAddendum = 'IN_PROGRESS_ADDENDUM',
  Pending = 'PENDING',
  Qc = 'QC',
}

export type WorklistItemSummary = {
  readonly __typename?: 'WorklistItemSummary';
  readonly patientHistory: Scalars['String']['output'];
  readonly relevantFindings: Scalars['JSON']['output'];
};

export type WorklistItems = List & {
  readonly __typename?: 'WorklistItems';
  readonly cursor?: Maybe<Scalars['ID']['output']>;
  readonly filteredCount: Scalars['Int']['output'];
  readonly items: ReadonlyArray<WorklistItem>;
  readonly next?: Maybe<Scalars['ID']['output']>;
  readonly previous?: Maybe<Scalars['ID']['output']>;
  readonly totalCount: Scalars['Int']['output'];
};

export type WorklistSettings = {
  readonly __typename?: 'WorklistSettings';
  readonly columns: ReadonlyArray<WorklistColumn>;
  readonly columnsBySurface?: Maybe<ReadonlyArray<WorklistColumnsBySurface>>;
  readonly defaultCustomWorklistsSettings?: Maybe<DefaultCustomWorklistsSetting>;
  readonly favoritedWorklistViews?: Maybe<ReadonlyArray<Scalars['ID']['output']>>;
  readonly isPatientJacketDocked: Scalars['Boolean']['output'];
  readonly lastViewedCustomWorklists?: Maybe<ReadonlyArray<Maybe<DefaultCustomWorklist>>>;
  readonly openPatientJacketOnRead: Scalars['Boolean']['output'];
  readonly patientJacketLayout?: Maybe<Scalars['JSON']['output']>;
  readonly savedSortingPreferences?: Maybe<ReadonlyArray<SavedSortingPreference>>;
  readonly savedWorklistViews?: Maybe<ReadonlyArray<SavedWorklistView>>;
  readonly settingsVersion?: Maybe<Scalars['String']['output']>;
  readonly worklistAutoLoad?: Maybe<WorklistAutoLoad>;
};

export enum WorklistStatusWithActiveLockEnum {
  AdndmCountdown = 'ADNDM_Countdown',
  AdndmLocked = 'ADNDM_Locked',
  AdndmQueued = 'ADNDM_Queued',
  Completed = 'Completed',
  Countdown = 'Countdown',
  ExternallyRead = 'Externally_Read',
  Locked = 'Locked',
  Qc = 'QC',
  Queued = 'Queued',
  Verified = 'Verified',
}

export type WorklistStudies = {
  readonly __typename?: 'WorklistStudies';
  readonly cursor?: Maybe<Scalars['ID']['output']>;
  readonly items: ReadonlyArray<WorklistStudy>;
  readonly totalCount: Scalars['Int']['output'];
};

export type WorklistStudy = {
  readonly __typename?: 'WorklistStudy';
  readonly primary: Scalars['Boolean']['output'];
  readonly study: Study;
};

export type WorklistView = {
  readonly __typename?: 'WorklistView';
  readonly name: Scalars['String']['output'];
  readonly smid: Scalars['ID']['output'];
};

export type WorklistViewDetails = {
  readonly __typename?: 'WorklistViewDetails';
  readonly filters: ReadonlyArray<WorklistViewFilters>;
  readonly name: Scalars['String']['output'];
  readonly smid: Scalars['ID']['output'];
};

export type WorklistViewFilter = {
  readonly __typename?: 'WorklistViewFilter';
  readonly label: Scalars['String']['output'];
  readonly value: Scalars['String']['output'];
};

export type WorklistViewFilters = {
  readonly __typename?: 'WorklistViewFilters';
  readonly name: Scalars['String']['output'];
  readonly options: ReadonlyArray<WorklistViewFilter>;
};

export type WorkspacePreferences = {
  readonly __typename?: 'WorkspacePreferences';
  readonly autoApply: Scalars['Boolean']['output'];
  readonly presets: ReadonlyArray<WorkspacePreset>;
};

export type WorkspacePreset = {
  readonly __typename?: 'WorkspacePreset';
  readonly createdAt: Scalars['DateTime']['output'];
  readonly instanceId: Scalars['ID']['output'];
  readonly name: Scalars['String']['output'];
  readonly smid: Scalars['ID']['output'];
  readonly updatedAt: Scalars['DateTime']['output'];
  readonly windows: ReadonlyArray<Window>;
};

export type WorkspacePresetPayload = {
  readonly instanceId: Scalars['ID']['input'];
  readonly name: Scalars['String']['input'];
  readonly windows: ReadonlyArray<WindowPayload>;
};

export type WorldCoordinates = {
  readonly __typename?: 'WorldCoordinates';
  readonly worldSpace: Scalars['Vector3']['output'];
};

export type WwcPresetDeleteResponse = {
  readonly __typename?: 'WwcPresetDeleteResponse';
  readonly id: Scalars['ID']['output'];
};

export type WwwcPreset = {
  readonly __typename?: 'WwwcPreset';
  readonly id: Scalars['ID']['output'];
  readonly level: Scalars['Int']['output'];
  readonly name: Scalars['String']['output'];
  readonly shortcut: Scalars['String']['output'];
  readonly width: Scalars['Int']['output'];
};

export type WwwcPresetPayload = {
  readonly level: Scalars['Int']['input'];
  readonly name: Scalars['String']['input'];
  readonly shortcut: Scalars['String']['input'];
  readonly width: Scalars['Int']['input'];
};

export type WwwcToolPreferences = {
  readonly __typename?: 'WwwcToolPreferences';
  readonly invertDragDirection: Scalars['Boolean']['output'];
  readonly presets: ReadonlyArray<WwwcPreset>;
};

export type PatientFieldsFragment = {
  readonly __typename?: 'Study';
  readonly patientSex?: string | null;
  readonly patientAge?: string | null;
  readonly patientName?: string | null;
  readonly patientDob?: Date | null;
  readonly modality: string;
  readonly bodyPart?: string | null;
  readonly mrn?: string | null;
  readonly studyDate?: Date | null;
  readonly laterality?: string | null;
};

export type AddendumFieldsFragment = {
  readonly __typename?: 'Addendum';
  readonly created: Date;
  readonly updated: Date;
  readonly submittedAt?: Date | null;
  readonly sentAt?: Date | null;
  readonly smid: string;
  readonly content:
    | { readonly __typename?: 'AddendumSironaSlate'; readonly sironaSlate: ReadonlyArray<JSON> }
    | { readonly __typename?: 'AddendumTextBlob'; readonly textBlob: string };
  readonly owner?: {
    readonly __typename?: 'Owner';
    readonly id: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly email: string;
  } | null;
};

export type ModuleDataFragment = {
  readonly __typename?: 'Modules';
  readonly cine?: {
    readonly __typename?: 'CineModule';
    readonly frameTimeVector: ReadonlyArray<number>;
    readonly preferredPlaybackSequencing: number;
  } | null;
  readonly multiFrame?: {
    readonly __typename?: 'MultiFrameModule';
    readonly numberOfFrames: number;
    readonly frameIncrementPointer: ReadonlyArray<string>;
  } | null;
  readonly regionCalibration?: {
    readonly __typename?: 'RegionCalibrationModule';
    readonly regions: ReadonlyArray<{
      readonly __typename?: 'Region';
      readonly spatialFormat: string;
      readonly dataType: string;
      readonly location: ReadonlyArray<number>;
      readonly physicalDelta: ReadonlyArray<number>;
      readonly physicalUnits: ReadonlyArray<string>;
      readonly flags: {
        readonly __typename?: 'RegionFlags';
        readonly highPriority: boolean;
        readonly scalingProtection: boolean;
      };
    }>;
  } | null;
  readonly modalityLut?: {
    readonly __typename?: 'ModalityLutModule';
    readonly rescaleIntercept?: string | null;
    readonly rescaleSlope?: string | null;
    readonly rescaleType?: string | null;
    readonly modalityLutSequence?: ReadonlyArray<{
      readonly __typename?: 'ModalityLutSequence';
      readonly lutDescriptor: [number, number, number];
      readonly modalityLutType: string;
      readonly lutData: ReadonlyArray<number>;
    }> | null;
  } | null;
  readonly patientStudy?: {
    readonly __typename?: 'PatientStudyModule';
    readonly patientWeight?: number | null;
  } | null;
  readonly petImage?: {
    readonly __typename?: 'PetImageModule';
    readonly correctedImage: ReadonlyArray<string>;
    readonly decayCorrection: string;
    readonly units: string;
    readonly startDateTime: Date;
    readonly scanDateTime: Date;
    readonly frameReferenceTime?: number | null;
    readonly suvScaleFactor?: number | null;
    readonly preCalculatedSuvBwFactor?: number | null;
    readonly radiopharmaceuticalInformation: {
      readonly __typename?: 'RadioPharmaceuticalInformation';
      readonly radiopharmaceuticalTotalDose?: number | null;
      readonly radionuclideHalfLife?: number | null;
    };
  } | null;
};

export type FrameDataFragment = {
  readonly __typename?: 'Frame';
  readonly smid: string;
  readonly sopInstanceUID: string;
  readonly sopClassUID: string;
  readonly colorChannels: number;
  readonly needsInversion: boolean;
  readonly imageNumber: number;
  readonly origin: [number, number, number];
  readonly spacing: [number, number, number];
  readonly size: ReadonlyArray<number>;
  readonly hasPixels: boolean;
  readonly validSpacing: boolean;
  readonly series: { readonly __typename?: 'Series'; readonly smid: string };
  readonly stack:
    | { readonly __typename?: 'LayeredStack'; readonly smid: string }
    | { readonly __typename?: 'SingleLayerStack'; readonly smid: string };
  readonly direction: {
    readonly __typename?: 'FrameDirection';
    readonly rows: number;
    readonly columns: number;
    readonly data: [number, number, number, number, number, number, number, number, number];
    readonly validDirection: boolean;
  };
  readonly modules: {
    readonly __typename?: 'Modules';
    readonly cine?: {
      readonly __typename?: 'CineModule';
      readonly frameTimeVector: ReadonlyArray<number>;
      readonly preferredPlaybackSequencing: number;
    } | null;
    readonly multiFrame?: {
      readonly __typename?: 'MultiFrameModule';
      readonly numberOfFrames: number;
      readonly frameIncrementPointer: ReadonlyArray<string>;
    } | null;
    readonly regionCalibration?: {
      readonly __typename?: 'RegionCalibrationModule';
      readonly regions: ReadonlyArray<{
        readonly __typename?: 'Region';
        readonly spatialFormat: string;
        readonly dataType: string;
        readonly location: ReadonlyArray<number>;
        readonly physicalDelta: ReadonlyArray<number>;
        readonly physicalUnits: ReadonlyArray<string>;
        readonly flags: {
          readonly __typename?: 'RegionFlags';
          readonly highPriority: boolean;
          readonly scalingProtection: boolean;
        };
      }>;
    } | null;
    readonly modalityLut?: {
      readonly __typename?: 'ModalityLutModule';
      readonly rescaleIntercept?: string | null;
      readonly rescaleSlope?: string | null;
      readonly rescaleType?: string | null;
      readonly modalityLutSequence?: ReadonlyArray<{
        readonly __typename?: 'ModalityLutSequence';
        readonly lutDescriptor: [number, number, number];
        readonly modalityLutType: string;
        readonly lutData: ReadonlyArray<number>;
      }> | null;
    } | null;
    readonly patientStudy?: {
      readonly __typename?: 'PatientStudyModule';
      readonly patientWeight?: number | null;
    } | null;
    readonly petImage?: {
      readonly __typename?: 'PetImageModule';
      readonly correctedImage: ReadonlyArray<string>;
      readonly decayCorrection: string;
      readonly units: string;
      readonly startDateTime: Date;
      readonly scanDateTime: Date;
      readonly frameReferenceTime?: number | null;
      readonly suvScaleFactor?: number | null;
      readonly preCalculatedSuvBwFactor?: number | null;
      readonly radiopharmaceuticalInformation: {
        readonly __typename?: 'RadioPharmaceuticalInformation';
        readonly radiopharmaceuticalTotalDose?: number | null;
        readonly radionuclideHalfLife?: number | null;
      };
    } | null;
  };
};

export type SeriesDataFragment = {
  readonly __typename?: 'Series';
  readonly smid: string;
  readonly description?: string | null;
  readonly seriesInstanceUID: string;
  readonly seriesDateTimeLocal?: DateTimeLocalScalarConfig | null;
  readonly frameOfReferenceUID?: string | null;
  readonly seriesNumber?: number | null;
  readonly is3Dable: boolean;
  readonly study: { readonly __typename?: 'Study'; readonly smid: string };
  readonly classifications?: ReadonlyArray<{
    readonly __typename?: 'SeriesClassification';
    readonly seriesDescription?: string | null;
    readonly studySmid?: string | null;
    readonly seriesSmid?: string | null;
    readonly seriesNumber?: number | null;
    readonly seriesDate?: string | null;
    readonly seriesTime?: string | null;
    readonly seriesCriteria?: {
      readonly __typename?: 'SeriesCriteria';
      readonly anatomic_focus?: ReadonlyArray<{
        readonly __typename?: 'ClassificationSummary';
        readonly display_name?: string | null;
        readonly ontology_id?: string | null;
      } | null> | null;
    } | null;
  } | null> | null;
  readonly imageRegistrations: ReadonlyArray<{
    readonly __typename?: 'ImageRegistration';
    readonly smid: string;
    readonly created: Date;
    readonly codeVersion: string;
    readonly modelName: string;
    readonly modelVersion: string;
    readonly seriesFixed: string;
    readonly seriesMoving: string;
    readonly dataType: string;
    readonly fixedFrameOfReferenceUid: string;
    readonly movingFrameOfReferenceUid: string;
    readonly transformMatrix: [
      [number, number, number, number],
      [number, number, number, number],
      [number, number, number, number],
      [number, number, number, number],
    ];
  }>;
};

export type StudyDataWithoutSeriesFragment = {
  readonly __typename?: 'Study';
  readonly smid: string;
  readonly created: Date;
  readonly updated: Date;
  readonly instanceUID: string;
  readonly description?: string | null;
  readonly hl7Order?: string | null;
  readonly patientSex?: string | null;
  readonly patientAge?: string | null;
  readonly patientName?: string | null;
  readonly patientDob?: Date | null;
  readonly modality: string;
  readonly bodyPart?: string | null;
  readonly mrn?: string | null;
  readonly studyDate?: Date | null;
  readonly laterality?: string | null;
  readonly provenanceData?: {
    readonly __typename?: 'ProvenanceData';
    readonly matchType?: MatchType | null;
    readonly relevant?: boolean | null;
  } | null;
};

export type StudyDataWithoutSeriesAndProvenanceDataFragment = {
  readonly __typename?: 'Study';
  readonly smid: string;
  readonly created: Date;
  readonly updated: Date;
  readonly instanceUID: string;
  readonly description?: string | null;
  readonly hl7Order?: string | null;
  readonly patientSex?: string | null;
  readonly patientAge?: string | null;
  readonly patientName?: string | null;
  readonly patientDob?: Date | null;
  readonly modality: string;
  readonly bodyPart?: string | null;
  readonly mrn?: string | null;
  readonly studyDate?: Date | null;
  readonly laterality?: string | null;
};

export type StackedFrameFragment = {
  readonly __typename?: 'SingleLayerStack';
  readonly smid: string;
  readonly type: string;
  readonly image: string;
  readonly is3Dable: boolean;
  readonly frames: ReadonlyArray<FrameDataFragment>;
  readonly series: { readonly __typename?: 'Series'; readonly smid: string };
  readonly study: { readonly __typename?: 'Study'; readonly smid: string };
  readonly supportedRenderers: {
    readonly __typename?: 'SupportedRenderers';
    readonly vtk: RendererSupport;
  };
  readonly imageParams: {
    readonly __typename?: 'ImageParams';
    readonly origin: [number, number, number];
    readonly spacing: [number, number, number];
    readonly dimensions: [number, number, number];
    readonly extent: [number, number, number, number, number, number];
    readonly direction: [number, number, number, number, number, number, number, number, number];
    readonly isMultiAxes: boolean;
    readonly colorWindow?: number | null;
    readonly colorLevel?: number | null;
  };
};

export type LayeredStackFragment = {
  readonly __typename?: 'LayeredStack';
  readonly smid: string;
  readonly type: string;
  readonly study: { readonly __typename?: 'Study'; readonly smid: string };
  readonly stackLayers: ReadonlyArray<{
    readonly __typename?: 'Layer';
    readonly stackSmid: string;
    readonly index: number;
  }>;
};

export type StudyDataFragment = {
  readonly __typename?: 'Study';
  readonly smid: string;
  readonly created: Date;
  readonly updated: Date;
  readonly instanceUID: string;
  readonly description?: string | null;
  readonly hl7Order?: string | null;
  readonly accessionNumber?: string | null;
  readonly seriesCount: number;
  readonly patientSex?: string | null;
  readonly patientAge?: string | null;
  readonly patientName?: string | null;
  readonly patientDob?: Date | null;
  readonly modality: string;
  readonly bodyPart?: string | null;
  readonly mrn?: string | null;
  readonly studyDate?: Date | null;
  readonly laterality?: string | null;
  readonly seriesList: ReadonlyArray<{
    readonly __typename?: 'Series';
    readonly smid: string;
    readonly description?: string | null;
    readonly seriesInstanceUID: string;
    readonly seriesDateTimeLocal?: DateTimeLocalScalarConfig | null;
    readonly frameOfReferenceUID?: string | null;
    readonly seriesNumber?: number | null;
    readonly is3Dable: boolean;
    readonly study: { readonly __typename?: 'Study'; readonly smid: string };
    readonly classifications?: ReadonlyArray<{
      readonly __typename?: 'SeriesClassification';
      readonly seriesDescription?: string | null;
      readonly studySmid?: string | null;
      readonly seriesSmid?: string | null;
      readonly seriesNumber?: number | null;
      readonly seriesDate?: string | null;
      readonly seriesTime?: string | null;
      readonly seriesCriteria?: {
        readonly __typename?: 'SeriesCriteria';
        readonly anatomic_focus?: ReadonlyArray<{
          readonly __typename?: 'ClassificationSummary';
          readonly display_name?: string | null;
          readonly ontology_id?: string | null;
        } | null> | null;
      } | null;
    } | null> | null;
    readonly imageRegistrations: ReadonlyArray<{
      readonly __typename?: 'ImageRegistration';
      readonly smid: string;
      readonly created: Date;
      readonly codeVersion: string;
      readonly modelName: string;
      readonly modelVersion: string;
      readonly seriesFixed: string;
      readonly seriesMoving: string;
      readonly dataType: string;
      readonly fixedFrameOfReferenceUid: string;
      readonly movingFrameOfReferenceUid: string;
      readonly transformMatrix: [
        [number, number, number, number],
        [number, number, number, number],
        [number, number, number, number],
        [number, number, number, number],
      ];
    }>;
  }>;
  readonly stackedFrames: ReadonlyArray<
    | {
        readonly __typename?: 'LayeredStack';
        readonly smid: string;
        readonly type: string;
        readonly study: { readonly __typename?: 'Study'; readonly smid: string };
        readonly stackLayers: ReadonlyArray<{
          readonly __typename?: 'Layer';
          readonly stackSmid: string;
          readonly index: number;
        }>;
      }
    | {
        readonly __typename?: 'SingleLayerStack';
        readonly smid: string;
        readonly type: string;
        readonly image: string;
        readonly is3Dable: boolean;
        readonly frames: ReadonlyArray<FrameDataFragment>;
        readonly series: { readonly __typename?: 'Series'; readonly smid: string };
        readonly study: { readonly __typename?: 'Study'; readonly smid: string };
        readonly supportedRenderers: {
          readonly __typename?: 'SupportedRenderers';
          readonly vtk: RendererSupport;
        };
        readonly imageParams: {
          readonly __typename?: 'ImageParams';
          readonly origin: [number, number, number];
          readonly spacing: [number, number, number];
          readonly dimensions: [number, number, number];
          readonly extent: [number, number, number, number, number, number];
          readonly direction: [
            number,
            number,
            number,
            number,
            number,
            number,
            number,
            number,
            number,
          ];
          readonly isMultiAxes: boolean;
          readonly colorWindow?: number | null;
          readonly colorLevel?: number | null;
        };
      }
  >;
};

export type TableWorklistItemFieldsFragment = {
  readonly __typename?: 'WorklistItem';
  readonly smid: string;
  readonly created?: Date | null;
  readonly updated?: Date | null;
  readonly studyDate?: Date | null;
  readonly submittedAt?: Date | null;
  readonly patientName?: string | null;
  readonly patientAge?: string | null;
  readonly patientSex?: string | null;
  readonly patientDob?: Date | null;
  readonly patientType?: string | null;
  readonly accessionNumber?: string | null;
  readonly hasQcComments?: boolean | null;
  readonly bodyPart?: string | null;
  readonly modality?: string | null;
  readonly referringPhysician?: string | null;
  readonly referringPhysicianLastName?: string | null;
  readonly referringPhysicianFirstName?: string | null;
  readonly referringPhysicianMiddleName?: string | null;
  readonly site?: string | null;
  readonly status: WorklistItemStatus;
  readonly isActive: boolean;
  readonly mrn?: string | null;
  readonly priority?: string | null;
  readonly studyDescription?: string | null;
  readonly dosage?: string | null;
  readonly studyReason?: string | null;
  readonly techNotes?: string | null;
  readonly isStat: boolean;
  readonly groupId?: string | null;
  readonly hasDraftReport: boolean;
  readonly dicomInstanceCount?: string | null;
  readonly firstVerifiedStatusDate?: Date | null;
  readonly studies: ReadonlyArray<{ readonly __typename?: 'Study'; readonly smid: string }>;
  readonly claimedBy?: {
    readonly __typename?: 'User';
    readonly id: string;
    readonly firstName: string;
    readonly lastName: string;
  } | null;
  readonly report?: {
    readonly __typename?: 'Report';
    readonly smid: string;
    readonly submittedAt?: Date | null;
    readonly sentAt?: Date | null;
    readonly owner?: {
      readonly __typename?: 'Owner';
      readonly firstName: string;
      readonly lastName: string;
    } | null;
    readonly addendums: ReadonlyArray<{
      readonly __typename?: 'Addendum';
      readonly created: Date;
      readonly updated: Date;
      readonly submittedAt?: Date | null;
      readonly sentAt?: Date | null;
      readonly smid: string;
      readonly content:
        | { readonly __typename?: 'AddendumSironaSlate'; readonly sironaSlate: ReadonlyArray<JSON> }
        | { readonly __typename?: 'AddendumTextBlob'; readonly textBlob: string };
      readonly owner?: {
        readonly __typename?: 'Owner';
        readonly id: string;
        readonly firstName: string;
        readonly lastName: string;
        readonly email: string;
      } | null;
    }>;
  } | null;
  readonly customStatus?: {
    readonly __typename?: 'WorklistCustomStatus';
    readonly smid: string;
    readonly name: string;
  } | null;
};

export type WorklistItemAnalyticsFieldsFragment = {
  readonly __typename?: 'WorklistItem';
  readonly smid: string;
  readonly modality?: string | null;
  readonly bodyPart?: string | null;
  readonly studyDescription?: string | null;
};

export type WorklistItemBaseFieldsFragment = {
  readonly __typename?: 'WorklistItem';
  readonly smid: string;
  readonly mrn?: string | null;
  readonly status: WorklistItemStatus;
  readonly isActive: boolean;
  readonly hasQcComments?: boolean | null;
  readonly dosage?: string | null;
  readonly studyReason?: string | null;
  readonly studyDescription?: string | null;
  readonly studyDate?: Date | null;
  readonly referringPhysician?: string | null;
  readonly accessionNumber?: string | null;
  readonly techNotes?: string | null;
  readonly patientName?: string | null;
  readonly patientDob?: Date | null;
  readonly patientAge?: string | null;
  readonly patientSex?: string | null;
  readonly date?: Date | null;
  readonly groupId?: string | null;
  readonly modality?: string | null;
  readonly hasDraftReport: boolean;
  readonly examCount?: number | null;
  readonly procedure?: {
    readonly __typename?: 'Procedure';
    readonly smid: string;
    readonly description: string;
    readonly code: string;
  } | null;
  readonly customMergeFields: ReadonlyArray<{
    readonly __typename?: 'CustomMergeField';
    readonly key: string;
    readonly value: string;
  }>;
  readonly claimedBy?: {
    readonly __typename?: 'User';
    readonly id: string;
    readonly firstName: string;
    readonly lastName: string;
  } | null;
  readonly report?: {
    readonly __typename?: 'Report';
    readonly smid: string;
    readonly submittedAt?: Date | null;
    readonly sentAt?: Date | null;
    readonly updated: Date;
    readonly version: number;
    readonly content?: JSON | null;
    readonly owner?: {
      readonly __typename?: 'Owner';
      readonly firstName: string;
      readonly lastName: string;
    } | null;
    readonly addendums: ReadonlyArray<{
      readonly __typename?: 'Addendum';
      readonly created: Date;
      readonly updated: Date;
      readonly submittedAt?: Date | null;
      readonly sentAt?: Date | null;
      readonly smid: string;
      readonly content:
        | { readonly __typename?: 'AddendumSironaSlate'; readonly sironaSlate: ReadonlyArray<JSON> }
        | { readonly __typename?: 'AddendumTextBlob'; readonly textBlob: string };
      readonly owner?: {
        readonly __typename?: 'Owner';
        readonly id: string;
        readonly firstName: string;
        readonly lastName: string;
        readonly email: string;
      } | null;
    }>;
  } | null;
  readonly customStatus?: {
    readonly __typename?: 'WorklistCustomStatus';
    readonly smid: string;
    readonly name: string;
  } | null;
};

export type WorklistItemFieldsFragment = {
  readonly __typename?: 'WorklistItem';
  readonly smid: string;
  readonly mrn?: string | null;
  readonly status: WorklistItemStatus;
  readonly isActive: boolean;
  readonly hasQcComments?: boolean | null;
  readonly dosage?: string | null;
  readonly studyReason?: string | null;
  readonly studyDescription?: string | null;
  readonly studyDate?: Date | null;
  readonly referringPhysician?: string | null;
  readonly accessionNumber?: string | null;
  readonly techNotes?: string | null;
  readonly patientName?: string | null;
  readonly patientDob?: Date | null;
  readonly patientAge?: string | null;
  readonly patientSex?: string | null;
  readonly date?: Date | null;
  readonly groupId?: string | null;
  readonly modality?: string | null;
  readonly hasDraftReport: boolean;
  readonly examCount?: number | null;
  readonly studies: ReadonlyArray<{
    readonly __typename?: 'Study';
    readonly smid: string;
    readonly created: Date;
    readonly updated: Date;
    readonly instanceUID: string;
    readonly description?: string | null;
    readonly hl7Order?: string | null;
    readonly patientSex?: string | null;
    readonly patientAge?: string | null;
    readonly patientName?: string | null;
    readonly patientDob?: Date | null;
    readonly modality: string;
    readonly bodyPart?: string | null;
    readonly mrn?: string | null;
    readonly studyDate?: Date | null;
    readonly laterality?: string | null;
    readonly provenanceData?: {
      readonly __typename?: 'ProvenanceData';
      readonly matchType?: MatchType | null;
      readonly relevant?: boolean | null;
    } | null;
  }>;
  readonly procedure?: {
    readonly __typename?: 'Procedure';
    readonly smid: string;
    readonly description: string;
    readonly code: string;
  } | null;
  readonly customMergeFields: ReadonlyArray<{
    readonly __typename?: 'CustomMergeField';
    readonly key: string;
    readonly value: string;
  }>;
  readonly claimedBy?: {
    readonly __typename?: 'User';
    readonly id: string;
    readonly firstName: string;
    readonly lastName: string;
  } | null;
  readonly report?: {
    readonly __typename?: 'Report';
    readonly smid: string;
    readonly submittedAt?: Date | null;
    readonly sentAt?: Date | null;
    readonly updated: Date;
    readonly version: number;
    readonly content?: JSON | null;
    readonly owner?: {
      readonly __typename?: 'Owner';
      readonly firstName: string;
      readonly lastName: string;
    } | null;
    readonly addendums: ReadonlyArray<{
      readonly __typename?: 'Addendum';
      readonly created: Date;
      readonly updated: Date;
      readonly submittedAt?: Date | null;
      readonly sentAt?: Date | null;
      readonly smid: string;
      readonly content:
        | { readonly __typename?: 'AddendumSironaSlate'; readonly sironaSlate: ReadonlyArray<JSON> }
        | { readonly __typename?: 'AddendumTextBlob'; readonly textBlob: string };
      readonly owner?: {
        readonly __typename?: 'Owner';
        readonly id: string;
        readonly firstName: string;
        readonly lastName: string;
        readonly email: string;
      } | null;
    }>;
  } | null;
  readonly customStatus?: {
    readonly __typename?: 'WorklistCustomStatus';
    readonly smid: string;
    readonly name: string;
  } | null;
};

export type WorklistItemFieldsWithoutProvenanceDataFragment = {
  readonly __typename?: 'WorklistItem';
  readonly smid: string;
  readonly mrn?: string | null;
  readonly status: WorklistItemStatus;
  readonly isActive: boolean;
  readonly hasQcComments?: boolean | null;
  readonly dosage?: string | null;
  readonly studyReason?: string | null;
  readonly studyDescription?: string | null;
  readonly studyDate?: Date | null;
  readonly referringPhysician?: string | null;
  readonly accessionNumber?: string | null;
  readonly techNotes?: string | null;
  readonly patientName?: string | null;
  readonly patientDob?: Date | null;
  readonly patientAge?: string | null;
  readonly patientSex?: string | null;
  readonly date?: Date | null;
  readonly groupId?: string | null;
  readonly modality?: string | null;
  readonly hasDraftReport: boolean;
  readonly examCount?: number | null;
  readonly studies: ReadonlyArray<{
    readonly __typename?: 'Study';
    readonly smid: string;
    readonly created: Date;
    readonly updated: Date;
    readonly instanceUID: string;
    readonly description?: string | null;
    readonly hl7Order?: string | null;
    readonly patientSex?: string | null;
    readonly patientAge?: string | null;
    readonly patientName?: string | null;
    readonly patientDob?: Date | null;
    readonly modality: string;
    readonly bodyPart?: string | null;
    readonly mrn?: string | null;
    readonly studyDate?: Date | null;
    readonly laterality?: string | null;
  }>;
  readonly procedure?: {
    readonly __typename?: 'Procedure';
    readonly smid: string;
    readonly description: string;
    readonly code: string;
  } | null;
  readonly customMergeFields: ReadonlyArray<{
    readonly __typename?: 'CustomMergeField';
    readonly key: string;
    readonly value: string;
  }>;
  readonly claimedBy?: {
    readonly __typename?: 'User';
    readonly id: string;
    readonly firstName: string;
    readonly lastName: string;
  } | null;
  readonly report?: {
    readonly __typename?: 'Report';
    readonly smid: string;
    readonly submittedAt?: Date | null;
    readonly sentAt?: Date | null;
    readonly updated: Date;
    readonly version: number;
    readonly content?: JSON | null;
    readonly owner?: {
      readonly __typename?: 'Owner';
      readonly firstName: string;
      readonly lastName: string;
    } | null;
    readonly addendums: ReadonlyArray<{
      readonly __typename?: 'Addendum';
      readonly created: Date;
      readonly updated: Date;
      readonly submittedAt?: Date | null;
      readonly sentAt?: Date | null;
      readonly smid: string;
      readonly content:
        | { readonly __typename?: 'AddendumSironaSlate'; readonly sironaSlate: ReadonlyArray<JSON> }
        | { readonly __typename?: 'AddendumTextBlob'; readonly textBlob: string };
      readonly owner?: {
        readonly __typename?: 'Owner';
        readonly id: string;
        readonly firstName: string;
        readonly lastName: string;
        readonly email: string;
      } | null;
    }>;
  } | null;
  readonly customStatus?: {
    readonly __typename?: 'WorklistCustomStatus';
    readonly smid: string;
    readonly name: string;
  } | null;
};

export type MacroFieldsFragment = {
  readonly __typename?: 'Macro';
  readonly smid: string;
  readonly name: string;
  readonly triggerPhrase: string;
  readonly text: ReadonlyArray<JSON>;
  readonly created: Date;
  readonly updated?: Date | null;
  readonly nestedMacros: ReadonlyArray<{
    readonly __typename?: 'Macro';
    readonly smid: string;
    readonly created: Date;
    readonly updated?: Date | null;
    readonly name: string;
    readonly triggerPhrase: string;
    readonly text: ReadonlyArray<JSON>;
  }>;
  readonly procedures?: ReadonlyArray<{
    readonly __typename?: 'Procedure';
    readonly smid: string;
    readonly description: string;
    readonly code: string;
  }> | null;
  readonly owner?: {
    readonly __typename?: 'SlimUser';
    readonly id: string;
    readonly firstName: string;
    readonly lastName: string;
  } | null;
  readonly picklists: ReadonlyArray<{
    readonly __typename?: 'ReportPicklist';
    readonly id: string;
    readonly options: ReadonlyArray<{
      readonly __typename?: 'ReportPicklistOption';
      readonly id: string;
      readonly name?: string | null;
      readonly text: ReadonlyArray<JSON>;
      readonly default?: boolean | null;
    }>;
  }>;
};

export type ReportFieldsFragment = {
  readonly __typename?: 'Report';
  readonly smid: string;
  readonly submittedAt?: Date | null;
  readonly sentAt?: Date | null;
  readonly sections: JSON;
  readonly content?: JSON | null;
  readonly sectionsLastUpdated?: Date | null;
  readonly version: number;
  readonly macros: ReadonlyArray<{
    readonly __typename?: 'Macro';
    readonly smid: string;
    readonly name: string;
    readonly triggerPhrase: string;
    readonly text: ReadonlyArray<JSON>;
    readonly created: Date;
    readonly updated?: Date | null;
    readonly nestedMacros: ReadonlyArray<{
      readonly __typename?: 'Macro';
      readonly smid: string;
      readonly created: Date;
      readonly updated?: Date | null;
      readonly name: string;
      readonly triggerPhrase: string;
      readonly text: ReadonlyArray<JSON>;
    }>;
    readonly procedures?: ReadonlyArray<{
      readonly __typename?: 'Procedure';
      readonly smid: string;
      readonly description: string;
      readonly code: string;
    }> | null;
    readonly owner?: {
      readonly __typename?: 'SlimUser';
      readonly id: string;
      readonly firstName: string;
      readonly lastName: string;
    } | null;
    readonly picklists: ReadonlyArray<{
      readonly __typename?: 'ReportPicklist';
      readonly id: string;
      readonly options: ReadonlyArray<{
        readonly __typename?: 'ReportPicklistOption';
        readonly id: string;
        readonly name?: string | null;
        readonly text: ReadonlyArray<JSON>;
        readonly default?: boolean | null;
      }>;
    }>;
  }>;
};

export type WorkspacePresetsFieldsFragment = {
  readonly __typename?: 'WorkspacePreset';
  readonly smid: string;
  readonly instanceId: string;
  readonly name: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  readonly windows: ReadonlyArray<{
    readonly __typename?: 'Window';
    readonly id: string;
    readonly width: number;
    readonly height: number;
    readonly left: number;
    readonly top: number;
    readonly screen: ScreenName;
    readonly type: WindowType;
    readonly state: WindowState;
  }>;
};

export type AutoCorrectEntryFieldsFragment = {
  readonly __typename?: 'AutoCorrectEntry';
  readonly id: string;
  readonly key: string;
  readonly value: string;
};

export type VocabEntryFieldsFragment = {
  readonly __typename?: 'VocabEntry';
  readonly id: string;
  readonly phrase: string;
};

export type WorklistSettingsFragment = {
  readonly __typename?: 'WorklistSettings';
  readonly openPatientJacketOnRead: boolean;
  readonly isPatientJacketDocked: boolean;
  readonly favoritedWorklistViews?: ReadonlyArray<string> | null;
  readonly patientJacketLayout?: JSON | null;
  readonly columns: ReadonlyArray<{
    readonly __typename?: 'WorklistColumn';
    readonly id: WorklistColumnId;
    readonly width: number;
    readonly enabled: boolean;
  }>;
  readonly columnsBySurface?: ReadonlyArray<{
    readonly __typename?: 'WorklistColumnsBySurface';
    readonly surface: string;
    readonly columns: ReadonlyArray<{
      readonly __typename?: 'WorklistColumn';
      readonly id: WorklistColumnId;
      readonly width: number;
      readonly enabled: boolean;
    }>;
  }> | null;
  readonly savedWorklistViews?: ReadonlyArray<{
    readonly __typename?: 'SavedWorklistView';
    readonly surface: string;
    readonly views: ReadonlyArray<string>;
  }> | null;
  readonly worklistAutoLoad?: {
    readonly __typename?: 'WorklistAutoLoad';
    readonly surface?: string | null;
  } | null;
  readonly savedSortingPreferences?: ReadonlyArray<{
    readonly __typename?: 'SavedSortingPreference';
    readonly surface: string;
    readonly searchParams: string;
    readonly worklistViewID?: string | null;
  }> | null;
  readonly lastViewedCustomWorklists?: ReadonlyArray<{
    readonly __typename?: 'DefaultCustomWorklist';
    readonly surface: string;
    readonly worklistViewID?: string | null;
  } | null> | null;
  readonly defaultCustomWorklistsSettings?: {
    readonly __typename?: 'DefaultCustomWorklistsSetting';
    readonly isEnabled: boolean;
    readonly defaultCustomWorklists: ReadonlyArray<{
      readonly __typename?: 'DefaultCustomWorklist';
      readonly surface: string;
      readonly worklistViewID?: string | null;
    } | null>;
  } | null;
};

export type ReportTemplateFieldsFragment = {
  readonly __typename?: 'ReportTemplate';
  readonly id: string;
  readonly name: string;
  readonly triggerPhrase: string;
  readonly created: Date;
  readonly content: JSON;
  readonly sections: ReadonlyArray<JSON>;
  readonly picklists: ReadonlyArray<{
    readonly __typename?: 'ReportPicklist';
    readonly id: string;
    readonly options: ReadonlyArray<{
      readonly __typename?: 'ReportPicklistOption';
      readonly id: string;
      readonly name?: string | null;
      readonly text: ReadonlyArray<JSON>;
      readonly default?: boolean | null;
    }>;
  }>;
  readonly macros: ReadonlyArray<{
    readonly __typename?: 'Macro';
    readonly smid: string;
    readonly name: string;
    readonly triggerPhrase: string;
    readonly text: ReadonlyArray<JSON>;
    readonly created: Date;
    readonly updated?: Date | null;
    readonly nestedMacros: ReadonlyArray<{
      readonly __typename?: 'Macro';
      readonly smid: string;
      readonly created: Date;
      readonly updated?: Date | null;
      readonly name: string;
      readonly triggerPhrase: string;
      readonly text: ReadonlyArray<JSON>;
    }>;
    readonly procedures?: ReadonlyArray<{
      readonly __typename?: 'Procedure';
      readonly smid: string;
      readonly description: string;
      readonly code: string;
    }> | null;
    readonly owner?: {
      readonly __typename?: 'SlimUser';
      readonly id: string;
      readonly firstName: string;
      readonly lastName: string;
    } | null;
    readonly picklists: ReadonlyArray<{
      readonly __typename?: 'ReportPicklist';
      readonly id: string;
      readonly options: ReadonlyArray<{
        readonly __typename?: 'ReportPicklistOption';
        readonly id: string;
        readonly name?: string | null;
        readonly text: ReadonlyArray<JSON>;
        readonly default?: boolean | null;
      }>;
    }>;
  }>;
  readonly procedureReferences?: ReadonlyArray<{
    readonly __typename?: 'ProcedureReference';
    readonly smid: string;
    readonly description?: string | null;
    readonly code?: string | null;
    readonly autoload: boolean;
  }> | null;
};

export type GetReporterSettingsFragment = {
  readonly __typename?: 'ReporterSettings';
  readonly triggerWord: string;
  readonly aiMode: {
    readonly __typename?: 'AIModeSettings';
    readonly findingsMapperEnabled: boolean;
    readonly formatImpressionGenerationAsList: boolean;
    readonly onDemandImpressionGeneration: boolean;
  };
  readonly mergeFieldsSettings: {
    readonly __typename?: 'MergeFieldsSettings';
    readonly ignoreNavigation: boolean;
    readonly ignoreDefaultSelection: boolean;
  };
  readonly styles: {
    readonly __typename?: 'Styles';
    readonly bodyStyle: {
      readonly __typename?: 'TextStyle';
      readonly fontSize?: number | null;
      readonly fontFamily?: string | null;
      readonly textTransform?: TextTransform | null;
      readonly textDecoration?: {
        readonly __typename?: 'TextDecoration';
        readonly isBold?: boolean | null;
        readonly isItalic?: boolean | null;
        readonly isUnderline?: boolean | null;
      } | null;
    };
    readonly headingStyles: ReadonlyArray<{
      readonly __typename?: 'HeadingStyle';
      readonly level: HeadingLevel;
      readonly textStyle: {
        readonly __typename?: 'TextStyle';
        readonly fontSize?: number | null;
        readonly textTransform?: TextTransform | null;
        readonly textDecoration?: {
          readonly __typename?: 'TextDecoration';
          readonly isBold?: boolean | null;
          readonly isItalic?: boolean | null;
          readonly isUnderline?: boolean | null;
        } | null;
      };
    }>;
  };
  readonly cursorStyle: { readonly __typename?: 'CursorStyle'; readonly color: string };
  readonly lineIndicator: {
    readonly __typename?: 'LineIndicator';
    readonly enabled: boolean;
    readonly placement: LineIndicatorPlacement;
    readonly variant: LineIndicatorVariant;
  };
  readonly impressionGenerator: {
    readonly __typename?: 'ImpressionGenerator';
    readonly enabled: boolean;
    readonly automatic: boolean;
    readonly modalities: ReadonlyArray<string>;
  };
  readonly autoFillComparison: {
    readonly __typename?: 'AutoFillComparison';
    readonly enabled: boolean;
  };
  readonly focusMode: { readonly __typename?: 'FocusMode'; readonly enabled: boolean };
};

export type GetToolPreferencesQueryVariables = Exact<{ [key: string]: never }>;

export type GetToolPreferencesQuery = {
  readonly __typename?: 'Query';
  readonly toolPreferences: {
    readonly __typename?: 'ToolPreferences';
    readonly invertScroll: boolean;
    readonly mouse: {
      readonly __typename?: 'MousePreferences';
      readonly left: string;
      readonly middle: string;
      readonly right: string;
    };
    readonly regularScroll: {
      readonly __typename?: 'RegularScrollPreferences';
      readonly sensitivity: number;
    };
    readonly fastScroll: {
      readonly __typename?: 'FastScrollPreferences';
      readonly sensitivity: number;
    };
    readonly fineScroll: {
      readonly __typename?: 'FineScrollPreferences';
      readonly enabled: boolean;
    };
    readonly wheel: {
      readonly __typename?: 'WheelPreferences';
      readonly toolIds: ReadonlyArray<string>;
    };
    readonly keyboard: {
      readonly __typename?: 'KeyboardPreferences';
      readonly shortcuts: ReadonlyArray<{
        readonly __typename?: 'Shortcut';
        readonly id: string;
        readonly key?: string | null;
        readonly interactionId?: string | null;
      }>;
    };
    readonly bar: {
      readonly __typename?: 'BarPreferences';
      readonly toolIds: ReadonlyArray<string>;
    };
    readonly wwwc: {
      readonly __typename?: 'WwwcToolPreferences';
      readonly invertDragDirection: boolean;
      readonly presets: ReadonlyArray<{
        readonly __typename?: 'WwwcPreset';
        readonly id: string;
        readonly name: string;
        readonly width: number;
        readonly level: number;
        readonly shortcut: string;
      }>;
    };
    readonly dictaphone: {
      readonly __typename?: 'DictaphonePreferences';
      readonly deviceLabel: string;
      readonly recordWhilePressed: boolean;
      readonly showPicklistOptionsInEditor: boolean;
      readonly keyMapping: ReadonlyArray<{
        readonly __typename?: 'DictaphoneKey';
        readonly id: GamepadActionId;
        readonly key: string;
      }>;
    };
    readonly workspaces: {
      readonly __typename?: 'WorkspacePreferences';
      readonly autoApply: boolean;
      readonly presets: ReadonlyArray<{
        readonly __typename?: 'WorkspacePreset';
        readonly smid: string;
        readonly instanceId: string;
        readonly name: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly windows: ReadonlyArray<{
          readonly __typename?: 'Window';
          readonly id: string;
          readonly width: number;
          readonly height: number;
          readonly left: number;
          readonly top: number;
          readonly screen: ScreenName;
          readonly type: WindowType;
          readonly state: WindowState;
        }>;
      }>;
    };
    readonly resetToolOnScroll: {
      readonly __typename?: 'ResetToolOnScrollPreferences';
      readonly enabled: boolean;
    };
    readonly mirrorViewerLayouts: {
      readonly __typename?: 'MirrorViewerLayoutsPreferences';
      readonly enabled: boolean;
    };
    readonly showIconLabel: {
      readonly __typename?: 'ShowIconLabelPreferences';
      readonly enabled: boolean;
    };
  };
};

export type GetDefaultAutoloadTemplateFragmentFragment = {
  readonly __typename?: 'User';
  readonly id: string;
  readonly defaultAutoloadTemplate?: {
    readonly __typename?: 'ReportTemplate';
    readonly id: string;
    readonly name: string;
    readonly triggerPhrase: string;
    readonly created: Date;
    readonly content: JSON;
    readonly sections: ReadonlyArray<JSON>;
    readonly picklists: ReadonlyArray<{
      readonly __typename?: 'ReportPicklist';
      readonly id: string;
      readonly options: ReadonlyArray<{
        readonly __typename?: 'ReportPicklistOption';
        readonly id: string;
        readonly name?: string | null;
        readonly text: ReadonlyArray<JSON>;
        readonly default?: boolean | null;
      }>;
    }>;
    readonly macros: ReadonlyArray<{
      readonly __typename?: 'Macro';
      readonly smid: string;
      readonly name: string;
      readonly triggerPhrase: string;
      readonly text: ReadonlyArray<JSON>;
      readonly created: Date;
      readonly updated?: Date | null;
      readonly nestedMacros: ReadonlyArray<{
        readonly __typename?: 'Macro';
        readonly smid: string;
        readonly created: Date;
        readonly updated?: Date | null;
        readonly name: string;
        readonly triggerPhrase: string;
        readonly text: ReadonlyArray<JSON>;
      }>;
      readonly procedures?: ReadonlyArray<{
        readonly __typename?: 'Procedure';
        readonly smid: string;
        readonly description: string;
        readonly code: string;
      }> | null;
      readonly owner?: {
        readonly __typename?: 'SlimUser';
        readonly id: string;
        readonly firstName: string;
        readonly lastName: string;
      } | null;
      readonly picklists: ReadonlyArray<{
        readonly __typename?: 'ReportPicklist';
        readonly id: string;
        readonly options: ReadonlyArray<{
          readonly __typename?: 'ReportPicklistOption';
          readonly id: string;
          readonly name?: string | null;
          readonly text: ReadonlyArray<JSON>;
          readonly default?: boolean | null;
        }>;
      }>;
    }>;
    readonly procedureReferences?: ReadonlyArray<{
      readonly __typename?: 'ProcedureReference';
      readonly smid: string;
      readonly description?: string | null;
      readonly code?: string | null;
      readonly autoload: boolean;
    }> | null;
  } | null;
};

export type GetMeFragmentFragment = {
  readonly __typename?: 'User';
  readonly id: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly email: string;
  readonly physicianId?: string | null;
  readonly sessionTimeoutInMinutes: number;
  readonly anatomicNavigatorEnabled: boolean;
  readonly stackInstancesByType: boolean;
  readonly stackedModalities: ReadonlyArray<string>;
  readonly screenShareEnabled: boolean;
  readonly nvoqCredentials: {
    readonly __typename?: 'NvoqCredentials';
    readonly username: string;
    readonly password: string;
  };
  readonly preferredWorkLocation: {
    readonly __typename?: 'PreferredWorkLocation';
    readonly hospital: boolean;
    readonly home: boolean;
  };
  readonly viewerSettings: {
    readonly __typename?: 'ViewerSettings';
    readonly showManualLocalizerLines: boolean;
    readonly virtualMonitorSplit?: VirtualMonitorSplit | null;
    readonly eraseAllToolScope?: EraseAllToolScope | null;
    readonly defaultThumbnailSizePx?: number | null;
    readonly customDicomTags: ReadonlyArray<{
      readonly __typename?: 'ModalityDicomTags';
      readonly modality: string;
      readonly modalityDicomTags: ReadonlyArray<{
        readonly __typename?: 'DicomTagRenderInfo';
        readonly id: string;
        readonly tagId: string;
        readonly enabled: boolean;
        readonly label: string;
      }>;
    }>;
    readonly seriesSkipSettings?: {
      readonly __typename?: 'SeriesSkipSettings';
      readonly amount?: number | null;
      readonly keepPreviousWhenAvailable: boolean;
    } | null;
  };
  readonly securitySettings: {
    readonly __typename?: 'SecuritySettings';
    readonly multiFactorAuth: {
      readonly __typename?: 'MultiFactorAuth';
      readonly enabled: boolean;
      readonly linked: boolean;
    };
  };
  readonly worklistSettings: {
    readonly __typename?: 'WorklistSettings';
    readonly openPatientJacketOnRead: boolean;
    readonly isPatientJacketDocked: boolean;
    readonly favoritedWorklistViews?: ReadonlyArray<string> | null;
    readonly patientJacketLayout?: JSON | null;
    readonly columns: ReadonlyArray<{
      readonly __typename?: 'WorklistColumn';
      readonly id: WorklistColumnId;
      readonly width: number;
      readonly enabled: boolean;
    }>;
    readonly columnsBySurface?: ReadonlyArray<{
      readonly __typename?: 'WorklistColumnsBySurface';
      readonly surface: string;
      readonly columns: ReadonlyArray<{
        readonly __typename?: 'WorklistColumn';
        readonly id: WorklistColumnId;
        readonly width: number;
        readonly enabled: boolean;
      }>;
    }> | null;
    readonly savedWorklistViews?: ReadonlyArray<{
      readonly __typename?: 'SavedWorklistView';
      readonly surface: string;
      readonly views: ReadonlyArray<string>;
    }> | null;
    readonly worklistAutoLoad?: {
      readonly __typename?: 'WorklistAutoLoad';
      readonly surface?: string | null;
    } | null;
    readonly savedSortingPreferences?: ReadonlyArray<{
      readonly __typename?: 'SavedSortingPreference';
      readonly surface: string;
      readonly searchParams: string;
      readonly worklistViewID?: string | null;
    }> | null;
    readonly lastViewedCustomWorklists?: ReadonlyArray<{
      readonly __typename?: 'DefaultCustomWorklist';
      readonly surface: string;
      readonly worklistViewID?: string | null;
    } | null> | null;
    readonly defaultCustomWorklistsSettings?: {
      readonly __typename?: 'DefaultCustomWorklistsSetting';
      readonly isEnabled: boolean;
      readonly defaultCustomWorklists: ReadonlyArray<{
        readonly __typename?: 'DefaultCustomWorklist';
        readonly surface: string;
        readonly worklistViewID?: string | null;
      } | null>;
    } | null;
  };
  readonly reporterSettings: {
    readonly __typename?: 'ReporterSettings';
    readonly triggerWord: string;
    readonly aiMode: {
      readonly __typename?: 'AIModeSettings';
      readonly findingsMapperEnabled: boolean;
      readonly formatImpressionGenerationAsList: boolean;
      readonly onDemandImpressionGeneration: boolean;
    };
    readonly mergeFieldsSettings: {
      readonly __typename?: 'MergeFieldsSettings';
      readonly ignoreNavigation: boolean;
      readonly ignoreDefaultSelection: boolean;
    };
    readonly styles: {
      readonly __typename?: 'Styles';
      readonly bodyStyle: {
        readonly __typename?: 'TextStyle';
        readonly fontSize?: number | null;
        readonly fontFamily?: string | null;
        readonly textTransform?: TextTransform | null;
        readonly textDecoration?: {
          readonly __typename?: 'TextDecoration';
          readonly isBold?: boolean | null;
          readonly isItalic?: boolean | null;
          readonly isUnderline?: boolean | null;
        } | null;
      };
      readonly headingStyles: ReadonlyArray<{
        readonly __typename?: 'HeadingStyle';
        readonly level: HeadingLevel;
        readonly textStyle: {
          readonly __typename?: 'TextStyle';
          readonly fontSize?: number | null;
          readonly textTransform?: TextTransform | null;
          readonly textDecoration?: {
            readonly __typename?: 'TextDecoration';
            readonly isBold?: boolean | null;
            readonly isItalic?: boolean | null;
            readonly isUnderline?: boolean | null;
          } | null;
        };
      }>;
    };
    readonly cursorStyle: { readonly __typename?: 'CursorStyle'; readonly color: string };
    readonly lineIndicator: {
      readonly __typename?: 'LineIndicator';
      readonly enabled: boolean;
      readonly placement: LineIndicatorPlacement;
      readonly variant: LineIndicatorVariant;
    };
    readonly impressionGenerator: {
      readonly __typename?: 'ImpressionGenerator';
      readonly enabled: boolean;
      readonly automatic: boolean;
      readonly modalities: ReadonlyArray<string>;
    };
    readonly autoFillComparison: {
      readonly __typename?: 'AutoFillComparison';
      readonly enabled: boolean;
    };
    readonly focusMode: { readonly __typename?: 'FocusMode'; readonly enabled: boolean };
  };
  readonly clinic?: {
    readonly __typename?: 'Clinic';
    readonly smid: string;
    readonly name: string;
  } | null;
  readonly autoCorrect: ReadonlyArray<{
    readonly __typename?: 'AutoCorrectEntry';
    readonly id: string;
    readonly key: string;
    readonly value: string;
  }>;
  readonly roles: ReadonlyArray<{
    readonly __typename?: 'Role';
    readonly name: string;
    readonly scope: {
      readonly __typename?: 'Scope';
      readonly name: string;
      readonly object_id: string;
    };
    readonly permissions: ReadonlyArray<{
      readonly __typename?: 'Permission';
      readonly name: string;
      readonly component: string;
    }>;
  }>;
};

export type GetUsersQueryVariables = Exact<{
  cursor?: InputMaybe<Scalars['ID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  hasMacros?: InputMaybe<Scalars['Boolean']['input']>;
  hasTemplates?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GetUsersQuery = {
  readonly __typename?: 'Query';
  readonly users: {
    readonly __typename?: 'Users';
    readonly next?: string | null;
    readonly items: ReadonlyArray<{
      readonly __typename?: 'LimitedUser';
      readonly firstName: string;
      readonly lastName: string;
      readonly email: string;
      readonly id: string;
    }>;
  };
};

export type GetMeQueryVariables = Exact<{ [key: string]: never }>;

export type GetMeQuery = {
  readonly __typename?: 'Query';
  readonly me: {
    readonly __typename?: 'User';
    readonly id: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly email: string;
    readonly physicianId?: string | null;
    readonly sessionTimeoutInMinutes: number;
    readonly anatomicNavigatorEnabled: boolean;
    readonly stackInstancesByType: boolean;
    readonly stackedModalities: ReadonlyArray<string>;
    readonly screenShareEnabled: boolean;
    readonly nvoqCredentials: {
      readonly __typename?: 'NvoqCredentials';
      readonly username: string;
      readonly password: string;
    };
    readonly preferredWorkLocation: {
      readonly __typename?: 'PreferredWorkLocation';
      readonly hospital: boolean;
      readonly home: boolean;
    };
    readonly viewerSettings: {
      readonly __typename?: 'ViewerSettings';
      readonly showManualLocalizerLines: boolean;
      readonly virtualMonitorSplit?: VirtualMonitorSplit | null;
      readonly eraseAllToolScope?: EraseAllToolScope | null;
      readonly defaultThumbnailSizePx?: number | null;
      readonly absoluteScroll: boolean;
      readonly customDicomTags: ReadonlyArray<{
        readonly __typename?: 'ModalityDicomTags';
        readonly modality: string;
        readonly modalityDicomTags: ReadonlyArray<{
          readonly __typename?: 'DicomTagRenderInfo';
          readonly id: string;
          readonly tagId: string;
          readonly enabled: boolean;
          readonly label: string;
        }>;
      }>;
      readonly seriesSkipSettings?: {
        readonly __typename?: 'SeriesSkipSettings';
        readonly amount?: number | null;
        readonly keepPreviousWhenAvailable: boolean;
      } | null;
    };
    readonly securitySettings: {
      readonly __typename?: 'SecuritySettings';
      readonly multiFactorAuth: {
        readonly __typename?: 'MultiFactorAuth';
        readonly enabled: boolean;
        readonly linked: boolean;
      };
    };
    readonly worklistSettings: {
      readonly __typename?: 'WorklistSettings';
      readonly openPatientJacketOnRead: boolean;
      readonly isPatientJacketDocked: boolean;
      readonly favoritedWorklistViews?: ReadonlyArray<string> | null;
      readonly patientJacketLayout?: JSON | null;
      readonly columns: ReadonlyArray<{
        readonly __typename?: 'WorklistColumn';
        readonly id: WorklistColumnId;
        readonly width: number;
        readonly enabled: boolean;
      }>;
      readonly columnsBySurface?: ReadonlyArray<{
        readonly __typename?: 'WorklistColumnsBySurface';
        readonly surface: string;
        readonly columns: ReadonlyArray<{
          readonly __typename?: 'WorklistColumn';
          readonly id: WorklistColumnId;
          readonly width: number;
          readonly enabled: boolean;
        }>;
      }> | null;
      readonly savedWorklistViews?: ReadonlyArray<{
        readonly __typename?: 'SavedWorklistView';
        readonly surface: string;
        readonly views: ReadonlyArray<string>;
      }> | null;
      readonly worklistAutoLoad?: {
        readonly __typename?: 'WorklistAutoLoad';
        readonly surface?: string | null;
      } | null;
      readonly savedSortingPreferences?: ReadonlyArray<{
        readonly __typename?: 'SavedSortingPreference';
        readonly surface: string;
        readonly searchParams: string;
        readonly worklistViewID?: string | null;
      }> | null;
      readonly lastViewedCustomWorklists?: ReadonlyArray<{
        readonly __typename?: 'DefaultCustomWorklist';
        readonly surface: string;
        readonly worklistViewID?: string | null;
      } | null> | null;
      readonly defaultCustomWorklistsSettings?: {
        readonly __typename?: 'DefaultCustomWorklistsSetting';
        readonly isEnabled: boolean;
        readonly defaultCustomWorklists: ReadonlyArray<{
          readonly __typename?: 'DefaultCustomWorklist';
          readonly surface: string;
          readonly worklistViewID?: string | null;
        } | null>;
      } | null;
    };
    readonly reporterSettings: {
      readonly __typename?: 'ReporterSettings';
      readonly triggerWord: string;
      readonly aiMode: {
        readonly __typename?: 'AIModeSettings';
        readonly findingsMapperEnabled: boolean;
        readonly formatImpressionGenerationAsList: boolean;
        readonly onDemandImpressionGeneration: boolean;
      };
      readonly mergeFieldsSettings: {
        readonly __typename?: 'MergeFieldsSettings';
        readonly ignoreNavigation: boolean;
        readonly ignoreDefaultSelection: boolean;
      };
      readonly styles: {
        readonly __typename?: 'Styles';
        readonly bodyStyle: {
          readonly __typename?: 'TextStyle';
          readonly fontSize?: number | null;
          readonly fontFamily?: string | null;
          readonly textTransform?: TextTransform | null;
          readonly textDecoration?: {
            readonly __typename?: 'TextDecoration';
            readonly isBold?: boolean | null;
            readonly isItalic?: boolean | null;
            readonly isUnderline?: boolean | null;
          } | null;
        };
        readonly headingStyles: ReadonlyArray<{
          readonly __typename?: 'HeadingStyle';
          readonly level: HeadingLevel;
          readonly textStyle: {
            readonly __typename?: 'TextStyle';
            readonly fontSize?: number | null;
            readonly textTransform?: TextTransform | null;
            readonly textDecoration?: {
              readonly __typename?: 'TextDecoration';
              readonly isBold?: boolean | null;
              readonly isItalic?: boolean | null;
              readonly isUnderline?: boolean | null;
            } | null;
          };
        }>;
      };
      readonly cursorStyle: { readonly __typename?: 'CursorStyle'; readonly color: string };
      readonly lineIndicator: {
        readonly __typename?: 'LineIndicator';
        readonly enabled: boolean;
        readonly placement: LineIndicatorPlacement;
        readonly variant: LineIndicatorVariant;
      };
      readonly impressionGenerator: {
        readonly __typename?: 'ImpressionGenerator';
        readonly enabled: boolean;
        readonly automatic: boolean;
        readonly modalities: ReadonlyArray<string>;
      };
      readonly autoFillComparison: {
        readonly __typename?: 'AutoFillComparison';
        readonly enabled: boolean;
      };
      readonly focusMode: { readonly __typename?: 'FocusMode'; readonly enabled: boolean };
    };
    readonly clinic?: {
      readonly __typename?: 'Clinic';
      readonly smid: string;
      readonly name: string;
      readonly isRbacEnabled: boolean;
    } | null;
    readonly autoCorrect: ReadonlyArray<{
      readonly __typename?: 'AutoCorrectEntry';
      readonly id: string;
      readonly key: string;
      readonly value: string;
    }>;
    readonly roles: ReadonlyArray<{
      readonly __typename?: 'Role';
      readonly name: string;
      readonly scope: {
        readonly __typename?: 'Scope';
        readonly name: string;
        readonly object_id: string;
      };
      readonly permissions: ReadonlyArray<{
        readonly __typename?: 'Permission';
        readonly name: string;
        readonly component: string;
      }>;
    }>;
    readonly defaultAutoloadTemplate?: {
      readonly __typename?: 'ReportTemplate';
      readonly id: string;
      readonly name: string;
      readonly triggerPhrase: string;
      readonly created: Date;
      readonly content: JSON;
      readonly sections: ReadonlyArray<JSON>;
      readonly picklists: ReadonlyArray<{
        readonly __typename?: 'ReportPicklist';
        readonly id: string;
        readonly options: ReadonlyArray<{
          readonly __typename?: 'ReportPicklistOption';
          readonly id: string;
          readonly name?: string | null;
          readonly text: ReadonlyArray<JSON>;
          readonly default?: boolean | null;
        }>;
      }>;
      readonly macros: ReadonlyArray<{
        readonly __typename?: 'Macro';
        readonly smid: string;
        readonly name: string;
        readonly triggerPhrase: string;
        readonly text: ReadonlyArray<JSON>;
        readonly created: Date;
        readonly updated?: Date | null;
        readonly nestedMacros: ReadonlyArray<{
          readonly __typename?: 'Macro';
          readonly smid: string;
          readonly created: Date;
          readonly updated?: Date | null;
          readonly name: string;
          readonly triggerPhrase: string;
          readonly text: ReadonlyArray<JSON>;
        }>;
        readonly procedures?: ReadonlyArray<{
          readonly __typename?: 'Procedure';
          readonly smid: string;
          readonly description: string;
          readonly code: string;
        }> | null;
        readonly owner?: {
          readonly __typename?: 'SlimUser';
          readonly id: string;
          readonly firstName: string;
          readonly lastName: string;
        } | null;
        readonly picklists: ReadonlyArray<{
          readonly __typename?: 'ReportPicklist';
          readonly id: string;
          readonly options: ReadonlyArray<{
            readonly __typename?: 'ReportPicklistOption';
            readonly id: string;
            readonly name?: string | null;
            readonly text: ReadonlyArray<JSON>;
            readonly default?: boolean | null;
          }>;
        }>;
      }>;
      readonly procedureReferences?: ReadonlyArray<{
        readonly __typename?: 'ProcedureReference';
        readonly smid: string;
        readonly description?: string | null;
        readonly code?: string | null;
        readonly autoload: boolean;
      }> | null;
    } | null;
  };
};

export type AuthenticateMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;

export type AuthenticateMutation = {
  readonly __typename?: 'Mutation';
  readonly authenticate?:
    | { readonly __typename?: 'MFARequired'; readonly mfaSessionId: string }
    | {
        readonly __typename?: 'User';
        readonly id: string;
        readonly firstName: string;
        readonly lastName: string;
        readonly email: string;
        readonly physicianId?: string | null;
        readonly sessionTimeoutInMinutes: number;
        readonly anatomicNavigatorEnabled: boolean;
        readonly stackInstancesByType: boolean;
        readonly stackedModalities: ReadonlyArray<string>;
        readonly screenShareEnabled: boolean;
        readonly nvoqCredentials: {
          readonly __typename?: 'NvoqCredentials';
          readonly username: string;
          readonly password: string;
        };
        readonly preferredWorkLocation: {
          readonly __typename?: 'PreferredWorkLocation';
          readonly hospital: boolean;
          readonly home: boolean;
        };
        readonly viewerSettings: {
          readonly __typename?: 'ViewerSettings';
          readonly showManualLocalizerLines: boolean;
          readonly virtualMonitorSplit?: VirtualMonitorSplit | null;
          readonly eraseAllToolScope?: EraseAllToolScope | null;
          readonly defaultThumbnailSizePx?: number | null;
          readonly customDicomTags: ReadonlyArray<{
            readonly __typename?: 'ModalityDicomTags';
            readonly modality: string;
            readonly modalityDicomTags: ReadonlyArray<{
              readonly __typename?: 'DicomTagRenderInfo';
              readonly id: string;
              readonly tagId: string;
              readonly enabled: boolean;
              readonly label: string;
            }>;
          }>;
          readonly seriesSkipSettings?: {
            readonly __typename?: 'SeriesSkipSettings';
            readonly amount?: number | null;
            readonly keepPreviousWhenAvailable: boolean;
          } | null;
        };
        readonly securitySettings: {
          readonly __typename?: 'SecuritySettings';
          readonly multiFactorAuth: {
            readonly __typename?: 'MultiFactorAuth';
            readonly enabled: boolean;
            readonly linked: boolean;
          };
        };
        readonly worklistSettings: {
          readonly __typename?: 'WorklistSettings';
          readonly openPatientJacketOnRead: boolean;
          readonly isPatientJacketDocked: boolean;
          readonly favoritedWorklistViews?: ReadonlyArray<string> | null;
          readonly patientJacketLayout?: JSON | null;
          readonly columns: ReadonlyArray<{
            readonly __typename?: 'WorklistColumn';
            readonly id: WorklistColumnId;
            readonly width: number;
            readonly enabled: boolean;
          }>;
          readonly columnsBySurface?: ReadonlyArray<{
            readonly __typename?: 'WorklistColumnsBySurface';
            readonly surface: string;
            readonly columns: ReadonlyArray<{
              readonly __typename?: 'WorklistColumn';
              readonly id: WorklistColumnId;
              readonly width: number;
              readonly enabled: boolean;
            }>;
          }> | null;
          readonly savedWorklistViews?: ReadonlyArray<{
            readonly __typename?: 'SavedWorklistView';
            readonly surface: string;
            readonly views: ReadonlyArray<string>;
          }> | null;
          readonly worklistAutoLoad?: {
            readonly __typename?: 'WorklistAutoLoad';
            readonly surface?: string | null;
          } | null;
          readonly savedSortingPreferences?: ReadonlyArray<{
            readonly __typename?: 'SavedSortingPreference';
            readonly surface: string;
            readonly searchParams: string;
            readonly worklistViewID?: string | null;
          }> | null;
          readonly lastViewedCustomWorklists?: ReadonlyArray<{
            readonly __typename?: 'DefaultCustomWorklist';
            readonly surface: string;
            readonly worklistViewID?: string | null;
          } | null> | null;
          readonly defaultCustomWorklistsSettings?: {
            readonly __typename?: 'DefaultCustomWorklistsSetting';
            readonly isEnabled: boolean;
            readonly defaultCustomWorklists: ReadonlyArray<{
              readonly __typename?: 'DefaultCustomWorklist';
              readonly surface: string;
              readonly worklistViewID?: string | null;
            } | null>;
          } | null;
        };
        readonly reporterSettings: {
          readonly __typename?: 'ReporterSettings';
          readonly triggerWord: string;
          readonly aiMode: {
            readonly __typename?: 'AIModeSettings';
            readonly findingsMapperEnabled: boolean;
            readonly formatImpressionGenerationAsList: boolean;
            readonly onDemandImpressionGeneration: boolean;
          };
          readonly mergeFieldsSettings: {
            readonly __typename?: 'MergeFieldsSettings';
            readonly ignoreNavigation: boolean;
            readonly ignoreDefaultSelection: boolean;
          };
          readonly styles: {
            readonly __typename?: 'Styles';
            readonly bodyStyle: {
              readonly __typename?: 'TextStyle';
              readonly fontSize?: number | null;
              readonly fontFamily?: string | null;
              readonly textTransform?: TextTransform | null;
              readonly textDecoration?: {
                readonly __typename?: 'TextDecoration';
                readonly isBold?: boolean | null;
                readonly isItalic?: boolean | null;
                readonly isUnderline?: boolean | null;
              } | null;
            };
            readonly headingStyles: ReadonlyArray<{
              readonly __typename?: 'HeadingStyle';
              readonly level: HeadingLevel;
              readonly textStyle: {
                readonly __typename?: 'TextStyle';
                readonly fontSize?: number | null;
                readonly textTransform?: TextTransform | null;
                readonly textDecoration?: {
                  readonly __typename?: 'TextDecoration';
                  readonly isBold?: boolean | null;
                  readonly isItalic?: boolean | null;
                  readonly isUnderline?: boolean | null;
                } | null;
              };
            }>;
          };
          readonly cursorStyle: { readonly __typename?: 'CursorStyle'; readonly color: string };
          readonly lineIndicator: {
            readonly __typename?: 'LineIndicator';
            readonly enabled: boolean;
            readonly placement: LineIndicatorPlacement;
            readonly variant: LineIndicatorVariant;
          };
          readonly impressionGenerator: {
            readonly __typename?: 'ImpressionGenerator';
            readonly enabled: boolean;
            readonly automatic: boolean;
            readonly modalities: ReadonlyArray<string>;
          };
          readonly autoFillComparison: {
            readonly __typename?: 'AutoFillComparison';
            readonly enabled: boolean;
          };
          readonly focusMode: { readonly __typename?: 'FocusMode'; readonly enabled: boolean };
        };
        readonly clinic?: {
          readonly __typename?: 'Clinic';
          readonly smid: string;
          readonly name: string;
        } | null;
        readonly autoCorrect: ReadonlyArray<{
          readonly __typename?: 'AutoCorrectEntry';
          readonly id: string;
          readonly key: string;
          readonly value: string;
        }>;
        readonly roles: ReadonlyArray<{
          readonly __typename?: 'Role';
          readonly name: string;
          readonly scope: {
            readonly __typename?: 'Scope';
            readonly name: string;
            readonly object_id: string;
          };
          readonly permissions: ReadonlyArray<{
            readonly __typename?: 'Permission';
            readonly name: string;
            readonly component: string;
          }>;
        }>;
      }
    | null;
};

export type AuthenticateMfaMutationVariables = Exact<{
  email: Scalars['String']['input'];
  totp: Scalars['String']['input'];
  mfaSessionId: Scalars['String']['input'];
}>;

export type AuthenticateMfaMutation = {
  readonly __typename?: 'Mutation';
  readonly authenticateMFA?: {
    readonly __typename?: 'User';
    readonly id: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly email: string;
    readonly physicianId?: string | null;
    readonly sessionTimeoutInMinutes: number;
    readonly anatomicNavigatorEnabled: boolean;
    readonly stackInstancesByType: boolean;
    readonly stackedModalities: ReadonlyArray<string>;
    readonly screenShareEnabled: boolean;
    readonly nvoqCredentials: {
      readonly __typename?: 'NvoqCredentials';
      readonly username: string;
      readonly password: string;
    };
    readonly preferredWorkLocation: {
      readonly __typename?: 'PreferredWorkLocation';
      readonly hospital: boolean;
      readonly home: boolean;
    };
    readonly viewerSettings: {
      readonly __typename?: 'ViewerSettings';
      readonly showManualLocalizerLines: boolean;
      readonly virtualMonitorSplit?: VirtualMonitorSplit | null;
      readonly eraseAllToolScope?: EraseAllToolScope | null;
      readonly defaultThumbnailSizePx?: number | null;
      readonly customDicomTags: ReadonlyArray<{
        readonly __typename?: 'ModalityDicomTags';
        readonly modality: string;
        readonly modalityDicomTags: ReadonlyArray<{
          readonly __typename?: 'DicomTagRenderInfo';
          readonly id: string;
          readonly tagId: string;
          readonly enabled: boolean;
          readonly label: string;
        }>;
      }>;
      readonly seriesSkipSettings?: {
        readonly __typename?: 'SeriesSkipSettings';
        readonly amount?: number | null;
        readonly keepPreviousWhenAvailable: boolean;
      } | null;
    };
    readonly securitySettings: {
      readonly __typename?: 'SecuritySettings';
      readonly multiFactorAuth: {
        readonly __typename?: 'MultiFactorAuth';
        readonly enabled: boolean;
        readonly linked: boolean;
      };
    };
    readonly worklistSettings: {
      readonly __typename?: 'WorklistSettings';
      readonly openPatientJacketOnRead: boolean;
      readonly isPatientJacketDocked: boolean;
      readonly favoritedWorklistViews?: ReadonlyArray<string> | null;
      readonly patientJacketLayout?: JSON | null;
      readonly columns: ReadonlyArray<{
        readonly __typename?: 'WorklistColumn';
        readonly id: WorklistColumnId;
        readonly width: number;
        readonly enabled: boolean;
      }>;
      readonly columnsBySurface?: ReadonlyArray<{
        readonly __typename?: 'WorklistColumnsBySurface';
        readonly surface: string;
        readonly columns: ReadonlyArray<{
          readonly __typename?: 'WorklistColumn';
          readonly id: WorklistColumnId;
          readonly width: number;
          readonly enabled: boolean;
        }>;
      }> | null;
      readonly savedWorklistViews?: ReadonlyArray<{
        readonly __typename?: 'SavedWorklistView';
        readonly surface: string;
        readonly views: ReadonlyArray<string>;
      }> | null;
      readonly worklistAutoLoad?: {
        readonly __typename?: 'WorklistAutoLoad';
        readonly surface?: string | null;
      } | null;
      readonly savedSortingPreferences?: ReadonlyArray<{
        readonly __typename?: 'SavedSortingPreference';
        readonly surface: string;
        readonly searchParams: string;
        readonly worklistViewID?: string | null;
      }> | null;
      readonly lastViewedCustomWorklists?: ReadonlyArray<{
        readonly __typename?: 'DefaultCustomWorklist';
        readonly surface: string;
        readonly worklistViewID?: string | null;
      } | null> | null;
      readonly defaultCustomWorklistsSettings?: {
        readonly __typename?: 'DefaultCustomWorklistsSetting';
        readonly isEnabled: boolean;
        readonly defaultCustomWorklists: ReadonlyArray<{
          readonly __typename?: 'DefaultCustomWorklist';
          readonly surface: string;
          readonly worklistViewID?: string | null;
        } | null>;
      } | null;
    };
    readonly reporterSettings: {
      readonly __typename?: 'ReporterSettings';
      readonly triggerWord: string;
      readonly aiMode: {
        readonly __typename?: 'AIModeSettings';
        readonly findingsMapperEnabled: boolean;
        readonly formatImpressionGenerationAsList: boolean;
        readonly onDemandImpressionGeneration: boolean;
      };
      readonly mergeFieldsSettings: {
        readonly __typename?: 'MergeFieldsSettings';
        readonly ignoreNavigation: boolean;
        readonly ignoreDefaultSelection: boolean;
      };
      readonly styles: {
        readonly __typename?: 'Styles';
        readonly bodyStyle: {
          readonly __typename?: 'TextStyle';
          readonly fontSize?: number | null;
          readonly fontFamily?: string | null;
          readonly textTransform?: TextTransform | null;
          readonly textDecoration?: {
            readonly __typename?: 'TextDecoration';
            readonly isBold?: boolean | null;
            readonly isItalic?: boolean | null;
            readonly isUnderline?: boolean | null;
          } | null;
        };
        readonly headingStyles: ReadonlyArray<{
          readonly __typename?: 'HeadingStyle';
          readonly level: HeadingLevel;
          readonly textStyle: {
            readonly __typename?: 'TextStyle';
            readonly fontSize?: number | null;
            readonly textTransform?: TextTransform | null;
            readonly textDecoration?: {
              readonly __typename?: 'TextDecoration';
              readonly isBold?: boolean | null;
              readonly isItalic?: boolean | null;
              readonly isUnderline?: boolean | null;
            } | null;
          };
        }>;
      };
      readonly cursorStyle: { readonly __typename?: 'CursorStyle'; readonly color: string };
      readonly lineIndicator: {
        readonly __typename?: 'LineIndicator';
        readonly enabled: boolean;
        readonly placement: LineIndicatorPlacement;
        readonly variant: LineIndicatorVariant;
      };
      readonly impressionGenerator: {
        readonly __typename?: 'ImpressionGenerator';
        readonly enabled: boolean;
        readonly automatic: boolean;
        readonly modalities: ReadonlyArray<string>;
      };
      readonly autoFillComparison: {
        readonly __typename?: 'AutoFillComparison';
        readonly enabled: boolean;
      };
      readonly focusMode: { readonly __typename?: 'FocusMode'; readonly enabled: boolean };
    };
    readonly clinic?: {
      readonly __typename?: 'Clinic';
      readonly smid: string;
      readonly name: string;
    } | null;
    readonly autoCorrect: ReadonlyArray<{
      readonly __typename?: 'AutoCorrectEntry';
      readonly id: string;
      readonly key: string;
      readonly value: string;
    }>;
    readonly roles: ReadonlyArray<{
      readonly __typename?: 'Role';
      readonly name: string;
      readonly scope: {
        readonly __typename?: 'Scope';
        readonly name: string;
        readonly object_id: string;
      };
      readonly permissions: ReadonlyArray<{
        readonly __typename?: 'Permission';
        readonly name: string;
        readonly component: string;
      }>;
    }>;
  } | null;
};

export type UnauthenticateMutationVariables = Exact<{ [key: string]: never }>;

export type UnauthenticateMutation = {
  readonly __typename?: 'Mutation';
  readonly unauthenticate: boolean;
};

export type AssignDefaultAutoloadTemplateMutationVariables = Exact<{
  input: AssignDefaultAutoloadTemplateInput;
}>;

export type AssignDefaultAutoloadTemplateMutation = {
  readonly __typename?: 'Mutation';
  readonly assignDefaultAutoloadTemplate: {
    readonly __typename?: 'AssignDefaultAutoloadTemplatePayload';
    readonly me: {
      readonly __typename?: 'User';
      readonly id: string;
      readonly defaultAutoloadTemplate?: {
        readonly __typename?: 'ReportTemplate';
        readonly id: string;
        readonly name: string;
        readonly triggerPhrase: string;
        readonly created: Date;
        readonly content: JSON;
        readonly sections: ReadonlyArray<JSON>;
        readonly picklists: ReadonlyArray<{
          readonly __typename?: 'ReportPicklist';
          readonly id: string;
          readonly options: ReadonlyArray<{
            readonly __typename?: 'ReportPicklistOption';
            readonly id: string;
            readonly name?: string | null;
            readonly text: ReadonlyArray<JSON>;
            readonly default?: boolean | null;
          }>;
        }>;
        readonly macros: ReadonlyArray<{
          readonly __typename?: 'Macro';
          readonly smid: string;
          readonly name: string;
          readonly triggerPhrase: string;
          readonly text: ReadonlyArray<JSON>;
          readonly created: Date;
          readonly updated?: Date | null;
          readonly nestedMacros: ReadonlyArray<{
            readonly __typename?: 'Macro';
            readonly smid: string;
            readonly created: Date;
            readonly updated?: Date | null;
            readonly name: string;
            readonly triggerPhrase: string;
            readonly text: ReadonlyArray<JSON>;
          }>;
          readonly procedures?: ReadonlyArray<{
            readonly __typename?: 'Procedure';
            readonly smid: string;
            readonly description: string;
            readonly code: string;
          }> | null;
          readonly owner?: {
            readonly __typename?: 'SlimUser';
            readonly id: string;
            readonly firstName: string;
            readonly lastName: string;
          } | null;
          readonly picklists: ReadonlyArray<{
            readonly __typename?: 'ReportPicklist';
            readonly id: string;
            readonly options: ReadonlyArray<{
              readonly __typename?: 'ReportPicklistOption';
              readonly id: string;
              readonly name?: string | null;
              readonly text: ReadonlyArray<JSON>;
              readonly default?: boolean | null;
            }>;
          }>;
        }>;
        readonly procedureReferences?: ReadonlyArray<{
          readonly __typename?: 'ProcedureReference';
          readonly smid: string;
          readonly description?: string | null;
          readonly code?: string | null;
          readonly autoload: boolean;
        }> | null;
      } | null;
    };
  };
};

export type ToggleAnatomicNavigatorMutationVariables = Exact<{
  anatomicNavigatorEnabled: Scalars['Boolean']['input'];
}>;

export type ToggleAnatomicNavigatorMutation = {
  readonly __typename?: 'Mutation';
  readonly toggleAnatomicNavigator: {
    readonly __typename?: 'AnatomicNavigatorResponse';
    readonly anatomicNavigatorEnabled: boolean;
  };
};

export type ToggleStackedScrollingMutationVariables = Exact<{
  stackInstancesByType: Scalars['Boolean']['input'];
}>;

export type ToggleStackedScrollingMutation = {
  readonly __typename?: 'Mutation';
  readonly toggleStackedScrolling: {
    readonly __typename?: 'StackedScrollingResponse';
    readonly stackInstancesByType: boolean;
  };
};

export type ToggleAbsoluteScrollMutationVariables = Exact<{
  absoluteScroll: Scalars['Boolean']['input'];
}>;

export type ToggleAbsoluteScrollMutation = {
  readonly __typename?: 'Mutation';
  readonly toggleAbsoluteScroll: {
    readonly __typename?: 'AbsoluteScrollingResponse';
    readonly absoluteScroll: boolean;
  };
};

export type UpdateStackedModalitiesMutationVariables = Exact<{
  stackedModalities: ReadonlyArray<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type UpdateStackedModalitiesMutation = {
  readonly __typename?: 'Mutation';
  readonly updateStackedModalities: {
    readonly __typename?: 'StackedScrollingModalitiesResponse';
    readonly stackedModalities: ReadonlyArray<string>;
  };
};

export type ToggleManualLocalizerLinesMutationVariables = Exact<{
  showManualLocalizerLines: Scalars['Boolean']['input'];
}>;

export type ToggleManualLocalizerLinesMutation = {
  readonly __typename?: 'Mutation';
  readonly toggleManualLocalizerLines: {
    readonly __typename?: 'ManualLocalizerLinesResponse';
    readonly showManualLocalizerLines: boolean;
  };
};

export type ToggleEraseAllToolScopeMutationVariables = Exact<{
  eraseAllToolScope?: InputMaybe<EraseAllToolScope>;
}>;

export type ToggleEraseAllToolScopeMutation = {
  readonly __typename?: 'Mutation';
  readonly toggleEraseAllToolScope?: {
    readonly __typename?: 'EraseAllToolScopeResponse';
    readonly eraseAllToolScope?: EraseAllToolScope | null;
  } | null;
};

export type SetDefaultThumbnailSizeMutationVariables = Exact<{
  thumbnailSizePx: Scalars['Int']['input'];
}>;

export type SetDefaultThumbnailSizeMutation = {
  readonly __typename?: 'Mutation';
  readonly setDefaultThumbnailSize?: {
    readonly __typename?: 'DefaultThumbnailSizeResponse';
    readonly thumbnailSizePx?: number | null;
  } | null;
};

export type ToggleVirtualMonitorSplitMutationVariables = Exact<{
  virtualMonitorSplit?: InputMaybe<VirtualMonitorSplit>;
}>;

export type ToggleVirtualMonitorSplitMutation = {
  readonly __typename?: 'Mutation';
  readonly toggleVirtualMonitorSplit?: {
    readonly __typename?: 'VirtualMonitorSplitResponse';
    readonly virtualMonitorSplit?: VirtualMonitorSplit | null;
  } | null;
};

export type UpdateSeriesSkipSettingsMutationVariables = Exact<{
  seriesSkipSettings?: InputMaybe<SeriesSkipSettingsPayload>;
}>;

export type UpdateSeriesSkipSettingsMutation = {
  readonly __typename?: 'Mutation';
  readonly updateSeriesSkipSettings: {
    readonly __typename?: 'SeriesSkipSettingsResponse';
    readonly seriesSkipSettings?: {
      readonly __typename?: 'SeriesSkipSettings';
      readonly amount?: number | null;
      readonly keepPreviousWhenAvailable: boolean;
    } | null;
  };
};

export type UpdateCustomDicomTagsMutationVariables = Exact<{
  modality: Scalars['String']['input'];
  modalityDicomTags: ReadonlyArray<DicomTagRenderInfoInput> | DicomTagRenderInfoInput;
}>;

export type UpdateCustomDicomTagsMutation = {
  readonly __typename?: 'Mutation';
  readonly updateCustomDicomTags: {
    readonly __typename?: 'CustomDicomTagsResponse';
    readonly modality: string;
    readonly modalityDicomTags: ReadonlyArray<{
      readonly __typename?: 'DicomTagRenderInfo';
      readonly id: string;
      readonly tagId: string;
      readonly enabled: boolean;
      readonly label: string;
    }>;
  };
};

export type GetLastViewedWorklistsQueryVariables = Exact<{ [key: string]: never }>;

export type GetLastViewedWorklistsQuery = {
  readonly __typename?: 'Query';
  readonly me: {
    readonly __typename?: 'User';
    readonly id: string;
    readonly lastViewedWorklists: ReadonlyArray<{
      readonly __typename?: 'WorklistItem';
      readonly smid: string;
      readonly mrn?: string | null;
      readonly status: WorklistItemStatus;
      readonly isActive: boolean;
      readonly hasQcComments?: boolean | null;
      readonly dosage?: string | null;
      readonly studyReason?: string | null;
      readonly studyDescription?: string | null;
      readonly studyDate?: Date | null;
      readonly referringPhysician?: string | null;
      readonly accessionNumber?: string | null;
      readonly techNotes?: string | null;
      readonly patientName?: string | null;
      readonly patientDob?: Date | null;
      readonly patientAge?: string | null;
      readonly patientSex?: string | null;
      readonly date?: Date | null;
      readonly groupId?: string | null;
      readonly modality?: string | null;
      readonly hasDraftReport: boolean;
      readonly examCount?: number | null;
      readonly studies: ReadonlyArray<{
        readonly __typename?: 'Study';
        readonly smid: string;
        readonly created: Date;
        readonly updated: Date;
        readonly instanceUID: string;
        readonly description?: string | null;
        readonly hl7Order?: string | null;
        readonly patientSex?: string | null;
        readonly patientAge?: string | null;
        readonly patientName?: string | null;
        readonly patientDob?: Date | null;
        readonly modality: string;
        readonly bodyPart?: string | null;
        readonly mrn?: string | null;
        readonly studyDate?: Date | null;
        readonly laterality?: string | null;
        readonly provenanceData?: {
          readonly __typename?: 'ProvenanceData';
          readonly matchType?: MatchType | null;
          readonly relevant?: boolean | null;
        } | null;
      }>;
      readonly procedure?: {
        readonly __typename?: 'Procedure';
        readonly smid: string;
        readonly description: string;
        readonly code: string;
      } | null;
      readonly customMergeFields: ReadonlyArray<{
        readonly __typename?: 'CustomMergeField';
        readonly key: string;
        readonly value: string;
      }>;
      readonly claimedBy?: {
        readonly __typename?: 'User';
        readonly id: string;
        readonly firstName: string;
        readonly lastName: string;
      } | null;
      readonly report?: {
        readonly __typename?: 'Report';
        readonly smid: string;
        readonly submittedAt?: Date | null;
        readonly sentAt?: Date | null;
        readonly updated: Date;
        readonly version: number;
        readonly content?: JSON | null;
        readonly owner?: {
          readonly __typename?: 'Owner';
          readonly firstName: string;
          readonly lastName: string;
        } | null;
        readonly addendums: ReadonlyArray<{
          readonly __typename?: 'Addendum';
          readonly created: Date;
          readonly updated: Date;
          readonly submittedAt?: Date | null;
          readonly sentAt?: Date | null;
          readonly smid: string;
          readonly content:
            | {
                readonly __typename?: 'AddendumSironaSlate';
                readonly sironaSlate: ReadonlyArray<JSON>;
              }
            | { readonly __typename?: 'AddendumTextBlob'; readonly textBlob: string };
          readonly owner?: {
            readonly __typename?: 'Owner';
            readonly id: string;
            readonly firstName: string;
            readonly lastName: string;
            readonly email: string;
          } | null;
        }>;
      } | null;
      readonly customStatus?: {
        readonly __typename?: 'WorklistCustomStatus';
        readonly smid: string;
        readonly name: string;
      } | null;
    }>;
  };
};

export type GetHeadingKeywordsQueryVariables = Exact<{ [key: string]: never }>;

export type GetHeadingKeywordsQuery = {
  readonly __typename?: 'Query';
  readonly headingKeywords: {
    readonly __typename?: 'HeadingKeywords';
    readonly exam: ReadonlyArray<string>;
    readonly history: ReadonlyArray<string>;
    readonly technique: ReadonlyArray<string>;
    readonly findings: ReadonlyArray<string>;
    readonly comparison: ReadonlyArray<string>;
    readonly impressions: ReadonlyArray<string>;
  };
};

export type UpdateLastViewedWorklistsMutationVariables = Exact<{
  smids: ReadonlyArray<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type UpdateLastViewedWorklistsMutation = {
  readonly __typename?: 'Mutation';
  readonly updateLastViewedWorklists?: {
    readonly __typename?: 'User';
    readonly lastViewedWorklists: ReadonlyArray<{
      readonly __typename?: 'WorklistItem';
      readonly smid: string;
    }>;
  } | null;
};

export type UpdateWorklistSettingsMutationVariables = Exact<{
  columns?: InputMaybe<ReadonlyArray<WorklistColumnInput> | WorklistColumnInput>;
  favoritedWorklistViews?: InputMaybe<
    ReadonlyArray<Scalars['ID']['input']> | Scalars['ID']['input']
  >;
  savedWorklistViews?: InputMaybe<ReadonlyArray<SavedWorklistViewInput> | SavedWorklistViewInput>;
  savedSortingPreferences?: InputMaybe<
    ReadonlyArray<SavedSortingPreferenceInput> | SavedSortingPreferenceInput
  >;
  openPatientJacketOnRead?: InputMaybe<Scalars['Boolean']['input']>;
  isPatientJacketDocked?: InputMaybe<Scalars['Boolean']['input']>;
  columnsBySurface?: InputMaybe<
    ReadonlyArray<WorklistColumnsBySurfaceInput> | WorklistColumnsBySurfaceInput
  >;
  worklistAutoLoad?: InputMaybe<WorklistAutoLoadInput>;
  patientJacketLayout?: InputMaybe<Scalars['JSON']['input']>;
  lastViewedCustomWorklists?: InputMaybe<
    ReadonlyArray<DefaultCustomWorklistInput> | DefaultCustomWorklistInput
  >;
  defaultCustomWorklistsSettings?: InputMaybe<DefaultCustomWorklistsSettingInput>;
}>;

export type UpdateWorklistSettingsMutation = {
  readonly __typename?: 'Mutation';
  readonly updateWorklistSettings: {
    readonly __typename?: 'WorklistSettings';
    readonly openPatientJacketOnRead: boolean;
    readonly isPatientJacketDocked: boolean;
    readonly favoritedWorklistViews?: ReadonlyArray<string> | null;
    readonly patientJacketLayout?: JSON | null;
    readonly columns: ReadonlyArray<{
      readonly __typename?: 'WorklistColumn';
      readonly id: WorklistColumnId;
      readonly width: number;
      readonly enabled: boolean;
    }>;
    readonly columnsBySurface?: ReadonlyArray<{
      readonly __typename?: 'WorklistColumnsBySurface';
      readonly surface: string;
      readonly columns: ReadonlyArray<{
        readonly __typename?: 'WorklistColumn';
        readonly id: WorklistColumnId;
        readonly width: number;
        readonly enabled: boolean;
      }>;
    }> | null;
    readonly savedWorklistViews?: ReadonlyArray<{
      readonly __typename?: 'SavedWorklistView';
      readonly surface: string;
      readonly views: ReadonlyArray<string>;
    }> | null;
    readonly worklistAutoLoad?: {
      readonly __typename?: 'WorklistAutoLoad';
      readonly surface?: string | null;
    } | null;
    readonly savedSortingPreferences?: ReadonlyArray<{
      readonly __typename?: 'SavedSortingPreference';
      readonly surface: string;
      readonly searchParams: string;
      readonly worklistViewID?: string | null;
    }> | null;
    readonly lastViewedCustomWorklists?: ReadonlyArray<{
      readonly __typename?: 'DefaultCustomWorklist';
      readonly surface: string;
      readonly worklistViewID?: string | null;
    } | null> | null;
    readonly defaultCustomWorklistsSettings?: {
      readonly __typename?: 'DefaultCustomWorklistsSetting';
      readonly isEnabled: boolean;
      readonly defaultCustomWorklists: ReadonlyArray<{
        readonly __typename?: 'DefaultCustomWorklist';
        readonly surface: string;
        readonly worklistViewID?: string | null;
      } | null>;
    } | null;
  };
};

export type GetAllAnnotationsQueryVariables = Exact<{
  worklistIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']> | Scalars['ID']['input']>;
  studyIds?: InputMaybe<ReadonlyArray<Scalars['ID']['input']> | Scalars['ID']['input']>;
  query?: InputMaybe<Scalars['JSON']['input']>;
}>;

export type GetAllAnnotationsQuery = {
  readonly __typename?: 'Query';
  readonly annotations?: ReadonlyArray<
    | {
        readonly __typename?: 'AiUgcAnnotation';
        readonly smid: string;
        readonly type: AnnotationTypes;
        readonly source: AnnotationSource;
        readonly seriesSmid: string;
        readonly aiAnnotationSmid?: string | null;
        readonly pixel_space_representation?: JSON | null;
        readonly data: JSON;
        readonly customLogic?: {
          readonly __typename?: 'CustomAnnotationLogic';
          readonly id: string;
          readonly logic: string;
        } | null;
      }
    | {
        readonly __typename?: 'CadAnnotation';
        readonly smid: string;
        readonly type: AnnotationTypes;
        readonly source: AnnotationSource;
        readonly seriesSmid: string;
        readonly aiAnnotationSmid?: string | null;
        readonly pixel_space_representation?: JSON | null;
        readonly data: JSON;
        readonly customLogic?: {
          readonly __typename?: 'CustomAnnotationLogic';
          readonly id: string;
          readonly logic: string;
        } | null;
      }
    | {
        readonly __typename?: 'GspsAnnotation';
        readonly created?: DateTimeLocalScalarConfig | null;
        readonly smid: string;
        readonly type: AnnotationTypes;
        readonly source: AnnotationSource;
        readonly seriesSmid: string;
        readonly aiAnnotationSmid?: string | null;
        readonly pixel_space_representation?: JSON | null;
        readonly data: JSON;
        readonly owner: {
          readonly __typename?: 'GspsOwner';
          readonly firstName?: string | null;
          readonly lastName?: string | null;
        };
        readonly presentationState: {
          readonly __typename?: 'GspsPresentationState';
          readonly smid: string;
        };
        readonly customLogic?: {
          readonly __typename?: 'CustomAnnotationLogic';
          readonly id: string;
          readonly logic: string;
        } | null;
      }
    | {
        readonly __typename?: 'UgcAnnotation';
        readonly smid: string;
        readonly type: AnnotationTypes;
        readonly source: AnnotationSource;
        readonly seriesSmid: string;
        readonly aiAnnotationSmid?: string | null;
        readonly pixel_space_representation?: JSON | null;
        readonly data: JSON;
        readonly customLogic?: {
          readonly __typename?: 'CustomAnnotationLogic';
          readonly id: string;
          readonly logic: string;
        } | null;
      }
  > | null;
};

export type CreateAnnotationMutationVariables = Exact<{
  seriesSmid: Scalars['ID']['input'];
  type: Scalars['String']['input'];
  aiAnnotationSmid?: InputMaybe<Scalars['ID']['input']>;
  pixel_space_representation?: InputMaybe<Scalars['JSON']['input']>;
  data: Scalars['JSON']['input'];
}>;

export type CreateAnnotationMutation = {
  readonly __typename?: 'Mutation';
  readonly createAnnotation?: {
    readonly __typename?: 'UgcAnnotation';
    readonly smid: string;
    readonly type: AnnotationTypes;
    readonly aiAnnotationSmid?: string | null;
    readonly pixel_space_representation?: JSON | null;
    readonly data: JSON;
    readonly customLogic?: {
      readonly __typename?: 'CustomAnnotationLogic';
      readonly id: string;
      readonly logic: string;
    } | null;
  } | null;
};

export type CreatePresentationStateMutationVariables = Exact<{
  name: Scalars['String']['input'];
  annotationSmids: ReadonlyArray<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type CreatePresentationStateMutation = {
  readonly __typename?: 'Mutation';
  readonly createPresentationState?: {
    readonly __typename?: 'PresentationState';
    readonly name: string;
    readonly annotationSmids: ReadonlyArray<string>;
  } | null;
};

export type UpdateAnnotationMutationVariables = Exact<{
  smid: Scalars['ID']['input'];
  pixel_space_representation?: InputMaybe<Scalars['JSON']['input']>;
  data: Scalars['JSON']['input'];
}>;

export type UpdateAnnotationMutation = {
  readonly __typename?: 'Mutation';
  readonly updateAnnotation?: {
    readonly __typename?: 'UgcAnnotation';
    readonly smid: string;
    readonly type: AnnotationTypes;
    readonly aiAnnotationSmid?: string | null;
    readonly pixel_space_representation?: JSON | null;
    readonly data: JSON;
    readonly customLogic?: {
      readonly __typename?: 'CustomAnnotationLogic';
      readonly id: string;
      readonly logic: string;
    } | null;
  } | null;
};

export type GetWorklistItemAnalyticsQueryVariables = Exact<{
  smid: Scalars['ID']['input'];
}>;

export type GetWorklistItemAnalyticsQuery = {
  readonly __typename?: 'Query';
  readonly worklistItem?: {
    readonly __typename?: 'WorklistItem';
    readonly smid: string;
    readonly modality?: string | null;
    readonly bodyPart?: string | null;
    readonly studyDescription?: string | null;
  } | null;
};

export type GetWorklistItemQueryVariables = Exact<{
  smid: Scalars['ID']['input'];
}>;

export type GetWorklistItemQuery = {
  readonly __typename?: 'Query';
  readonly worklistItem?: {
    readonly __typename?: 'WorklistItem';
    readonly smid: string;
    readonly mrn?: string | null;
    readonly status: WorklistItemStatus;
    readonly isActive: boolean;
    readonly hasQcComments?: boolean | null;
    readonly dosage?: string | null;
    readonly studyReason?: string | null;
    readonly studyDescription?: string | null;
    readonly studyDate?: Date | null;
    readonly referringPhysician?: string | null;
    readonly accessionNumber?: string | null;
    readonly techNotes?: string | null;
    readonly patientName?: string | null;
    readonly patientDob?: Date | null;
    readonly patientAge?: string | null;
    readonly patientSex?: string | null;
    readonly date?: Date | null;
    readonly groupId?: string | null;
    readonly modality?: string | null;
    readonly hasDraftReport: boolean;
    readonly examCount?: number | null;
    readonly studies: ReadonlyArray<{
      readonly __typename?: 'Study';
      readonly smid: string;
      readonly created: Date;
      readonly updated: Date;
      readonly instanceUID: string;
      readonly description?: string | null;
      readonly hl7Order?: string | null;
      readonly patientSex?: string | null;
      readonly patientAge?: string | null;
      readonly patientName?: string | null;
      readonly patientDob?: Date | null;
      readonly modality: string;
      readonly bodyPart?: string | null;
      readonly mrn?: string | null;
      readonly studyDate?: Date | null;
      readonly laterality?: string | null;
      readonly provenanceData?: {
        readonly __typename?: 'ProvenanceData';
        readonly matchType?: MatchType | null;
        readonly relevant?: boolean | null;
      } | null;
    }>;
    readonly procedure?: {
      readonly __typename?: 'Procedure';
      readonly smid: string;
      readonly description: string;
      readonly code: string;
    } | null;
    readonly customMergeFields: ReadonlyArray<{
      readonly __typename?: 'CustomMergeField';
      readonly key: string;
      readonly value: string;
    }>;
    readonly claimedBy?: {
      readonly __typename?: 'User';
      readonly id: string;
      readonly firstName: string;
      readonly lastName: string;
    } | null;
    readonly report?: {
      readonly __typename?: 'Report';
      readonly smid: string;
      readonly submittedAt?: Date | null;
      readonly sentAt?: Date | null;
      readonly updated: Date;
      readonly version: number;
      readonly content?: JSON | null;
      readonly owner?: {
        readonly __typename?: 'Owner';
        readonly firstName: string;
        readonly lastName: string;
      } | null;
      readonly addendums: ReadonlyArray<{
        readonly __typename?: 'Addendum';
        readonly created: Date;
        readonly updated: Date;
        readonly submittedAt?: Date | null;
        readonly sentAt?: Date | null;
        readonly smid: string;
        readonly content:
          | {
              readonly __typename?: 'AddendumSironaSlate';
              readonly sironaSlate: ReadonlyArray<JSON>;
            }
          | { readonly __typename?: 'AddendumTextBlob'; readonly textBlob: string };
        readonly owner?: {
          readonly __typename?: 'Owner';
          readonly id: string;
          readonly firstName: string;
          readonly lastName: string;
          readonly email: string;
        } | null;
      }>;
    } | null;
    readonly customStatus?: {
      readonly __typename?: 'WorklistCustomStatus';
      readonly smid: string;
      readonly name: string;
    } | null;
  } | null;
};

export type GetWorklistItemByStudySmidQueryVariables = Exact<{
  smid: Scalars['ID']['input'];
}>;

export type GetWorklistItemByStudySmidQuery = {
  readonly __typename?: 'Query';
  readonly worklistItemByStudySmid?: {
    readonly __typename?: 'WorklistItem';
    readonly smid: string;
    readonly mrn?: string | null;
    readonly status: WorklistItemStatus;
    readonly isActive: boolean;
    readonly hasQcComments?: boolean | null;
    readonly dosage?: string | null;
    readonly studyReason?: string | null;
    readonly studyDescription?: string | null;
    readonly studyDate?: Date | null;
    readonly referringPhysician?: string | null;
    readonly accessionNumber?: string | null;
    readonly techNotes?: string | null;
    readonly patientName?: string | null;
    readonly patientDob?: Date | null;
    readonly patientAge?: string | null;
    readonly patientSex?: string | null;
    readonly date?: Date | null;
    readonly groupId?: string | null;
    readonly modality?: string | null;
    readonly hasDraftReport: boolean;
    readonly examCount?: number | null;
    readonly studies: ReadonlyArray<{
      readonly __typename?: 'Study';
      readonly smid: string;
      readonly created: Date;
      readonly updated: Date;
      readonly instanceUID: string;
      readonly description?: string | null;
      readonly hl7Order?: string | null;
      readonly patientSex?: string | null;
      readonly patientAge?: string | null;
      readonly patientName?: string | null;
      readonly patientDob?: Date | null;
      readonly modality: string;
      readonly bodyPart?: string | null;
      readonly mrn?: string | null;
      readonly studyDate?: Date | null;
      readonly laterality?: string | null;
    }>;
    readonly procedure?: {
      readonly __typename?: 'Procedure';
      readonly smid: string;
      readonly description: string;
      readonly code: string;
    } | null;
    readonly customMergeFields: ReadonlyArray<{
      readonly __typename?: 'CustomMergeField';
      readonly key: string;
      readonly value: string;
    }>;
    readonly claimedBy?: {
      readonly __typename?: 'User';
      readonly id: string;
      readonly firstName: string;
      readonly lastName: string;
    } | null;
    readonly report?: {
      readonly __typename?: 'Report';
      readonly smid: string;
      readonly submittedAt?: Date | null;
      readonly sentAt?: Date | null;
      readonly updated: Date;
      readonly version: number;
      readonly content?: JSON | null;
      readonly owner?: {
        readonly __typename?: 'Owner';
        readonly firstName: string;
        readonly lastName: string;
      } | null;
      readonly addendums: ReadonlyArray<{
        readonly __typename?: 'Addendum';
        readonly created: Date;
        readonly updated: Date;
        readonly submittedAt?: Date | null;
        readonly sentAt?: Date | null;
        readonly smid: string;
        readonly content:
          | {
              readonly __typename?: 'AddendumSironaSlate';
              readonly sironaSlate: ReadonlyArray<JSON>;
            }
          | { readonly __typename?: 'AddendumTextBlob'; readonly textBlob: string };
        readonly owner?: {
          readonly __typename?: 'Owner';
          readonly id: string;
          readonly firstName: string;
          readonly lastName: string;
          readonly email: string;
        } | null;
      }>;
    } | null;
    readonly customStatus?: {
      readonly __typename?: 'WorklistCustomStatus';
      readonly smid: string;
      readonly name: string;
    } | null;
  } | null;
};

export type AssignExamCountMutationVariables = Exact<{
  input: AssignExamCountInput;
}>;

export type AssignExamCountMutation = {
  readonly __typename?: 'Mutation';
  readonly assignExamCount?: {
    readonly __typename?: 'WorklistItem';
    readonly smid: string;
    readonly examCount?: number | null;
  } | null;
};

export type AssignExamsToUserMutationVariables = Exact<{
  userSmid: Scalars['ID']['input'];
  worklistItemSmids: ReadonlyArray<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type AssignExamsToUserMutation = {
  readonly __typename?: 'Mutation';
  readonly assignExamsToUser: {
    readonly __typename?: 'AssignExamsToUserResponse';
    readonly assignedExams: ReadonlyArray<{
      readonly __typename?: 'WorklistItem';
      readonly smid: string;
      readonly claimedBy?: {
        readonly __typename?: 'User';
        readonly id: string;
        readonly firstName: string;
        readonly lastName: string;
      } | null;
    }>;
    readonly failedExams: ReadonlyArray<{
      readonly __typename?: 'FailedAssignExamSmid';
      readonly smid: string;
      readonly errorReason: string;
    }>;
  };
};

export type GetWorklistItemAuditLogsQueryVariables = Exact<{
  smid: Scalars['ID']['input'];
}>;

export type GetWorklistItemAuditLogsQuery = {
  readonly __typename?: 'Query';
  readonly worklistItemAuditLogs: ReadonlyArray<{
    readonly __typename?: 'WorklistItemAuditLogItem';
    readonly createdAt: Date;
    readonly customStatus?: string | null;
    readonly groupId?: string | null;
    readonly status?: string | null;
    readonly actor?: {
      readonly __typename?: 'WorklistItemAuditLogUser';
      readonly id: string;
      readonly firstName: string;
      readonly lastName: string;
    } | null;
    readonly claimedBy?: {
      readonly __typename?: 'WorklistItemAuditLogUser';
      readonly id: string;
      readonly firstName: string;
      readonly lastName: string;
    } | null;
  }>;
};

export type GetWorklistCustomStatusesQueryVariables = Exact<{ [key: string]: never }>;

export type GetWorklistCustomStatusesQuery = {
  readonly __typename?: 'Query';
  readonly worklistCustomStatuses: ReadonlyArray<{
    readonly __typename?: 'WorklistCustomStatus';
    readonly smid: string;
    readonly name: string;
  }>;
};

export type GetPatientJacketQueryVariables = Exact<{
  smid: Scalars['ID']['input'];
  aiMode: Scalars['Boolean']['input'];
}>;

export type GetPatientJacketQuery = {
  readonly __typename?: 'Query';
  readonly worklistItem?: {
    readonly __typename?: 'WorklistItem';
    readonly mrn?: string | null;
    readonly summary?: {
      readonly __typename?: 'WorklistItemSummary';
      readonly patientHistory: string;
      readonly relevantFindings: JSON;
    } | null;
  } | null;
};

export type GetWorklistQueryVariables = Exact<{
  cursor?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sortOrder?: InputMaybe<ReadonlyArray<SortOrder> | SortOrder>;
  sortColumn?: InputMaybe<ReadonlyArray<SortColumn> | SortColumn>;
  claimedBy?: InputMaybe<Scalars['ID']['input']>;
  site?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>
  >;
  patientType?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>
  >;
  patientSex?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>
  >;
  patientDob?: InputMaybe<
    | ReadonlyArray<InputMaybe<Scalars['DateTime']['input']>>
    | InputMaybe<Scalars['DateTime']['input']>
  >;
  modality?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>
  >;
  priority?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>
  >;
  status?: InputMaybe<WorklistItemStatus>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  smids?: InputMaybe<ReadonlyArray<Scalars['ID']['input']> | Scalars['ID']['input']>;
  studyDate?: InputMaybe<
    | ReadonlyArray<InputMaybe<Scalars['DateTime']['input']>>
    | InputMaybe<Scalars['DateTime']['input']>
  >;
  submittedAt?: InputMaybe<
    | ReadonlyArray<InputMaybe<Scalars['DateTime']['input']>>
    | InputMaybe<Scalars['DateTime']['input']>
  >;
  customStatus?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>
  >;
  mrn?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>
  >;
  mrnPartial?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>
  >;
  accessionNumber?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>
  >;
  accessionNumberPartial?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>
  >;
  activeViews?: InputMaybe<ReadonlyArray<Scalars['ID']['input']> | Scalars['ID']['input']>;
  issuer?: InputMaybe<ReadonlyArray<Scalars['String']['input']> | Scalars['String']['input']>;
  hasDraftReport?: InputMaybe<Scalars['Boolean']['input']>;
  studyDescription?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>
  >;
  bodyPart?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>
  >;
  patientName?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>
  >;
  referringPhysician?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>
  >;
  priorityFilter?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>
  >;
}>;

export type GetWorklistQuery = {
  readonly __typename?: 'Query';
  readonly worklistItems: {
    readonly __typename?: 'WorklistItems';
    readonly totalCount: number;
    readonly filteredCount: number;
    readonly next?: string | null;
    readonly items: ReadonlyArray<{
      readonly __typename?: 'WorklistItem';
      readonly smid: string;
      readonly created?: Date | null;
      readonly updated?: Date | null;
      readonly studyDate?: Date | null;
      readonly submittedAt?: Date | null;
      readonly patientName?: string | null;
      readonly patientAge?: string | null;
      readonly patientSex?: string | null;
      readonly patientDob?: Date | null;
      readonly patientType?: string | null;
      readonly accessionNumber?: string | null;
      readonly hasQcComments?: boolean | null;
      readonly bodyPart?: string | null;
      readonly modality?: string | null;
      readonly referringPhysician?: string | null;
      readonly referringPhysicianLastName?: string | null;
      readonly referringPhysicianFirstName?: string | null;
      readonly referringPhysicianMiddleName?: string | null;
      readonly site?: string | null;
      readonly status: WorklistItemStatus;
      readonly isActive: boolean;
      readonly mrn?: string | null;
      readonly priority?: string | null;
      readonly studyDescription?: string | null;
      readonly dosage?: string | null;
      readonly studyReason?: string | null;
      readonly techNotes?: string | null;
      readonly isStat: boolean;
      readonly groupId?: string | null;
      readonly hasDraftReport: boolean;
      readonly dicomInstanceCount?: string | null;
      readonly firstVerifiedStatusDate?: Date | null;
      readonly studies: ReadonlyArray<{ readonly __typename?: 'Study'; readonly smid: string }>;
      readonly claimedBy?: {
        readonly __typename?: 'User';
        readonly id: string;
        readonly firstName: string;
        readonly lastName: string;
      } | null;
      readonly report?: {
        readonly __typename?: 'Report';
        readonly smid: string;
        readonly submittedAt?: Date | null;
        readonly sentAt?: Date | null;
        readonly owner?: {
          readonly __typename?: 'Owner';
          readonly firstName: string;
          readonly lastName: string;
        } | null;
        readonly addendums: ReadonlyArray<{
          readonly __typename?: 'Addendum';
          readonly created: Date;
          readonly updated: Date;
          readonly submittedAt?: Date | null;
          readonly sentAt?: Date | null;
          readonly smid: string;
          readonly content:
            | {
                readonly __typename?: 'AddendumSironaSlate';
                readonly sironaSlate: ReadonlyArray<JSON>;
              }
            | { readonly __typename?: 'AddendumTextBlob'; readonly textBlob: string };
          readonly owner?: {
            readonly __typename?: 'Owner';
            readonly id: string;
            readonly firstName: string;
            readonly lastName: string;
            readonly email: string;
          } | null;
        }>;
      } | null;
      readonly customStatus?: {
        readonly __typename?: 'WorklistCustomStatus';
        readonly smid: string;
        readonly name: string;
      } | null;
    }>;
  };
};

export type GetGroupableWorklistItemsQueryVariables = Exact<{
  mrn: Scalars['String']['input'];
  patientName: Scalars['String']['input'];
}>;

export type GetGroupableWorklistItemsQuery = {
  readonly __typename?: 'Query';
  readonly groupableWorklistItems: ReadonlyArray<{
    readonly __typename?: 'WorklistItem';
    readonly smid: string;
    readonly studyDescription?: string | null;
    readonly studyDate?: Date | null;
    readonly accessionNumber?: string | null;
    readonly groupId?: string | null;
    readonly claimedBy?: {
      readonly __typename?: 'User';
      readonly id: string;
      readonly firstName: string;
      readonly lastName: string;
    } | null;
  }>;
};

export type GetGroupedWorklistItemsQueryVariables = Exact<{
  groupId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type GetGroupedWorklistItemsQuery = {
  readonly __typename?: 'Query';
  readonly groupedWorklistItems: ReadonlyArray<{
    readonly __typename?: 'WorklistItem';
    readonly smid: string;
    readonly accessionNumber?: string | null;
    readonly patientAge?: string | null;
    readonly patientSex?: string | null;
    readonly dosage?: string | null;
    readonly studyDescription?: string | null;
    readonly studyReason?: string | null;
    readonly studyDate?: Date | null;
    readonly referringPhysician?: string | null;
    readonly techNotes?: string | null;
    readonly studies: ReadonlyArray<{
      readonly __typename?: 'Study';
      readonly laterality?: string | null;
      readonly studyDate?: Date | null;
    }>;
    readonly customMergeFields: ReadonlyArray<{
      readonly __typename?: 'CustomMergeField';
      readonly key: string;
      readonly value: string;
    }>;
  }>;
};

export type CreateWorklistItemGroupMutationVariables = Exact<{
  worklistItemSmids: ReadonlyArray<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type CreateWorklistItemGroupMutation = {
  readonly __typename?: 'Mutation';
  readonly createWorklistItemGroup: string;
};

export type UpdateWorklistItemGroupMutationVariables = Exact<{
  groupId: Scalars['ID']['input'];
  worklistItemSmids: ReadonlyArray<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type UpdateWorklistItemGroupMutation = {
  readonly __typename?: 'Mutation';
  readonly updateWorklistItemGroup: ReadonlyArray<string>;
};

export type DeleteWorklistItemGroupMutationVariables = Exact<{
  groupId: Scalars['ID']['input'];
}>;

export type DeleteWorklistItemGroupMutation = {
  readonly __typename?: 'Mutation';
  readonly deleteWorklistItemGroup?: {
    readonly __typename?: 'Response';
    readonly status?: number | null;
  } | null;
};

export type GetStudyQueryVariables = Exact<{
  smid: Scalars['ID']['input'];
}>;

export type GetStudyQuery = {
  readonly __typename?: 'Query';
  readonly study: {
    readonly __typename?: 'Study';
    readonly smid: string;
    readonly created: Date;
    readonly updated: Date;
    readonly instanceUID: string;
    readonly description?: string | null;
    readonly hl7Order?: string | null;
    readonly accessionNumber?: string | null;
    readonly seriesCount: number;
    readonly patientSex?: string | null;
    readonly patientAge?: string | null;
    readonly patientName?: string | null;
    readonly patientDob?: Date | null;
    readonly modality: string;
    readonly bodyPart?: string | null;
    readonly mrn?: string | null;
    readonly studyDate?: Date | null;
    readonly laterality?: string | null;
    readonly seriesList: ReadonlyArray<{
      readonly __typename?: 'Series';
      readonly smid: string;
      readonly description?: string | null;
      readonly seriesInstanceUID: string;
      readonly seriesDateTimeLocal?: DateTimeLocalScalarConfig | null;
      readonly frameOfReferenceUID?: string | null;
      readonly seriesNumber?: number | null;
      readonly is3Dable: boolean;
      readonly study: { readonly __typename?: 'Study'; readonly smid: string };
      readonly classifications?: ReadonlyArray<{
        readonly __typename?: 'SeriesClassification';
        readonly seriesDescription?: string | null;
        readonly studySmid?: string | null;
        readonly seriesSmid?: string | null;
        readonly seriesNumber?: number | null;
        readonly seriesDate?: string | null;
        readonly seriesTime?: string | null;
        readonly seriesCriteria?: {
          readonly __typename?: 'SeriesCriteria';
          readonly anatomic_focus?: ReadonlyArray<{
            readonly __typename?: 'ClassificationSummary';
            readonly display_name?: string | null;
            readonly ontology_id?: string | null;
          } | null> | null;
        } | null;
      } | null> | null;
      readonly imageRegistrations: ReadonlyArray<{
        readonly __typename?: 'ImageRegistration';
        readonly smid: string;
        readonly created: Date;
        readonly codeVersion: string;
        readonly modelName: string;
        readonly modelVersion: string;
        readonly seriesFixed: string;
        readonly seriesMoving: string;
        readonly dataType: string;
        readonly fixedFrameOfReferenceUid: string;
        readonly movingFrameOfReferenceUid: string;
        readonly transformMatrix: [
          [number, number, number, number],
          [number, number, number, number],
          [number, number, number, number],
          [number, number, number, number],
        ];
      }>;
    }>;
    readonly stackedFrames: ReadonlyArray<
      | {
          readonly __typename?: 'LayeredStack';
          readonly smid: string;
          readonly type: string;
          readonly study: { readonly __typename?: 'Study'; readonly smid: string };
          readonly stackLayers: ReadonlyArray<{
            readonly __typename?: 'Layer';
            readonly stackSmid: string;
            readonly index: number;
          }>;
        }
      | {
          readonly __typename?: 'SingleLayerStack';
          readonly smid: string;
          readonly type: string;
          readonly image: string;
          readonly is3Dable: boolean;
          readonly frames: ReadonlyArray<FrameDataFragment>;
          readonly series: { readonly __typename?: 'Series'; readonly smid: string };
          readonly study: { readonly __typename?: 'Study'; readonly smid: string };
          readonly supportedRenderers: {
            readonly __typename?: 'SupportedRenderers';
            readonly vtk: RendererSupport;
          };
          readonly imageParams: {
            readonly __typename?: 'ImageParams';
            readonly origin: [number, number, number];
            readonly spacing: [number, number, number];
            readonly dimensions: [number, number, number];
            readonly extent: [number, number, number, number, number, number];
            readonly direction: [
              number,
              number,
              number,
              number,
              number,
              number,
              number,
              number,
              number,
            ];
            readonly isMultiAxes: boolean;
            readonly colorWindow?: number | null;
            readonly colorLevel?: number | null;
          };
        }
    >;
  };
};

export type GetComparativeStudiesQueryVariables = Exact<{
  searchSmid: Scalars['ID']['input'];
  matchTypes?: InputMaybe<ReadonlyArray<MatchType> | MatchType>;
  bodyPart?: InputMaybe<ReadonlyArray<Scalars['String']['input']> | Scalars['String']['input']>;
}>;

export type GetComparativeStudiesQuery = {
  readonly __typename?: 'Query';
  readonly bodyPartMatchedComparativeStudies: {
    readonly __typename?: 'StudyItems';
    readonly items: ReadonlyArray<{
      readonly __typename?: 'Study';
      readonly accessionNumber?: string | null;
      readonly description?: string | null;
      readonly modality: string;
      readonly mrn?: string | null;
      readonly patientAge?: string | null;
      readonly smid: string;
      readonly studyDate?: Date | null;
      readonly seriesCount: number;
    }>;
  };
  readonly allComparativeStudies: {
    readonly __typename?: 'StudyItems';
    readonly totalCount: number;
  };
};

export type GetStudiesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  cursor?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  modality?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>
  >;
  mrn?: InputMaybe<Scalars['String']['input']>;
  bodyPart?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>
  >;
  studyDate?: InputMaybe<
    | ReadonlyArray<InputMaybe<Scalars['DateTime']['input']>>
    | InputMaybe<Scalars['DateTime']['input']>
  >;
  sortOrder?: InputMaybe<SortOrder>;
  sortColumn?: InputMaybe<SortColumn>;
  searchSmid?: InputMaybe<Scalars['ID']['input']>;
  matchTypes?: InputMaybe<ReadonlyArray<MatchType> | MatchType>;
}>;

export type GetStudiesQuery = {
  readonly __typename?: 'Query';
  readonly studies: {
    readonly __typename?: 'StudyItems';
    readonly totalCount: number;
    readonly next?: string | null;
    readonly items: ReadonlyArray<{
      readonly __typename?: 'Study';
      readonly smid: string;
      readonly created: Date;
      readonly updated: Date;
      readonly instanceUID: string;
      readonly description?: string | null;
      readonly hl7Order?: string | null;
      readonly accessionNumber?: string | null;
      readonly seriesCount: number;
      readonly patientSex?: string | null;
      readonly patientAge?: string | null;
      readonly patientName?: string | null;
      readonly patientDob?: Date | null;
      readonly modality: string;
      readonly bodyPart?: string | null;
      readonly mrn?: string | null;
      readonly studyDate?: Date | null;
      readonly laterality?: string | null;
      readonly seriesList: ReadonlyArray<{
        readonly __typename?: 'Series';
        readonly smid: string;
        readonly description?: string | null;
        readonly seriesInstanceUID: string;
        readonly seriesDateTimeLocal?: DateTimeLocalScalarConfig | null;
        readonly frameOfReferenceUID?: string | null;
        readonly seriesNumber?: number | null;
        readonly is3Dable: boolean;
        readonly study: { readonly __typename?: 'Study'; readonly smid: string };
        readonly classifications?: ReadonlyArray<{
          readonly __typename?: 'SeriesClassification';
          readonly seriesDescription?: string | null;
          readonly studySmid?: string | null;
          readonly seriesSmid?: string | null;
          readonly seriesNumber?: number | null;
          readonly seriesDate?: string | null;
          readonly seriesTime?: string | null;
          readonly seriesCriteria?: {
            readonly __typename?: 'SeriesCriteria';
            readonly anatomic_focus?: ReadonlyArray<{
              readonly __typename?: 'ClassificationSummary';
              readonly display_name?: string | null;
              readonly ontology_id?: string | null;
            } | null> | null;
          } | null;
        } | null> | null;
        readonly imageRegistrations: ReadonlyArray<{
          readonly __typename?: 'ImageRegistration';
          readonly smid: string;
          readonly created: Date;
          readonly codeVersion: string;
          readonly modelName: string;
          readonly modelVersion: string;
          readonly seriesFixed: string;
          readonly seriesMoving: string;
          readonly dataType: string;
          readonly fixedFrameOfReferenceUid: string;
          readonly movingFrameOfReferenceUid: string;
          readonly transformMatrix: [
            [number, number, number, number],
            [number, number, number, number],
            [number, number, number, number],
            [number, number, number, number],
          ];
        }>;
      }>;
      readonly stackedFrames: ReadonlyArray<
        | {
            readonly __typename?: 'LayeredStack';
            readonly smid: string;
            readonly type: string;
            readonly study: { readonly __typename?: 'Study'; readonly smid: string };
            readonly stackLayers: ReadonlyArray<{
              readonly __typename?: 'Layer';
              readonly stackSmid: string;
              readonly index: number;
            }>;
          }
        | {
            readonly __typename?: 'SingleLayerStack';
            readonly smid: string;
            readonly type: string;
            readonly image: string;
            readonly is3Dable: boolean;
            readonly frames: ReadonlyArray<FrameDataFragment>;
            readonly series: { readonly __typename?: 'Series'; readonly smid: string };
            readonly study: { readonly __typename?: 'Study'; readonly smid: string };
            readonly supportedRenderers: {
              readonly __typename?: 'SupportedRenderers';
              readonly vtk: RendererSupport;
            };
            readonly imageParams: {
              readonly __typename?: 'ImageParams';
              readonly origin: [number, number, number];
              readonly spacing: [number, number, number];
              readonly dimensions: [number, number, number];
              readonly extent: [number, number, number, number, number, number];
              readonly direction: [
                number,
                number,
                number,
                number,
                number,
                number,
                number,
                number,
                number,
              ];
              readonly isMultiAxes: boolean;
              readonly colorWindow?: number | null;
              readonly colorLevel?: number | null;
            };
          }
      >;
    }>;
  };
};

export type GetStudiesWithoutSeriesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  cursor?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  modality?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>
  >;
  mrn?: InputMaybe<Scalars['String']['input']>;
  bodyPart?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>
  >;
  studyDate?: InputMaybe<
    | ReadonlyArray<InputMaybe<Scalars['DateTime']['input']>>
    | InputMaybe<Scalars['DateTime']['input']>
  >;
  sortOrder?: InputMaybe<SortOrder>;
  sortColumn?: InputMaybe<SortColumn>;
  searchSmid?: InputMaybe<Scalars['ID']['input']>;
  matchTypes?: InputMaybe<ReadonlyArray<MatchType> | MatchType>;
  overrideShowRelevantPriorsOnly?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GetStudiesWithoutSeriesQuery = {
  readonly __typename?: 'Query';
  readonly studies: {
    readonly __typename?: 'StudyItems';
    readonly totalCount: number;
    readonly next?: string | null;
    readonly items: ReadonlyArray<{
      readonly __typename?: 'Study';
      readonly smid: string;
      readonly created: Date;
      readonly updated: Date;
      readonly instanceUID: string;
      readonly description?: string | null;
      readonly hl7Order?: string | null;
      readonly patientSex?: string | null;
      readonly patientAge?: string | null;
      readonly patientName?: string | null;
      readonly patientDob?: Date | null;
      readonly modality: string;
      readonly bodyPart?: string | null;
      readonly mrn?: string | null;
      readonly studyDate?: Date | null;
      readonly laterality?: string | null;
      readonly provenanceData?: {
        readonly __typename?: 'ProvenanceData';
        readonly matchType?: MatchType | null;
        readonly relevant?: boolean | null;
      } | null;
    }>;
  };
};

export type GetStudiesWithoutSeriesBySmidsQueryVariables = Exact<{
  smids: ReadonlyArray<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type GetStudiesWithoutSeriesBySmidsQuery = {
  readonly __typename?: 'Query';
  readonly studiesBySmid: ReadonlyArray<{
    readonly __typename?: 'Study';
    readonly smid: string;
    readonly created: Date;
    readonly updated: Date;
    readonly instanceUID: string;
    readonly description?: string | null;
    readonly hl7Order?: string | null;
    readonly patientSex?: string | null;
    readonly patientAge?: string | null;
    readonly patientName?: string | null;
    readonly patientDob?: Date | null;
    readonly modality: string;
    readonly bodyPart?: string | null;
    readonly mrn?: string | null;
    readonly studyDate?: Date | null;
    readonly laterality?: string | null;
    readonly provenanceData?: {
      readonly __typename?: 'ProvenanceData';
      readonly matchType?: MatchType | null;
      readonly relevant?: boolean | null;
    } | null;
  }>;
};

export type GetSeriesFromStudiesQueryVariables = Exact<{
  smids: ReadonlyArray<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type GetSeriesFromStudiesQuery = {
  readonly __typename?: 'Query';
  readonly studiesBySmid: ReadonlyArray<{
    readonly __typename?: 'Study';
    readonly seriesList: ReadonlyArray<{
      readonly __typename?: 'Series';
      readonly smid: string;
      readonly seriesInstanceUID: string;
      readonly description?: string | null;
      readonly SOPClassUID?: string | null;
      readonly seriesDateTimeLocal?: DateTimeLocalScalarConfig | null;
      readonly frameOfReferenceUID?: string | null;
      readonly seriesNumber?: number | null;
    }>;
  }>;
};

export type GetClaimedItemsQueryVariables = Exact<{
  claimedBy: Scalars['ID']['input'];
}>;

export type GetClaimedItemsQuery = {
  readonly __typename?: 'Query';
  readonly worklistItems: {
    readonly __typename?: 'WorklistItems';
    readonly totalCount: number;
    readonly items: ReadonlyArray<{ readonly __typename?: 'WorklistItem'; readonly smid: string }>;
  };
};

export type GetWorklistFilterDropdownItemsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  fieldName?: InputMaybe<Scalars['String']['input']>;
  searchValue?: InputMaybe<Scalars['String']['input']>;
  claimedBy?: InputMaybe<Scalars['ID']['input']>;
  site?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>
  >;
  patientType?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>
  >;
  patientSex?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>
  >;
  patientDob?: InputMaybe<
    | ReadonlyArray<InputMaybe<Scalars['DateTime']['input']>>
    | InputMaybe<Scalars['DateTime']['input']>
  >;
  modality?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>
  >;
  priority?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>
  >;
  status?: InputMaybe<WorklistItemStatus>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  smids?: InputMaybe<ReadonlyArray<Scalars['ID']['input']> | Scalars['ID']['input']>;
  studyDate?: InputMaybe<
    | ReadonlyArray<InputMaybe<Scalars['DateTime']['input']>>
    | InputMaybe<Scalars['DateTime']['input']>
  >;
  submittedAt?: InputMaybe<
    | ReadonlyArray<InputMaybe<Scalars['DateTime']['input']>>
    | InputMaybe<Scalars['DateTime']['input']>
  >;
  customStatus?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>
  >;
  mrn?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>
  >;
  mrnPartial?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>
  >;
  accessionNumber?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>
  >;
  accessionNumberPartial?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>
  >;
  activeViews?: InputMaybe<ReadonlyArray<Scalars['ID']['input']> | Scalars['ID']['input']>;
  issuer?: InputMaybe<ReadonlyArray<Scalars['String']['input']> | Scalars['String']['input']>;
  hasDraftReport?: InputMaybe<Scalars['Boolean']['input']>;
  studyDescription?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>
  >;
  bodyPart?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>
  >;
  patientName?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>
  >;
  referringPhysician?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>
  >;
  priorityFilter?: InputMaybe<
    ReadonlyArray<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>
  >;
}>;

export type GetWorklistFilterDropdownItemsQuery = {
  readonly __typename?: 'Query';
  readonly worklistFilterDropdownItems?: {
    readonly __typename?: 'WorklistFilterDropdownItems';
    readonly totalCount: number;
    readonly items?: ReadonlyArray<{
      readonly __typename?: 'WorklistFilterDropdownItem';
      readonly count: number;
      readonly value: string;
      readonly label: string;
    }> | null;
  } | null;
};

export type GetMyQueueQueryVariables = Exact<{
  claimedBy: Scalars['ID']['input'];
}>;

export type GetMyQueueQuery = {
  readonly __typename?: 'Query';
  readonly worklistItems: {
    readonly __typename?: 'WorklistItems';
    readonly totalCount: number;
    readonly items: ReadonlyArray<{
      readonly __typename?: 'WorklistItem';
      readonly smid: string;
      readonly groupId?: string | null;
      readonly report?: {
        readonly __typename?: 'Report';
        readonly smid: string;
        readonly submittedAt?: Date | null;
      } | null;
    }>;
  };
};

export type GetStudyDescriptionsQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>;
  cursor?: InputMaybe<Scalars['ID']['input']>;
}>;

export type GetStudyDescriptionsQuery = {
  readonly __typename?: 'Query';
  readonly studyDescriptions: {
    readonly __typename?: 'StudyDescriptions';
    readonly totalCount: number;
    readonly cursor?: string | null;
    readonly next?: string | null;
    readonly items: ReadonlyArray<{
      readonly __typename?: 'StudyDescription';
      readonly smid: string;
      readonly description: string;
      readonly autoloadTemplate?: {
        readonly __typename?: 'ReportTemplate';
        readonly id: string;
        readonly name: string;
      } | null;
    }>;
  };
};

export type GetProcedureQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetProcedureQuery = {
  readonly __typename?: 'Query';
  readonly procedure: {
    readonly __typename?: 'Procedure';
    readonly smid: string;
    readonly code: string;
    readonly description: string;
    readonly autoloadTemplate?: {
      readonly __typename?: 'ReportTemplate';
      readonly id: string;
      readonly name: string;
    } | null;
  };
};

export type GetProceduresQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>;
  cursor?: InputMaybe<Scalars['ID']['input']>;
}>;

export type GetProceduresQuery = {
  readonly __typename?: 'Query';
  readonly procedures: {
    readonly __typename?: 'Procedures';
    readonly totalCount: number;
    readonly cursor?: string | null;
    readonly next?: string | null;
    readonly items: ReadonlyArray<{
      readonly __typename?: 'Procedure';
      readonly smid: string;
      readonly code: string;
      readonly description: string;
      readonly autoloadTemplate?: {
        readonly __typename?: 'ReportTemplate';
        readonly id: string;
        readonly name: string;
      } | null;
    }>;
  };
};

export type GetProceduresBySmidQueryVariables = Exact<{
  smids: ReadonlyArray<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type GetProceduresBySmidQuery = {
  readonly __typename?: 'Query';
  readonly proceduresBySmid: ReadonlyArray<{
    readonly __typename?: 'Procedure';
    readonly smid: string;
    readonly code: string;
    readonly description: string;
    readonly autoloadTemplate?: {
      readonly __typename?: 'ReportTemplate';
      readonly id: string;
      readonly name: string;
    } | null;
  }>;
};

export type GetPrioritiesQueryVariables = Exact<{ [key: string]: never }>;

export type GetPrioritiesQuery = {
  readonly __typename?: 'Query';
  readonly priorities: ReadonlyArray<{
    readonly __typename?: 'Priority';
    readonly name: string;
    readonly value: number;
    readonly dueDuration: string;
  }>;
};

export type HangingProtocolFragment = {
  readonly __typename?: 'HangingProtocol';
  readonly smid: string;
  readonly name: string;
  readonly numberOfPriors: number;
  readonly created: Date;
  readonly updated: Date;
  readonly studyDescriptions: ReadonlyArray<string>;
  readonly studyCriteria?: JSON | null;
  readonly data: {
    readonly __typename?: 'HangingProtocolData';
    readonly layouts: ReadonlyArray<{
      readonly __typename?: 'HangingProtocolLayout';
      readonly id: string;
      readonly layout: ReadonlyArray<number>;
    }>;
  };
  readonly viewportsConfigurations?: ReadonlyArray<{
    readonly __typename?: 'ViewportConfiguration';
    readonly id: string;
    readonly configuration: {
      readonly __typename?: 'ViewportConfigurationData';
      readonly viewType?: ViewType | null;
      readonly comparisonIndex?: number | null;
      readonly seriesDescriptions: ReadonlyArray<string>;
      readonly study?: { readonly __typename?: 'DehydratedStudy'; readonly smid: string } | null;
      readonly series?: { readonly __typename?: 'DehydratedSeries'; readonly smid: string } | null;
      readonly stack?: { readonly __typename?: 'DehydratedStack'; readonly smid: string } | null;
      readonly seriesCriteria?: {
        readonly __typename?: 'SeriesCriteria';
        readonly anatomic_focus?: ReadonlyArray<{
          readonly __typename?: 'ClassificationSummary';
          readonly display_name?: string | null;
          readonly ontology_id?: string | null;
        } | null> | null;
      } | null;
    };
  }> | null;
};

export type GetHangingProtocolsQueryVariables = Exact<{
  cursor?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  studyDescriptions?: InputMaybe<
    ReadonlyArray<Scalars['String']['input']> | Scalars['String']['input']
  >;
  minimumNumberOfPriors: Scalars['Int']['input'];
  cases?: InputMaybe<
    | ReadonlyArray<ReadonlyArray<Scalars['ID']['input']> | Scalars['ID']['input']>
    | ReadonlyArray<Scalars['ID']['input']>
    | Scalars['ID']['input']
  >;
}>;

export type GetHangingProtocolsQuery = {
  readonly __typename?: 'Query';
  readonly hangingProtocols: {
    readonly __typename?: 'HangingProtocols';
    readonly items: ReadonlyArray<{
      readonly __typename?: 'HangingProtocol';
      readonly smid: string;
      readonly name: string;
      readonly numberOfPriors: number;
      readonly created: Date;
      readonly updated: Date;
      readonly studyDescriptions: ReadonlyArray<string>;
      readonly studyCriteria?: JSON | null;
      readonly data: {
        readonly __typename?: 'HangingProtocolData';
        readonly layouts: ReadonlyArray<{
          readonly __typename?: 'HangingProtocolLayout';
          readonly id: string;
          readonly layout: ReadonlyArray<number>;
        }>;
      };
      readonly viewportsConfigurations?: ReadonlyArray<{
        readonly __typename?: 'ViewportConfiguration';
        readonly id: string;
        readonly configuration: {
          readonly __typename?: 'ViewportConfigurationData';
          readonly viewType?: ViewType | null;
          readonly comparisonIndex?: number | null;
          readonly seriesDescriptions: ReadonlyArray<string>;
          readonly study?: {
            readonly __typename?: 'DehydratedStudy';
            readonly smid: string;
          } | null;
          readonly series?: {
            readonly __typename?: 'DehydratedSeries';
            readonly smid: string;
          } | null;
          readonly stack?: {
            readonly __typename?: 'DehydratedStack';
            readonly smid: string;
          } | null;
          readonly seriesCriteria?: {
            readonly __typename?: 'SeriesCriteria';
            readonly anatomic_focus?: ReadonlyArray<{
              readonly __typename?: 'ClassificationSummary';
              readonly display_name?: string | null;
              readonly ontology_id?: string | null;
            } | null> | null;
          } | null;
        };
      }> | null;
    }>;
  };
};

export type GetCaseClassificationQueryVariables = Exact<{
  studySmids: ReadonlyArray<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type GetCaseClassificationQuery = {
  readonly __typename?: 'Query';
  readonly caseClassification?: ReadonlyArray<{
    readonly __typename?: 'StudyClassificationSummary';
    readonly study_description?: string | null;
    readonly study_smid?: string | null;
    readonly study_classification?: JSON | null;
  } | null> | null;
};

export type GetWorklistItemsClassificationsQueryVariables = Exact<{
  worklistItemSmids: ReadonlyArray<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type GetWorklistItemsClassificationsQuery = {
  readonly __typename?: 'Query';
  readonly worklistItemsClassifications?: JSON | null;
};

export type GetSeriesClassificationsQueryVariables = Exact<{
  seriesSmids: ReadonlyArray<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type GetSeriesClassificationsQuery = {
  readonly __typename?: 'Query';
  readonly seriesClassifications?: ReadonlyArray<{
    readonly __typename?: 'SeriesClassification';
    readonly seriesDescription?: string | null;
    readonly studySmid?: string | null;
    readonly seriesSmid?: string | null;
    readonly seriesNumber?: number | null;
    readonly seriesDate?: string | null;
    readonly seriesTime?: string | null;
    readonly seriesCriteria?: {
      readonly __typename?: 'SeriesCriteria';
      readonly anatomic_focus?: ReadonlyArray<{
        readonly __typename?: 'ClassificationSummary';
        readonly display_name?: string | null;
        readonly ontology_id?: string | null;
      } | null> | null;
    } | null;
  } | null> | null;
};

export type GetHangingProtocolQueryVariables = Exact<{
  id: Scalars['ID']['input'];
  cases?: InputMaybe<
    | ReadonlyArray<ReadonlyArray<Scalars['ID']['input']> | Scalars['ID']['input']>
    | ReadonlyArray<Scalars['ID']['input']>
    | Scalars['ID']['input']
  >;
}>;

export type GetHangingProtocolQuery = {
  readonly __typename?: 'Query';
  readonly hangingProtocol?: {
    readonly __typename?: 'HangingProtocol';
    readonly smid: string;
    readonly name: string;
    readonly numberOfPriors: number;
    readonly created: Date;
    readonly updated: Date;
    readonly studyDescriptions: ReadonlyArray<string>;
    readonly studyCriteria?: JSON | null;
    readonly data: {
      readonly __typename?: 'HangingProtocolData';
      readonly layouts: ReadonlyArray<{
        readonly __typename?: 'HangingProtocolLayout';
        readonly id: string;
        readonly layout: ReadonlyArray<number>;
      }>;
    };
    readonly viewportsConfigurations?: ReadonlyArray<{
      readonly __typename?: 'ViewportConfiguration';
      readonly id: string;
      readonly configuration: {
        readonly __typename?: 'ViewportConfigurationData';
        readonly viewType?: ViewType | null;
        readonly comparisonIndex?: number | null;
        readonly seriesDescriptions: ReadonlyArray<string>;
        readonly study?: { readonly __typename?: 'DehydratedStudy'; readonly smid: string } | null;
        readonly series?: {
          readonly __typename?: 'DehydratedSeries';
          readonly smid: string;
        } | null;
        readonly stack?: { readonly __typename?: 'DehydratedStack'; readonly smid: string } | null;
        readonly seriesCriteria?: {
          readonly __typename?: 'SeriesCriteria';
          readonly anatomic_focus?: ReadonlyArray<{
            readonly __typename?: 'ClassificationSummary';
            readonly display_name?: string | null;
            readonly ontology_id?: string | null;
          } | null> | null;
        } | null;
      };
    }> | null;
  } | null;
};

export type GetAllHangingProtocolsQueryVariables = Exact<{
  cursor?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetAllHangingProtocolsQuery = {
  readonly __typename?: 'Query';
  readonly hangingProtocols: {
    readonly __typename?: 'HangingProtocols';
    readonly items: ReadonlyArray<{
      readonly __typename?: 'HangingProtocol';
      readonly smid: string;
      readonly name: string;
      readonly numberOfPriors: number;
      readonly created: Date;
      readonly updated: Date;
      readonly studyDescriptions: ReadonlyArray<string>;
      readonly studyCriteria?: JSON | null;
      readonly data: {
        readonly __typename?: 'HangingProtocolData';
        readonly layouts: ReadonlyArray<{
          readonly __typename?: 'HangingProtocolLayout';
          readonly id: string;
          readonly layout: ReadonlyArray<number>;
        }>;
      };
    }>;
  };
};

export type UpdateHangingProtocolMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  payload: HangingProtocolPayload;
}>;

export type UpdateHangingProtocolMutation = {
  readonly __typename?: 'Mutation';
  readonly updateHangingProtocol?: {
    readonly __typename?: 'HangingProtocol';
    readonly smid: string;
    readonly name: string;
    readonly numberOfPriors: number;
    readonly created: Date;
    readonly updated: Date;
    readonly studyDescriptions: ReadonlyArray<string>;
    readonly studyCriteria?: JSON | null;
    readonly data: {
      readonly __typename?: 'HangingProtocolData';
      readonly layouts: ReadonlyArray<{
        readonly __typename?: 'HangingProtocolLayout';
        readonly id: string;
        readonly layout: ReadonlyArray<number>;
      }>;
    };
    readonly viewportsConfigurations?: ReadonlyArray<{
      readonly __typename?: 'ViewportConfiguration';
      readonly id: string;
      readonly configuration: {
        readonly __typename?: 'ViewportConfigurationData';
        readonly viewType?: ViewType | null;
        readonly comparisonIndex?: number | null;
        readonly seriesDescriptions: ReadonlyArray<string>;
        readonly study?: { readonly __typename?: 'DehydratedStudy'; readonly smid: string } | null;
        readonly series?: {
          readonly __typename?: 'DehydratedSeries';
          readonly smid: string;
        } | null;
        readonly stack?: { readonly __typename?: 'DehydratedStack'; readonly smid: string } | null;
        readonly seriesCriteria?: {
          readonly __typename?: 'SeriesCriteria';
          readonly anatomic_focus?: ReadonlyArray<{
            readonly __typename?: 'ClassificationSummary';
            readonly display_name?: string | null;
            readonly ontology_id?: string | null;
          } | null> | null;
        } | null;
      };
    }> | null;
  } | null;
};

export type CreateHangingProtocolMutationVariables = Exact<{
  payload: HangingProtocolPayload;
}>;

export type CreateHangingProtocolMutation = {
  readonly __typename?: 'Mutation';
  readonly createHangingProtocol?: {
    readonly __typename?: 'HangingProtocol';
    readonly smid: string;
    readonly name: string;
    readonly numberOfPriors: number;
    readonly created: Date;
    readonly updated: Date;
    readonly studyDescriptions: ReadonlyArray<string>;
    readonly studyCriteria?: JSON | null;
    readonly data: {
      readonly __typename?: 'HangingProtocolData';
      readonly layouts: ReadonlyArray<{
        readonly __typename?: 'HangingProtocolLayout';
        readonly id: string;
        readonly layout: ReadonlyArray<number>;
      }>;
    };
    readonly viewportsConfigurations?: ReadonlyArray<{
      readonly __typename?: 'ViewportConfiguration';
      readonly id: string;
      readonly configuration: {
        readonly __typename?: 'ViewportConfigurationData';
        readonly viewType?: ViewType | null;
        readonly comparisonIndex?: number | null;
        readonly seriesDescriptions: ReadonlyArray<string>;
        readonly study?: { readonly __typename?: 'DehydratedStudy'; readonly smid: string } | null;
        readonly series?: {
          readonly __typename?: 'DehydratedSeries';
          readonly smid: string;
        } | null;
        readonly stack?: { readonly __typename?: 'DehydratedStack'; readonly smid: string } | null;
        readonly seriesCriteria?: {
          readonly __typename?: 'SeriesCriteria';
          readonly anatomic_focus?: ReadonlyArray<{
            readonly __typename?: 'ClassificationSummary';
            readonly display_name?: string | null;
            readonly ontology_id?: string | null;
          } | null> | null;
        } | null;
      };
    }> | null;
  } | null;
};

export type DeleteHangingProtocolMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteHangingProtocolMutation = {
  readonly __typename?: 'Mutation';
  readonly deleteHangingProtocol?: {
    readonly __typename?: 'HangingProtocolDeleted';
    readonly smid: string;
  } | null;
};

export type DeleteAnnotationsMutationVariables = Exact<{
  smids: ReadonlyArray<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type DeleteAnnotationsMutation = {
  readonly __typename?: 'Mutation';
  readonly deleteAnnotations: {
    readonly __typename?: 'DeleteAnnotationsResponse';
    readonly results: ReadonlyArray<{
      readonly __typename?: 'DeleteAnnotationsItem';
      readonly status: DeleteAnnotationsStatus;
      readonly smid: string;
    }>;
  };
};

export type UpdateReportMutationVariables = Exact<{
  smid: Scalars['ID']['input'];
  worklistItemSmid: Scalars['ID']['input'];
  payload: ReportPayload;
}>;

export type UpdateReportMutation = {
  readonly __typename?: 'Mutation';
  readonly updateReport: {
    readonly __typename?: 'Report';
    readonly smid: string;
    readonly created: Date;
    readonly updated: Date;
    readonly version: number;
    readonly sectionsLastUpdated?: Date | null;
    readonly content?: JSON | null;
    readonly sections: JSON;
    readonly template?: {
      readonly __typename?: 'ReportTemplate';
      readonly id: string;
      readonly picklists: ReadonlyArray<{
        readonly __typename?: 'ReportPicklist';
        readonly id: string;
        readonly options: ReadonlyArray<{
          readonly __typename?: 'ReportPicklistOption';
          readonly id: string;
          readonly name?: string | null;
          readonly text: ReadonlyArray<JSON>;
          readonly default?: boolean | null;
        }>;
      }>;
      readonly macros: ReadonlyArray<{
        readonly __typename?: 'Macro';
        readonly smid: string;
        readonly name: string;
        readonly triggerPhrase: string;
        readonly text: ReadonlyArray<JSON>;
        readonly created: Date;
        readonly updated?: Date | null;
        readonly procedures?: ReadonlyArray<{
          readonly __typename?: 'Procedure';
          readonly smid: string;
          readonly description: string;
          readonly code: string;
        }> | null;
        readonly owner?: {
          readonly __typename?: 'SlimUser';
          readonly id: string;
          readonly firstName: string;
          readonly lastName: string;
        } | null;
      }>;
    } | null;
    readonly addendums: ReadonlyArray<{
      readonly __typename?: 'Addendum';
      readonly created: Date;
      readonly updated: Date;
      readonly submittedAt?: Date | null;
      readonly sentAt?: Date | null;
      readonly smid: string;
      readonly content:
        | { readonly __typename?: 'AddendumSironaSlate'; readonly sironaSlate: ReadonlyArray<JSON> }
        | { readonly __typename?: 'AddendumTextBlob'; readonly textBlob: string };
      readonly owner?: {
        readonly __typename?: 'Owner';
        readonly id: string;
        readonly firstName: string;
        readonly lastName: string;
        readonly email: string;
      } | null;
    }>;
    readonly macros: ReadonlyArray<{
      readonly __typename?: 'Macro';
      readonly smid: string;
      readonly name: string;
      readonly triggerPhrase: string;
      readonly text: ReadonlyArray<JSON>;
      readonly created: Date;
      readonly updated?: Date | null;
      readonly procedures?: ReadonlyArray<{
        readonly __typename?: 'Procedure';
        readonly smid: string;
        readonly description: string;
        readonly code: string;
      }> | null;
      readonly owner?: {
        readonly __typename?: 'SlimUser';
        readonly id: string;
        readonly firstName: string;
        readonly lastName: string;
      } | null;
    }>;
    readonly picklists?: ReadonlyArray<{
      readonly __typename?: 'ReportPicklist';
      readonly id: string;
      readonly options: ReadonlyArray<{
        readonly __typename?: 'ReportPicklistOption';
        readonly id: string;
        readonly name?: string | null;
        readonly text: ReadonlyArray<JSON>;
        readonly default?: boolean | null;
      }>;
    }> | null;
  };
};

export type CreateAddendumMutationVariables = Exact<{
  reportSmid: Scalars['ID']['input'];
}>;

export type CreateAddendumMutation = {
  readonly __typename?: 'Mutation';
  readonly createAddendum: {
    readonly __typename?: 'Addendum';
    readonly created: Date;
    readonly updated: Date;
    readonly submittedAt?: Date | null;
    readonly sentAt?: Date | null;
    readonly smid: string;
    readonly content:
      | { readonly __typename?: 'AddendumSironaSlate'; readonly sironaSlate: ReadonlyArray<JSON> }
      | { readonly __typename?: 'AddendumTextBlob'; readonly textBlob: string };
    readonly owner?: {
      readonly __typename?: 'Owner';
      readonly id: string;
      readonly firstName: string;
      readonly lastName: string;
      readonly email: string;
    } | null;
  };
};

export type CancelAddendumMutationVariables = Exact<{
  smid: Scalars['ID']['input'];
}>;

export type CancelAddendumMutation = {
  readonly __typename?: 'Mutation';
  readonly cancelAddendum: {
    readonly __typename?: 'Addendum';
    readonly created: Date;
    readonly updated: Date;
    readonly submittedAt?: Date | null;
    readonly sentAt?: Date | null;
    readonly smid: string;
    readonly content:
      | { readonly __typename?: 'AddendumSironaSlate'; readonly sironaSlate: ReadonlyArray<JSON> }
      | { readonly __typename?: 'AddendumTextBlob'; readonly textBlob: string };
    readonly owner?: {
      readonly __typename?: 'Owner';
      readonly id: string;
      readonly firstName: string;
      readonly lastName: string;
      readonly email: string;
    } | null;
  };
};

export type UpdateAddendumMutationVariables = Exact<{
  smid: Scalars['ID']['input'];
  payload: AddendumPayload;
}>;

export type UpdateAddendumMutation = {
  readonly __typename?: 'Mutation';
  readonly updateAddendum: {
    readonly __typename?: 'Addendum';
    readonly created: Date;
    readonly updated: Date;
    readonly submittedAt?: Date | null;
    readonly sentAt?: Date | null;
    readonly smid: string;
    readonly content:
      | { readonly __typename?: 'AddendumSironaSlate'; readonly sironaSlate: ReadonlyArray<JSON> }
      | { readonly __typename?: 'AddendumTextBlob'; readonly textBlob: string };
    readonly owner?: {
      readonly __typename?: 'Owner';
      readonly id: string;
      readonly firstName: string;
      readonly lastName: string;
      readonly email: string;
    } | null;
  };
};

export type SubmitAddendumMutationVariables = Exact<{
  smid: Scalars['ID']['input'];
  immediate_submit?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type SubmitAddendumMutation = {
  readonly __typename?: 'Mutation';
  readonly submitAddendum: {
    readonly __typename?: 'Addendum';
    readonly created: Date;
    readonly updated: Date;
    readonly submittedAt?: Date | null;
    readonly sentAt?: Date | null;
    readonly smid: string;
    readonly content:
      | { readonly __typename?: 'AddendumSironaSlate'; readonly sironaSlate: ReadonlyArray<JSON> }
      | { readonly __typename?: 'AddendumTextBlob'; readonly textBlob: string };
    readonly owner?: {
      readonly __typename?: 'Owner';
      readonly id: string;
      readonly firstName: string;
      readonly lastName: string;
      readonly email: string;
    } | null;
  };
};

export type DeleteAddendumMutationVariables = Exact<{
  smid: Scalars['ID']['input'];
}>;

export type DeleteAddendumMutation = {
  readonly __typename?: 'Mutation';
  readonly deleteAddendum: { readonly __typename?: 'ConfirmResponse'; readonly confirmed: boolean };
};

export type UpdateMousePreferencesMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  button: Scalars['String']['input'];
}>;

export type UpdateMousePreferencesMutation = {
  readonly __typename?: 'Mutation';
  readonly updateMousePreferences: {
    readonly __typename?: 'MousePreferences';
    readonly left: string;
    readonly middle: string;
    readonly right: string;
  };
};

export type UpdateMouseScrollDirectionMutationVariables = Exact<{
  invertScroll: Scalars['Boolean']['input'];
}>;

export type UpdateMouseScrollDirectionMutation = {
  readonly __typename?: 'Mutation';
  readonly updateMouseScrollDirection: {
    readonly __typename?: 'ToolPreferences';
    readonly invertScroll: boolean;
  };
};

export type UpdateMouseSensitivityPreferencesMutationVariables = Exact<{
  regularScroll?: InputMaybe<Scalars['Float']['input']>;
  fastScroll?: InputMaybe<Scalars['Float']['input']>;
  fineScroll?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type UpdateMouseSensitivityPreferencesMutation = {
  readonly __typename?: 'Mutation';
  readonly updateMouseSensitivityPreferences: {
    readonly __typename?: 'ToolPreferences';
    readonly regularScroll: {
      readonly __typename?: 'RegularScrollPreferences';
      readonly sensitivity: number;
    };
    readonly fastScroll: {
      readonly __typename?: 'FastScrollPreferences';
      readonly sensitivity: number;
    };
    readonly fineScroll: {
      readonly __typename?: 'FineScrollPreferences';
      readonly enabled: boolean;
    };
  };
};

export type UpdateWheelPreferencesMutationVariables = Exact<{
  ids: ReadonlyArray<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type UpdateWheelPreferencesMutation = {
  readonly __typename?: 'Mutation';
  readonly updateWheelPreferences: {
    readonly __typename?: 'WheelPreferences';
    readonly toolIds: ReadonlyArray<string>;
  };
};

export type ClearKeyboardPreferencesMutationVariables = Exact<{
  resetToDefault: Scalars['Boolean']['input'];
}>;

export type ClearKeyboardPreferencesMutation = {
  readonly __typename?: 'Mutation';
  readonly clearKeyboardPreferences: {
    readonly __typename?: 'KeyboardPreferences';
    readonly shortcuts: ReadonlyArray<{
      readonly __typename?: 'Shortcut';
      readonly id: string;
      readonly key?: string | null;
      readonly interactionId?: string | null;
    }>;
  };
};

export type UpdateKeyboardPreferencesMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  key: Scalars['String']['input'];
  interactionId?: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdateKeyboardPreferencesMutation = {
  readonly __typename?: 'Mutation';
  readonly updateKeyboardPreferences: {
    readonly __typename?: 'KeyboardPreferences';
    readonly shortcuts: ReadonlyArray<{
      readonly __typename?: 'Shortcut';
      readonly id: string;
      readonly key?: string | null;
      readonly interactionId?: string | null;
    }>;
  };
};

export type UpdateDictaphonePreferencesMutationVariables = Exact<{
  keyMapping?: InputMaybe<ReadonlyArray<IDictaphoneKey> | IDictaphoneKey>;
  deviceLabel?: InputMaybe<Scalars['String']['input']>;
  recordWhilePressed?: InputMaybe<Scalars['Boolean']['input']>;
  showPicklistOptionsInEditor?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type UpdateDictaphonePreferencesMutation = {
  readonly __typename?: 'Mutation';
  readonly updateDictaphonePreferences: {
    readonly __typename?: 'DictaphonePreferences';
    readonly deviceLabel: string;
    readonly recordWhilePressed: boolean;
    readonly showPicklistOptionsInEditor: boolean;
    readonly keyMapping: ReadonlyArray<{
      readonly __typename?: 'DictaphoneKey';
      readonly id: GamepadActionId;
      readonly key: string;
    }>;
  };
};

export type UpdateBarPreferencesMutationVariables = Exact<{
  ids: ReadonlyArray<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type UpdateBarPreferencesMutation = {
  readonly __typename?: 'Mutation';
  readonly updateBarPreferences: {
    readonly __typename?: 'BarPreferences';
    readonly toolIds: ReadonlyArray<string>;
  };
};

export type UpdateResetToolOnScrollPreferencesMutationVariables = Exact<{
  enabled: Scalars['Boolean']['input'];
}>;

export type UpdateResetToolOnScrollPreferencesMutation = {
  readonly __typename?: 'Mutation';
  readonly updateResetToolOnScrollPreferences: {
    readonly __typename?: 'ResetToolOnScrollPreferences';
    readonly enabled: boolean;
  };
};

export type UpdateMirrorViewerLayoutsPreferencesMutationVariables = Exact<{
  enabled: Scalars['Boolean']['input'];
}>;

export type UpdateMirrorViewerLayoutsPreferencesMutation = {
  readonly __typename?: 'Mutation';
  readonly updateMirrorViewerLayoutsPreferences: {
    readonly __typename?: 'MirrorViewerLayoutsPreferences';
    readonly enabled: boolean;
  };
};

export type UpdateShowIconLabelPreferencesMutationVariables = Exact<{
  enabled: Scalars['Boolean']['input'];
}>;

export type UpdateShowIconLabelPreferencesMutation = {
  readonly __typename?: 'Mutation';
  readonly updateShowIconLabelPreferences: {
    readonly __typename?: 'ShowIconLabelPreferences';
    readonly enabled: boolean;
  };
};

export type UpdateMeMutationVariables = Exact<{
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  physicianId?: InputMaybe<Scalars['String']['input']>;
  preferredWorkLocation?: InputMaybe<PreferredWorkLocationPayload>;
}>;

export type UpdateMeMutation = {
  readonly __typename?: 'Mutation';
  readonly updateMe: {
    readonly __typename?: 'User';
    readonly firstName: string;
    readonly lastName: string;
    readonly physicianId?: string | null;
    readonly preferredWorkLocation: {
      readonly __typename?: 'PreferredWorkLocation';
      readonly hospital: boolean;
      readonly home: boolean;
    };
  };
};

export type UpdatePasswordMutationVariables = Exact<{
  oldPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
  totp?: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdatePasswordMutation = {
  readonly __typename?: 'Mutation';
  readonly updatePassword: { readonly __typename?: 'ConfirmResponse'; readonly confirmed: boolean };
};

export type ChangeWorklistItemQualityControlStateMutationVariables = Exact<{
  smid: Scalars['ID']['input'];
  qc: Scalars['Boolean']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
}>;

export type ChangeWorklistItemQualityControlStateMutation = {
  readonly __typename?: 'Mutation';
  readonly changeWorklistItemQualityControlState?: {
    readonly __typename?: 'WorklistItem';
    readonly smid: string;
    readonly status: WorklistItemStatus;
  } | null;
};

export type ChangeWorklistItemStatusMutationVariables = Exact<{
  smid: Scalars['ID']['input'];
  claim?: InputMaybe<Scalars['Boolean']['input']>;
  resetReport?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type ChangeWorklistItemStatusMutation = {
  readonly __typename?: 'Mutation';
  readonly changeWorklistItemStatus?: {
    readonly __typename?: 'WorklistItem';
    readonly smid: string;
    readonly accessionNumber?: string | null;
    readonly claimedBy?: {
      readonly __typename?: 'User';
      readonly id: string;
      readonly firstName: string;
      readonly lastName: string;
    } | null;
  } | null;
};

export type BatchClaimWorklistItemsMutationVariables = Exact<{
  worklistItems: ReadonlyArray<BatchClaimInput> | BatchClaimInput;
}>;

export type BatchClaimWorklistItemsMutation = {
  readonly __typename?: 'Mutation';
  readonly batchClaimWorklistItems: ReadonlyArray<{
    readonly __typename?: 'BatchClaimResult';
    readonly smid: string;
    readonly success: boolean;
    readonly errorReason?: string | null;
    readonly accessionNumber?: string | null;
    readonly claimedBy?: {
      readonly __typename?: 'User';
      readonly id: string;
      readonly firstName: string;
      readonly lastName: string;
    } | null;
  }>;
};

export type BatchUnclaimWorklistItemsMutationVariables = Exact<{
  worklistItems: ReadonlyArray<BatchUnclaimInput> | BatchUnclaimInput;
  isLockClaimAddendumEnabled: Scalars['Boolean']['input'];
}>;

export type BatchUnclaimWorklistItemsMutation = {
  readonly __typename?: 'Mutation';
  readonly batchUnclaimWorklistItems: ReadonlyArray<{
    readonly __typename?: 'BatchUnclaimResult';
    readonly smid: string;
    readonly success: boolean;
    readonly errorReason?: string | null;
    readonly accessionNumber?: string | null;
  }>;
};

export type ChangeWorklistItemCustomStatusMutationVariables = Exact<{
  smid: Scalars['ID']['input'];
  customStatusID: Scalars['ID']['input'];
}>;

export type ChangeWorklistItemCustomStatusMutation = {
  readonly __typename?: 'Mutation';
  readonly changeWorklistItemCustomStatus?: {
    readonly __typename?: 'WorklistItem';
    readonly smid: string;
    readonly customStatus?: {
      readonly __typename?: 'WorklistCustomStatus';
      readonly smid: string;
      readonly name: string;
    } | null;
  } | null;
};

export type UndoChangeWorklistItemCustomStatusMutationVariables = Exact<{
  smid: Scalars['ID']['input'];
  customStatusID: Scalars['ID']['input'];
}>;

export type UndoChangeWorklistItemCustomStatusMutation = {
  readonly __typename?: 'Mutation';
  readonly undoChangeWorklistItemCustomStatus?: {
    readonly __typename?: 'WorklistItem';
    readonly smid: string;
    readonly customStatus?: {
      readonly __typename?: 'WorklistCustomStatus';
      readonly smid: string;
      readonly name: string;
    } | null;
  } | null;
};

export type ChangeWorklistItemPriorityMutationVariables = Exact<{
  smid: Scalars['ID']['input'];
  name: Scalars['String']['input'];
}>;

export type ChangeWorklistItemPriorityMutation = {
  readonly __typename?: 'Mutation';
  readonly changeWorklistItemPriority?: {
    readonly __typename?: 'WorklistItem';
    readonly smid: string;
    readonly priority?: string | null;
  } | null;
};

export type CreateWorklistItemActivityMutationVariables = Exact<{
  smid: Scalars['ID']['input'];
}>;

export type CreateWorklistItemActivityMutation = {
  readonly __typename?: 'Mutation';
  readonly createWorklistItemActivity?: string | null;
};

export type ResetAndReleaseWorklistItemMutationVariables = Exact<{
  smid: Scalars['ID']['input'];
}>;

export type ResetAndReleaseWorklistItemMutation = {
  readonly __typename?: 'Mutation';
  readonly resetAndReleaseWorklistItem?: {
    readonly __typename?: 'WorklistItem';
    readonly smid: string;
  } | null;
};

export type GetStudiesBySmidQueryVariables = Exact<{
  smids: ReadonlyArray<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type GetStudiesBySmidQuery = {
  readonly __typename?: 'Query';
  readonly studiesBySmid: ReadonlyArray<{
    readonly __typename?: 'Study';
    readonly smid: string;
    readonly created: Date;
    readonly updated: Date;
    readonly instanceUID: string;
    readonly description?: string | null;
    readonly hl7Order?: string | null;
    readonly accessionNumber?: string | null;
    readonly seriesCount: number;
    readonly patientSex?: string | null;
    readonly patientAge?: string | null;
    readonly patientName?: string | null;
    readonly patientDob?: Date | null;
    readonly modality: string;
    readonly bodyPart?: string | null;
    readonly mrn?: string | null;
    readonly studyDate?: Date | null;
    readonly laterality?: string | null;
    readonly provenanceData?: {
      readonly __typename?: 'ProvenanceData';
      readonly matchType?: MatchType | null;
      readonly relevant?: boolean | null;
    } | null;
    readonly seriesList: ReadonlyArray<{
      readonly __typename?: 'Series';
      readonly smid: string;
      readonly description?: string | null;
      readonly seriesInstanceUID: string;
      readonly seriesDateTimeLocal?: DateTimeLocalScalarConfig | null;
      readonly frameOfReferenceUID?: string | null;
      readonly seriesNumber?: number | null;
      readonly is3Dable: boolean;
      readonly study: { readonly __typename?: 'Study'; readonly smid: string };
      readonly classifications?: ReadonlyArray<{
        readonly __typename?: 'SeriesClassification';
        readonly seriesDescription?: string | null;
        readonly studySmid?: string | null;
        readonly seriesSmid?: string | null;
        readonly seriesNumber?: number | null;
        readonly seriesDate?: string | null;
        readonly seriesTime?: string | null;
        readonly seriesCriteria?: {
          readonly __typename?: 'SeriesCriteria';
          readonly anatomic_focus?: ReadonlyArray<{
            readonly __typename?: 'ClassificationSummary';
            readonly display_name?: string | null;
            readonly ontology_id?: string | null;
          } | null> | null;
        } | null;
      } | null> | null;
      readonly imageRegistrations: ReadonlyArray<{
        readonly __typename?: 'ImageRegistration';
        readonly smid: string;
        readonly created: Date;
        readonly codeVersion: string;
        readonly modelName: string;
        readonly modelVersion: string;
        readonly seriesFixed: string;
        readonly seriesMoving: string;
        readonly dataType: string;
        readonly fixedFrameOfReferenceUid: string;
        readonly movingFrameOfReferenceUid: string;
        readonly transformMatrix: [
          [number, number, number, number],
          [number, number, number, number],
          [number, number, number, number],
          [number, number, number, number],
        ];
      }>;
    }>;
    readonly stackedFrames: ReadonlyArray<
      | {
          readonly __typename?: 'LayeredStack';
          readonly smid: string;
          readonly type: string;
          readonly study: { readonly __typename?: 'Study'; readonly smid: string };
          readonly stackLayers: ReadonlyArray<{
            readonly __typename?: 'Layer';
            readonly stackSmid: string;
            readonly index: number;
          }>;
        }
      | {
          readonly __typename?: 'SingleLayerStack';
          readonly smid: string;
          readonly type: string;
          readonly image: string;
          readonly is3Dable: boolean;
          readonly frames: ReadonlyArray<FrameDataFragment>;
          readonly series: { readonly __typename?: 'Series'; readonly smid: string };
          readonly study: { readonly __typename?: 'Study'; readonly smid: string };
          readonly supportedRenderers: {
            readonly __typename?: 'SupportedRenderers';
            readonly vtk: RendererSupport;
          };
          readonly imageParams: {
            readonly __typename?: 'ImageParams';
            readonly origin: [number, number, number];
            readonly spacing: [number, number, number];
            readonly dimensions: [number, number, number];
            readonly extent: [number, number, number, number, number, number];
            readonly direction: [
              number,
              number,
              number,
              number,
              number,
              number,
              number,
              number,
              number,
            ];
            readonly isMultiAxes: boolean;
            readonly colorWindow?: number | null;
            readonly colorLevel?: number | null;
          };
        }
    >;
  }>;
};

export type CreateTemplateMutationVariables = Exact<{
  payload: IReportTemplatePayload;
}>;

export type CreateTemplateMutation = {
  readonly __typename?: 'Mutation';
  readonly createTemplate: { readonly __typename?: 'ReportTemplate'; readonly id: string };
};

export type UpdateTemplateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  payload: IReportTemplatePayload;
}>;

export type UpdateTemplateMutation = {
  readonly __typename?: 'Mutation';
  readonly updateTemplate: { readonly __typename?: 'ReportTemplate'; readonly id: string };
};

export type GetTemplatesQueryVariables = Exact<{
  cursor?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  owners?: InputMaybe<ReadonlyArray<Scalars['ID']['input']> | Scalars['ID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  procedures?: InputMaybe<ReadonlyArray<Scalars['ID']['input']> | Scalars['ID']['input']>;
  studyDescriptions?: InputMaybe<
    ReadonlyArray<Scalars['String']['input']> | Scalars['String']['input']
  >;
  autoload?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GetTemplatesQuery = {
  readonly __typename?: 'Query';
  readonly templates: {
    readonly __typename?: 'ReportTemplates';
    readonly totalCount: number;
    readonly cursor?: string | null;
    readonly next?: string | null;
    readonly items: ReadonlyArray<{
      readonly __typename?: 'ReportTemplate';
      readonly id: string;
      readonly name: string;
      readonly triggerPhrase: string;
      readonly created: Date;
      readonly content: JSON;
      readonly owner?: {
        readonly __typename?: 'Owner';
        readonly id: string;
        readonly firstName: string;
        readonly lastName: string;
      } | null;
    }>;
  };
};

export type GetTemplateQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetTemplateQuery = {
  readonly __typename?: 'Query';
  readonly template?: {
    readonly __typename?: 'ReportTemplate';
    readonly id: string;
    readonly name: string;
    readonly triggerPhrase: string;
    readonly created: Date;
    readonly content: JSON;
    readonly sections: ReadonlyArray<JSON>;
    readonly picklists: ReadonlyArray<{
      readonly __typename?: 'ReportPicklist';
      readonly id: string;
      readonly options: ReadonlyArray<{
        readonly __typename?: 'ReportPicklistOption';
        readonly id: string;
        readonly name?: string | null;
        readonly text: ReadonlyArray<JSON>;
        readonly default?: boolean | null;
      }>;
    }>;
    readonly macros: ReadonlyArray<{
      readonly __typename?: 'Macro';
      readonly smid: string;
      readonly name: string;
      readonly triggerPhrase: string;
      readonly text: ReadonlyArray<JSON>;
      readonly created: Date;
      readonly updated?: Date | null;
      readonly nestedMacros: ReadonlyArray<{
        readonly __typename?: 'Macro';
        readonly smid: string;
        readonly created: Date;
        readonly updated?: Date | null;
        readonly name: string;
        readonly triggerPhrase: string;
        readonly text: ReadonlyArray<JSON>;
      }>;
      readonly procedures?: ReadonlyArray<{
        readonly __typename?: 'Procedure';
        readonly smid: string;
        readonly description: string;
        readonly code: string;
      }> | null;
      readonly owner?: {
        readonly __typename?: 'SlimUser';
        readonly id: string;
        readonly firstName: string;
        readonly lastName: string;
      } | null;
      readonly picklists: ReadonlyArray<{
        readonly __typename?: 'ReportPicklist';
        readonly id: string;
        readonly options: ReadonlyArray<{
          readonly __typename?: 'ReportPicklistOption';
          readonly id: string;
          readonly name?: string | null;
          readonly text: ReadonlyArray<JSON>;
          readonly default?: boolean | null;
        }>;
      }>;
    }>;
    readonly procedureReferences?: ReadonlyArray<{
      readonly __typename?: 'ProcedureReference';
      readonly smid: string;
      readonly description?: string | null;
      readonly code?: string | null;
      readonly autoload: boolean;
    }> | null;
  } | null;
};

export type DeleteTemplateMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteTemplateMutation = {
  readonly __typename?: 'Mutation';
  readonly deleteTemplate: {
    readonly __typename?: 'DeleteTemplateResponse';
    readonly smid: string;
  };
};

export type GetMacrosQueryVariables = Exact<{
  owners?: InputMaybe<ReadonlyArray<Scalars['ID']['input']> | Scalars['ID']['input']>;
  cursor?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  procedures?: InputMaybe<ReadonlyArray<Scalars['ID']['input']> | Scalars['ID']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetMacrosQuery = {
  readonly __typename?: 'Query';
  readonly macros: {
    readonly __typename?: 'Macros';
    readonly cursor?: string | null;
    readonly next?: string | null;
    readonly items: ReadonlyArray<{
      readonly __typename?: 'Macro';
      readonly smid: string;
      readonly name: string;
      readonly triggerPhrase: string;
      readonly text: ReadonlyArray<JSON>;
      readonly created: Date;
      readonly updated?: Date | null;
      readonly procedures?: ReadonlyArray<{
        readonly __typename?: 'Procedure';
        readonly smid: string;
        readonly description: string;
        readonly code: string;
      }> | null;
      readonly owner?: {
        readonly __typename?: 'SlimUser';
        readonly id: string;
        readonly firstName: string;
        readonly lastName: string;
      } | null;
    }>;
  };
};

export type GetMacroQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetMacroQuery = {
  readonly __typename?: 'Query';
  readonly macro: {
    readonly __typename?: 'Macro';
    readonly smid: string;
    readonly name: string;
    readonly triggerPhrase: string;
    readonly text: ReadonlyArray<JSON>;
    readonly created: Date;
    readonly updated?: Date | null;
    readonly procedures?: ReadonlyArray<{
      readonly __typename?: 'Procedure';
      readonly smid: string;
      readonly description: string;
      readonly code: string;
    }> | null;
    readonly nestedMacros: ReadonlyArray<{
      readonly __typename?: 'Macro';
      readonly smid: string;
      readonly name: string;
      readonly text: ReadonlyArray<JSON>;
      readonly picklists: ReadonlyArray<{
        readonly __typename?: 'ReportPicklist';
        readonly id: string;
        readonly options: ReadonlyArray<{
          readonly __typename?: 'ReportPicklistOption';
          readonly id: string;
          readonly name?: string | null;
          readonly text: ReadonlyArray<JSON>;
          readonly default?: boolean | null;
        }>;
      }>;
    }>;
    readonly picklists: ReadonlyArray<{
      readonly __typename?: 'ReportPicklist';
      readonly id: string;
      readonly options: ReadonlyArray<{
        readonly __typename?: 'ReportPicklistOption';
        readonly id: string;
        readonly name?: string | null;
        readonly text: ReadonlyArray<JSON>;
        readonly default?: boolean | null;
      }>;
    }>;
    readonly owner?: {
      readonly __typename?: 'SlimUser';
      readonly id: string;
      readonly firstName: string;
      readonly lastName: string;
    } | null;
  };
};

export type CreateMacroMutationVariables = Exact<{
  payload: MacroPayload;
}>;

export type CreateMacroMutation = {
  readonly __typename?: 'Mutation';
  readonly createMacro: { readonly __typename?: 'Macro'; readonly smid: string };
};

export type UpdateMacroMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  payload: MacroPayload;
}>;

export type UpdateMacroMutation = {
  readonly __typename?: 'Mutation';
  readonly updateMacro: { readonly __typename?: 'Macro'; readonly smid: string };
};

export type DeleteMacroMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteMacroMutation = {
  readonly __typename?: 'Mutation';
  readonly deleteMacro: { readonly __typename?: 'DeleteMacroResponse'; readonly smid: string };
};

export type CreateWwwcToolPresetMutationVariables = Exact<{
  payload: WwwcPresetPayload;
}>;

export type CreateWwwcToolPresetMutation = {
  readonly __typename?: 'Mutation';
  readonly createWwwcToolPreset?: {
    readonly __typename?: 'WwwcPreset';
    readonly id: string;
  } | null;
};

export type UpdateWwwcToolPresetMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  payload: WwwcPresetPayload;
}>;

export type UpdateWwwcToolPresetMutation = {
  readonly __typename?: 'Mutation';
  readonly updateWwwcToolPreset?: {
    readonly __typename?: 'WwwcPreset';
    readonly id: string;
  } | null;
};

export type DeleteWwwcToolPresetMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DeleteWwwcToolPresetMutation = {
  readonly __typename?: 'Mutation';
  readonly deleteWwwcToolPreset: {
    readonly __typename?: 'WwcPresetDeleteResponse';
    readonly id: string;
  };
};

export type SetWwwcDragDirectionMutationVariables = Exact<{
  value: Scalars['Boolean']['input'];
}>;

export type SetWwwcDragDirectionMutation = {
  readonly __typename?: 'Mutation';
  readonly setWwwcDragDirection: {
    readonly __typename?: 'SetWwwcDragDirectionResponse';
    readonly invertDragDirection: boolean;
  };
};

export type ConfirmAccountMutationVariables = Exact<{
  userId: Scalars['String']['input'];
  token: Scalars['ID']['input'];
  password: Scalars['String']['input'];
}>;

export type ConfirmAccountMutation = {
  readonly __typename?: 'Mutation';
  readonly confirmAccount: { readonly __typename?: 'ConfirmResponse'; readonly confirmed: boolean };
};

export type RequestPasswordResetMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;

export type RequestPasswordResetMutation = {
  readonly __typename?: 'Mutation';
  readonly requestPasswordReset: {
    readonly __typename?: 'ConfirmResponse';
    readonly confirmed: boolean;
  };
};

export type ResetPasswordMutationVariables = Exact<{
  token: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
  password: Scalars['String']['input'];
}>;

export type ResetPasswordMutation = {
  readonly __typename?: 'Mutation';
  readonly resetPassword: { readonly __typename?: 'ConfirmResponse'; readonly confirmed: boolean };
};

export type GetReportQueryVariables = Exact<{
  smid: Scalars['ID']['input'];
}>;

export type GetReportQuery = {
  readonly __typename?: 'Query';
  readonly report: {
    readonly __typename?: 'Report';
    readonly smid: string;
    readonly submittedAt?: Date | null;
    readonly sentAt?: Date | null;
    readonly sections: JSON;
    readonly content?: JSON | null;
    readonly sectionsLastUpdated?: Date | null;
    readonly version: number;
    readonly macros: ReadonlyArray<{
      readonly __typename?: 'Macro';
      readonly smid: string;
      readonly name: string;
      readonly triggerPhrase: string;
      readonly text: ReadonlyArray<JSON>;
      readonly created: Date;
      readonly updated?: Date | null;
      readonly nestedMacros: ReadonlyArray<{
        readonly __typename?: 'Macro';
        readonly smid: string;
        readonly created: Date;
        readonly updated?: Date | null;
        readonly name: string;
        readonly triggerPhrase: string;
        readonly text: ReadonlyArray<JSON>;
      }>;
      readonly procedures?: ReadonlyArray<{
        readonly __typename?: 'Procedure';
        readonly smid: string;
        readonly description: string;
        readonly code: string;
      }> | null;
      readonly owner?: {
        readonly __typename?: 'SlimUser';
        readonly id: string;
        readonly firstName: string;
        readonly lastName: string;
      } | null;
      readonly picklists: ReadonlyArray<{
        readonly __typename?: 'ReportPicklist';
        readonly id: string;
        readonly options: ReadonlyArray<{
          readonly __typename?: 'ReportPicklistOption';
          readonly id: string;
          readonly name?: string | null;
          readonly text: ReadonlyArray<JSON>;
          readonly default?: boolean | null;
        }>;
      }>;
    }>;
  };
};

export type GetReportsQueryVariables = Exact<{
  accessionNumbers: ReadonlyArray<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type GetReportsQuery = {
  readonly __typename?: 'Query';
  readonly reports: ReadonlyArray<{
    readonly __typename?: 'Report';
    readonly smid: string;
    readonly submittedAt?: Date | null;
    readonly sentAt?: Date | null;
    readonly sectionsLastUpdated?: Date | null;
    readonly sections: JSON;
    readonly content?: JSON | null;
    readonly format: ReportFormat;
    readonly macros: ReadonlyArray<{
      readonly __typename?: 'Macro';
      readonly smid: string;
      readonly name: string;
      readonly triggerPhrase: string;
      readonly text: ReadonlyArray<JSON>;
      readonly created: Date;
      readonly updated?: Date | null;
      readonly nestedMacros: ReadonlyArray<{
        readonly __typename?: 'Macro';
        readonly smid: string;
        readonly created: Date;
        readonly updated?: Date | null;
        readonly name: string;
        readonly triggerPhrase: string;
        readonly text: ReadonlyArray<JSON>;
      }>;
      readonly procedures?: ReadonlyArray<{
        readonly __typename?: 'Procedure';
        readonly smid: string;
        readonly description: string;
        readonly code: string;
      }> | null;
      readonly owner?: {
        readonly __typename?: 'SlimUser';
        readonly id: string;
        readonly firstName: string;
        readonly lastName: string;
      } | null;
      readonly picklists: ReadonlyArray<{
        readonly __typename?: 'ReportPicklist';
        readonly id: string;
        readonly options: ReadonlyArray<{
          readonly __typename?: 'ReportPicklistOption';
          readonly id: string;
          readonly name?: string | null;
          readonly text: ReadonlyArray<JSON>;
          readonly default?: boolean | null;
        }>;
      }>;
    }>;
    readonly addendums: ReadonlyArray<{
      readonly __typename?: 'Addendum';
      readonly created: Date;
      readonly updated: Date;
      readonly submittedAt?: Date | null;
      readonly sentAt?: Date | null;
      readonly smid: string;
      readonly content:
        | { readonly __typename?: 'AddendumSironaSlate'; readonly sironaSlate: ReadonlyArray<JSON> }
        | { readonly __typename?: 'AddendumTextBlob'; readonly textBlob: string };
      readonly owner?: {
        readonly __typename?: 'Owner';
        readonly id: string;
        readonly firstName: string;
        readonly lastName: string;
        readonly email: string;
      } | null;
    }>;
  }>;
};

export type GetPatientHistoryListQueryVariables = Exact<{
  mrn: ReadonlyArray<Scalars['String']['input']> | Scalars['String']['input'];
  sortColumn?: InputMaybe<ReadonlyArray<SortColumn> | SortColumn>;
  sortOrder?: InputMaybe<ReadonlyArray<SortOrder> | SortOrder>;
}>;

export type GetPatientHistoryListQuery = {
  readonly __typename?: 'Query';
  readonly worklistItems: {
    readonly __typename?: 'WorklistItems';
    readonly items: ReadonlyArray<{
      readonly __typename?: 'WorklistItem';
      readonly smid: string;
      readonly patientName?: string | null;
      readonly studyDate?: Date | null;
      readonly created?: Date | null;
      readonly bodyPart?: string | null;
      readonly modality?: string | null;
      readonly status: WorklistItemStatus;
      readonly mrn?: string | null;
      readonly studyDescription?: string | null;
      readonly dosage?: string | null;
      readonly studyReason?: string | null;
      readonly techNotes?: string | null;
      readonly patientSex?: string | null;
      readonly patientDob?: Date | null;
      readonly patientAge?: string | null;
      readonly accessionNumber?: string | null;
      readonly studies: ReadonlyArray<{ readonly __typename?: 'Study'; readonly smid: string }>;
      readonly claimedBy?: { readonly __typename?: 'User'; readonly id: string } | null;
      readonly report?: {
        readonly __typename?: 'Report';
        readonly smid: string;
        readonly worklistitem?: string | null;
        readonly content?: JSON | null;
      } | null;
    }>;
  };
};

export type GetBaseViewerDataQueryVariables = Exact<{
  worklistIds: ReadonlyArray<Scalars['ID']['input']> | Scalars['ID']['input'];
  studyIds: ReadonlyArray<Scalars['ID']['input']> | Scalars['ID']['input'];
  skippedStudyIds: ReadonlyArray<Scalars['ID']['input']> | Scalars['ID']['input'];
  hasWorklistIds: Scalars['Boolean']['input'];
  hasStudyIds: Scalars['Boolean']['input'];
  includeAutoloadedPriors: Scalars['Boolean']['input'];
}>;

export type GetBaseViewerDataQuery = {
  readonly __typename?: 'Query';
  readonly worklistItems?: {
    readonly __typename?: 'WorklistItems';
    readonly items: ReadonlyArray<{
      readonly __typename?: 'WorklistItem';
      readonly smid: string;
      readonly studies: ReadonlyArray<{
        readonly __typename?: 'Study';
        readonly smid: string;
        readonly created: Date;
        readonly updated: Date;
        readonly instanceUID: string;
        readonly description?: string | null;
        readonly hl7Order?: string | null;
        readonly accessionNumber?: string | null;
        readonly seriesCount: number;
        readonly patientSex?: string | null;
        readonly patientAge?: string | null;
        readonly patientName?: string | null;
        readonly patientDob?: Date | null;
        readonly modality: string;
        readonly bodyPart?: string | null;
        readonly mrn?: string | null;
        readonly studyDate?: Date | null;
        readonly laterality?: string | null;
        readonly seriesList: ReadonlyArray<{
          readonly __typename?: 'Series';
          readonly smid: string;
          readonly description?: string | null;
          readonly seriesInstanceUID: string;
          readonly seriesDateTimeLocal?: DateTimeLocalScalarConfig | null;
          readonly frameOfReferenceUID?: string | null;
          readonly seriesNumber?: number | null;
          readonly is3Dable: boolean;
          readonly study: { readonly __typename?: 'Study'; readonly smid: string };
          readonly classifications?: ReadonlyArray<{
            readonly __typename?: 'SeriesClassification';
            readonly seriesDescription?: string | null;
            readonly studySmid?: string | null;
            readonly seriesSmid?: string | null;
            readonly seriesNumber?: number | null;
            readonly seriesDate?: string | null;
            readonly seriesTime?: string | null;
            readonly seriesCriteria?: {
              readonly __typename?: 'SeriesCriteria';
              readonly anatomic_focus?: ReadonlyArray<{
                readonly __typename?: 'ClassificationSummary';
                readonly display_name?: string | null;
                readonly ontology_id?: string | null;
              } | null> | null;
            } | null;
          } | null> | null;
          readonly imageRegistrations: ReadonlyArray<{
            readonly __typename?: 'ImageRegistration';
            readonly smid: string;
            readonly created: Date;
            readonly codeVersion: string;
            readonly modelName: string;
            readonly modelVersion: string;
            readonly seriesFixed: string;
            readonly seriesMoving: string;
            readonly dataType: string;
            readonly fixedFrameOfReferenceUid: string;
            readonly movingFrameOfReferenceUid: string;
            readonly transformMatrix: [
              [number, number, number, number],
              [number, number, number, number],
              [number, number, number, number],
              [number, number, number, number],
            ];
          }>;
        }>;
        readonly stackedFrames: ReadonlyArray<
          | {
              readonly __typename?: 'LayeredStack';
              readonly smid: string;
              readonly type: string;
              readonly study: { readonly __typename?: 'Study'; readonly smid: string };
              readonly stackLayers: ReadonlyArray<{
                readonly __typename?: 'Layer';
                readonly stackSmid: string;
                readonly index: number;
              }>;
            }
          | {
              readonly __typename?: 'SingleLayerStack';
              readonly smid: string;
              readonly type: string;
              readonly image: string;
              readonly is3Dable: boolean;
              readonly frames: ReadonlyArray<FrameDataFragment>;
              readonly series: { readonly __typename?: 'Series'; readonly smid: string };
              readonly study: { readonly __typename?: 'Study'; readonly smid: string };
              readonly supportedRenderers: {
                readonly __typename?: 'SupportedRenderers';
                readonly vtk: RendererSupport;
              };
              readonly imageParams: {
                readonly __typename?: 'ImageParams';
                readonly origin: [number, number, number];
                readonly spacing: [number, number, number];
                readonly dimensions: [number, number, number];
                readonly extent: [number, number, number, number, number, number];
                readonly direction: [
                  number,
                  number,
                  number,
                  number,
                  number,
                  number,
                  number,
                  number,
                  number,
                ];
                readonly isMultiAxes: boolean;
                readonly colorWindow?: number | null;
                readonly colorLevel?: number | null;
              };
            }
        >;
      }>;
      readonly autoloadedPriors?: ReadonlyArray<{
        readonly __typename?: 'Study';
        readonly smid: string;
        readonly created: Date;
        readonly updated: Date;
        readonly instanceUID: string;
        readonly description?: string | null;
        readonly hl7Order?: string | null;
        readonly accessionNumber?: string | null;
        readonly seriesCount: number;
        readonly patientSex?: string | null;
        readonly patientAge?: string | null;
        readonly patientName?: string | null;
        readonly patientDob?: Date | null;
        readonly modality: string;
        readonly bodyPart?: string | null;
        readonly mrn?: string | null;
        readonly studyDate?: Date | null;
        readonly laterality?: string | null;
        readonly provenanceData?: {
          readonly __typename?: 'ProvenanceData';
          readonly matchType?: MatchType | null;
          readonly relevant?: boolean | null;
        } | null;
        readonly seriesList: ReadonlyArray<{
          readonly __typename?: 'Series';
          readonly smid: string;
          readonly description?: string | null;
          readonly seriesInstanceUID: string;
          readonly seriesDateTimeLocal?: DateTimeLocalScalarConfig | null;
          readonly frameOfReferenceUID?: string | null;
          readonly seriesNumber?: number | null;
          readonly is3Dable: boolean;
          readonly study: { readonly __typename?: 'Study'; readonly smid: string };
          readonly classifications?: ReadonlyArray<{
            readonly __typename?: 'SeriesClassification';
            readonly seriesDescription?: string | null;
            readonly studySmid?: string | null;
            readonly seriesSmid?: string | null;
            readonly seriesNumber?: number | null;
            readonly seriesDate?: string | null;
            readonly seriesTime?: string | null;
            readonly seriesCriteria?: {
              readonly __typename?: 'SeriesCriteria';
              readonly anatomic_focus?: ReadonlyArray<{
                readonly __typename?: 'ClassificationSummary';
                readonly display_name?: string | null;
                readonly ontology_id?: string | null;
              } | null> | null;
            } | null;
          } | null> | null;
          readonly imageRegistrations: ReadonlyArray<{
            readonly __typename?: 'ImageRegistration';
            readonly smid: string;
            readonly created: Date;
            readonly codeVersion: string;
            readonly modelName: string;
            readonly modelVersion: string;
            readonly seriesFixed: string;
            readonly seriesMoving: string;
            readonly dataType: string;
            readonly fixedFrameOfReferenceUid: string;
            readonly movingFrameOfReferenceUid: string;
            readonly transformMatrix: [
              [number, number, number, number],
              [number, number, number, number],
              [number, number, number, number],
              [number, number, number, number],
            ];
          }>;
        }>;
        readonly stackedFrames: ReadonlyArray<
          | {
              readonly __typename?: 'LayeredStack';
              readonly smid: string;
              readonly type: string;
              readonly study: { readonly __typename?: 'Study'; readonly smid: string };
              readonly stackLayers: ReadonlyArray<{
                readonly __typename?: 'Layer';
                readonly stackSmid: string;
                readonly index: number;
              }>;
            }
          | {
              readonly __typename?: 'SingleLayerStack';
              readonly smid: string;
              readonly type: string;
              readonly image: string;
              readonly is3Dable: boolean;
              readonly frames: ReadonlyArray<FrameDataFragment>;
              readonly series: { readonly __typename?: 'Series'; readonly smid: string };
              readonly study: { readonly __typename?: 'Study'; readonly smid: string };
              readonly supportedRenderers: {
                readonly __typename?: 'SupportedRenderers';
                readonly vtk: RendererSupport;
              };
              readonly imageParams: {
                readonly __typename?: 'ImageParams';
                readonly origin: [number, number, number];
                readonly spacing: [number, number, number];
                readonly dimensions: [number, number, number];
                readonly extent: [number, number, number, number, number, number];
                readonly direction: [
                  number,
                  number,
                  number,
                  number,
                  number,
                  number,
                  number,
                  number,
                  number,
                ];
                readonly isMultiAxes: boolean;
                readonly colorWindow?: number | null;
                readonly colorLevel?: number | null;
              };
            }
        >;
      }>;
      readonly allAutoloadedPriors?: ReadonlyArray<{
        readonly __typename?: 'DehydratedStudy';
        readonly smid: string;
      }> | null;
    }>;
  };
  readonly studiesBySmid?: ReadonlyArray<{
    readonly __typename?: 'Study';
    readonly smid: string;
    readonly created: Date;
    readonly updated: Date;
    readonly instanceUID: string;
    readonly description?: string | null;
    readonly hl7Order?: string | null;
    readonly accessionNumber?: string | null;
    readonly seriesCount: number;
    readonly patientSex?: string | null;
    readonly patientAge?: string | null;
    readonly patientName?: string | null;
    readonly patientDob?: Date | null;
    readonly modality: string;
    readonly bodyPart?: string | null;
    readonly mrn?: string | null;
    readonly studyDate?: Date | null;
    readonly laterality?: string | null;
    readonly provenanceData?: {
      readonly __typename?: 'ProvenanceData';
      readonly matchType?: MatchType | null;
      readonly relevant?: boolean | null;
    } | null;
    readonly seriesList: ReadonlyArray<{
      readonly __typename?: 'Series';
      readonly smid: string;
      readonly description?: string | null;
      readonly seriesInstanceUID: string;
      readonly seriesDateTimeLocal?: DateTimeLocalScalarConfig | null;
      readonly frameOfReferenceUID?: string | null;
      readonly seriesNumber?: number | null;
      readonly is3Dable: boolean;
      readonly study: { readonly __typename?: 'Study'; readonly smid: string };
      readonly classifications?: ReadonlyArray<{
        readonly __typename?: 'SeriesClassification';
        readonly seriesDescription?: string | null;
        readonly studySmid?: string | null;
        readonly seriesSmid?: string | null;
        readonly seriesNumber?: number | null;
        readonly seriesDate?: string | null;
        readonly seriesTime?: string | null;
        readonly seriesCriteria?: {
          readonly __typename?: 'SeriesCriteria';
          readonly anatomic_focus?: ReadonlyArray<{
            readonly __typename?: 'ClassificationSummary';
            readonly display_name?: string | null;
            readonly ontology_id?: string | null;
          } | null> | null;
        } | null;
      } | null> | null;
      readonly imageRegistrations: ReadonlyArray<{
        readonly __typename?: 'ImageRegistration';
        readonly smid: string;
        readonly created: Date;
        readonly codeVersion: string;
        readonly modelName: string;
        readonly modelVersion: string;
        readonly seriesFixed: string;
        readonly seriesMoving: string;
        readonly dataType: string;
        readonly fixedFrameOfReferenceUid: string;
        readonly movingFrameOfReferenceUid: string;
        readonly transformMatrix: [
          [number, number, number, number],
          [number, number, number, number],
          [number, number, number, number],
          [number, number, number, number],
        ];
      }>;
    }>;
    readonly stackedFrames: ReadonlyArray<
      | {
          readonly __typename?: 'LayeredStack';
          readonly smid: string;
          readonly type: string;
          readonly study: { readonly __typename?: 'Study'; readonly smid: string };
          readonly stackLayers: ReadonlyArray<{
            readonly __typename?: 'Layer';
            readonly stackSmid: string;
            readonly index: number;
          }>;
        }
      | {
          readonly __typename?: 'SingleLayerStack';
          readonly smid: string;
          readonly type: string;
          readonly image: string;
          readonly is3Dable: boolean;
          readonly frames: ReadonlyArray<FrameDataFragment>;
          readonly series: { readonly __typename?: 'Series'; readonly smid: string };
          readonly study: { readonly __typename?: 'Study'; readonly smid: string };
          readonly supportedRenderers: {
            readonly __typename?: 'SupportedRenderers';
            readonly vtk: RendererSupport;
          };
          readonly imageParams: {
            readonly __typename?: 'ImageParams';
            readonly origin: [number, number, number];
            readonly spacing: [number, number, number];
            readonly dimensions: [number, number, number];
            readonly extent: [number, number, number, number, number, number];
            readonly direction: [
              number,
              number,
              number,
              number,
              number,
              number,
              number,
              number,
              number,
            ];
            readonly isMultiAxes: boolean;
            readonly colorWindow?: number | null;
            readonly colorLevel?: number | null;
          };
        }
    >;
  }>;
  readonly matchHangingProtocolByWorklistsAndStudies?: {
    readonly __typename?: 'HangingProtocol';
    readonly smid: string;
    readonly name: string;
    readonly numberOfPriors: number;
    readonly created: Date;
    readonly updated: Date;
    readonly studyDescriptions: ReadonlyArray<string>;
    readonly studyCriteria?: JSON | null;
    readonly data: {
      readonly __typename?: 'HangingProtocolData';
      readonly layouts: ReadonlyArray<{
        readonly __typename?: 'HangingProtocolLayout';
        readonly id: string;
        readonly layout: ReadonlyArray<number>;
      }>;
    };
    readonly viewportsConfigurations?: ReadonlyArray<{
      readonly __typename?: 'ViewportConfiguration';
      readonly id: string;
      readonly configuration: {
        readonly __typename?: 'ViewportConfigurationData';
        readonly viewType?: ViewType | null;
        readonly comparisonIndex?: number | null;
        readonly seriesDescriptions: ReadonlyArray<string>;
        readonly study?: { readonly __typename?: 'DehydratedStudy'; readonly smid: string } | null;
        readonly series?: {
          readonly __typename?: 'DehydratedSeries';
          readonly smid: string;
        } | null;
        readonly stack?: { readonly __typename?: 'DehydratedStack'; readonly smid: string } | null;
        readonly seriesCriteria?: {
          readonly __typename?: 'SeriesCriteria';
          readonly anatomic_focus?: ReadonlyArray<{
            readonly __typename?: 'ClassificationSummary';
            readonly display_name?: string | null;
            readonly ontology_id?: string | null;
          } | null> | null;
        } | null;
      };
    }> | null;
  } | null;
  readonly presentationStates: ReadonlyArray<
    | {
        readonly __typename?: 'GspsPresentationState';
        readonly smid: string;
        readonly created?: DateTimeLocalScalarConfig | null;
        readonly name?: string | null;
        readonly owner?: {
          readonly __typename?: 'GspsOwner';
          readonly firstName?: string | null;
          readonly lastName?: string | null;
        } | null;
        readonly study: {
          readonly __typename?: 'Study';
          readonly smid: string;
          readonly description?: string | null;
        };
        readonly annotations: ReadonlyArray<{
          readonly __typename?: 'GspsAnnotation';
          readonly smid: string;
        }>;
      }
    | { readonly __typename?: 'GspsPresentationStateError'; readonly message: string }
  >;
};

export type GetStackSignedUrlsQueryVariables = Exact<{
  stackSmid: Scalars['ID']['input'];
  isMultiFrame: Scalars['Boolean']['input'];
  frames: ReadonlyArray<FrameToLoad> | FrameToLoad;
}>;

export type GetStackSignedUrlsQuery = {
  readonly __typename?: 'Query';
  readonly pixelURLs: ReadonlyArray<{
    readonly __typename?: 'PixelURL';
    readonly frameSmid: string;
    readonly fallbackUrl: string;
    readonly signedUrl: {
      readonly __typename?: 'SignedUrl';
      readonly url: string;
      readonly expirationTime: number;
    };
  }>;
};

export type GetWorkspacesPreferencesQueryVariables = Exact<{ [key: string]: never }>;

export type GetWorkspacesPreferencesQuery = {
  readonly __typename?: 'Query';
  readonly toolPreferences: {
    readonly __typename?: 'ToolPreferences';
    readonly workspaces: {
      readonly __typename?: 'WorkspacePreferences';
      readonly autoApply: boolean;
      readonly presets: ReadonlyArray<{
        readonly __typename?: 'WorkspacePreset';
        readonly smid: string;
        readonly instanceId: string;
        readonly name: string;
        readonly createdAt: Date;
        readonly updatedAt: Date;
        readonly windows: ReadonlyArray<{
          readonly __typename?: 'Window';
          readonly id: string;
          readonly width: number;
          readonly height: number;
          readonly left: number;
          readonly top: number;
          readonly screen: ScreenName;
          readonly type: WindowType;
          readonly state: WindowState;
        }>;
      }>;
    };
  };
};

export type CancelReportMutationVariables = Exact<{
  smid: Scalars['ID']['input'];
}>;

export type CancelReportMutation = {
  readonly __typename?: 'Mutation';
  readonly cancelReport: {
    readonly __typename?: 'Report';
    readonly smid: string;
    readonly submittedAt?: Date | null;
  };
};

export type SubmitReportMutationVariables = Exact<{
  smid: Scalars['ID']['input'];
  immediate_submit?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type SubmitReportMutation = {
  readonly __typename?: 'Mutation';
  readonly submitReport:
    | {
        readonly __typename: 'SubmitReportPayload';
        readonly report: {
          readonly __typename?: 'Report';
          readonly smid: string;
          readonly submittedAt?: Date | null;
        };
      }
    | {
        readonly __typename: 'SubmitReportProblem';
        readonly unknownProblem?: string | null;
        readonly invalidInputs: ReadonlyArray<{
          readonly __typename?: 'SubmitReportInvalidInput';
          readonly field: SubmitReportInvalidInputField;
          readonly message: string;
        } | null>;
      };
};

export type CreateWorkspacePresetMutationVariables = Exact<{
  payload: WorkspacePresetPayload;
}>;

export type CreateWorkspacePresetMutation = {
  readonly __typename?: 'Mutation';
  readonly createWorkspacePreset: {
    readonly __typename?: 'WorkspacePreset';
    readonly smid: string;
    readonly instanceId: string;
    readonly name: string;
    readonly createdAt: Date;
    readonly updatedAt: Date;
    readonly windows: ReadonlyArray<{
      readonly __typename?: 'Window';
      readonly id: string;
      readonly width: number;
      readonly height: number;
      readonly left: number;
      readonly top: number;
      readonly screen: ScreenName;
      readonly type: WindowType;
      readonly state: WindowState;
    }>;
  };
};

export type UpdateWorkspacePresetMutationVariables = Exact<{
  smid: Scalars['ID']['input'];
  payload: WorkspacePresetPayload;
}>;

export type UpdateWorkspacePresetMutation = {
  readonly __typename?: 'Mutation';
  readonly updateWorkspacePreset: {
    readonly __typename?: 'WorkspacePreset';
    readonly smid: string;
    readonly instanceId: string;
    readonly name: string;
    readonly createdAt: Date;
    readonly updatedAt: Date;
    readonly windows: ReadonlyArray<{
      readonly __typename?: 'Window';
      readonly id: string;
      readonly width: number;
      readonly height: number;
      readonly left: number;
      readonly top: number;
      readonly screen: ScreenName;
      readonly type: WindowType;
      readonly state: WindowState;
    }>;
  };
};

export type SetWorkspaceAutoApplyMutationVariables = Exact<{
  value: Scalars['Boolean']['input'];
}>;

export type SetWorkspaceAutoApplyMutation = {
  readonly __typename?: 'Mutation';
  readonly setWorkspaceAutoApply: {
    readonly __typename?: 'WorkspacePreferences';
    readonly autoApply: boolean;
  };
};

export type DeleteWorkspacePresetMutationVariables = Exact<{
  smid: Scalars['ID']['input'];
}>;

export type DeleteWorkspacePresetMutation = {
  readonly __typename?: 'Mutation';
  readonly deleteWorkspacePreset: {
    readonly __typename?: 'ConfirmDeleteWorkspacePreset';
    readonly smid: string;
  };
};

export type UpdateReporterSettingsMutationVariables = Exact<{
  triggerWord?: InputMaybe<Scalars['String']['input']>;
  styles?: InputMaybe<StylesInput>;
  cursorStyle?: InputMaybe<CursorStyleInput>;
  lineIndicator?: InputMaybe<LineIndicatorInput>;
  mergeFieldsSettings?: InputMaybe<MergeFieldsSettingsInput>;
  aiMode?: InputMaybe<AiModeInput>;
  impressionGenerator?: InputMaybe<ImpressionGeneratorInput>;
  autoFillComparison?: InputMaybe<AutoFillComparisonInput>;
  focusMode?: InputMaybe<FocusModeInput>;
}>;

export type UpdateReporterSettingsMutation = {
  readonly __typename?: 'Mutation';
  readonly updateReporterSettings: {
    readonly __typename?: 'ReporterSettings';
    readonly triggerWord: string;
    readonly aiMode: {
      readonly __typename?: 'AIModeSettings';
      readonly findingsMapperEnabled: boolean;
      readonly formatImpressionGenerationAsList: boolean;
      readonly onDemandImpressionGeneration: boolean;
    };
    readonly impressionGenerator: {
      readonly __typename?: 'ImpressionGenerator';
      readonly automatic: boolean;
      readonly enabled: boolean;
      readonly modalities: ReadonlyArray<string>;
    };
    readonly mergeFieldsSettings: {
      readonly __typename?: 'MergeFieldsSettings';
      readonly ignoreNavigation: boolean;
      readonly ignoreDefaultSelection: boolean;
    };
    readonly styles: {
      readonly __typename?: 'Styles';
      readonly bodyStyle: {
        readonly __typename?: 'TextStyle';
        readonly fontSize?: number | null;
        readonly fontFamily?: string | null;
        readonly textTransform?: TextTransform | null;
        readonly textDecoration?: {
          readonly __typename?: 'TextDecoration';
          readonly isBold?: boolean | null;
          readonly isItalic?: boolean | null;
          readonly isUnderline?: boolean | null;
        } | null;
      };
      readonly headingStyles: ReadonlyArray<{
        readonly __typename?: 'HeadingStyle';
        readonly level: HeadingLevel;
        readonly textStyle: {
          readonly __typename?: 'TextStyle';
          readonly fontSize?: number | null;
          readonly textTransform?: TextTransform | null;
          readonly textDecoration?: {
            readonly __typename?: 'TextDecoration';
            readonly isBold?: boolean | null;
            readonly isItalic?: boolean | null;
            readonly isUnderline?: boolean | null;
          } | null;
        };
      }>;
    };
    readonly cursorStyle: { readonly __typename?: 'CursorStyle'; readonly color: string };
    readonly lineIndicator: {
      readonly __typename?: 'LineIndicator';
      readonly enabled: boolean;
      readonly placement: LineIndicatorPlacement;
      readonly variant: LineIndicatorVariant;
    };
    readonly autoFillComparison: {
      readonly __typename?: 'AutoFillComparison';
      readonly enabled: boolean;
    };
    readonly focusMode: { readonly __typename?: 'FocusMode'; readonly enabled: boolean };
  };
};

export type DeleteUserLinkedAiAnnotationsMutationVariables = Exact<{
  payload: ReadonlyArray<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type DeleteUserLinkedAiAnnotationsMutation = {
  readonly __typename?: 'Mutation';
  readonly deleteUserLinkedAiAnnotations?: {
    readonly __typename?: 'Response';
    readonly status?: number | null;
  } | null;
};

export type RejectAiAnnotationsMutationVariables = Exact<{
  ids: ReadonlyArray<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type RejectAiAnnotationsMutation = {
  readonly __typename?: 'Mutation';
  readonly rejectAiAnnotations: {
    readonly __typename?: 'RejectAiAnnotationLog';
    readonly logIds: ReadonlyArray<string>;
  };
};

export type AcceptAiAnnotationsMutationVariables = Exact<{
  annotationsByGroup: ReadonlyArray<AcceptAiAnnotation> | AcceptAiAnnotation;
}>;

export type AcceptAiAnnotationsMutation = {
  readonly __typename?: 'Mutation';
  readonly acceptAiAnnotations: {
    readonly __typename?: 'AcceptAiAnnotationResponse';
    readonly logIds: ReadonlyArray<string>;
    readonly annotations: ReadonlyArray<{
      readonly __typename?: 'AnnotationLogResponse';
      readonly annotationSmid: string;
    }>;
  };
};

export type GetBodyPartsQueryVariables = Exact<{ [key: string]: never }>;

export type GetBodyPartsQuery = {
  readonly __typename?: 'Query';
  readonly bodyParts: ReadonlyArray<{ readonly __typename?: 'BodyPart'; readonly name: string }>;
};

export type GetModalitiesQueryVariables = Exact<{ [key: string]: never }>;

export type GetModalitiesQuery = {
  readonly __typename?: 'Query';
  readonly modalities: ReadonlyArray<{ readonly __typename?: 'Modality'; readonly name: string }>;
};

export type GetInstanceTagsQueryVariables = Exact<{
  seriesSmid: Scalars['ID']['input'];
  instanceSmid: Scalars['ID']['input'];
}>;

export type GetInstanceTagsQuery = {
  readonly __typename?: 'Query';
  readonly dicomInstance?: {
    readonly __typename?: 'DicomInstance';
    readonly smid: string;
    readonly dicomTags: ReadonlyArray<{
      readonly __typename?: 'DicomTag';
      readonly id: string;
      readonly vr: string;
      readonly name: string;
      readonly values: ReadonlyArray<{
        readonly __typename?: 'DicomTagValue';
        readonly key?: string | null;
        readonly value?: JSON | null;
      }>;
    }>;
  } | null;
};

export type CreateAutoCorrectEntryMutationVariables = Exact<{
  input: CreateAutoCorrectEntryInput;
}>;

export type CreateAutoCorrectEntryMutation = {
  readonly __typename?: 'Mutation';
  readonly createAutoCorrectEntry: {
    readonly __typename?: 'CreateAutoCorrectEntryResponse';
    readonly entry?: {
      readonly __typename?: 'AutoCorrectEntry';
      readonly id: string;
      readonly key: string;
      readonly value: string;
    } | null;
  };
};

export type DeleteAutoCorrectEntryMutationVariables = Exact<{
  input: DeleteAutoCorrectEntryInput;
}>;

export type DeleteAutoCorrectEntryMutation = {
  readonly __typename?: 'Mutation';
  readonly deleteAutoCorrectEntry: {
    readonly __typename?: 'DeleteAutoCorrectEntryResponse';
    readonly id: string;
  };
};

export type UpdateAutoCorrectEntryMutationVariables = Exact<{
  input: UpdateAutoCorrectEntryInput;
}>;

export type UpdateAutoCorrectEntryMutation = {
  readonly __typename?: 'Mutation';
  readonly updateAutoCorrectEntry: {
    readonly __typename?: 'UpdateAutoCorrectEntryResponse';
    readonly entry?: {
      readonly __typename?: 'AutoCorrectEntry';
      readonly id: string;
      readonly key: string;
      readonly value: string;
    } | null;
  };
};

export type GetVocabEntriesQueryVariables = Exact<{ [key: string]: never }>;

export type GetVocabEntriesQuery = {
  readonly __typename?: 'Query';
  readonly vocabEntries: ReadonlyArray<{
    readonly __typename?: 'VocabEntry';
    readonly id: string;
    readonly phrase: string;
  }>;
};

export type CreateVocabEntryMutationVariables = Exact<{
  input: CreateVocabEntryInput;
}>;

export type CreateVocabEntryMutation = {
  readonly __typename?: 'Mutation';
  readonly createVocabEntry: {
    readonly __typename?: 'CreateVocabEntryResponse';
    readonly entry?: {
      readonly __typename?: 'VocabEntry';
      readonly id: string;
      readonly phrase: string;
    } | null;
  };
};

export type DeleteVocabEntryMutationVariables = Exact<{
  input: DeleteVocabEntryInput;
}>;

export type DeleteVocabEntryMutation = {
  readonly __typename?: 'Mutation';
  readonly deleteVocabEntry: {
    readonly __typename?: 'DeleteVocabEntryResponse';
    readonly id: string;
  };
};

export type GetToolsQueryVariables = Exact<{ [key: string]: never }>;

export type GetToolsQuery = {
  readonly __typename?: 'Query';
  readonly tools: ReadonlyArray<{
    readonly __typename?: 'Tool';
    readonly id: string;
    readonly name: string;
    readonly category: ToolCategory;
    readonly description: string;
    readonly icon: string;
    readonly payloadCapabilities: ReadonlyArray<ToolPayloadCapability>;
    readonly interactions: ReadonlyArray<
      | {
          readonly __typename?: 'ToolAnnotationInteraction';
          readonly type: ToolInteractionType;
          readonly id: string;
          readonly maxConcurrentRequests?: number | null;
          readonly targetAnnotationId?: string | null;
          readonly targetAnnotationGroupId?: string | null;
          readonly button: MouseButton;
          readonly action: MouseAction;
          readonly request: {
            readonly __typename?: 'ToolInteractionRequest';
            readonly url: string;
            readonly method: ToolInteractionRequestMethod;
          };
        }
      | {
          readonly __typename?: 'ToolContextMenuOptionInteraction';
          readonly type: ToolInteractionType;
          readonly id: string;
          readonly maxConcurrentRequests?: number | null;
          readonly label: string;
          readonly request: {
            readonly __typename?: 'ToolInteractionRequest';
            readonly url: string;
            readonly method: ToolInteractionRequestMethod;
          };
        }
      | {
          readonly __typename?: 'ToolIconButtonClickInteraction';
          readonly type: ToolInteractionType;
          readonly id: string;
          readonly maxConcurrentRequests?: number | null;
          readonly request: {
            readonly __typename?: 'ToolInteractionRequest';
            readonly url: string;
            readonly method: ToolInteractionRequestMethod;
          };
        }
      | {
          readonly __typename?: 'ToolKeyboardShortcutInteraction';
          readonly type: ToolInteractionType;
          readonly id: string;
          readonly maxConcurrentRequests?: number | null;
          readonly keyboardShortcut: string;
          readonly request: {
            readonly __typename?: 'ToolInteractionRequest';
            readonly url: string;
            readonly method: ToolInteractionRequestMethod;
          };
        }
      | {
          readonly __typename?: 'ToolMergeFieldInteraction';
          readonly type: ToolInteractionType;
          readonly id: string;
          readonly maxConcurrentRequests?: number | null;
          readonly label: string;
          readonly request: {
            readonly __typename?: 'ToolInteractionRequest';
            readonly url: string;
            readonly method: ToolInteractionRequestMethod;
          };
        }
      | {
          readonly __typename?: 'ToolViewportClickInteraction';
          readonly type: ToolInteractionType;
          readonly id: string;
          readonly maxConcurrentRequests?: number | null;
          readonly button: MouseButton;
          readonly cursorIcon: string;
          readonly request: {
            readonly __typename?: 'ToolInteractionRequest';
            readonly url: string;
            readonly method: ToolInteractionRequestMethod;
          };
        }
      | {
          readonly __typename?: 'ToolVoiceCommandInteraction';
          readonly type: ToolInteractionType;
          readonly id: string;
          readonly maxConcurrentRequests?: number | null;
          readonly triggerPhrase: string;
          readonly request: {
            readonly __typename?: 'ToolInteractionRequest';
            readonly url: string;
            readonly method: ToolInteractionRequestMethod;
          };
        }
    >;
    readonly customAnnotationsLogic: ReadonlyArray<{
      readonly __typename?: 'CustomAnnotationLogic';
      readonly id: string;
      readonly logic: string;
    }>;
  }>;
};

export type TriggerToolInteractionMutationVariables = Exact<{
  payload: ToolInteractionPayload;
  toolId: Scalars['ID']['input'];
  toolInteractionId: Scalars['ID']['input'];
  toolInteractionType: ToolInteractionType;
}>;

export type TriggerToolInteractionMutation = {
  readonly __typename?: 'Mutation';
  readonly triggerToolInteraction: ReadonlyArray<
    | { readonly __typename?: 'ToolInteractionAddInteractionResponse' }
    | {
        readonly __typename?: 'ToolInteractionAnnotationResponse';
        readonly type: ToolInteractionResponseType;
        readonly annotation: {
          readonly __typename?: 'ToolInteractionAnnotationData';
          readonly type: AnnotationTypes;
          readonly groupId?: string | null;
          readonly label?: string | null;
          readonly style?: {
            readonly __typename?: 'AnnotationStyle';
            readonly color?: string | null;
            readonly opacity?: number | null;
          } | null;
          readonly allowedTransformations?: {
            readonly __typename?: 'AnnotationEditableOptions';
            readonly move?: boolean | null;
            readonly transform?: boolean | null;
          } | null;
          readonly segments: ReadonlyArray<{
            readonly __typename?: 'AnnotationSegment';
            readonly start:
              | {
                  readonly __typename?: 'IndexCoordinates';
                  readonly indexSpace: [number, number, number];
                }
              | {
                  readonly __typename?: 'WorldCoordinates';
                  readonly worldSpace: [number, number, number];
                };
            readonly end:
              | {
                  readonly __typename?: 'IndexCoordinates';
                  readonly indexSpace: [number, number, number];
                }
              | {
                  readonly __typename?: 'WorldCoordinates';
                  readonly worldSpace: [number, number, number];
                };
          }>;
          readonly customLogic?: {
            readonly __typename?: 'CustomAnnotationLogic';
            readonly id: string;
            readonly toolId: string;
            readonly logic: string;
          } | null;
        };
      }
    | { readonly __typename?: 'ToolInteractionInsertTextResponse' }
    | { readonly __typename?: 'ToolInteractionKeyboardShortcutResponse' }
    | {
        readonly __typename?: 'ToolInteractionMessageResponse';
        readonly type: ToolInteractionResponseType;
        readonly message: string;
        readonly display: MessageDisplay;
        readonly severity: MessageSeverity;
      }
    | {
        readonly __typename?: 'ToolInteractionStartAnnotationResponse';
        readonly type: ToolInteractionResponseType;
        readonly annotationType: string;
      }
    | {
        readonly __typename?: 'ToolInteractionUpdateToolActiveStateResponse';
        readonly type: ToolInteractionResponseType;
        readonly active: boolean;
      }
    | { readonly __typename?: 'ToolInteractionUpdateToolbarButtonIconResponse' }
    | {
        readonly __typename?: 'ToolInteractionUpdateViewportPresentationStateResponse';
        readonly viewportId?: string | null;
        readonly type: ToolInteractionResponseType;
        readonly window?: number | null;
        readonly level?: number | null;
        readonly zoom?: number | null;
        readonly rotate?: number | null;
      }
    | {
        readonly __typename?: 'ToolInteractionUpdateViewportSliceResponse';
        readonly type: ToolInteractionResponseType;
        readonly viewportId?: string | null;
        readonly slice: number;
      }
    | {
        readonly __typename?: 'ToolInteractionWidgetResponse';
        readonly type: ToolInteractionResponseType;
        readonly widget: {
          readonly __typename?: 'WidgetConfiguration';
          readonly id: string;
          readonly title: string;
          readonly type: WidgetType;
          readonly placement?: WidgetPlacement | null;
          readonly urlPath?: string | null;
          readonly data?: JSON | null;
          readonly dismissActions?: ReadonlyArray<WidgetDismissAction> | null;
          readonly position?:
            | {
                readonly __typename?: 'IndexCoordinates';
                readonly indexSpace: [number, number, number];
              }
            | {
                readonly __typename?: 'WorldCoordinates';
                readonly worldSpace: [number, number, number];
              }
            | null;
        };
      }
  >;
};

export type GetWorklistViewsQueryVariables = Exact<{ [key: string]: never }>;

export type GetWorklistViewsQuery = {
  readonly __typename?: 'Query';
  readonly worklistViews: ReadonlyArray<{
    readonly __typename?: 'WorklistView';
    readonly smid: string;
    readonly name: string;
  }>;
};

export type GetWorklistViewQueryVariables = Exact<{
  smid: Scalars['ID']['input'];
}>;

export type GetWorklistViewQuery = {
  readonly __typename?: 'Query';
  readonly worklistView?: {
    readonly __typename?: 'WorklistViewDetails';
    readonly smid: string;
    readonly name: string;
    readonly filters: ReadonlyArray<{
      readonly __typename?: 'WorklistViewFilters';
      readonly name: string;
      readonly options: ReadonlyArray<{
        readonly __typename?: 'WorklistViewFilter';
        readonly label: string;
        readonly value: string;
      }>;
    }>;
  } | null;
};

export type GetCustomAnnotationLogicQueryVariables = Exact<{
  toolId: Scalars['ID']['input'];
  type: Scalars['String']['input'];
}>;

export type GetCustomAnnotationLogicQuery = {
  readonly __typename?: 'Query';
  readonly customAnnotationLogic?: {
    readonly __typename?: 'CustomAnnotationLogic';
    readonly id: string;
    readonly logic: string;
  } | null;
};

export type GetCurrentWorklistReportQueryVariables = Exact<{
  smid: Scalars['ID']['input'];
}>;

export type GetCurrentWorklistReportQuery = {
  readonly __typename?: 'Query';
  readonly worklistItem?: {
    readonly __typename?: 'WorklistItem';
    readonly smid: string;
    readonly mrn?: string | null;
    readonly status: WorklistItemStatus;
    readonly isActive: boolean;
    readonly hasQcComments?: boolean | null;
    readonly dosage?: string | null;
    readonly studyReason?: string | null;
    readonly studyDescription?: string | null;
    readonly studyDate?: Date | null;
    readonly referringPhysician?: string | null;
    readonly accessionNumber?: string | null;
    readonly techNotes?: string | null;
    readonly patientName?: string | null;
    readonly patientDob?: Date | null;
    readonly patientAge?: string | null;
    readonly patientSex?: string | null;
    readonly date?: Date | null;
    readonly groupId?: string | null;
    readonly modality?: string | null;
    readonly hasDraftReport: boolean;
    readonly examCount?: number | null;
    readonly report?: {
      readonly __typename?: 'Report';
      readonly smid: string;
      readonly created: Date;
      readonly updated: Date;
      readonly version: number;
      readonly sectionsLastUpdated?: Date | null;
      readonly workstationID?: string | null;
      readonly status: ReportStatus;
      readonly format: ReportFormat;
      readonly submittedAt?: Date | null;
      readonly sentAt?: Date | null;
      readonly sections: JSON;
      readonly content?: JSON | null;
      readonly template?: {
        readonly __typename?: 'ReportTemplate';
        readonly id: string;
        readonly name: string;
        readonly triggerPhrase: string;
        readonly created: Date;
        readonly content: JSON;
        readonly sections: ReadonlyArray<JSON>;
        readonly picklists: ReadonlyArray<{
          readonly __typename?: 'ReportPicklist';
          readonly id: string;
          readonly options: ReadonlyArray<{
            readonly __typename?: 'ReportPicklistOption';
            readonly id: string;
            readonly name?: string | null;
            readonly text: ReadonlyArray<JSON>;
            readonly default?: boolean | null;
          }>;
        }>;
        readonly macros: ReadonlyArray<{
          readonly __typename?: 'Macro';
          readonly smid: string;
          readonly name: string;
          readonly triggerPhrase: string;
          readonly text: ReadonlyArray<JSON>;
          readonly created: Date;
          readonly updated?: Date | null;
          readonly nestedMacros: ReadonlyArray<{
            readonly __typename?: 'Macro';
            readonly smid: string;
            readonly created: Date;
            readonly updated?: Date | null;
            readonly name: string;
            readonly triggerPhrase: string;
            readonly text: ReadonlyArray<JSON>;
          }>;
          readonly procedures?: ReadonlyArray<{
            readonly __typename?: 'Procedure';
            readonly smid: string;
            readonly description: string;
            readonly code: string;
          }> | null;
          readonly owner?: {
            readonly __typename?: 'SlimUser';
            readonly id: string;
            readonly firstName: string;
            readonly lastName: string;
          } | null;
          readonly picklists: ReadonlyArray<{
            readonly __typename?: 'ReportPicklist';
            readonly id: string;
            readonly options: ReadonlyArray<{
              readonly __typename?: 'ReportPicklistOption';
              readonly id: string;
              readonly name?: string | null;
              readonly text: ReadonlyArray<JSON>;
              readonly default?: boolean | null;
            }>;
          }>;
        }>;
        readonly procedureReferences?: ReadonlyArray<{
          readonly __typename?: 'ProcedureReference';
          readonly smid: string;
          readonly description?: string | null;
          readonly code?: string | null;
          readonly autoload: boolean;
        }> | null;
      } | null;
      readonly macros: ReadonlyArray<{
        readonly __typename?: 'Macro';
        readonly smid: string;
        readonly name: string;
        readonly triggerPhrase: string;
        readonly text: ReadonlyArray<JSON>;
        readonly created: Date;
        readonly updated?: Date | null;
        readonly nestedMacros: ReadonlyArray<{
          readonly __typename?: 'Macro';
          readonly smid: string;
          readonly created: Date;
          readonly updated?: Date | null;
          readonly name: string;
          readonly triggerPhrase: string;
          readonly text: ReadonlyArray<JSON>;
        }>;
        readonly procedures?: ReadonlyArray<{
          readonly __typename?: 'Procedure';
          readonly smid: string;
          readonly description: string;
          readonly code: string;
        }> | null;
        readonly owner?: {
          readonly __typename?: 'SlimUser';
          readonly id: string;
          readonly firstName: string;
          readonly lastName: string;
        } | null;
        readonly picklists: ReadonlyArray<{
          readonly __typename?: 'ReportPicklist';
          readonly id: string;
          readonly options: ReadonlyArray<{
            readonly __typename?: 'ReportPicklistOption';
            readonly id: string;
            readonly name?: string | null;
            readonly text: ReadonlyArray<JSON>;
            readonly default?: boolean | null;
          }>;
        }>;
      }>;
      readonly addendums: ReadonlyArray<{
        readonly __typename?: 'Addendum';
        readonly created: Date;
        readonly updated: Date;
        readonly submittedAt?: Date | null;
        readonly sentAt?: Date | null;
        readonly smid: string;
        readonly content:
          | {
              readonly __typename?: 'AddendumSironaSlate';
              readonly sironaSlate: ReadonlyArray<JSON>;
            }
          | { readonly __typename?: 'AddendumTextBlob'; readonly textBlob: string };
        readonly owner?: {
          readonly __typename?: 'Owner';
          readonly id: string;
          readonly firstName: string;
          readonly lastName: string;
          readonly email: string;
        } | null;
      }>;
      readonly picklists?: ReadonlyArray<{
        readonly __typename?: 'ReportPicklist';
        readonly id: string;
        readonly options: ReadonlyArray<{
          readonly __typename?: 'ReportPicklistOption';
          readonly id: string;
          readonly name?: string | null;
          readonly text: ReadonlyArray<JSON>;
          readonly default?: boolean | null;
        }>;
      }> | null;
      readonly owner?: {
        readonly __typename?: 'Owner';
        readonly firstName: string;
        readonly lastName: string;
      } | null;
    } | null;
    readonly studies: ReadonlyArray<{
      readonly __typename?: 'Study';
      readonly smid: string;
      readonly created: Date;
      readonly updated: Date;
      readonly instanceUID: string;
      readonly description?: string | null;
      readonly hl7Order?: string | null;
      readonly patientSex?: string | null;
      readonly patientAge?: string | null;
      readonly patientName?: string | null;
      readonly patientDob?: Date | null;
      readonly modality: string;
      readonly bodyPart?: string | null;
      readonly mrn?: string | null;
      readonly studyDate?: Date | null;
      readonly laterality?: string | null;
      readonly provenanceData?: {
        readonly __typename?: 'ProvenanceData';
        readonly matchType?: MatchType | null;
        readonly relevant?: boolean | null;
      } | null;
    }>;
    readonly procedure?: {
      readonly __typename?: 'Procedure';
      readonly smid: string;
      readonly description: string;
      readonly code: string;
    } | null;
    readonly customMergeFields: ReadonlyArray<{
      readonly __typename?: 'CustomMergeField';
      readonly key: string;
      readonly value: string;
    }>;
    readonly claimedBy?: {
      readonly __typename?: 'User';
      readonly id: string;
      readonly firstName: string;
      readonly lastName: string;
    } | null;
    readonly customStatus?: {
      readonly __typename?: 'WorklistCustomStatus';
      readonly smid: string;
      readonly name: string;
    } | null;
  } | null;
};

export type ToggleScreenShareMutationVariables = Exact<{
  screenShareEnabled: Scalars['Boolean']['input'];
}>;

export type ToggleScreenShareMutation = {
  readonly __typename?: 'Mutation';
  readonly toggleScreenShare: {
    readonly __typename?: 'ScreenShareSettingResponse';
    readonly screenShareEnabled: boolean;
  };
};

export type GetStructuredReportQueryVariables = Exact<{
  seriesSmid: Scalars['ID']['input'];
  frameSmid: Scalars['ID']['input'];
}>;

export type GetStructuredReportQuery = {
  readonly __typename?: 'Query';
  readonly structuredReport: {
    readonly __typename?: 'StructuredReport';
    readonly id: string;
    readonly modalities: string;
    readonly content: Json;
    readonly patient: {
      readonly __typename?: 'SRPatient';
      readonly name?: string | null;
      readonly age?: string | null;
      readonly id?: string | null;
      readonly sex?: string | null;
    };
    readonly study: {
      readonly __typename?: 'SRStudy';
      readonly date?: Date | null;
      readonly description?: string | null;
      readonly id?: string | null;
      readonly time?: string | null;
      readonly uid?: string | null;
    };
    readonly series: {
      readonly __typename?: 'SRSeries';
      readonly description?: string | null;
      readonly number?: number | null;
      readonly uid?: string | null;
    };
    readonly instance: {
      readonly __typename?: 'SRInstance';
      readonly number?: number | null;
      readonly uid?: string | null;
    };
    readonly general: {
      readonly __typename?: 'SRGeneralInfo';
      readonly completion_flag?: CompletionFlag | null;
      readonly completion_flag_description?: string | null;
      readonly date?: Date | null;
      readonly instance_number?: number | null;
      readonly preliminary_flag?: PreliminaryFlag | null;
      readonly time?: string | null;
      readonly verification_status?: VerificationStatus | null;
    };
    readonly observation: {
      readonly __typename?: 'SRObservation';
      readonly uid?: string | null;
      readonly datetime?: string | null;
    };
    readonly sop_class: { readonly __typename?: 'SRSOPClass'; readonly uid?: string | null };
  };
};

export type GetRhinoPromptByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GetRhinoPromptByIdQuery = {
  readonly __typename?: 'Query';
  readonly rhinoPrompt?: {
    readonly __typename?: 'RhinoPrompt';
    readonly id: string;
    readonly prompt: string;
    readonly label: string;
    readonly featureName: string;
    readonly modelIdentifier: string;
    readonly role: string;
  } | null;
};

export type GetRhinoPromptsQueryVariables = Exact<{
  role?: InputMaybe<Scalars['String']['input']>;
  featureName?: InputMaybe<Scalars['String']['input']>;
  modelIdentifier?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetRhinoPromptsQuery = {
  readonly __typename?: 'Query';
  readonly rhinoPrompts: ReadonlyArray<{
    readonly __typename?: 'RhinoPrompt';
    readonly id: string;
    readonly prompt: string;
    readonly label: string;
    readonly featureName: string;
    readonly modelIdentifier: string;
    readonly role: string;
  }>;
};

export type GetRhinoModelsQueryVariables = Exact<{
  modelIdentifier?: InputMaybe<Scalars['String']['input']>;
  vendor?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetRhinoModelsQuery = {
  readonly __typename?: 'Query';
  readonly rhinoModels: ReadonlyArray<{
    readonly __typename?: 'RhinoModel';
    readonly id: string;
    readonly vendor: string;
    readonly label: string;
    readonly config: {
      readonly __typename?: 'RhinoModelConfig';
      readonly modelName: string;
      readonly temperature: number;
    };
  }>;
};

export type SendRhinoQueryMutationVariables = Exact<{
  input: RhinoQueryInput;
}>;

export type SendRhinoQueryMutation = {
  readonly __typename?: 'Mutation';
  readonly rhinoQuery?: {
    readonly __typename?: 'RhinoQueryResponse';
    readonly id: string;
    readonly modelIdentifier: string;
    readonly queryMessages: ReadonlyArray<{
      readonly __typename?: 'RhinoQueryMessage';
      readonly role: string;
      readonly content: string;
    }>;
    readonly response: {
      readonly __typename?: 'SironaCustomLLMResponseModel';
      readonly message: {
        readonly __typename?: 'SironaCustomLLMResponseMessage';
        readonly role: string;
        readonly content: string;
      };
      readonly usage: {
        readonly __typename?: 'SironaCustomLLMResponseUsage';
        readonly promptTokens: number;
        readonly completionTokens: number;
        readonly totalTokens: number;
      };
    };
    readonly rawApiResponse: {
      readonly __typename?: 'RhinoRawAPIResponse';
      readonly id: string;
      readonly object: string;
      readonly created: number;
      readonly model: string;
      readonly choices: ReadonlyArray<{
        readonly __typename?: 'RhinoRawAPIResponseChoice';
        readonly index: number;
        readonly finishReason: string;
        readonly message: {
          readonly __typename?: 'SironaCustomLLMResponseMessage';
          readonly role: string;
          readonly content: string;
        };
      }>;
      readonly usage: {
        readonly __typename?: 'RhinoRawAPIResponseUsage';
        readonly promptTokens: number;
        readonly completionTokens: number;
        readonly totalTokens: number;
      };
    };
  } | null;
};

export type SendRhinoFeedbackMutationVariables = Exact<{
  input: RhinoFeedbackInput;
}>;

export type SendRhinoFeedbackMutation = {
  readonly __typename?: 'Mutation';
  readonly rhinoFeedback?: {
    readonly __typename?: 'RhinoFeedbackResponse';
    readonly success: boolean;
  } | null;
};

export type SendRhinoMedCheckQueryMutationVariables = Exact<{
  input: RhinoMedCheckInput;
}>;

export type SendRhinoMedCheckQueryMutation = {
  readonly __typename?: 'Mutation';
  readonly rhinoMedCheck?: {
    readonly __typename?: 'RhinoMedCheckResponse';
    readonly content?: string | null;
  } | null;
};

export type GetAuthenticatorTokenQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
}>;

export type GetAuthenticatorTokenQuery = {
  readonly __typename?: 'Query';
  readonly authenticatorToken: string;
};

export type LinkAuthenticatorMutationVariables = Exact<{
  totp: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
}>;

export type LinkAuthenticatorMutation = {
  readonly __typename?: 'Mutation';
  readonly linkAuthenticator: {
    readonly __typename?: 'LinkAuthenticatorResponse';
    readonly linked: boolean;
  };
};

export type ToggleMultiFactorAuthMutationVariables = Exact<{
  multiFactorAuthEnabled: Scalars['Boolean']['input'];
  password: Scalars['String']['input'];
  totp?: InputMaybe<Scalars['String']['input']>;
}>;

export type ToggleMultiFactorAuthMutation = {
  readonly __typename?: 'Mutation';
  readonly toggleMultiFactorAuth: {
    readonly __typename?: 'MultiFactorAuthResponse';
    readonly multiFactorAuthEnabled: boolean;
    readonly authenticatorToken?: string | null;
  };
};

export type ToggleMultiFactorLinkMutationVariables = Exact<{
  multiFactorAuthLinked: Scalars['Boolean']['input'];
}>;

export type ToggleMultiFactorLinkMutation = {
  readonly __typename?: 'Mutation';
  readonly toggleMultiFactorLink: {
    readonly __typename?: 'MultiFactorLinkResponse';
    readonly multiFactorAuthLinked: boolean;
  };
};

export type GenerateImpressionMutationVariables = Exact<{
  input: GenerateImpressionInput;
}>;

export type GenerateImpressionMutation = {
  readonly __typename?: 'Mutation';
  readonly generateImpression?: {
    readonly __typename?: 'GenerateImpressionResponse';
    readonly impression: JSON;
  } | null;
};

export type GeneratePriorsSummaryQueryVariables = Exact<{
  caseSmid: Scalars['ID']['input'];
}>;

export type GeneratePriorsSummaryQuery = {
  readonly __typename?: 'Query';
  readonly generatePriorsSummary: {
    readonly __typename?: 'GeneratePriorsSummaryResponse';
    readonly priorsSummary: JSON;
  };
};

export type GetQualityControlCommentsQueryVariables = Exact<{
  smid: Scalars['ID']['input'];
}>;

export type GetQualityControlCommentsQuery = {
  readonly __typename?: 'Query';
  readonly qualityControlComments: ReadonlyArray<{
    readonly __typename?: 'QualityControlComment';
    readonly comment: string;
    readonly authorSmid: string;
    readonly authorName: string;
    readonly createdAt: number;
  } | null>;
};

export type ResolverTypeWrapper<T> = Promise<T> | T;

export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs,
> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<
  TResult,
  TKey extends string,
  TParent = {},
  TContext = {},
  TArgs = {},
> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (
  obj: T,
  context: TContext,
  info: GraphQLResolveInfo
) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping of union types */
export type ResolversUnionTypes<_RefType extends Record<string, unknown>> = {
  AddendumContent: AddendumSironaSlate | AddendumTextBlob;
  AuthenticateResponse:
    | MfaRequired
    | (Omit<User, 'autoCorrect' | 'lastViewedWorklists'> & {
        autoCorrect: ReadonlyArray<_RefType['AutoCorrectEntry']>;
        lastViewedWorklists: ReadonlyArray<_RefType['WorklistItem']>;
      });
  ExtendedCoordinates: IndexCoordinates | WorldCoordinates;
  FrameStack:
    | (Omit<LayeredStack, 'study'> & { study: _RefType['Study'] })
    | (Omit<SingleLayerStack, 'study'> & { study: _RefType['Study'] });
  GspsPresentationStateResponse:
    | (Omit<GspsPresentationState, 'annotations' | 'study'> & {
        annotations: ReadonlyArray<_RefType['GspsAnnotation']>;
        study: _RefType['Study'];
      })
    | GspsPresentationStateError;
  SubmitReportResult:
    | (Omit<SubmitReportPayload, 'report'> & { report: _RefType['Report'] })
    | SubmitReportProblem;
  ToolInteractionResponseUnion:
    | (Omit<ToolInteractionAddInteractionResponse, 'interactions'> & {
        interactions: ReadonlyArray<_RefType['ToolInteraction']>;
      })
    | (Omit<ToolInteractionAnnotationResponse, 'annotation'> & {
        annotation: _RefType['ToolInteractionAnnotationData'];
      })
    | ToolInteractionInsertTextResponse
    | ToolInteractionKeyboardShortcutResponse
    | ToolInteractionMessageResponse
    | ToolInteractionStartAnnotationResponse
    | ToolInteractionUpdateToolActiveStateResponse
    | ToolInteractionUpdateToolbarButtonIconResponse
    | ToolInteractionUpdateViewportPresentationStateResponse
    | ToolInteractionUpdateViewportSliceResponse
    | (Omit<ToolInteractionWidgetResponse, 'widget'> & { widget: _RefType['WidgetConfiguration'] });
  ToolInteractionUnion:
    | ToolAnnotationInteraction
    | ToolContextMenuOptionInteraction
    | ToolIconButtonClickInteraction
    | ToolKeyboardShortcutInteraction
    | ToolMergeFieldInteraction
    | ToolViewportClickInteraction
    | ToolVoiceCommandInteraction;
};

/** Mapping of interface types */
export type ResolversInterfaceTypes<_RefType extends Record<string, unknown>> = {
  Annotation:
    | AiUgcAnnotation
    | CadAnnotation
    | (Omit<GspsAnnotation, 'presentationState' | 'study'> & {
        presentationState: _RefType['GspsPresentationState'];
        study: _RefType['Study'];
      })
    | (Omit<UgcAnnotation, 'owner'> & { owner?: Maybe<_RefType['User']> });
  BaseStack:
    | (Omit<LayeredStack, 'study'> & { study: _RefType['Study'] })
    | (Omit<SingleLayerStack, 'study'> & { study: _RefType['Study'] });
  List:
    | (Omit<StudyItems, 'items'> & { items: ReadonlyArray<_RefType['Study']> })
    | (Omit<WorklistItems, 'items'> & { items: ReadonlyArray<_RefType['WorklistItem']> });
  ReferencedImage: SourceImage;
  ToolInteraction:
    | ToolAnnotationInteraction
    | ToolContextMenuOptionInteraction
    | ToolIconButtonClickInteraction
    | ToolKeyboardShortcutInteraction
    | ToolMergeFieldInteraction
    | ToolViewportClickInteraction
    | ToolVoiceCommandInteraction;
  ToolInteractionResponse:
    | (Omit<ToolInteractionAddInteractionResponse, 'interactions'> & {
        interactions: ReadonlyArray<_RefType['ToolInteraction']>;
      })
    | (Omit<ToolInteractionAnnotationResponse, 'annotation'> & {
        annotation: _RefType['ToolInteractionAnnotationData'];
      })
    | ToolInteractionInsertTextResponse
    | ToolInteractionKeyboardShortcutResponse
    | ToolInteractionMessageResponse
    | ToolInteractionStartAnnotationResponse
    | ToolInteractionUpdateToolActiveStateResponse
    | ToolInteractionUpdateToolbarButtonIconResponse
    | ToolInteractionUpdateViewportPresentationStateResponse
    | ToolInteractionUpdateViewportSliceResponse
    | (Omit<ToolInteractionWidgetResponse, 'widget'> & { widget: _RefType['WidgetConfiguration'] });
};

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  AIModeInput: AiModeInput;
  AIModeSettings: ResolverTypeWrapper<AiModeSettings>;
  AbsoluteScrollingResponse: ResolverTypeWrapper<AbsoluteScrollingResponse>;
  AcceptAiAnnotation: AcceptAiAnnotation;
  AcceptAiAnnotationResponse: ResolverTypeWrapper<AcceptAiAnnotationResponse>;
  Addendum: ResolverTypeWrapper<
    Omit<Addendum, 'content'> & { content: ResolversTypes['AddendumContent'] }
  >;
  AddendumContent: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['AddendumContent']>;
  AddendumPayload: AddendumPayload;
  AddendumSironaSlate: ResolverTypeWrapper<AddendumSironaSlate>;
  AddendumTextBlob: ResolverTypeWrapper<AddendumTextBlob>;
  AiAnnotation: ResolverTypeWrapper<AiAnnotation>;
  AiAnnotationGroupStatus: AiAnnotationGroupStatus;
  AiAnnotationTypes: AiAnnotationTypes;
  AiUgcAnnotation: ResolverTypeWrapper<AiUgcAnnotation>;
  AnatomicNavigatorResponse: ResolverTypeWrapper<AnatomicNavigatorResponse>;
  AnatomicalDirection: AnatomicalDirection;
  Annotation: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['Annotation']>;
  AnnotationEditableOptions: ResolverTypeWrapper<AnnotationEditableOptions>;
  AnnotationInstance: AnnotationInstance;
  AnnotationLog: AnnotationLog;
  AnnotationLogResponse: ResolverTypeWrapper<AnnotationLogResponse>;
  AnnotationSegment: ResolverTypeWrapper<
    Omit<AnnotationSegment, 'end' | 'start'> & {
      end: ResolversTypes['ExtendedCoordinates'];
      start: ResolversTypes['ExtendedCoordinates'];
    }
  >;
  AnnotationSource: AnnotationSource;
  AnnotationStyle: ResolverTypeWrapper<AnnotationStyle>;
  AnnotationTypes: AnnotationTypes;
  AssignDefaultAutoloadTemplateInput: AssignDefaultAutoloadTemplateInput;
  AssignDefaultAutoloadTemplatePayload: ResolverTypeWrapper<
    Omit<AssignDefaultAutoloadTemplatePayload, 'me'> & { me: ResolversTypes['User'] }
  >;
  AssignExamCountInput: AssignExamCountInput;
  AssignExamsToUserResponse: ResolverTypeWrapper<
    Omit<AssignExamsToUserResponse, 'assignedExams'> & {
      assignedExams: ReadonlyArray<ResolversTypes['WorklistItem']>;
    }
  >;
  AuthRole: AuthRole;
  AuthenticateResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['AuthenticateResponse']
  >;
  AutoCorrectEntry: ResolverTypeWrapper<AutoCorrectEntry>;
  AutoFillComparison: ResolverTypeWrapper<AutoFillComparison>;
  AutoFillComparisonInput: AutoFillComparisonInput;
  BarPreferences: ResolverTypeWrapper<BarPreferences>;
  BaseStack: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['BaseStack']>;
  BatchClaimInput: BatchClaimInput;
  BatchClaimResult: ResolverTypeWrapper<
    Omit<BatchClaimResult, 'claimedBy'> & { claimedBy?: Maybe<ResolversTypes['User']> }
  >;
  BatchUnclaimInput: BatchUnclaimInput;
  BatchUnclaimResult: ResolverTypeWrapper<BatchUnclaimResult>;
  BodyPart: ResolverTypeWrapper<BodyPart>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']['output']>;
  CadAnnotation: ResolverTypeWrapper<CadAnnotation>;
  CineModule: ResolverTypeWrapper<CineModule>;
  ClassificationItem: ResolverTypeWrapper<ClassificationItem>;
  ClassificationSummary: ResolverTypeWrapper<ClassificationSummary>;
  Classifications: ResolverTypeWrapper<Classifications>;
  Clinic: ResolverTypeWrapper<Clinic>;
  CompletionFlag: CompletionFlag;
  ConfirmDeleteWorkspacePreset: ResolverTypeWrapper<ConfirmDeleteWorkspacePreset>;
  ConfirmResponse: ResolverTypeWrapper<ConfirmResponse>;
  ContentSequence: ResolverTypeWrapper<Scalars['ContentSequence']['output']>;
  Counter: ResolverTypeWrapper<Counter>;
  CreateAutoCorrectEntryInput: CreateAutoCorrectEntryInput;
  CreateAutoCorrectEntryResponse: ResolverTypeWrapper<
    Omit<CreateAutoCorrectEntryResponse, 'entry'> & {
      entry?: Maybe<ResolversTypes['AutoCorrectEntry']>;
    }
  >;
  CreateVocabEntryInput: CreateVocabEntryInput;
  CreateVocabEntryResponse: ResolverTypeWrapper<CreateVocabEntryResponse>;
  CursorStyle: ResolverTypeWrapper<CursorStyle>;
  CursorStyleInput: CursorStyleInput;
  CustomAnnotationLogic: ResolverTypeWrapper<CustomAnnotationLogic>;
  CustomDicomTagsResponse: ResolverTypeWrapper<CustomDicomTagsResponse>;
  CustomMergeField: ResolverTypeWrapper<CustomMergeField>;
  Date: ResolverTypeWrapper<Scalars['Date']['output']>;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']['output']>;
  DateTimeLocal: ResolverTypeWrapper<Scalars['DateTimeLocal']['output']>;
  DefaultCustomWorklist: ResolverTypeWrapper<DefaultCustomWorklist>;
  DefaultCustomWorklistInput: DefaultCustomWorklistInput;
  DefaultCustomWorklistsSetting: ResolverTypeWrapper<DefaultCustomWorklistsSetting>;
  DefaultCustomWorklistsSettingInput: DefaultCustomWorklistsSettingInput;
  DefaultThumbnailSizeResponse: ResolverTypeWrapper<DefaultThumbnailSizeResponse>;
  DehydratedSeries: ResolverTypeWrapper<DehydratedSeries>;
  DehydratedStack: ResolverTypeWrapper<DehydratedStack>;
  DehydratedStudy: ResolverTypeWrapper<DehydratedStudy>;
  DeleteAnnotationsItem: ResolverTypeWrapper<DeleteAnnotationsItem>;
  DeleteAnnotationsResponse: ResolverTypeWrapper<DeleteAnnotationsResponse>;
  DeleteAnnotationsStatus: DeleteAnnotationsStatus;
  DeleteAutoCorrectEntryInput: DeleteAutoCorrectEntryInput;
  DeleteAutoCorrectEntryResponse: ResolverTypeWrapper<DeleteAutoCorrectEntryResponse>;
  DeleteMacroResponse: ResolverTypeWrapper<DeleteMacroResponse>;
  DeleteTemplateResponse: ResolverTypeWrapper<DeleteTemplateResponse>;
  DeleteVocabEntryInput: DeleteVocabEntryInput;
  DeleteVocabEntryResponse: ResolverTypeWrapper<DeleteVocabEntryResponse>;
  DicomInstance: ResolverTypeWrapper<DicomInstance>;
  DicomTag: ResolverTypeWrapper<DicomTag>;
  DicomTagRenderInfo: ResolverTypeWrapper<DicomTagRenderInfo>;
  DicomTagRenderInfoInput: DicomTagRenderInfoInput;
  DicomTagValue: ResolverTypeWrapper<DicomTagValue>;
  DictaphoneKey: ResolverTypeWrapper<DictaphoneKey>;
  DictaphonePreferences: ResolverTypeWrapper<DictaphonePreferences>;
  DisplayConfiguration: DisplayConfiguration;
  DisplayConfigurationParams2d: DisplayConfigurationParams2d;
  EraseAllToolScope: EraseAllToolScope;
  EraseAllToolScopeResponse: ResolverTypeWrapper<EraseAllToolScopeResponse>;
  ExtendedCoordinates: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['ExtendedCoordinates']
  >;
  FailedAssignExamSmid: ResolverTypeWrapper<FailedAssignExamSmid>;
  FastScrollPreferences: ResolverTypeWrapper<FastScrollPreferences>;
  FineScrollPreferences: ResolverTypeWrapper<FineScrollPreferences>;
  Float: ResolverTypeWrapper<Scalars['Float']['output']>;
  FocusMode: ResolverTypeWrapper<FocusMode>;
  FocusModeInput: FocusModeInput;
  Frame: ResolverTypeWrapper<Omit<Frame, 'stack'> & { stack: ResolversTypes['FrameStack'] }>;
  FrameDirection: ResolverTypeWrapper<FrameDirection>;
  FrameGroupByType: FrameGroupByType;
  FrameScalar: ResolverTypeWrapper<Scalars['FrameScalar']['output']>;
  FrameStack: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['FrameStack']>;
  FrameToLoad: FrameToLoad;
  GamepadActionID: GamepadActionId;
  GeneralImageModule: ResolverTypeWrapper<GeneralImageModule>;
  GeneralReferenceModule: ResolverTypeWrapper<GeneralReferenceModule>;
  GenerateImpressionInput: GenerateImpressionInput;
  GenerateImpressionResponse: ResolverTypeWrapper<GenerateImpressionResponse>;
  GeneratePriorsSummaryResponse: ResolverTypeWrapper<GeneratePriorsSummaryResponse>;
  GspsAnnotation: ResolverTypeWrapper<
    Omit<GspsAnnotation, 'presentationState' | 'study'> & {
      presentationState: ResolversTypes['GspsPresentationState'];
      study: ResolversTypes['Study'];
    }
  >;
  GspsOwner: ResolverTypeWrapper<GspsOwner>;
  GspsPresentationState: ResolverTypeWrapper<
    Omit<GspsPresentationState, 'annotations' | 'study'> & {
      annotations: ReadonlyArray<ResolversTypes['GspsAnnotation']>;
      study: ResolversTypes['Study'];
    }
  >;
  GspsPresentationStateError: ResolverTypeWrapper<GspsPresentationStateError>;
  GspsPresentationStateResponse: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['GspsPresentationStateResponse']
  >;
  HangingProtocol: ResolverTypeWrapper<
    Omit<HangingProtocol, 'owner' | 'viewportsConfigurations'> & {
      owner: ResolversTypes['User'];
      viewportsConfigurations?: Maybe<ReadonlyArray<ResolversTypes['ViewportConfiguration']>>;
    }
  >;
  HangingProtocolData: ResolverTypeWrapper<HangingProtocolData>;
  HangingProtocolDeleted: ResolverTypeWrapper<HangingProtocolDeleted>;
  HangingProtocolLayout: ResolverTypeWrapper<HangingProtocolLayout>;
  HangingProtocolPayload: HangingProtocolPayload;
  HangingProtocolSortBy: HangingProtocolSortBy;
  HangingProtocols: ResolverTypeWrapper<
    Omit<HangingProtocols, 'items'> & { items: ReadonlyArray<ResolversTypes['HangingProtocol']> }
  >;
  HeadingKeywords: ResolverTypeWrapper<HeadingKeywords>;
  HeadingLevel: HeadingLevel;
  HeadingStyle: ResolverTypeWrapper<HeadingStyle>;
  HeadingStyleInput: HeadingStyleInput;
  IAddendum: IAddendum;
  IAnnotationSegment: IAnnotationSegment;
  ID: ResolverTypeWrapper<Scalars['ID']['output']>;
  IDictaphoneKey: IDictaphoneKey;
  IExtendedCoordinates: IExtendedCoordinates;
  IHangingProtocolData: IHangingProtocolData;
  IHangingProtocolLayout: IHangingProtocolLayout;
  IProcedureReference: IProcedureReference;
  IReportPicklist: IReportPicklist;
  IReportPicklistOption: IReportPicklistOption;
  IReportTemplatePayload: IReportTemplatePayload;
  IViewportConfiguration: IViewportConfiguration;
  ImageParams: ResolverTypeWrapper<ImageParams>;
  ImageRegistration: ResolverTypeWrapper<ImageRegistration>;
  ImpressionGenerator: ResolverTypeWrapper<ImpressionGenerator>;
  ImpressionGeneratorInput: ImpressionGeneratorInput;
  IndexCoordinates: ResolverTypeWrapper<IndexCoordinates>;
  Int: ResolverTypeWrapper<Scalars['Int']['output']>;
  JSON: ResolverTypeWrapper<Scalars['JSON']['output']>;
  JSONObject: ResolverTypeWrapper<Scalars['JSONObject']['output']>;
  KeyboardPreferences: ResolverTypeWrapper<KeyboardPreferences>;
  Layer: ResolverTypeWrapper<Layer>;
  LayeredStack: ResolverTypeWrapper<
    Omit<LayeredStack, 'study'> & { study: ResolversTypes['Study'] }
  >;
  LimitedUser: ResolverTypeWrapper<LimitedUser>;
  LineIndicator: ResolverTypeWrapper<LineIndicator>;
  LineIndicatorInput: LineIndicatorInput;
  LineIndicatorPlacement: LineIndicatorPlacement;
  LineIndicatorVariant: LineIndicatorVariant;
  LinkAuthenticatorResponse: ResolverTypeWrapper<LinkAuthenticatorResponse>;
  List: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['List']>;
  MFARequired: ResolverTypeWrapper<MfaRequired>;
  Macro: ResolverTypeWrapper<Macro>;
  MacroPayload: MacroPayload;
  Macros: ResolverTypeWrapper<Macros>;
  ManualLocalizerLinesResponse: ResolverTypeWrapper<ManualLocalizerLinesResponse>;
  MatchType: MatchType;
  Matrix3x3: ResolverTypeWrapper<Scalars['Matrix3x3']['output']>;
  MergeFieldInstance: MergeFieldInstance;
  MergeFieldsSettings: ResolverTypeWrapper<MergeFieldsSettings>;
  MergeFieldsSettingsInput: MergeFieldsSettingsInput;
  MessageDisplay: MessageDisplay;
  MessageSeverity: MessageSeverity;
  MipPreferences: ResolverTypeWrapper<MipPreferences>;
  MirrorViewerLayoutsPreferences: ResolverTypeWrapper<MirrorViewerLayoutsPreferences>;
  Modality: ResolverTypeWrapper<Modality>;
  ModalityDicomTags: ResolverTypeWrapper<ModalityDicomTags>;
  ModalityLutDescriptor: ResolverTypeWrapper<Scalars['ModalityLutDescriptor']['output']>;
  ModalityLutModule: ResolverTypeWrapper<ModalityLutModule>;
  ModalityLutSequence: ResolverTypeWrapper<ModalityLutSequence>;
  Modules: ResolverTypeWrapper<Modules>;
  MouseAction: MouseAction;
  MouseButton: MouseButton;
  MousePreferences: ResolverTypeWrapper<MousePreferences>;
  MultiFactorAuth: ResolverTypeWrapper<MultiFactorAuth>;
  MultiFactorAuthResponse: ResolverTypeWrapper<MultiFactorAuthResponse>;
  MultiFactorLinkResponse: ResolverTypeWrapper<MultiFactorLinkResponse>;
  MultiFrameModule: ResolverTypeWrapper<MultiFrameModule>;
  Mutation: ResolverTypeWrapper<{}>;
  NvoqCredentials: ResolverTypeWrapper<NvoqCredentials>;
  Owner: ResolverTypeWrapper<Owner>;
  PatientOrientation: ResolverTypeWrapper<PatientOrientation>;
  PatientStudyModule: ResolverTypeWrapper<PatientStudyModule>;
  Permission: ResolverTypeWrapper<Permission>;
  PetImageModule: ResolverTypeWrapper<PetImageModule>;
  PixelURL: ResolverTypeWrapper<PixelUrl>;
  PredictTemplateResponse: ResolverTypeWrapper<PredictTemplateResponse>;
  PreferredWorkLocation: ResolverTypeWrapper<PreferredWorkLocation>;
  PreferredWorkLocationPayload: PreferredWorkLocationPayload;
  PreliminaryFlag: PreliminaryFlag;
  PresentationState: ResolverTypeWrapper<PresentationState>;
  Priority: ResolverTypeWrapper<Priority>;
  Procedure: ResolverTypeWrapper<Procedure>;
  ProcedureReference: ResolverTypeWrapper<ProcedureReference>;
  Procedures: ResolverTypeWrapper<Procedures>;
  ProvenanceData: ResolverTypeWrapper<ProvenanceData>;
  QualityControlComment: ResolverTypeWrapper<QualityControlComment>;
  Query: ResolverTypeWrapper<{}>;
  RadioPharmaceuticalInformation: ResolverTypeWrapper<RadioPharmaceuticalInformation>;
  ReferencedImage: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['ReferencedImage']>;
  Region: ResolverTypeWrapper<Region>;
  RegionCalibrationModule: ResolverTypeWrapper<RegionCalibrationModule>;
  RegionFlags: ResolverTypeWrapper<RegionFlags>;
  RegularScrollPreferences: ResolverTypeWrapper<RegularScrollPreferences>;
  RejectAiAnnotationLog: ResolverTypeWrapper<RejectAiAnnotationLog>;
  RendererSupport: RendererSupport;
  Report: ResolverTypeWrapper<
    Omit<Report, 'addendums'> & { addendums: ReadonlyArray<ResolversTypes['Addendum']> }
  >;
  ReportFormat: ReportFormat;
  ReportPayload: ReportPayload;
  ReportPicklist: ResolverTypeWrapper<ReportPicklist>;
  ReportPicklistOption: ResolverTypeWrapper<ReportPicklistOption>;
  ReportStatus: ReportStatus;
  ReportTemplate: ResolverTypeWrapper<ReportTemplate>;
  ReportTemplates: ResolverTypeWrapper<ReportTemplates>;
  ReporterSettings: ResolverTypeWrapper<ReporterSettings>;
  ResetToolOnScrollPreferences: ResolverTypeWrapper<ResetToolOnScrollPreferences>;
  Response: ResolverTypeWrapper<Response>;
  RhinoFeedbackInput: RhinoFeedbackInput;
  RhinoFeedbackResponse: ResolverTypeWrapper<RhinoFeedbackResponse>;
  RhinoMedCheckInput: RhinoMedCheckInput;
  RhinoMedCheckResponse: ResolverTypeWrapper<RhinoMedCheckResponse>;
  RhinoModel: ResolverTypeWrapper<RhinoModel>;
  RhinoModelConfig: ResolverTypeWrapper<RhinoModelConfig>;
  RhinoPrompt: ResolverTypeWrapper<RhinoPrompt>;
  RhinoQueryInput: RhinoQueryInput;
  RhinoQueryMessage: ResolverTypeWrapper<RhinoQueryMessage>;
  RhinoQueryMessageInput: RhinoQueryMessageInput;
  RhinoQueryResponse: ResolverTypeWrapper<RhinoQueryResponse>;
  RhinoRawAPIResponse: ResolverTypeWrapper<RhinoRawApiResponse>;
  RhinoRawAPIResponseChoice: ResolverTypeWrapper<RhinoRawApiResponseChoice>;
  RhinoRawAPIResponseUsage: ResolverTypeWrapper<RhinoRawApiResponseUsage>;
  RhinoUserFeedbackInput: RhinoUserFeedbackInput;
  Role: ResolverTypeWrapper<Role>;
  SRGeneralInfo: ResolverTypeWrapper<SrGeneralInfo>;
  SRInstance: ResolverTypeWrapper<SrInstance>;
  SRObservation: ResolverTypeWrapper<SrObservation>;
  SRPatient: ResolverTypeWrapper<SrPatient>;
  SRSOPClass: ResolverTypeWrapper<SrsopClass>;
  SRSeries: ResolverTypeWrapper<SrSeries>;
  SRStudy: ResolverTypeWrapper<SrStudy>;
  SavedSortingPreference: ResolverTypeWrapper<SavedSortingPreference>;
  SavedSortingPreferenceInput: SavedSortingPreferenceInput;
  SavedWorklistView: ResolverTypeWrapper<SavedWorklistView>;
  SavedWorklistViewInput: SavedWorklistViewInput;
  Scope: ResolverTypeWrapper<Scope>;
  ScreenName: ScreenName;
  ScreenShareSettingResponse: ResolverTypeWrapper<ScreenShareSettingResponse>;
  SecuritySettings: ResolverTypeWrapper<SecuritySettings>;
  Series: ResolverTypeWrapper<Omit<Series, 'study'> & { study: ResolversTypes['Study'] }>;
  SeriesClassification: ResolverTypeWrapper<SeriesClassification>;
  SeriesCriteria: ResolverTypeWrapper<SeriesCriteria>;
  SeriesSkipSettings: ResolverTypeWrapper<SeriesSkipSettings>;
  SeriesSkipSettingsPayload: SeriesSkipSettingsPayload;
  SeriesSkipSettingsResponse: ResolverTypeWrapper<SeriesSkipSettingsResponse>;
  SetWwwcDragDirectionResponse: ResolverTypeWrapper<SetWwwcDragDirectionResponse>;
  Shortcut: ResolverTypeWrapper<Shortcut>;
  ShowIconLabelPreferences: ResolverTypeWrapper<ShowIconLabelPreferences>;
  SignedUrl: ResolverTypeWrapper<SignedUrl>;
  SingleLayerStack: ResolverTypeWrapper<
    Omit<SingleLayerStack, 'study'> & { study: ResolversTypes['Study'] }
  >;
  SironaCustomLLMResponseMessage: ResolverTypeWrapper<SironaCustomLlmResponseMessage>;
  SironaCustomLLMResponseMessageInput: SironaCustomLlmResponseMessageInput;
  SironaCustomLLMResponseModel: ResolverTypeWrapper<SironaCustomLlmResponseModel>;
  SironaCustomLLMResponseModelInput: SironaCustomLlmResponseModelInput;
  SironaCustomLLMResponseUsage: ResolverTypeWrapper<SironaCustomLlmResponseUsage>;
  SironaCustomLLMResponseUsageInput: SironaCustomLlmResponseUsageInput;
  Slice: Slice;
  SlimUser: ResolverTypeWrapper<SlimUser>;
  SortColumn: SortColumn;
  SortOrder: SortOrder;
  SourceImage: ResolverTypeWrapper<SourceImage>;
  SpatialLocationsPreserved: SpatialLocationsPreserved;
  StackedScrollingModalitiesResponse: ResolverTypeWrapper<StackedScrollingModalitiesResponse>;
  StackedScrollingResponse: ResolverTypeWrapper<StackedScrollingResponse>;
  String: ResolverTypeWrapper<Scalars['String']['output']>;
  StringOrBooleanOrDate: ResolverTypeWrapper<Scalars['StringOrBooleanOrDate']['output']>;
  StructuredReport: ResolverTypeWrapper<StructuredReport>;
  Study: ResolverTypeWrapper<
    Omit<Study, 'provenanceData' | 'stackedFrames'> & {
      provenanceData?: Maybe<ResolversTypes['ProvenanceData']>;
      stackedFrames: ReadonlyArray<ResolversTypes['FrameStack']>;
    }
  >;
  StudyClassificationSummary: ResolverTypeWrapper<StudyClassificationSummary>;
  StudyDescription: ResolverTypeWrapper<StudyDescription>;
  StudyDescriptions: ResolverTypeWrapper<StudyDescriptions>;
  StudyItems: ResolverTypeWrapper<
    Omit<StudyItems, 'items'> & { items: ReadonlyArray<ResolversTypes['Study']> }
  >;
  Styles: ResolverTypeWrapper<Styles>;
  StylesInput: StylesInput;
  SubmitReportInvalidInput: ResolverTypeWrapper<SubmitReportInvalidInput>;
  SubmitReportInvalidInputField: SubmitReportInvalidInputField;
  SubmitReportPayload: ResolverTypeWrapper<
    Omit<SubmitReportPayload, 'report'> & { report: ResolversTypes['Report'] }
  >;
  SubmitReportProblem: ResolverTypeWrapper<SubmitReportProblem>;
  SubmitReportResult: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['SubmitReportResult']
  >;
  Subscription: ResolverTypeWrapper<{}>;
  SupportedRenderers: ResolverTypeWrapper<SupportedRenderers>;
  TemplatePrediction: ResolverTypeWrapper<Scalars['TemplatePrediction']['output']>;
  TextDecoration: ResolverTypeWrapper<TextDecoration>;
  TextDecorationInput: TextDecorationInput;
  TextStyle: ResolverTypeWrapper<TextStyle>;
  TextStyleInput: TextStyleInput;
  TextTransform: TextTransform;
  Tool: ResolverTypeWrapper<
    Omit<Tool, 'interactions'> & {
      interactions: ReadonlyArray<ResolversTypes['ToolInteractionUnion']>;
    }
  >;
  ToolAnnotationInteraction: ResolverTypeWrapper<ToolAnnotationInteraction>;
  ToolCategory: ToolCategory;
  ToolContextMenuOptionInteraction: ResolverTypeWrapper<ToolContextMenuOptionInteraction>;
  ToolIconButtonClickInteraction: ResolverTypeWrapper<ToolIconButtonClickInteraction>;
  ToolInteraction: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['ToolInteraction']>;
  ToolInteractionAddInteractionResponse: ResolverTypeWrapper<
    Omit<ToolInteractionAddInteractionResponse, 'interactions'> & {
      interactions: ReadonlyArray<ResolversTypes['ToolInteraction']>;
    }
  >;
  ToolInteractionAnnotationData: ResolverTypeWrapper<
    Omit<ToolInteractionAnnotationData, 'segments'> & {
      segments: ReadonlyArray<ResolversTypes['AnnotationSegment']>;
    }
  >;
  ToolInteractionAnnotationResponse: ResolverTypeWrapper<
    Omit<ToolInteractionAnnotationResponse, 'annotation'> & {
      annotation: ResolversTypes['ToolInteractionAnnotationData'];
    }
  >;
  ToolInteractionInsertTextResponse: ResolverTypeWrapper<ToolInteractionInsertTextResponse>;
  ToolInteractionKeyboardShortcutResponse: ResolverTypeWrapper<ToolInteractionKeyboardShortcutResponse>;
  ToolInteractionMessageResponse: ResolverTypeWrapper<ToolInteractionMessageResponse>;
  ToolInteractionPayload: ToolInteractionPayload;
  ToolInteractionRequest: ResolverTypeWrapper<ToolInteractionRequest>;
  ToolInteractionRequestMethod: ToolInteractionRequestMethod;
  ToolInteractionResponse: ResolverTypeWrapper<
    ResolversInterfaceTypes<ResolversTypes>['ToolInteractionResponse']
  >;
  ToolInteractionResponseType: ToolInteractionResponseType;
  ToolInteractionResponseUnion: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['ToolInteractionResponseUnion']
  >;
  ToolInteractionStartAnnotationResponse: ResolverTypeWrapper<ToolInteractionStartAnnotationResponse>;
  ToolInteractionType: ToolInteractionType;
  ToolInteractionUnion: ResolverTypeWrapper<
    ResolversUnionTypes<ResolversTypes>['ToolInteractionUnion']
  >;
  ToolInteractionUpdateToolActiveStateResponse: ResolverTypeWrapper<ToolInteractionUpdateToolActiveStateResponse>;
  ToolInteractionUpdateToolbarButtonIconResponse: ResolverTypeWrapper<ToolInteractionUpdateToolbarButtonIconResponse>;
  ToolInteractionUpdateViewportPresentationStateResponse: ResolverTypeWrapper<ToolInteractionUpdateViewportPresentationStateResponse>;
  ToolInteractionUpdateViewportSliceResponse: ResolverTypeWrapper<ToolInteractionUpdateViewportSliceResponse>;
  ToolInteractionWidgetResponse: ResolverTypeWrapper<
    Omit<ToolInteractionWidgetResponse, 'widget'> & {
      widget: ResolversTypes['WidgetConfiguration'];
    }
  >;
  ToolKeyboardShortcutInteraction: ResolverTypeWrapper<ToolKeyboardShortcutInteraction>;
  ToolMergeFieldInteraction: ResolverTypeWrapper<ToolMergeFieldInteraction>;
  ToolPayloadCapability: ToolPayloadCapability;
  ToolPreferences: ResolverTypeWrapper<ToolPreferences>;
  ToolViewportClickInteraction: ResolverTypeWrapper<ToolViewportClickInteraction>;
  ToolVoiceCommandInteraction: ResolverTypeWrapper<ToolVoiceCommandInteraction>;
  TransformMatrix: ResolverTypeWrapper<Scalars['TransformMatrix']['output']>;
  UgcAnnotation: ResolverTypeWrapper<
    Omit<UgcAnnotation, 'owner'> & { owner?: Maybe<ResolversTypes['User']> }
  >;
  UpdateAutoCorrectEntryInput: UpdateAutoCorrectEntryInput;
  UpdateAutoCorrectEntryResponse: ResolverTypeWrapper<
    Omit<UpdateAutoCorrectEntryResponse, 'entry'> & {
      entry?: Maybe<ResolversTypes['AutoCorrectEntry']>;
    }
  >;
  User: ResolverTypeWrapper<
    Omit<User, 'autoCorrect' | 'lastViewedWorklists'> & {
      autoCorrect: ReadonlyArray<ResolversTypes['AutoCorrectEntry']>;
      lastViewedWorklists: ReadonlyArray<ResolversTypes['WorklistItem']>;
    }
  >;
  UserAnnotation: UserAnnotation;
  Users: ResolverTypeWrapper<Users>;
  Vector2: ResolverTypeWrapper<Scalars['Vector2']['output']>;
  Vector3: ResolverTypeWrapper<Scalars['Vector3']['output']>;
  Vector4: ResolverTypeWrapper<Scalars['Vector4']['output']>;
  Vector6: ResolverTypeWrapper<Scalars['Vector6']['output']>;
  VerificationStatus: VerificationStatus;
  ViewType: ViewType;
  ViewerRenderer: ViewerRenderer;
  ViewerSettings: ResolverTypeWrapper<ViewerSettings>;
  ViewportConfiguration: ResolverTypeWrapper<
    Omit<ViewportConfiguration, 'configuration'> & {
      configuration: ResolversTypes['ViewportConfigurationData'];
    }
  >;
  ViewportConfigurationData: ResolverTypeWrapper<
    Omit<ViewportConfigurationData, 'series' | 'study'> & {
      series?: Maybe<ResolversTypes['DehydratedSeries']>;
      study?: Maybe<ResolversTypes['DehydratedStudy']>;
    }
  >;
  ViewportState: ViewportState;
  VirtualMonitorSplit: VirtualMonitorSplit;
  VirtualMonitorSplitResponse: ResolverTypeWrapper<VirtualMonitorSplitResponse>;
  VocabEntry: ResolverTypeWrapper<VocabEntry>;
  VoiLutDescriptor: ResolverTypeWrapper<Scalars['VoiLutDescriptor']['output']>;
  VoiLutModule: ResolverTypeWrapper<VoiLutModule>;
  VoiLutSequence: ResolverTypeWrapper<VoiLutSequence>;
  WheelPreferences: ResolverTypeWrapper<WheelPreferences>;
  WidgetConfiguration: ResolverTypeWrapper<
    Omit<WidgetConfiguration, 'position'> & {
      position?: Maybe<ResolversTypes['ExtendedCoordinates']>;
    }
  >;
  WidgetDismissAction: WidgetDismissAction;
  WidgetInstance: WidgetInstance;
  WidgetPlacement: WidgetPlacement;
  WidgetState: WidgetState;
  WidgetType: WidgetType;
  Window: ResolverTypeWrapper<Window>;
  WindowPayload: WindowPayload;
  WindowState: WindowState;
  WindowType: WindowType;
  WorklistAutoLoad: ResolverTypeWrapper<WorklistAutoLoad>;
  WorklistAutoLoadInput: WorklistAutoLoadInput;
  WorklistColumn: ResolverTypeWrapper<WorklistColumn>;
  WorklistColumnID: WorklistColumnId;
  WorklistColumnInput: WorklistColumnInput;
  WorklistColumnsBySurface: ResolverTypeWrapper<WorklistColumnsBySurface>;
  WorklistColumnsBySurfaceInput: WorklistColumnsBySurfaceInput;
  WorklistCustomStatus: ResolverTypeWrapper<WorklistCustomStatus>;
  WorklistFilter: ResolverTypeWrapper<WorklistFilter>;
  WorklistFilterDropdownItem: ResolverTypeWrapper<WorklistFilterDropdownItem>;
  WorklistFilterDropdownItems: ResolverTypeWrapper<WorklistFilterDropdownItems>;
  WorklistFilterInput: WorklistFilterInput;
  WorklistFilterType: WorklistFilterType;
  WorklistFilterValue: ResolverTypeWrapper<WorklistFilterValue>;
  WorklistFilterValueInput: WorklistFilterValueInput;
  WorklistItem: ResolverTypeWrapper<
    Omit<
      WorklistItem,
      'allAutoloadedPriors' | 'autoloadedPriors' | 'claimedBy' | 'report' | 'studies'
    > & {
      allAutoloadedPriors?: Maybe<ReadonlyArray<ResolversTypes['DehydratedStudy']>>;
      autoloadedPriors: ReadonlyArray<ResolversTypes['Study']>;
      claimedBy?: Maybe<ResolversTypes['User']>;
      report?: Maybe<ResolversTypes['Report']>;
      studies: ReadonlyArray<ResolversTypes['Study']>;
    }
  >;
  WorklistItemAuditLogItem: ResolverTypeWrapper<WorklistItemAuditLogItem>;
  WorklistItemAuditLogUser: ResolverTypeWrapper<WorklistItemAuditLogUser>;
  WorklistItemStatus: WorklistItemStatus;
  WorklistItemSummary: ResolverTypeWrapper<WorklistItemSummary>;
  WorklistItems: ResolverTypeWrapper<
    Omit<WorklistItems, 'items'> & { items: ReadonlyArray<ResolversTypes['WorklistItem']> }
  >;
  WorklistSettings: ResolverTypeWrapper<WorklistSettings>;
  WorklistStatusWithActiveLockEnum: WorklistStatusWithActiveLockEnum;
  WorklistStudies: ResolverTypeWrapper<
    Omit<WorklistStudies, 'items'> & { items: ReadonlyArray<ResolversTypes['WorklistStudy']> }
  >;
  WorklistStudy: ResolverTypeWrapper<
    Omit<WorklistStudy, 'study'> & { study: ResolversTypes['Study'] }
  >;
  WorklistView: ResolverTypeWrapper<WorklistView>;
  WorklistViewDetails: ResolverTypeWrapper<WorklistViewDetails>;
  WorklistViewFilter: ResolverTypeWrapper<WorklistViewFilter>;
  WorklistViewFilters: ResolverTypeWrapper<WorklistViewFilters>;
  WorkspacePreferences: ResolverTypeWrapper<WorkspacePreferences>;
  WorkspacePreset: ResolverTypeWrapper<WorkspacePreset>;
  WorkspacePresetPayload: WorkspacePresetPayload;
  WorldCoordinates: ResolverTypeWrapper<WorldCoordinates>;
  WwcPresetDeleteResponse: ResolverTypeWrapper<WwcPresetDeleteResponse>;
  WwwcPreset: ResolverTypeWrapper<WwwcPreset>;
  WwwcPresetPayload: WwwcPresetPayload;
  WwwcToolPreferences: ResolverTypeWrapper<WwwcToolPreferences>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  AIModeInput: AiModeInput;
  AIModeSettings: AiModeSettings;
  AbsoluteScrollingResponse: AbsoluteScrollingResponse;
  AcceptAiAnnotation: AcceptAiAnnotation;
  AcceptAiAnnotationResponse: AcceptAiAnnotationResponse;
  Addendum: Omit<Addendum, 'content'> & { content: ResolversParentTypes['AddendumContent'] };
  AddendumContent: ResolversUnionTypes<ResolversParentTypes>['AddendumContent'];
  AddendumPayload: AddendumPayload;
  AddendumSironaSlate: AddendumSironaSlate;
  AddendumTextBlob: AddendumTextBlob;
  AiAnnotation: AiAnnotation;
  AiUgcAnnotation: AiUgcAnnotation;
  AnatomicNavigatorResponse: AnatomicNavigatorResponse;
  Annotation: ResolversInterfaceTypes<ResolversParentTypes>['Annotation'];
  AnnotationEditableOptions: AnnotationEditableOptions;
  AnnotationInstance: AnnotationInstance;
  AnnotationLog: AnnotationLog;
  AnnotationLogResponse: AnnotationLogResponse;
  AnnotationSegment: Omit<AnnotationSegment, 'end' | 'start'> & {
    end: ResolversParentTypes['ExtendedCoordinates'];
    start: ResolversParentTypes['ExtendedCoordinates'];
  };
  AnnotationStyle: AnnotationStyle;
  AssignDefaultAutoloadTemplateInput: AssignDefaultAutoloadTemplateInput;
  AssignDefaultAutoloadTemplatePayload: Omit<AssignDefaultAutoloadTemplatePayload, 'me'> & {
    me: ResolversParentTypes['User'];
  };
  AssignExamCountInput: AssignExamCountInput;
  AssignExamsToUserResponse: Omit<AssignExamsToUserResponse, 'assignedExams'> & {
    assignedExams: ReadonlyArray<ResolversParentTypes['WorklistItem']>;
  };
  AuthenticateResponse: ResolversUnionTypes<ResolversParentTypes>['AuthenticateResponse'];
  AutoCorrectEntry: AutoCorrectEntry;
  AutoFillComparison: AutoFillComparison;
  AutoFillComparisonInput: AutoFillComparisonInput;
  BarPreferences: BarPreferences;
  BaseStack: ResolversInterfaceTypes<ResolversParentTypes>['BaseStack'];
  BatchClaimInput: BatchClaimInput;
  BatchClaimResult: Omit<BatchClaimResult, 'claimedBy'> & {
    claimedBy?: Maybe<ResolversParentTypes['User']>;
  };
  BatchUnclaimInput: BatchUnclaimInput;
  BatchUnclaimResult: BatchUnclaimResult;
  BodyPart: BodyPart;
  Boolean: Scalars['Boolean']['output'];
  CadAnnotation: CadAnnotation;
  CineModule: CineModule;
  ClassificationItem: ClassificationItem;
  ClassificationSummary: ClassificationSummary;
  Classifications: Classifications;
  Clinic: Clinic;
  ConfirmDeleteWorkspacePreset: ConfirmDeleteWorkspacePreset;
  ConfirmResponse: ConfirmResponse;
  ContentSequence: Scalars['ContentSequence']['output'];
  Counter: Counter;
  CreateAutoCorrectEntryInput: CreateAutoCorrectEntryInput;
  CreateAutoCorrectEntryResponse: Omit<CreateAutoCorrectEntryResponse, 'entry'> & {
    entry?: Maybe<ResolversParentTypes['AutoCorrectEntry']>;
  };
  CreateVocabEntryInput: CreateVocabEntryInput;
  CreateVocabEntryResponse: CreateVocabEntryResponse;
  CursorStyle: CursorStyle;
  CursorStyleInput: CursorStyleInput;
  CustomAnnotationLogic: CustomAnnotationLogic;
  CustomDicomTagsResponse: CustomDicomTagsResponse;
  CustomMergeField: CustomMergeField;
  Date: Scalars['Date']['output'];
  DateTime: Scalars['DateTime']['output'];
  DateTimeLocal: Scalars['DateTimeLocal']['output'];
  DefaultCustomWorklist: DefaultCustomWorklist;
  DefaultCustomWorklistInput: DefaultCustomWorklistInput;
  DefaultCustomWorklistsSetting: DefaultCustomWorklistsSetting;
  DefaultCustomWorklistsSettingInput: DefaultCustomWorklistsSettingInput;
  DefaultThumbnailSizeResponse: DefaultThumbnailSizeResponse;
  DehydratedSeries: DehydratedSeries;
  DehydratedStack: DehydratedStack;
  DehydratedStudy: DehydratedStudy;
  DeleteAnnotationsItem: DeleteAnnotationsItem;
  DeleteAnnotationsResponse: DeleteAnnotationsResponse;
  DeleteAutoCorrectEntryInput: DeleteAutoCorrectEntryInput;
  DeleteAutoCorrectEntryResponse: DeleteAutoCorrectEntryResponse;
  DeleteMacroResponse: DeleteMacroResponse;
  DeleteTemplateResponse: DeleteTemplateResponse;
  DeleteVocabEntryInput: DeleteVocabEntryInput;
  DeleteVocabEntryResponse: DeleteVocabEntryResponse;
  DicomInstance: DicomInstance;
  DicomTag: DicomTag;
  DicomTagRenderInfo: DicomTagRenderInfo;
  DicomTagRenderInfoInput: DicomTagRenderInfoInput;
  DicomTagValue: DicomTagValue;
  DictaphoneKey: DictaphoneKey;
  DictaphonePreferences: DictaphonePreferences;
  DisplayConfiguration: DisplayConfiguration;
  DisplayConfigurationParams2d: DisplayConfigurationParams2d;
  EraseAllToolScopeResponse: EraseAllToolScopeResponse;
  ExtendedCoordinates: ResolversUnionTypes<ResolversParentTypes>['ExtendedCoordinates'];
  FailedAssignExamSmid: FailedAssignExamSmid;
  FastScrollPreferences: FastScrollPreferences;
  FineScrollPreferences: FineScrollPreferences;
  Float: Scalars['Float']['output'];
  FocusMode: FocusMode;
  FocusModeInput: FocusModeInput;
  Frame: Omit<Frame, 'stack'> & { stack: ResolversParentTypes['FrameStack'] };
  FrameDirection: FrameDirection;
  FrameScalar: Scalars['FrameScalar']['output'];
  FrameStack: ResolversUnionTypes<ResolversParentTypes>['FrameStack'];
  FrameToLoad: FrameToLoad;
  GeneralImageModule: GeneralImageModule;
  GeneralReferenceModule: GeneralReferenceModule;
  GenerateImpressionInput: GenerateImpressionInput;
  GenerateImpressionResponse: GenerateImpressionResponse;
  GeneratePriorsSummaryResponse: GeneratePriorsSummaryResponse;
  GspsAnnotation: Omit<GspsAnnotation, 'presentationState' | 'study'> & {
    presentationState: ResolversParentTypes['GspsPresentationState'];
    study: ResolversParentTypes['Study'];
  };
  GspsOwner: GspsOwner;
  GspsPresentationState: Omit<GspsPresentationState, 'annotations' | 'study'> & {
    annotations: ReadonlyArray<ResolversParentTypes['GspsAnnotation']>;
    study: ResolversParentTypes['Study'];
  };
  GspsPresentationStateError: GspsPresentationStateError;
  GspsPresentationStateResponse: ResolversUnionTypes<ResolversParentTypes>['GspsPresentationStateResponse'];
  HangingProtocol: Omit<HangingProtocol, 'owner' | 'viewportsConfigurations'> & {
    owner: ResolversParentTypes['User'];
    viewportsConfigurations?: Maybe<ReadonlyArray<ResolversParentTypes['ViewportConfiguration']>>;
  };
  HangingProtocolData: HangingProtocolData;
  HangingProtocolDeleted: HangingProtocolDeleted;
  HangingProtocolLayout: HangingProtocolLayout;
  HangingProtocolPayload: HangingProtocolPayload;
  HangingProtocols: Omit<HangingProtocols, 'items'> & {
    items: ReadonlyArray<ResolversParentTypes['HangingProtocol']>;
  };
  HeadingKeywords: HeadingKeywords;
  HeadingStyle: HeadingStyle;
  HeadingStyleInput: HeadingStyleInput;
  IAddendum: IAddendum;
  IAnnotationSegment: IAnnotationSegment;
  ID: Scalars['ID']['output'];
  IDictaphoneKey: IDictaphoneKey;
  IExtendedCoordinates: IExtendedCoordinates;
  IHangingProtocolData: IHangingProtocolData;
  IHangingProtocolLayout: IHangingProtocolLayout;
  IProcedureReference: IProcedureReference;
  IReportPicklist: IReportPicklist;
  IReportPicklistOption: IReportPicklistOption;
  IReportTemplatePayload: IReportTemplatePayload;
  IViewportConfiguration: IViewportConfiguration;
  ImageParams: ImageParams;
  ImageRegistration: ImageRegistration;
  ImpressionGenerator: ImpressionGenerator;
  ImpressionGeneratorInput: ImpressionGeneratorInput;
  IndexCoordinates: IndexCoordinates;
  Int: Scalars['Int']['output'];
  JSON: Scalars['JSON']['output'];
  JSONObject: Scalars['JSONObject']['output'];
  KeyboardPreferences: KeyboardPreferences;
  Layer: Layer;
  LayeredStack: Omit<LayeredStack, 'study'> & { study: ResolversParentTypes['Study'] };
  LimitedUser: LimitedUser;
  LineIndicator: LineIndicator;
  LineIndicatorInput: LineIndicatorInput;
  LinkAuthenticatorResponse: LinkAuthenticatorResponse;
  List: ResolversInterfaceTypes<ResolversParentTypes>['List'];
  MFARequired: MfaRequired;
  Macro: Macro;
  MacroPayload: MacroPayload;
  Macros: Macros;
  ManualLocalizerLinesResponse: ManualLocalizerLinesResponse;
  Matrix3x3: Scalars['Matrix3x3']['output'];
  MergeFieldInstance: MergeFieldInstance;
  MergeFieldsSettings: MergeFieldsSettings;
  MergeFieldsSettingsInput: MergeFieldsSettingsInput;
  MipPreferences: MipPreferences;
  MirrorViewerLayoutsPreferences: MirrorViewerLayoutsPreferences;
  Modality: Modality;
  ModalityDicomTags: ModalityDicomTags;
  ModalityLutDescriptor: Scalars['ModalityLutDescriptor']['output'];
  ModalityLutModule: ModalityLutModule;
  ModalityLutSequence: ModalityLutSequence;
  Modules: Modules;
  MousePreferences: MousePreferences;
  MultiFactorAuth: MultiFactorAuth;
  MultiFactorAuthResponse: MultiFactorAuthResponse;
  MultiFactorLinkResponse: MultiFactorLinkResponse;
  MultiFrameModule: MultiFrameModule;
  Mutation: {};
  NvoqCredentials: NvoqCredentials;
  Owner: Owner;
  PatientOrientation: PatientOrientation;
  PatientStudyModule: PatientStudyModule;
  Permission: Permission;
  PetImageModule: PetImageModule;
  PixelURL: PixelUrl;
  PredictTemplateResponse: PredictTemplateResponse;
  PreferredWorkLocation: PreferredWorkLocation;
  PreferredWorkLocationPayload: PreferredWorkLocationPayload;
  PresentationState: PresentationState;
  Priority: Priority;
  Procedure: Procedure;
  ProcedureReference: ProcedureReference;
  Procedures: Procedures;
  ProvenanceData: ProvenanceData;
  QualityControlComment: QualityControlComment;
  Query: {};
  RadioPharmaceuticalInformation: RadioPharmaceuticalInformation;
  ReferencedImage: ResolversInterfaceTypes<ResolversParentTypes>['ReferencedImage'];
  Region: Region;
  RegionCalibrationModule: RegionCalibrationModule;
  RegionFlags: RegionFlags;
  RegularScrollPreferences: RegularScrollPreferences;
  RejectAiAnnotationLog: RejectAiAnnotationLog;
  Report: Omit<Report, 'addendums'> & {
    addendums: ReadonlyArray<ResolversParentTypes['Addendum']>;
  };
  ReportPayload: ReportPayload;
  ReportPicklist: ReportPicklist;
  ReportPicklistOption: ReportPicklistOption;
  ReportTemplate: ReportTemplate;
  ReportTemplates: ReportTemplates;
  ReporterSettings: ReporterSettings;
  ResetToolOnScrollPreferences: ResetToolOnScrollPreferences;
  Response: Response;
  RhinoFeedbackInput: RhinoFeedbackInput;
  RhinoFeedbackResponse: RhinoFeedbackResponse;
  RhinoMedCheckInput: RhinoMedCheckInput;
  RhinoMedCheckResponse: RhinoMedCheckResponse;
  RhinoModel: RhinoModel;
  RhinoModelConfig: RhinoModelConfig;
  RhinoPrompt: RhinoPrompt;
  RhinoQueryInput: RhinoQueryInput;
  RhinoQueryMessage: RhinoQueryMessage;
  RhinoQueryMessageInput: RhinoQueryMessageInput;
  RhinoQueryResponse: RhinoQueryResponse;
  RhinoRawAPIResponse: RhinoRawApiResponse;
  RhinoRawAPIResponseChoice: RhinoRawApiResponseChoice;
  RhinoRawAPIResponseUsage: RhinoRawApiResponseUsage;
  RhinoUserFeedbackInput: RhinoUserFeedbackInput;
  Role: Role;
  SRGeneralInfo: SrGeneralInfo;
  SRInstance: SrInstance;
  SRObservation: SrObservation;
  SRPatient: SrPatient;
  SRSOPClass: SrsopClass;
  SRSeries: SrSeries;
  SRStudy: SrStudy;
  SavedSortingPreference: SavedSortingPreference;
  SavedSortingPreferenceInput: SavedSortingPreferenceInput;
  SavedWorklistView: SavedWorklistView;
  SavedWorklistViewInput: SavedWorklistViewInput;
  Scope: Scope;
  ScreenShareSettingResponse: ScreenShareSettingResponse;
  SecuritySettings: SecuritySettings;
  Series: Omit<Series, 'study'> & { study: ResolversParentTypes['Study'] };
  SeriesClassification: SeriesClassification;
  SeriesCriteria: SeriesCriteria;
  SeriesSkipSettings: SeriesSkipSettings;
  SeriesSkipSettingsPayload: SeriesSkipSettingsPayload;
  SeriesSkipSettingsResponse: SeriesSkipSettingsResponse;
  SetWwwcDragDirectionResponse: SetWwwcDragDirectionResponse;
  Shortcut: Shortcut;
  ShowIconLabelPreferences: ShowIconLabelPreferences;
  SignedUrl: SignedUrl;
  SingleLayerStack: Omit<SingleLayerStack, 'study'> & { study: ResolversParentTypes['Study'] };
  SironaCustomLLMResponseMessage: SironaCustomLlmResponseMessage;
  SironaCustomLLMResponseMessageInput: SironaCustomLlmResponseMessageInput;
  SironaCustomLLMResponseModel: SironaCustomLlmResponseModel;
  SironaCustomLLMResponseModelInput: SironaCustomLlmResponseModelInput;
  SironaCustomLLMResponseUsage: SironaCustomLlmResponseUsage;
  SironaCustomLLMResponseUsageInput: SironaCustomLlmResponseUsageInput;
  Slice: Slice;
  SlimUser: SlimUser;
  SourceImage: SourceImage;
  StackedScrollingModalitiesResponse: StackedScrollingModalitiesResponse;
  StackedScrollingResponse: StackedScrollingResponse;
  String: Scalars['String']['output'];
  StringOrBooleanOrDate: Scalars['StringOrBooleanOrDate']['output'];
  StructuredReport: StructuredReport;
  Study: Omit<Study, 'provenanceData' | 'stackedFrames'> & {
    provenanceData?: Maybe<ResolversParentTypes['ProvenanceData']>;
    stackedFrames: ReadonlyArray<ResolversParentTypes['FrameStack']>;
  };
  StudyClassificationSummary: StudyClassificationSummary;
  StudyDescription: StudyDescription;
  StudyDescriptions: StudyDescriptions;
  StudyItems: Omit<StudyItems, 'items'> & { items: ReadonlyArray<ResolversParentTypes['Study']> };
  Styles: Styles;
  StylesInput: StylesInput;
  SubmitReportInvalidInput: SubmitReportInvalidInput;
  SubmitReportPayload: Omit<SubmitReportPayload, 'report'> & {
    report: ResolversParentTypes['Report'];
  };
  SubmitReportProblem: SubmitReportProblem;
  SubmitReportResult: ResolversUnionTypes<ResolversParentTypes>['SubmitReportResult'];
  Subscription: {};
  SupportedRenderers: SupportedRenderers;
  TemplatePrediction: Scalars['TemplatePrediction']['output'];
  TextDecoration: TextDecoration;
  TextDecorationInput: TextDecorationInput;
  TextStyle: TextStyle;
  TextStyleInput: TextStyleInput;
  Tool: Omit<Tool, 'interactions'> & {
    interactions: ReadonlyArray<ResolversParentTypes['ToolInteractionUnion']>;
  };
  ToolAnnotationInteraction: ToolAnnotationInteraction;
  ToolContextMenuOptionInteraction: ToolContextMenuOptionInteraction;
  ToolIconButtonClickInteraction: ToolIconButtonClickInteraction;
  ToolInteraction: ResolversInterfaceTypes<ResolversParentTypes>['ToolInteraction'];
  ToolInteractionAddInteractionResponse: Omit<
    ToolInteractionAddInteractionResponse,
    'interactions'
  > & { interactions: ReadonlyArray<ResolversParentTypes['ToolInteraction']> };
  ToolInteractionAnnotationData: Omit<ToolInteractionAnnotationData, 'segments'> & {
    segments: ReadonlyArray<ResolversParentTypes['AnnotationSegment']>;
  };
  ToolInteractionAnnotationResponse: Omit<ToolInteractionAnnotationResponse, 'annotation'> & {
    annotation: ResolversParentTypes['ToolInteractionAnnotationData'];
  };
  ToolInteractionInsertTextResponse: ToolInteractionInsertTextResponse;
  ToolInteractionKeyboardShortcutResponse: ToolInteractionKeyboardShortcutResponse;
  ToolInteractionMessageResponse: ToolInteractionMessageResponse;
  ToolInteractionPayload: ToolInteractionPayload;
  ToolInteractionRequest: ToolInteractionRequest;
  ToolInteractionResponse: ResolversInterfaceTypes<ResolversParentTypes>['ToolInteractionResponse'];
  ToolInteractionResponseUnion: ResolversUnionTypes<ResolversParentTypes>['ToolInteractionResponseUnion'];
  ToolInteractionStartAnnotationResponse: ToolInteractionStartAnnotationResponse;
  ToolInteractionUnion: ResolversUnionTypes<ResolversParentTypes>['ToolInteractionUnion'];
  ToolInteractionUpdateToolActiveStateResponse: ToolInteractionUpdateToolActiveStateResponse;
  ToolInteractionUpdateToolbarButtonIconResponse: ToolInteractionUpdateToolbarButtonIconResponse;
  ToolInteractionUpdateViewportPresentationStateResponse: ToolInteractionUpdateViewportPresentationStateResponse;
  ToolInteractionUpdateViewportSliceResponse: ToolInteractionUpdateViewportSliceResponse;
  ToolInteractionWidgetResponse: Omit<ToolInteractionWidgetResponse, 'widget'> & {
    widget: ResolversParentTypes['WidgetConfiguration'];
  };
  ToolKeyboardShortcutInteraction: ToolKeyboardShortcutInteraction;
  ToolMergeFieldInteraction: ToolMergeFieldInteraction;
  ToolPreferences: ToolPreferences;
  ToolViewportClickInteraction: ToolViewportClickInteraction;
  ToolVoiceCommandInteraction: ToolVoiceCommandInteraction;
  TransformMatrix: Scalars['TransformMatrix']['output'];
  UgcAnnotation: Omit<UgcAnnotation, 'owner'> & { owner?: Maybe<ResolversParentTypes['User']> };
  UpdateAutoCorrectEntryInput: UpdateAutoCorrectEntryInput;
  UpdateAutoCorrectEntryResponse: Omit<UpdateAutoCorrectEntryResponse, 'entry'> & {
    entry?: Maybe<ResolversParentTypes['AutoCorrectEntry']>;
  };
  User: Omit<User, 'autoCorrect' | 'lastViewedWorklists'> & {
    autoCorrect: ReadonlyArray<ResolversParentTypes['AutoCorrectEntry']>;
    lastViewedWorklists: ReadonlyArray<ResolversParentTypes['WorklistItem']>;
  };
  UserAnnotation: UserAnnotation;
  Users: Users;
  Vector2: Scalars['Vector2']['output'];
  Vector3: Scalars['Vector3']['output'];
  Vector4: Scalars['Vector4']['output'];
  Vector6: Scalars['Vector6']['output'];
  ViewerSettings: ViewerSettings;
  ViewportConfiguration: Omit<ViewportConfiguration, 'configuration'> & {
    configuration: ResolversParentTypes['ViewportConfigurationData'];
  };
  ViewportConfigurationData: Omit<ViewportConfigurationData, 'series' | 'study'> & {
    series?: Maybe<ResolversParentTypes['DehydratedSeries']>;
    study?: Maybe<ResolversParentTypes['DehydratedStudy']>;
  };
  ViewportState: ViewportState;
  VirtualMonitorSplitResponse: VirtualMonitorSplitResponse;
  VocabEntry: VocabEntry;
  VoiLutDescriptor: Scalars['VoiLutDescriptor']['output'];
  VoiLutModule: VoiLutModule;
  VoiLutSequence: VoiLutSequence;
  WheelPreferences: WheelPreferences;
  WidgetConfiguration: Omit<WidgetConfiguration, 'position'> & {
    position?: Maybe<ResolversParentTypes['ExtendedCoordinates']>;
  };
  WidgetInstance: WidgetInstance;
  WidgetState: WidgetState;
  Window: Window;
  WindowPayload: WindowPayload;
  WorklistAutoLoad: WorklistAutoLoad;
  WorklistAutoLoadInput: WorklistAutoLoadInput;
  WorklistColumn: WorklistColumn;
  WorklistColumnInput: WorklistColumnInput;
  WorklistColumnsBySurface: WorklistColumnsBySurface;
  WorklistColumnsBySurfaceInput: WorklistColumnsBySurfaceInput;
  WorklistCustomStatus: WorklistCustomStatus;
  WorklistFilter: WorklistFilter;
  WorklistFilterDropdownItem: WorklistFilterDropdownItem;
  WorklistFilterDropdownItems: WorklistFilterDropdownItems;
  WorklistFilterInput: WorklistFilterInput;
  WorklistFilterValue: WorklistFilterValue;
  WorklistFilterValueInput: WorklistFilterValueInput;
  WorklistItem: Omit<
    WorklistItem,
    'allAutoloadedPriors' | 'autoloadedPriors' | 'claimedBy' | 'report' | 'studies'
  > & {
    allAutoloadedPriors?: Maybe<ReadonlyArray<ResolversParentTypes['DehydratedStudy']>>;
    autoloadedPriors: ReadonlyArray<ResolversParentTypes['Study']>;
    claimedBy?: Maybe<ResolversParentTypes['User']>;
    report?: Maybe<ResolversParentTypes['Report']>;
    studies: ReadonlyArray<ResolversParentTypes['Study']>;
  };
  WorklistItemAuditLogItem: WorklistItemAuditLogItem;
  WorklistItemAuditLogUser: WorklistItemAuditLogUser;
  WorklistItemSummary: WorklistItemSummary;
  WorklistItems: Omit<WorklistItems, 'items'> & {
    items: ReadonlyArray<ResolversParentTypes['WorklistItem']>;
  };
  WorklistSettings: WorklistSettings;
  WorklistStudies: Omit<WorklistStudies, 'items'> & {
    items: ReadonlyArray<ResolversParentTypes['WorklistStudy']>;
  };
  WorklistStudy: Omit<WorklistStudy, 'study'> & { study: ResolversParentTypes['Study'] };
  WorklistView: WorklistView;
  WorklistViewDetails: WorklistViewDetails;
  WorklistViewFilter: WorklistViewFilter;
  WorklistViewFilters: WorklistViewFilters;
  WorkspacePreferences: WorkspacePreferences;
  WorkspacePreset: WorkspacePreset;
  WorkspacePresetPayload: WorkspacePresetPayload;
  WorldCoordinates: WorldCoordinates;
  WwcPresetDeleteResponse: WwcPresetDeleteResponse;
  WwwcPreset: WwwcPreset;
  WwwcPresetPayload: WwwcPresetPayload;
  WwwcToolPreferences: WwwcToolPreferences;
};

export type AuthDirectiveArgs = {
  requires?: Maybe<ReadonlyArray<AuthRole>>;
};

export type AuthDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = AuthDirectiveArgs,
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type AiModeSettingsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AIModeSettings'] = ResolversParentTypes['AIModeSettings'],
> = {
  findingsMapperEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  formatImpressionGenerationAsList?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  onDemandImpressionGeneration?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AbsoluteScrollingResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AbsoluteScrollingResponse'] = ResolversParentTypes['AbsoluteScrollingResponse'],
> = {
  absoluteScroll?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AcceptAiAnnotationResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AcceptAiAnnotationResponse'] = ResolversParentTypes['AcceptAiAnnotationResponse'],
> = {
  annotations?: Resolver<
    ReadonlyArray<ResolversTypes['AnnotationLogResponse']>,
    ParentType,
    ContextType
  >;
  logIds?: Resolver<ReadonlyArray<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddendumResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Addendum'] = ResolversParentTypes['Addendum'],
> = {
  content?: Resolver<ResolversTypes['AddendumContent'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  owner?: Resolver<Maybe<ResolversTypes['Owner']>, ParentType, ContextType>;
  sentAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  smid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  submittedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  workstationID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddendumContentResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AddendumContent'] = ResolversParentTypes['AddendumContent'],
> = {
  __resolveType: TypeResolveFn<'AddendumSironaSlate' | 'AddendumTextBlob', ParentType, ContextType>;
};

export type AddendumSironaSlateResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AddendumSironaSlate'] = ResolversParentTypes['AddendumSironaSlate'],
> = {
  sironaSlate?: Resolver<ReadonlyArray<ResolversTypes['JSON']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddendumTextBlobResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AddendumTextBlob'] = ResolversParentTypes['AddendumTextBlob'],
> = {
  textBlob?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AiAnnotationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AiAnnotation'] = ResolversParentTypes['AiAnnotation'],
> = {
  annotations?: Resolver<ReadonlyArray<ResolversTypes['JSON']>, ParentType, ContextType>;
  data?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  frameOfReferenceUID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  modelName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  seriesSmid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['AiAnnotationGroupStatus'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['AiAnnotationTypes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AiUgcAnnotationResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AiUgcAnnotation'] = ResolversParentTypes['AiUgcAnnotation'],
> = {
  aiAnnotationSmid?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  customLogic?: Resolver<Maybe<ResolversTypes['CustomAnnotationLogic']>, ParentType, ContextType>;
  data?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  pixel_space_representation?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  seriesSmid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  smid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  source?: Resolver<ResolversTypes['AnnotationSource'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['AnnotationTypes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AnatomicNavigatorResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AnatomicNavigatorResponse'] = ResolversParentTypes['AnatomicNavigatorResponse'],
> = {
  anatomicNavigatorEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AnnotationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Annotation'] = ResolversParentTypes['Annotation'],
> = {
  __resolveType: TypeResolveFn<
    'AiUgcAnnotation' | 'CadAnnotation' | 'GspsAnnotation' | 'UgcAnnotation',
    ParentType,
    ContextType
  >;
  aiAnnotationSmid?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  customLogic?: Resolver<Maybe<ResolversTypes['CustomAnnotationLogic']>, ParentType, ContextType>;
  data?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  pixel_space_representation?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  seriesSmid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  smid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  source?: Resolver<ResolversTypes['AnnotationSource'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['AnnotationTypes'], ParentType, ContextType>;
};

export type AnnotationEditableOptionsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AnnotationEditableOptions'] = ResolversParentTypes['AnnotationEditableOptions'],
> = {
  delete?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  move?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  transform?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AnnotationLogResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AnnotationLogResponse'] = ResolversParentTypes['AnnotationLogResponse'],
> = {
  annotationSmid?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AnnotationSegmentResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AnnotationSegment'] = ResolversParentTypes['AnnotationSegment'],
> = {
  end?: Resolver<ResolversTypes['ExtendedCoordinates'], ParentType, ContextType>;
  start?: Resolver<ResolversTypes['ExtendedCoordinates'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AnnotationStyleResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AnnotationStyle'] = ResolversParentTypes['AnnotationStyle'],
> = {
  color?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  opacity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AssignDefaultAutoloadTemplatePayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AssignDefaultAutoloadTemplatePayload'] = ResolversParentTypes['AssignDefaultAutoloadTemplatePayload'],
> = {
  me?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AssignExamsToUserResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AssignExamsToUserResponse'] = ResolversParentTypes['AssignExamsToUserResponse'],
> = {
  assignedExams?: Resolver<ReadonlyArray<ResolversTypes['WorklistItem']>, ParentType, ContextType>;
  failedExams?: Resolver<
    ReadonlyArray<ResolversTypes['FailedAssignExamSmid']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthenticateResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AuthenticateResponse'] = ResolversParentTypes['AuthenticateResponse'],
> = {
  __resolveType: TypeResolveFn<'MFARequired' | 'User', ParentType, ContextType>;
};

export type AutoCorrectEntryResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AutoCorrectEntry'] = ResolversParentTypes['AutoCorrectEntry'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AutoFillComparisonResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AutoFillComparison'] = ResolversParentTypes['AutoFillComparison'],
> = {
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BarPreferencesResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['BarPreferences'] = ResolversParentTypes['BarPreferences'],
> = {
  toolIds?: Resolver<ReadonlyArray<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BaseStackResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BaseStack'] = ResolversParentTypes['BaseStack'],
> = {
  __resolveType: TypeResolveFn<'LayeredStack' | 'SingleLayerStack', ParentType, ContextType>;
  smid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  study?: Resolver<ResolversTypes['Study'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
};

export type BatchClaimResultResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['BatchClaimResult'] = ResolversParentTypes['BatchClaimResult'],
> = {
  accessionNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  claimedBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  errorReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  smid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BatchUnclaimResultResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['BatchUnclaimResult'] = ResolversParentTypes['BatchUnclaimResult'],
> = {
  accessionNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  errorReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  smid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BodyPartResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BodyPart'] = ResolversParentTypes['BodyPart'],
> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CadAnnotationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CadAnnotation'] = ResolversParentTypes['CadAnnotation'],
> = {
  aiAnnotationSmid?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  customLogic?: Resolver<Maybe<ResolversTypes['CustomAnnotationLogic']>, ParentType, ContextType>;
  data?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  pixel_space_representation?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  seriesSmid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  smid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  source?: Resolver<ResolversTypes['AnnotationSource'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['AnnotationTypes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CineModuleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CineModule'] = ResolversParentTypes['CineModule'],
> = {
  frameTimeVector?: Resolver<ReadonlyArray<ResolversTypes['Int']>, ParentType, ContextType>;
  preferredPlaybackSequencing?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassificationItemResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ClassificationItem'] = ResolversParentTypes['ClassificationItem'],
> = {
  category?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  classifications?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>;
  codeVersion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Date'], ParentType, ContextType>;
  modelName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modelVersion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  smid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassificationSummaryResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ClassificationSummary'] = ResolversParentTypes['ClassificationSummary'],
> = {
  display_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ontology_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClassificationsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['Classifications'] = ResolversParentTypes['Classifications'],
> = {
  cursor?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  items?: Resolver<ReadonlyArray<ResolversTypes['ClassificationItem']>, ParentType, ContextType>;
  next?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  previous?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClinicResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Clinic'] = ResolversParentTypes['Clinic'],
> = {
  isRbacEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  smid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  worklistLockDuration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConfirmDeleteWorkspacePresetResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ConfirmDeleteWorkspacePreset'] = ResolversParentTypes['ConfirmDeleteWorkspacePreset'],
> = {
  smid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConfirmResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ConfirmResponse'] = ResolversParentTypes['ConfirmResponse'],
> = {
  confirmed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface ContentSequenceScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['ContentSequence'], any> {
  name: 'ContentSequence';
}

export type CounterResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Counter'] = ResolversParentTypes['Counter'],
> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  countStr?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateAutoCorrectEntryResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreateAutoCorrectEntryResponse'] = ResolversParentTypes['CreateAutoCorrectEntryResponse'],
> = {
  entry?: Resolver<Maybe<ResolversTypes['AutoCorrectEntry']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateVocabEntryResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CreateVocabEntryResponse'] = ResolversParentTypes['CreateVocabEntryResponse'],
> = {
  entry?: Resolver<Maybe<ResolversTypes['VocabEntry']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CursorStyleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CursorStyle'] = ResolversParentTypes['CursorStyle'],
> = {
  color?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomAnnotationLogicResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CustomAnnotationLogic'] = ResolversParentTypes['CustomAnnotationLogic'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  logic?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  toolId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomDicomTagsResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CustomDicomTagsResponse'] = ResolversParentTypes['CustomDicomTagsResponse'],
> = {
  modality?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  modalityDicomTags?: Resolver<
    ReadonlyArray<ResolversTypes['DicomTagRenderInfo']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomMergeFieldResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CustomMergeField'] = ResolversParentTypes['CustomMergeField'],
> = {
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Date'], any> {
  name: 'Date';
}

export interface DateTimeScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export interface DateTimeLocalScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['DateTimeLocal'], any> {
  name: 'DateTimeLocal';
}

export type DefaultCustomWorklistResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DefaultCustomWorklist'] = ResolversParentTypes['DefaultCustomWorklist'],
> = {
  surface?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  worklistViewID?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DefaultCustomWorklistsSettingResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DefaultCustomWorklistsSetting'] = ResolversParentTypes['DefaultCustomWorklistsSetting'],
> = {
  defaultCustomWorklists?: Resolver<
    ReadonlyArray<Maybe<ResolversTypes['DefaultCustomWorklist']>>,
    ParentType,
    ContextType
  >;
  isEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DefaultThumbnailSizeResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DefaultThumbnailSizeResponse'] = ResolversParentTypes['DefaultThumbnailSizeResponse'],
> = {
  thumbnailSizePx?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DehydratedSeriesResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DehydratedSeries'] = ResolversParentTypes['DehydratedSeries'],
> = {
  smid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DehydratedStackResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DehydratedStack'] = ResolversParentTypes['DehydratedStack'],
> = {
  smid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DehydratedStudyResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DehydratedStudy'] = ResolversParentTypes['DehydratedStudy'],
> = {
  smid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteAnnotationsItemResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DeleteAnnotationsItem'] = ResolversParentTypes['DeleteAnnotationsItem'],
> = {
  smid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['DeleteAnnotationsStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteAnnotationsResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DeleteAnnotationsResponse'] = ResolversParentTypes['DeleteAnnotationsResponse'],
> = {
  results?: Resolver<
    ReadonlyArray<ResolversTypes['DeleteAnnotationsItem']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteAutoCorrectEntryResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DeleteAutoCorrectEntryResponse'] = ResolversParentTypes['DeleteAutoCorrectEntryResponse'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteMacroResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DeleteMacroResponse'] = ResolversParentTypes['DeleteMacroResponse'],
> = {
  smid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteTemplateResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DeleteTemplateResponse'] = ResolversParentTypes['DeleteTemplateResponse'],
> = {
  smid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteVocabEntryResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DeleteVocabEntryResponse'] = ResolversParentTypes['DeleteVocabEntryResponse'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DicomInstanceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DicomInstance'] = ResolversParentTypes['DicomInstance'],
> = {
  dicomTags?: Resolver<
    ReadonlyArray<ResolversTypes['DicomTag']>,
    ParentType,
    ContextType,
    Partial<DicomInstanceDicomTagsArgs>
  >;
  smid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DicomTagResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DicomTag'] = ResolversParentTypes['DicomTag'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  values?: Resolver<ReadonlyArray<ResolversTypes['DicomTagValue']>, ParentType, ContextType>;
  vr?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DicomTagRenderInfoResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DicomTagRenderInfo'] = ResolversParentTypes['DicomTagRenderInfo'],
> = {
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tagId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DicomTagValueResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DicomTagValue'] = ResolversParentTypes['DicomTagValue'],
> = {
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DictaphoneKeyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DictaphoneKey'] = ResolversParentTypes['DictaphoneKey'],
> = {
  id?: Resolver<ResolversTypes['GamepadActionID'], ParentType, ContextType>;
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DictaphonePreferencesResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DictaphonePreferences'] = ResolversParentTypes['DictaphonePreferences'],
> = {
  deviceLabel?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  keyMapping?: Resolver<ReadonlyArray<ResolversTypes['DictaphoneKey']>, ParentType, ContextType>;
  recordWhilePressed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  showPicklistOptionsInEditor?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EraseAllToolScopeResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['EraseAllToolScopeResponse'] = ResolversParentTypes['EraseAllToolScopeResponse'],
> = {
  eraseAllToolScope?: Resolver<Maybe<ResolversTypes['EraseAllToolScope']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExtendedCoordinatesResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExtendedCoordinates'] = ResolversParentTypes['ExtendedCoordinates'],
> = {
  __resolveType: TypeResolveFn<'IndexCoordinates' | 'WorldCoordinates', ParentType, ContextType>;
};

export type FailedAssignExamSmidResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['FailedAssignExamSmid'] = ResolversParentTypes['FailedAssignExamSmid'],
> = {
  errorReason?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  smid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FastScrollPreferencesResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['FastScrollPreferences'] = ResolversParentTypes['FastScrollPreferences'],
> = {
  sensitivity?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FineScrollPreferencesResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['FineScrollPreferences'] = ResolversParentTypes['FineScrollPreferences'],
> = {
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FocusModeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FocusMode'] = ResolversParentTypes['FocusMode'],
> = {
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FrameResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Frame'] = ResolversParentTypes['Frame'],
> = {
  _groupType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  colorChannels?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  dicomInstance?: Resolver<
    ResolversTypes['DicomInstance'],
    ParentType,
    ContextType,
    Partial<FrameDicomInstanceArgs>
  >;
  direction?: Resolver<ResolversTypes['FrameDirection'], ParentType, ContextType>;
  hasPixels?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  imageNumber?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  modules?: Resolver<ResolversTypes['Modules'], ParentType, ContextType>;
  needsInversion?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  origin?: Resolver<ResolversTypes['Vector3'], ParentType, ContextType>;
  series?: Resolver<ResolversTypes['Series'], ParentType, ContextType>;
  size?: Resolver<ReadonlyArray<ResolversTypes['Float']>, ParentType, ContextType>;
  smid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  sopClassUID?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sopInstanceUID?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  spacing?: Resolver<ResolversTypes['Vector3'], ParentType, ContextType>;
  stack?: Resolver<ResolversTypes['FrameStack'], ParentType, ContextType>;
  supportedRenderers?: Resolver<ResolversTypes['SupportedRenderers'], ParentType, ContextType>;
  validSpacing?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FrameDirectionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['FrameDirection'] = ResolversParentTypes['FrameDirection'],
> = {
  columns?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  data?: Resolver<ResolversTypes['Matrix3x3'], ParentType, ContextType>;
  rows?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  validDirection?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface FrameScalarScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['FrameScalar'], any> {
  name: 'FrameScalar';
}

export type FrameStackResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FrameStack'] = ResolversParentTypes['FrameStack'],
> = {
  __resolveType: TypeResolveFn<'LayeredStack' | 'SingleLayerStack', ParentType, ContextType>;
};

export type GeneralImageModuleResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GeneralImageModule'] = ResolversParentTypes['GeneralImageModule'],
> = {
  imageType?: Resolver<Maybe<ReadonlyArray<ResolversTypes['String']>>, ParentType, ContextType>;
  instanceNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  patientOrientation?: Resolver<
    Maybe<ResolversTypes['PatientOrientation']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeneralReferenceModuleResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GeneralReferenceModule'] = ResolversParentTypes['GeneralReferenceModule'],
> = {
  sourceImageSequence?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['SourceImage']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GenerateImpressionResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GenerateImpressionResponse'] = ResolversParentTypes['GenerateImpressionResponse'],
> = {
  impression?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeneratePriorsSummaryResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GeneratePriorsSummaryResponse'] = ResolversParentTypes['GeneratePriorsSummaryResponse'],
> = {
  priorsSummary?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GspsAnnotationResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GspsAnnotation'] = ResolversParentTypes['GspsAnnotation'],
> = {
  aiAnnotationSmid?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['DateTimeLocal']>, ParentType, ContextType>;
  customLogic?: Resolver<Maybe<ResolversTypes['CustomAnnotationLogic']>, ParentType, ContextType>;
  data?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  owner?: Resolver<ResolversTypes['GspsOwner'], ParentType, ContextType>;
  pixel_space_representation?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  presentationState?: Resolver<ResolversTypes['GspsPresentationState'], ParentType, ContextType>;
  seriesInstanceUID?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  seriesSmid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  smid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  source?: Resolver<ResolversTypes['AnnotationSource'], ParentType, ContextType>;
  study?: Resolver<ResolversTypes['Study'], ParentType, ContextType>;
  studyInstanceUID?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['AnnotationTypes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GspsOwnerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GspsOwner'] = ResolversParentTypes['GspsOwner'],
> = {
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GspsPresentationStateResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GspsPresentationState'] = ResolversParentTypes['GspsPresentationState'],
> = {
  annotations?: Resolver<
    ReadonlyArray<ResolversTypes['GspsAnnotation']>,
    ParentType,
    ContextType,
    Partial<GspsPresentationStateAnnotationsArgs>
  >;
  created?: Resolver<Maybe<ResolversTypes['DateTimeLocal']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  owner?: Resolver<Maybe<ResolversTypes['GspsOwner']>, ParentType, ContextType>;
  smid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  study?: Resolver<ResolversTypes['Study'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GspsPresentationStateErrorResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GspsPresentationStateError'] = ResolversParentTypes['GspsPresentationStateError'],
> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GspsPresentationStateResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GspsPresentationStateResponse'] = ResolversParentTypes['GspsPresentationStateResponse'],
> = {
  __resolveType: TypeResolveFn<
    'GspsPresentationState' | 'GspsPresentationStateError',
    ParentType,
    ContextType
  >;
};

export type HangingProtocolResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['HangingProtocol'] = ResolversParentTypes['HangingProtocol'],
> = {
  clinic?: Resolver<ResolversTypes['Clinic'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  data?: Resolver<ResolversTypes['HangingProtocolData'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numberOfPriors?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  owner?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  smid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  studyCriteria?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  studyDescriptions?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  viewportsConfigurations?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['ViewportConfiguration']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HangingProtocolDataResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['HangingProtocolData'] = ResolversParentTypes['HangingProtocolData'],
> = {
  layouts?: Resolver<
    ReadonlyArray<ResolversTypes['HangingProtocolLayout']>,
    ParentType,
    ContextType
  >;
  viewportsConfigurations?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HangingProtocolDeletedResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['HangingProtocolDeleted'] = ResolversParentTypes['HangingProtocolDeleted'],
> = {
  smid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HangingProtocolLayoutResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['HangingProtocolLayout'] = ResolversParentTypes['HangingProtocolLayout'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  layout?: Resolver<ReadonlyArray<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HangingProtocolsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['HangingProtocols'] = ResolversParentTypes['HangingProtocols'],
> = {
  items?: Resolver<ReadonlyArray<ResolversTypes['HangingProtocol']>, ParentType, ContextType>;
  next?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  previous?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HeadingKeywordsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['HeadingKeywords'] = ResolversParentTypes['HeadingKeywords'],
> = {
  comparison?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>;
  exam?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>;
  findings?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>;
  history?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>;
  impressions?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>;
  technique?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HeadingStyleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HeadingStyle'] = ResolversParentTypes['HeadingStyle'],
> = {
  level?: Resolver<ResolversTypes['HeadingLevel'], ParentType, ContextType>;
  textStyle?: Resolver<ResolversTypes['TextStyle'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImageParamsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ImageParams'] = ResolversParentTypes['ImageParams'],
> = {
  colorLevel?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  colorWindow?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  dimensions?: Resolver<ResolversTypes['Vector3'], ParentType, ContextType>;
  direction?: Resolver<ResolversTypes['Matrix3x3'], ParentType, ContextType>;
  extent?: Resolver<ResolversTypes['Vector6'], ParentType, ContextType>;
  isMultiAxes?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  origin?: Resolver<ResolversTypes['Vector3'], ParentType, ContextType>;
  spacing?: Resolver<ResolversTypes['Vector3'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImageRegistrationResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ImageRegistration'] = ResolversParentTypes['ImageRegistration'],
> = {
  clinic?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  codeVersion?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  dataType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fixedFrameOfReferenceUid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  modelName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  modelVersion?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  movingFrameOfReferenceUid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  seriesFixed?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  seriesMoving?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  smid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  transformMatrix?: Resolver<ResolversTypes['TransformMatrix'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImpressionGeneratorResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ImpressionGenerator'] = ResolversParentTypes['ImpressionGenerator'],
> = {
  automatic?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  modalities?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IndexCoordinatesResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['IndexCoordinates'] = ResolversParentTypes['IndexCoordinates'],
> = {
  indexSpace?: Resolver<ResolversTypes['Vector3'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface JsonScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['JSON'], any> {
  name: 'JSON';
}

export interface JsonObjectScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['JSONObject'], any> {
  name: 'JSONObject';
}

export type KeyboardPreferencesResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['KeyboardPreferences'] = ResolversParentTypes['KeyboardPreferences'],
> = {
  shortcuts?: Resolver<ReadonlyArray<ResolversTypes['Shortcut']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LayerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Layer'] = ResolversParentTypes['Layer'],
> = {
  index?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  stackSmid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LayeredStackResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LayeredStack'] = ResolversParentTypes['LayeredStack'],
> = {
  smid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  stackLayers?: Resolver<ReadonlyArray<ResolversTypes['Layer']>, ParentType, ContextType>;
  study?: Resolver<ResolversTypes['Study'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LimitedUserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LimitedUser'] = ResolversParentTypes['LimitedUser'],
> = {
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LineIndicatorResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LineIndicator'] = ResolversParentTypes['LineIndicator'],
> = {
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  placement?: Resolver<ResolversTypes['LineIndicatorPlacement'], ParentType, ContextType>;
  variant?: Resolver<ResolversTypes['LineIndicatorVariant'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LinkAuthenticatorResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['LinkAuthenticatorResponse'] = ResolversParentTypes['LinkAuthenticatorResponse'],
> = {
  linked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ListResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['List'] = ResolversParentTypes['List'],
> = {
  __resolveType: TypeResolveFn<'StudyItems' | 'WorklistItems', ParentType, ContextType>;
  cursor?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
};

export type MfaRequiredResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MFARequired'] = ResolversParentTypes['MFARequired'],
> = {
  mfaSessionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MacroResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Macro'] = ResolversParentTypes['Macro'],
> = {
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nestedMacros?: Resolver<ReadonlyArray<ResolversTypes['Macro']>, ParentType, ContextType>;
  owner?: Resolver<Maybe<ResolversTypes['SlimUser']>, ParentType, ContextType>;
  picklists?: Resolver<ReadonlyArray<ResolversTypes['ReportPicklist']>, ParentType, ContextType>;
  procedures?: Resolver<Maybe<ReadonlyArray<ResolversTypes['Procedure']>>, ParentType, ContextType>;
  smid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  text?: Resolver<ReadonlyArray<ResolversTypes['JSON']>, ParentType, ContextType>;
  triggerPhrase?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MacrosResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Macros'] = ResolversParentTypes['Macros'],
> = {
  cursor?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  items?: Resolver<ReadonlyArray<ResolversTypes['Macro']>, ParentType, ContextType>;
  next?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  previous?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManualLocalizerLinesResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ManualLocalizerLinesResponse'] = ResolversParentTypes['ManualLocalizerLinesResponse'],
> = {
  showManualLocalizerLines?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface Matrix3x3ScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Matrix3x3'], any> {
  name: 'Matrix3x3';
}

export type MergeFieldsSettingsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['MergeFieldsSettings'] = ResolversParentTypes['MergeFieldsSettings'],
> = {
  ignoreDefaultSelection?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  ignoreNavigation?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MipPreferencesResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['MipPreferences'] = ResolversParentTypes['MipPreferences'],
> = {
  defaultSlabThickness?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  slabThicknessInterval?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MirrorViewerLayoutsPreferencesResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['MirrorViewerLayoutsPreferences'] = ResolversParentTypes['MirrorViewerLayoutsPreferences'],
> = {
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ModalityResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Modality'] = ResolversParentTypes['Modality'],
> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ModalityDicomTagsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ModalityDicomTags'] = ResolversParentTypes['ModalityDicomTags'],
> = {
  modality?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  modalityDicomTags?: Resolver<
    ReadonlyArray<ResolversTypes['DicomTagRenderInfo']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface ModalityLutDescriptorScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['ModalityLutDescriptor'], any> {
  name: 'ModalityLutDescriptor';
}

export type ModalityLutModuleResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ModalityLutModule'] = ResolversParentTypes['ModalityLutModule'],
> = {
  modalityLutSequence?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['ModalityLutSequence']>>,
    ParentType,
    ContextType
  >;
  rescaleIntercept?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rescaleSlope?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rescaleType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ModalityLutSequenceResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ModalityLutSequence'] = ResolversParentTypes['ModalityLutSequence'],
> = {
  lutData?: Resolver<ReadonlyArray<ResolversTypes['Int']>, ParentType, ContextType>;
  lutDescriptor?: Resolver<ResolversTypes['ModalityLutDescriptor'], ParentType, ContextType>;
  lutExplanation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modalityLutType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ModulesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Modules'] = ResolversParentTypes['Modules'],
> = {
  cine?: Resolver<Maybe<ResolversTypes['CineModule']>, ParentType, ContextType>;
  generalImage?: Resolver<Maybe<ResolversTypes['GeneralImageModule']>, ParentType, ContextType>;
  generalReference?: Resolver<
    Maybe<ResolversTypes['GeneralReferenceModule']>,
    ParentType,
    ContextType
  >;
  modalityLut?: Resolver<Maybe<ResolversTypes['ModalityLutModule']>, ParentType, ContextType>;
  multiFrame?: Resolver<Maybe<ResolversTypes['MultiFrameModule']>, ParentType, ContextType>;
  patientStudy?: Resolver<Maybe<ResolversTypes['PatientStudyModule']>, ParentType, ContextType>;
  petImage?: Resolver<Maybe<ResolversTypes['PetImageModule']>, ParentType, ContextType>;
  regionCalibration?: Resolver<
    Maybe<ResolversTypes['RegionCalibrationModule']>,
    ParentType,
    ContextType
  >;
  voiLut?: Resolver<Maybe<ResolversTypes['VoiLutModule']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MousePreferencesResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['MousePreferences'] = ResolversParentTypes['MousePreferences'],
> = {
  left?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  middle?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  right?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MultiFactorAuthResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['MultiFactorAuth'] = ResolversParentTypes['MultiFactorAuth'],
> = {
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  linked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MultiFactorAuthResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['MultiFactorAuthResponse'] = ResolversParentTypes['MultiFactorAuthResponse'],
> = {
  authenticatorToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  multiFactorAuthEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MultiFactorLinkResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['MultiFactorLinkResponse'] = ResolversParentTypes['MultiFactorLinkResponse'],
> = {
  multiFactorAuthLinked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MultiFrameModuleResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['MultiFrameModule'] = ResolversParentTypes['MultiFrameModule'],
> = {
  frameIncrementPointer?: Resolver<
    ReadonlyArray<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  numberOfFrames?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation'],
> = {
  acceptAiAnnotations?: Resolver<
    ResolversTypes['AcceptAiAnnotationResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationAcceptAiAnnotationsArgs, 'payload'>
  >;
  assignDefaultAutoloadTemplate?: Resolver<
    ResolversTypes['AssignDefaultAutoloadTemplatePayload'],
    ParentType,
    ContextType,
    RequireFields<MutationAssignDefaultAutoloadTemplateArgs, 'input'>
  >;
  assignExamCount?: Resolver<
    Maybe<ResolversTypes['WorklistItem']>,
    ParentType,
    ContextType,
    RequireFields<MutationAssignExamCountArgs, 'input'>
  >;
  assignExamsToUser?: Resolver<
    ResolversTypes['AssignExamsToUserResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationAssignExamsToUserArgs, 'userSmid' | 'worklistItemSmids'>
  >;
  authenticate?: Resolver<
    Maybe<ResolversTypes['AuthenticateResponse']>,
    ParentType,
    ContextType,
    RequireFields<MutationAuthenticateArgs, 'email' | 'password'>
  >;
  authenticateMFA?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    RequireFields<MutationAuthenticateMfaArgs, 'email' | 'mfaSessionId' | 'totp'>
  >;
  batchClaimWorklistItems?: Resolver<
    ReadonlyArray<ResolversTypes['BatchClaimResult']>,
    ParentType,
    ContextType,
    RequireFields<MutationBatchClaimWorklistItemsArgs, 'worklistItems'>
  >;
  batchUnclaimWorklistItems?: Resolver<
    ReadonlyArray<ResolversTypes['BatchUnclaimResult']>,
    ParentType,
    ContextType,
    RequireFields<MutationBatchUnclaimWorklistItemsArgs, 'worklistItems'>
  >;
  cancelAddendum?: Resolver<
    ResolversTypes['Addendum'],
    ParentType,
    ContextType,
    RequireFields<MutationCancelAddendumArgs, 'smid'>
  >;
  cancelReport?: Resolver<
    ResolversTypes['Report'],
    ParentType,
    ContextType,
    RequireFields<MutationCancelReportArgs, 'smid'>
  >;
  changeWorklistItemCustomStatus?: Resolver<
    Maybe<ResolversTypes['WorklistItem']>,
    ParentType,
    ContextType,
    RequireFields<MutationChangeWorklistItemCustomStatusArgs, 'customStatusID' | 'smid'>
  >;
  changeWorklistItemPriority?: Resolver<
    Maybe<ResolversTypes['WorklistItem']>,
    ParentType,
    ContextType,
    RequireFields<MutationChangeWorklistItemPriorityArgs, 'name' | 'smid'>
  >;
  changeWorklistItemQualityControlState?: Resolver<
    Maybe<ResolversTypes['WorklistItem']>,
    ParentType,
    ContextType,
    RequireFields<MutationChangeWorklistItemQualityControlStateArgs, 'qc' | 'smid'>
  >;
  changeWorklistItemStatus?: Resolver<
    Maybe<ResolversTypes['WorklistItem']>,
    ParentType,
    ContextType,
    RequireFields<MutationChangeWorklistItemStatusArgs, 'smid'>
  >;
  clearKeyboardPreferences?: Resolver<
    ResolversTypes['KeyboardPreferences'],
    ParentType,
    ContextType,
    RequireFields<MutationClearKeyboardPreferencesArgs, 'resetToDefault'>
  >;
  confirmAccount?: Resolver<
    ResolversTypes['ConfirmResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationConfirmAccountArgs, 'password' | 'token' | 'userId'>
  >;
  createAddendum?: Resolver<
    ResolversTypes['Addendum'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateAddendumArgs, 'reportSmid'>
  >;
  createAnnotation?: Resolver<
    Maybe<ResolversTypes['UgcAnnotation']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateAnnotationArgs, 'data' | 'seriesSmid'>
  >;
  createAutoCorrectEntry?: Resolver<
    ResolversTypes['CreateAutoCorrectEntryResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateAutoCorrectEntryArgs, 'input'>
  >;
  createHangingProtocol?: Resolver<
    Maybe<ResolversTypes['HangingProtocol']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateHangingProtocolArgs, 'payload'>
  >;
  createMacro?: Resolver<
    ResolversTypes['Macro'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateMacroArgs, 'payload'>
  >;
  createPresentationState?: Resolver<
    Maybe<ResolversTypes['PresentationState']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreatePresentationStateArgs, 'annotationSmids' | 'name'>
  >;
  createTemplate?: Resolver<
    ResolversTypes['ReportTemplate'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateTemplateArgs, 'payload'>
  >;
  createVocabEntry?: Resolver<
    ResolversTypes['CreateVocabEntryResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateVocabEntryArgs, 'input'>
  >;
  createWorklistItemActivity?: Resolver<
    Maybe<ResolversTypes['ID']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateWorklistItemActivityArgs, 'smid'>
  >;
  createWorklistItemGroup?: Resolver<
    ResolversTypes['ID'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateWorklistItemGroupArgs, 'worklistItemSmids'>
  >;
  createWorkspacePreset?: Resolver<
    ResolversTypes['WorkspacePreset'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateWorkspacePresetArgs, 'payload'>
  >;
  createWwwcToolPreset?: Resolver<
    Maybe<ResolversTypes['WwwcPreset']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateWwwcToolPresetArgs, 'payload'>
  >;
  deleteAddendum?: Resolver<
    ResolversTypes['ConfirmResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteAddendumArgs, 'smid'>
  >;
  deleteAllAnnotationsInStudy?: Resolver<
    Maybe<ResolversTypes['Response']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteAllAnnotationsInStudyArgs, 'studyInstanceUID'>
  >;
  deleteAnnotation?: Resolver<
    Maybe<ResolversTypes['Response']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteAnnotationArgs, 'smid'>
  >;
  deleteAnnotations?: Resolver<
    ResolversTypes['DeleteAnnotationsResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteAnnotationsArgs, 'smids'>
  >;
  deleteAutoCorrectEntry?: Resolver<
    ResolversTypes['DeleteAutoCorrectEntryResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteAutoCorrectEntryArgs, 'input'>
  >;
  deleteHangingProtocol?: Resolver<
    Maybe<ResolversTypes['HangingProtocolDeleted']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteHangingProtocolArgs, 'id'>
  >;
  deleteMacro?: Resolver<
    ResolversTypes['DeleteMacroResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteMacroArgs, 'id'>
  >;
  deleteTemplate?: Resolver<
    ResolversTypes['DeleteTemplateResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteTemplateArgs, 'id'>
  >;
  deleteUserLinkedAiAnnotations?: Resolver<
    Maybe<ResolversTypes['Response']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteUserLinkedAiAnnotationsArgs, 'payload'>
  >;
  deleteVocabEntry?: Resolver<
    ResolversTypes['DeleteVocabEntryResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteVocabEntryArgs, 'input'>
  >;
  deleteWorklistItemGroup?: Resolver<
    Maybe<ResolversTypes['Response']>,
    ParentType,
    ContextType,
    RequireFields<MutationDeleteWorklistItemGroupArgs, 'groupId'>
  >;
  deleteWorkspacePreset?: Resolver<
    ResolversTypes['ConfirmDeleteWorkspacePreset'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteWorkspacePresetArgs, 'smid'>
  >;
  deleteWwwcToolPreset?: Resolver<
    ResolversTypes['WwcPresetDeleteResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteWwwcToolPresetArgs, 'id'>
  >;
  empty?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  generateImpression?: Resolver<
    Maybe<ResolversTypes['GenerateImpressionResponse']>,
    ParentType,
    ContextType,
    RequireFields<MutationGenerateImpressionArgs, 'input'>
  >;
  linkAuthenticator?: Resolver<
    ResolversTypes['LinkAuthenticatorResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationLinkAuthenticatorArgs, 'password' | 'totp'>
  >;
  rejectAiAnnotations?: Resolver<
    ResolversTypes['RejectAiAnnotationLog'],
    ParentType,
    ContextType,
    RequireFields<MutationRejectAiAnnotationsArgs, 'ids'>
  >;
  requestPasswordReset?: Resolver<
    ResolversTypes['ConfirmResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationRequestPasswordResetArgs, 'email'>
  >;
  resetAndReleaseWorklistItem?: Resolver<
    Maybe<ResolversTypes['WorklistItem']>,
    ParentType,
    ContextType,
    RequireFields<MutationResetAndReleaseWorklistItemArgs, 'smid'>
  >;
  resetPassword?: Resolver<
    ResolversTypes['ConfirmResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationResetPasswordArgs, 'password' | 'token' | 'userId'>
  >;
  rhinoFeedback?: Resolver<
    Maybe<ResolversTypes['RhinoFeedbackResponse']>,
    ParentType,
    ContextType,
    RequireFields<MutationRhinoFeedbackArgs, 'input'>
  >;
  rhinoMedCheck?: Resolver<
    Maybe<ResolversTypes['RhinoMedCheckResponse']>,
    ParentType,
    ContextType,
    RequireFields<MutationRhinoMedCheckArgs, 'input'>
  >;
  rhinoQuery?: Resolver<
    Maybe<ResolversTypes['RhinoQueryResponse']>,
    ParentType,
    ContextType,
    RequireFields<MutationRhinoQueryArgs, 'input'>
  >;
  saveHangingProtocol?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    RequireFields<MutationSaveHangingProtocolArgs, 'hangingProtocolId' | 'payload' | 'userId'>
  >;
  setDefaultThumbnailSize?: Resolver<
    Maybe<ResolversTypes['DefaultThumbnailSizeResponse']>,
    ParentType,
    ContextType,
    RequireFields<MutationSetDefaultThumbnailSizeArgs, 'thumbnailSizePx'>
  >;
  setWorkspaceAutoApply?: Resolver<
    ResolversTypes['WorkspacePreferences'],
    ParentType,
    ContextType,
    RequireFields<MutationSetWorkspaceAutoApplyArgs, 'value'>
  >;
  setWwwcDragDirection?: Resolver<
    ResolversTypes['SetWwwcDragDirectionResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationSetWwwcDragDirectionArgs, 'value'>
  >;
  submitAddendum?: Resolver<
    ResolversTypes['Addendum'],
    ParentType,
    ContextType,
    RequireFields<MutationSubmitAddendumArgs, 'smid'>
  >;
  submitReport?: Resolver<
    ResolversTypes['SubmitReportResult'],
    ParentType,
    ContextType,
    RequireFields<MutationSubmitReportArgs, 'smid'>
  >;
  toggleAbsoluteScroll?: Resolver<
    ResolversTypes['AbsoluteScrollingResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationToggleAbsoluteScrollArgs, 'absoluteScroll'>
  >;
  toggleAnatomicNavigator?: Resolver<
    ResolversTypes['AnatomicNavigatorResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationToggleAnatomicNavigatorArgs, 'anatomicNavigatorEnabled'>
  >;
  toggleEraseAllToolScope?: Resolver<
    Maybe<ResolversTypes['EraseAllToolScopeResponse']>,
    ParentType,
    ContextType,
    Partial<MutationToggleEraseAllToolScopeArgs>
  >;
  toggleManualLocalizerLines?: Resolver<
    ResolversTypes['ManualLocalizerLinesResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationToggleManualLocalizerLinesArgs, 'showManualLocalizerLines'>
  >;
  toggleMultiFactorAuth?: Resolver<
    ResolversTypes['MultiFactorAuthResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationToggleMultiFactorAuthArgs, 'multiFactorAuthEnabled' | 'password'>
  >;
  toggleMultiFactorLink?: Resolver<
    ResolversTypes['MultiFactorLinkResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationToggleMultiFactorLinkArgs, 'multiFactorAuthLinked'>
  >;
  toggleScreenShare?: Resolver<
    ResolversTypes['ScreenShareSettingResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationToggleScreenShareArgs, 'screenShareEnabled'>
  >;
  toggleStackedScrolling?: Resolver<
    ResolversTypes['StackedScrollingResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationToggleStackedScrollingArgs, 'stackInstancesByType'>
  >;
  toggleVirtualMonitorSplit?: Resolver<
    Maybe<ResolversTypes['VirtualMonitorSplitResponse']>,
    ParentType,
    ContextType,
    Partial<MutationToggleVirtualMonitorSplitArgs>
  >;
  triggerToolInteraction?: Resolver<
    ReadonlyArray<ResolversTypes['ToolInteractionResponseUnion']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationTriggerToolInteractionArgs,
      'payload' | 'toolId' | 'toolInteractionId' | 'toolInteractionType'
    >
  >;
  unauthenticate?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  undoChangeWorklistItemCustomStatus?: Resolver<
    Maybe<ResolversTypes['WorklistItem']>,
    ParentType,
    ContextType,
    RequireFields<MutationUndoChangeWorklistItemCustomStatusArgs, 'customStatusID' | 'smid'>
  >;
  updateAddendum?: Resolver<
    ResolversTypes['Addendum'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateAddendumArgs, 'payload' | 'smid'>
  >;
  updateAnnotation?: Resolver<
    Maybe<ResolversTypes['UgcAnnotation']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateAnnotationArgs, 'data' | 'smid'>
  >;
  updateAutoCorrectEntry?: Resolver<
    ResolversTypes['UpdateAutoCorrectEntryResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateAutoCorrectEntryArgs, 'input'>
  >;
  updateBarPreferences?: Resolver<
    ResolversTypes['BarPreferences'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateBarPreferencesArgs, 'ids'>
  >;
  updateCustomDicomTags?: Resolver<
    ResolversTypes['CustomDicomTagsResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateCustomDicomTagsArgs, 'modality' | 'modalityDicomTags'>
  >;
  updateDictaphonePreferences?: Resolver<
    ResolversTypes['DictaphonePreferences'],
    ParentType,
    ContextType,
    Partial<MutationUpdateDictaphonePreferencesArgs>
  >;
  updateHangingProtocol?: Resolver<
    Maybe<ResolversTypes['HangingProtocol']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateHangingProtocolArgs, 'id' | 'payload'>
  >;
  updateKeyboardPreferences?: Resolver<
    ResolversTypes['KeyboardPreferences'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateKeyboardPreferencesArgs, 'id' | 'key'>
  >;
  updateLastViewedWorklists?: Resolver<
    Maybe<ResolversTypes['User']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateLastViewedWorklistsArgs, 'smids'>
  >;
  updateMacro?: Resolver<
    ResolversTypes['Macro'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateMacroArgs, 'id' | 'payload'>
  >;
  updateMe?: Resolver<
    ResolversTypes['User'],
    ParentType,
    ContextType,
    Partial<MutationUpdateMeArgs>
  >;
  updateMipPreferences?: Resolver<
    ResolversTypes['MipPreferences'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateMipPreferencesArgs, 'id' | 'value'>
  >;
  updateMirrorViewerLayoutsPreferences?: Resolver<
    ResolversTypes['MirrorViewerLayoutsPreferences'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateMirrorViewerLayoutsPreferencesArgs, 'enabled'>
  >;
  updateMousePreferences?: Resolver<
    ResolversTypes['MousePreferences'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateMousePreferencesArgs, 'button' | 'id'>
  >;
  updateMouseScrollDirection?: Resolver<
    ResolversTypes['ToolPreferences'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateMouseScrollDirectionArgs, 'invertScroll'>
  >;
  updateMouseSensitivityPreferences?: Resolver<
    ResolversTypes['ToolPreferences'],
    ParentType,
    ContextType,
    Partial<MutationUpdateMouseSensitivityPreferencesArgs>
  >;
  updatePassword?: Resolver<
    ResolversTypes['ConfirmResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdatePasswordArgs, 'newPassword' | 'oldPassword'>
  >;
  updateReport?: Resolver<
    ResolversTypes['Report'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateReportArgs, 'payload' | 'smid' | 'worklistItemSmid'>
  >;
  updateReporterSettings?: Resolver<
    ResolversTypes['ReporterSettings'],
    ParentType,
    ContextType,
    Partial<MutationUpdateReporterSettingsArgs>
  >;
  updateResetToolOnScrollPreferences?: Resolver<
    ResolversTypes['ResetToolOnScrollPreferences'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateResetToolOnScrollPreferencesArgs, 'enabled'>
  >;
  updateSeriesSkipSettings?: Resolver<
    ResolversTypes['SeriesSkipSettingsResponse'],
    ParentType,
    ContextType,
    Partial<MutationUpdateSeriesSkipSettingsArgs>
  >;
  updateShowIconLabelPreferences?: Resolver<
    ResolversTypes['ShowIconLabelPreferences'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateShowIconLabelPreferencesArgs, 'enabled'>
  >;
  updateStackedModalities?: Resolver<
    ResolversTypes['StackedScrollingModalitiesResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateStackedModalitiesArgs, 'stackedModalities'>
  >;
  updateTemplate?: Resolver<
    ResolversTypes['ReportTemplate'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateTemplateArgs, 'id' | 'payload'>
  >;
  updateWheelPreferences?: Resolver<
    ResolversTypes['WheelPreferences'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateWheelPreferencesArgs, 'ids'>
  >;
  updateWorklistItemGroup?: Resolver<
    ReadonlyArray<ResolversTypes['ID']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateWorklistItemGroupArgs, 'groupId' | 'worklistItemSmids'>
  >;
  updateWorklistSettings?: Resolver<
    ResolversTypes['WorklistSettings'],
    ParentType,
    ContextType,
    Partial<MutationUpdateWorklistSettingsArgs>
  >;
  updateWorkspacePreset?: Resolver<
    ResolversTypes['WorkspacePreset'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateWorkspacePresetArgs, 'payload' | 'smid'>
  >;
  updateWwwcToolPreset?: Resolver<
    Maybe<ResolversTypes['WwwcPreset']>,
    ParentType,
    ContextType,
    RequireFields<MutationUpdateWwwcToolPresetArgs, 'id' | 'payload'>
  >;
};

export type NvoqCredentialsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['NvoqCredentials'] = ResolversParentTypes['NvoqCredentials'],
> = {
  password?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OwnerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Owner'] = ResolversParentTypes['Owner'],
> = {
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PatientOrientationResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['PatientOrientation'] = ResolversParentTypes['PatientOrientation'],
> = {
  columns?: Resolver<ResolversTypes['AnatomicalDirection'], ParentType, ContextType>;
  rows?: Resolver<ResolversTypes['AnatomicalDirection'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PatientStudyModuleResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['PatientStudyModule'] = ResolversParentTypes['PatientStudyModule'],
> = {
  patientWeight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PermissionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Permission'] = ResolversParentTypes['Permission'],
> = {
  component?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PetImageModuleResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['PetImageModule'] = ResolversParentTypes['PetImageModule'],
> = {
  correctedImage?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>;
  decayCorrection?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  frameReferenceTime?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  preCalculatedSuvBwFactor?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  radiopharmaceuticalInformation?: Resolver<
    ResolversTypes['RadioPharmaceuticalInformation'],
    ParentType,
    ContextType
  >;
  scanDateTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  startDateTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  suvScaleFactor?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  units?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PixelUrlResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PixelURL'] = ResolversParentTypes['PixelURL'],
> = {
  fallbackUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  frameSmid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  signedUrl?: Resolver<ResolversTypes['SignedUrl'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PredictTemplateResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['PredictTemplateResponse'] = ResolversParentTypes['PredictTemplateResponse'],
> = {
  findings?: Resolver<ResolversTypes['TemplatePrediction'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PreferredWorkLocationResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['PreferredWorkLocation'] = ResolversParentTypes['PreferredWorkLocation'],
> = {
  home?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hospital?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PresentationStateResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['PresentationState'] = ResolversParentTypes['PresentationState'],
> = {
  annotationSmids?: Resolver<ReadonlyArray<ResolversTypes['ID']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PriorityResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Priority'] = ResolversParentTypes['Priority'],
> = {
  dueDuration?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProcedureResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Procedure'] = ResolversParentTypes['Procedure'],
> = {
  autoload?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  autoloadTemplate?: Resolver<Maybe<ResolversTypes['ReportTemplate']>, ParentType, ContextType>;
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  smid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProcedureReferenceResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ProcedureReference'] = ResolversParentTypes['ProcedureReference'],
> = {
  autoload?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  smid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProceduresResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Procedures'] = ResolversParentTypes['Procedures'],
> = {
  cursor?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  items?: Resolver<ReadonlyArray<ResolversTypes['Procedure']>, ParentType, ContextType>;
  next?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  previous?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProvenanceDataResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ProvenanceData'] = ResolversParentTypes['ProvenanceData'],
> = {
  matchType?: Resolver<Maybe<ResolversTypes['MatchType']>, ParentType, ContextType>;
  relevant?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QualityControlCommentResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['QualityControlComment'] = ResolversParentTypes['QualityControlComment'],
> = {
  authorName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  authorSmid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  comment?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query'],
> = {
  aiAnnotations?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['AiAnnotation']>>,
    ParentType,
    ContextType,
    RequireFields<QueryAiAnnotationsArgs, 'studySmids'>
  >;
  annotations?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['Annotation']>>,
    ParentType,
    ContextType,
    Partial<QueryAnnotationsArgs>
  >;
  annotationsByStudyInstanceUIDs?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['Annotation']>>,
    ParentType,
    ContextType,
    RequireFields<QueryAnnotationsByStudyInstanceUiDsArgs, 'studyInstanceUIDs' | 'studySmids'>
  >;
  authenticatorToken?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType,
    RequireFields<QueryAuthenticatorTokenArgs, 'password'>
  >;
  bodyParts?: Resolver<ReadonlyArray<ResolversTypes['BodyPart']>, ParentType, ContextType>;
  caseClassification?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['StudyClassificationSummary']>>>,
    ParentType,
    ContextType,
    RequireFields<QueryCaseClassificationArgs, 'studySmids'>
  >;
  clinic?: Resolver<
    Maybe<ResolversTypes['Clinic']>,
    ParentType,
    ContextType,
    RequireFields<QueryClinicArgs, 'id'>
  >;
  customAnnotationLogic?: Resolver<
    Maybe<ResolversTypes['CustomAnnotationLogic']>,
    ParentType,
    ContextType,
    RequireFields<QueryCustomAnnotationLogicArgs, 'toolId' | 'type'>
  >;
  customAnnotationsLogic?: Resolver<
    ReadonlyArray<ResolversTypes['CustomAnnotationLogic']>,
    ParentType,
    ContextType,
    RequireFields<QueryCustomAnnotationsLogicArgs, 'toolId'>
  >;
  dicomInstance?: Resolver<
    Maybe<ResolversTypes['DicomInstance']>,
    ParentType,
    ContextType,
    RequireFields<QueryDicomInstanceArgs, 'instanceSmid' | 'seriesSmid'>
  >;
  empty?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  generatePriorsSummary?: Resolver<
    ResolversTypes['GeneratePriorsSummaryResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryGeneratePriorsSummaryArgs, 'caseSmid'>
  >;
  groupableWorklistItems?: Resolver<
    ReadonlyArray<ResolversTypes['WorklistItem']>,
    ParentType,
    ContextType,
    RequireFields<QueryGroupableWorklistItemsArgs, 'mrn' | 'patientName'>
  >;
  groupedWorklistItems?: Resolver<
    ReadonlyArray<ResolversTypes['WorklistItem']>,
    ParentType,
    ContextType,
    Partial<QueryGroupedWorklistItemsArgs>
  >;
  hangingProtocol?: Resolver<
    Maybe<ResolversTypes['HangingProtocol']>,
    ParentType,
    ContextType,
    RequireFields<QueryHangingProtocolArgs, 'id'>
  >;
  hangingProtocols?: Resolver<
    ResolversTypes['HangingProtocols'],
    ParentType,
    ContextType,
    Partial<QueryHangingProtocolsArgs>
  >;
  headingKeywords?: Resolver<ResolversTypes['HeadingKeywords'], ParentType, ContextType>;
  macro?: Resolver<
    ResolversTypes['Macro'],
    ParentType,
    ContextType,
    RequireFields<QueryMacroArgs, 'id'>
  >;
  macros?: Resolver<ResolversTypes['Macros'], ParentType, ContextType, Partial<QueryMacrosArgs>>;
  matchHangingProtocol?: Resolver<
    Maybe<ResolversTypes['HangingProtocol']>,
    ParentType,
    ContextType,
    RequireFields<QueryMatchHangingProtocolArgs, 'cases'>
  >;
  matchHangingProtocolByWorklistsAndStudies?: Resolver<
    Maybe<ResolversTypes['HangingProtocol']>,
    ParentType,
    ContextType,
    RequireFields<QueryMatchHangingProtocolByWorklistsAndStudiesArgs, 'worklistIds'>
  >;
  me?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  modalities?: Resolver<ReadonlyArray<ResolversTypes['Modality']>, ParentType, ContextType>;
  pixelURLs?: Resolver<
    ReadonlyArray<ResolversTypes['PixelURL']>,
    ParentType,
    ContextType,
    RequireFields<QueryPixelUrLsArgs, 'frames' | 'isMultiFrame' | 'stackSmid'>
  >;
  predictTemplate?: Resolver<
    ResolversTypes['PredictTemplateResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryPredictTemplateArgs, 'headers' | 'report' | 'reportSmid'>
  >;
  presentationStates?: Resolver<
    ReadonlyArray<ResolversTypes['GspsPresentationStateResponse']>,
    ParentType,
    ContextType,
    RequireFields<QueryPresentationStatesArgs, 'worklistIds'>
  >;
  priorities?: Resolver<ReadonlyArray<ResolversTypes['Priority']>, ParentType, ContextType>;
  procedure?: Resolver<
    ResolversTypes['Procedure'],
    ParentType,
    ContextType,
    RequireFields<QueryProcedureArgs, 'id'>
  >;
  procedures?: Resolver<
    ResolversTypes['Procedures'],
    ParentType,
    ContextType,
    Partial<QueryProceduresArgs>
  >;
  proceduresBySmid?: Resolver<
    ReadonlyArray<ResolversTypes['Procedure']>,
    ParentType,
    ContextType,
    RequireFields<QueryProceduresBySmidArgs, 'smids'>
  >;
  qualityControlComments?: Resolver<
    ReadonlyArray<Maybe<ResolversTypes['QualityControlComment']>>,
    ParentType,
    ContextType,
    RequireFields<QueryQualityControlCommentsArgs, 'smid'>
  >;
  report?: Resolver<
    ResolversTypes['Report'],
    ParentType,
    ContextType,
    RequireFields<QueryReportArgs, 'smid'>
  >;
  reports?: Resolver<
    ReadonlyArray<ResolversTypes['Report']>,
    ParentType,
    ContextType,
    RequireFields<QueryReportsArgs, 'accessionNumbers'>
  >;
  rhinoModels?: Resolver<
    ReadonlyArray<ResolversTypes['RhinoModel']>,
    ParentType,
    ContextType,
    Partial<QueryRhinoModelsArgs>
  >;
  rhinoPrompt?: Resolver<
    Maybe<ResolversTypes['RhinoPrompt']>,
    ParentType,
    ContextType,
    RequireFields<QueryRhinoPromptArgs, 'id'>
  >;
  rhinoPrompts?: Resolver<
    ReadonlyArray<ResolversTypes['RhinoPrompt']>,
    ParentType,
    ContextType,
    Partial<QueryRhinoPromptsArgs>
  >;
  seriesBySmid?: Resolver<
    ReadonlyArray<ResolversTypes['Series']>,
    ParentType,
    ContextType,
    RequireFields<QuerySeriesBySmidArgs, 'smids'>
  >;
  seriesClassifications?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['SeriesClassification']>>>,
    ParentType,
    ContextType,
    RequireFields<QuerySeriesClassificationsArgs, 'seriesSmids'>
  >;
  structuredReport?: Resolver<
    ResolversTypes['StructuredReport'],
    ParentType,
    ContextType,
    RequireFields<QueryStructuredReportArgs, 'frameSmid' | 'seriesSmid'>
  >;
  studies?: Resolver<
    ResolversTypes['StudyItems'],
    ParentType,
    ContextType,
    Partial<QueryStudiesArgs>
  >;
  studiesBySmid?: Resolver<
    ReadonlyArray<ResolversTypes['Study']>,
    ParentType,
    ContextType,
    RequireFields<QueryStudiesBySmidArgs, 'smids'>
  >;
  study?: Resolver<
    ResolversTypes['Study'],
    ParentType,
    ContextType,
    RequireFields<QueryStudyArgs, 'smid'>
  >;
  studyDescriptions?: Resolver<
    ResolversTypes['StudyDescriptions'],
    ParentType,
    ContextType,
    Partial<QueryStudyDescriptionsArgs>
  >;
  template?: Resolver<
    Maybe<ResolversTypes['ReportTemplate']>,
    ParentType,
    ContextType,
    RequireFields<QueryTemplateArgs, 'id'>
  >;
  templates?: Resolver<
    ResolversTypes['ReportTemplates'],
    ParentType,
    ContextType,
    Partial<QueryTemplatesArgs>
  >;
  toolPreferences?: Resolver<ResolversTypes['ToolPreferences'], ParentType, ContextType>;
  tools?: Resolver<ReadonlyArray<ResolversTypes['Tool']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, Partial<QueryUserArgs>>;
  users?: Resolver<ResolversTypes['Users'], ParentType, ContextType, Partial<QueryUsersArgs>>;
  vocabEntries?: Resolver<ReadonlyArray<ResolversTypes['VocabEntry']>, ParentType, ContextType>;
  worklistCustomStatuses?: Resolver<
    ReadonlyArray<ResolversTypes['WorklistCustomStatus']>,
    ParentType,
    ContextType
  >;
  worklistFilterDropdownItems?: Resolver<
    Maybe<ResolversTypes['WorklistFilterDropdownItems']>,
    ParentType,
    ContextType,
    Partial<QueryWorklistFilterDropdownItemsArgs>
  >;
  worklistItem?: Resolver<
    Maybe<ResolversTypes['WorklistItem']>,
    ParentType,
    ContextType,
    RequireFields<QueryWorklistItemArgs, 'id'>
  >;
  worklistItemAuditLogs?: Resolver<
    ReadonlyArray<ResolversTypes['WorklistItemAuditLogItem']>,
    ParentType,
    ContextType,
    Partial<QueryWorklistItemAuditLogsArgs>
  >;
  worklistItemByStudySmid?: Resolver<
    Maybe<ResolversTypes['WorklistItem']>,
    ParentType,
    ContextType,
    RequireFields<QueryWorklistItemByStudySmidArgs, 'smid'>
  >;
  worklistItems?: Resolver<
    ResolversTypes['WorklistItems'],
    ParentType,
    ContextType,
    Partial<QueryWorklistItemsArgs>
  >;
  worklistItemsClassifications?: Resolver<
    Maybe<ResolversTypes['JSON']>,
    ParentType,
    ContextType,
    RequireFields<QueryWorklistItemsClassificationsArgs, 'worklistItemSmids'>
  >;
  worklistView?: Resolver<
    Maybe<ResolversTypes['WorklistViewDetails']>,
    ParentType,
    ContextType,
    RequireFields<QueryWorklistViewArgs, 'smid'>
  >;
  worklistViews?: Resolver<ReadonlyArray<ResolversTypes['WorklistView']>, ParentType, ContextType>;
};

export type RadioPharmaceuticalInformationResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RadioPharmaceuticalInformation'] = ResolversParentTypes['RadioPharmaceuticalInformation'],
> = {
  radionuclideHalfLife?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  radiopharmaceuticalStartDateTime?: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  radiopharmaceuticalStartTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  radiopharmaceuticalTotalDose?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReferencedImageResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ReferencedImage'] = ResolversParentTypes['ReferencedImage'],
> = {
  __resolveType: TypeResolveFn<'SourceImage', ParentType, ContextType>;
  referencedFrameNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  referencedSOPClassUID?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  referencedSOPInstanceUID?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
};

export type RegionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Region'] = ResolversParentTypes['Region'],
> = {
  dataType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  flags?: Resolver<ResolversTypes['RegionFlags'], ParentType, ContextType>;
  location?: Resolver<ReadonlyArray<ResolversTypes['Int']>, ParentType, ContextType>;
  physicalDelta?: Resolver<ReadonlyArray<ResolversTypes['Float']>, ParentType, ContextType>;
  physicalUnits?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>;
  spatialFormat?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RegionCalibrationModuleResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RegionCalibrationModule'] = ResolversParentTypes['RegionCalibrationModule'],
> = {
  regions?: Resolver<ReadonlyArray<ResolversTypes['Region']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RegionFlagsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RegionFlags'] = ResolversParentTypes['RegionFlags'],
> = {
  highPriority?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  scalingProtection?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RegularScrollPreferencesResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RegularScrollPreferences'] = ResolversParentTypes['RegularScrollPreferences'],
> = {
  sensitivity?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RejectAiAnnotationLogResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RejectAiAnnotationLog'] = ResolversParentTypes['RejectAiAnnotationLog'],
> = {
  logIds?: Resolver<ReadonlyArray<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Report'] = ResolversParentTypes['Report'],
> = {
  addendums?: Resolver<ReadonlyArray<ResolversTypes['Addendum']>, ParentType, ContextType>;
  content?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  format?: Resolver<ResolversTypes['ReportFormat'], ParentType, ContextType>;
  macros?: Resolver<ReadonlyArray<ResolversTypes['Macro']>, ParentType, ContextType>;
  owner?: Resolver<Maybe<ResolversTypes['Owner']>, ParentType, ContextType>;
  picklists?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['ReportPicklist']>>,
    ParentType,
    ContextType
  >;
  sections?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  sectionsLastUpdated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  sentAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  smid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['ReportStatus'], ParentType, ContextType>;
  submittedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  template?: Resolver<Maybe<ResolversTypes['ReportTemplate']>, ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  version?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  worklistitem?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  workstationID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportPicklistResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ReportPicklist'] = ResolversParentTypes['ReportPicklist'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  options?: Resolver<
    ReadonlyArray<ResolversTypes['ReportPicklistOption']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportPicklistOptionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ReportPicklistOption'] = ResolversParentTypes['ReportPicklistOption'],
> = {
  default?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  text?: Resolver<ReadonlyArray<ResolversTypes['JSON']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportTemplateResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ReportTemplate'] = ResolversParentTypes['ReportTemplate'],
> = {
  content?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  macros?: Resolver<ReadonlyArray<ResolversTypes['Macro']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  owner?: Resolver<Maybe<ResolversTypes['Owner']>, ParentType, ContextType>;
  picklists?: Resolver<ReadonlyArray<ResolversTypes['ReportPicklist']>, ParentType, ContextType>;
  procedureReferences?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['ProcedureReference']>>,
    ParentType,
    ContextType
  >;
  sections?: Resolver<ReadonlyArray<ResolversTypes['JSON']>, ParentType, ContextType>;
  triggerPhrase?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportTemplatesResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ReportTemplates'] = ResolversParentTypes['ReportTemplates'],
> = {
  cursor?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  items?: Resolver<ReadonlyArray<ResolversTypes['ReportTemplate']>, ParentType, ContextType>;
  next?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  previous?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReporterSettingsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ReporterSettings'] = ResolversParentTypes['ReporterSettings'],
> = {
  aiMode?: Resolver<ResolversTypes['AIModeSettings'], ParentType, ContextType>;
  autoFillComparison?: Resolver<ResolversTypes['AutoFillComparison'], ParentType, ContextType>;
  cursorStyle?: Resolver<ResolversTypes['CursorStyle'], ParentType, ContextType>;
  focusMode?: Resolver<ResolversTypes['FocusMode'], ParentType, ContextType>;
  impressionGenerator?: Resolver<ResolversTypes['ImpressionGenerator'], ParentType, ContextType>;
  lineIndicator?: Resolver<ResolversTypes['LineIndicator'], ParentType, ContextType>;
  mergeFieldsSettings?: Resolver<ResolversTypes['MergeFieldsSettings'], ParentType, ContextType>;
  styles?: Resolver<ResolversTypes['Styles'], ParentType, ContextType>;
  triggerWord?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResetToolOnScrollPreferencesResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ResetToolOnScrollPreferences'] = ResolversParentTypes['ResetToolOnScrollPreferences'],
> = {
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Response'] = ResolversParentTypes['Response'],
> = {
  status?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RhinoFeedbackResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RhinoFeedbackResponse'] = ResolversParentTypes['RhinoFeedbackResponse'],
> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RhinoMedCheckResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RhinoMedCheckResponse'] = ResolversParentTypes['RhinoMedCheckResponse'],
> = {
  content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RhinoModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RhinoModel'] = ResolversParentTypes['RhinoModel'],
> = {
  config?: Resolver<ResolversTypes['RhinoModelConfig'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  vendor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RhinoModelConfigResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RhinoModelConfig'] = ResolversParentTypes['RhinoModelConfig'],
> = {
  modelName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  temperature?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RhinoPromptResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RhinoPrompt'] = ResolversParentTypes['RhinoPrompt'],
> = {
  featureName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  modelIdentifier?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  prompt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  role?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RhinoQueryMessageResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RhinoQueryMessage'] = ResolversParentTypes['RhinoQueryMessage'],
> = {
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  role?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RhinoQueryResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RhinoQueryResponse'] = ResolversParentTypes['RhinoQueryResponse'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  modelIdentifier?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  queryMessages?: Resolver<
    ReadonlyArray<ResolversTypes['RhinoQueryMessage']>,
    ParentType,
    ContextType
  >;
  rawApiResponse?: Resolver<ResolversTypes['RhinoRawAPIResponse'], ParentType, ContextType>;
  response?: Resolver<ResolversTypes['SironaCustomLLMResponseModel'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RhinoRawApiResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RhinoRawAPIResponse'] = ResolversParentTypes['RhinoRawAPIResponse'],
> = {
  choices?: Resolver<
    ReadonlyArray<ResolversTypes['RhinoRawAPIResponseChoice']>,
    ParentType,
    ContextType
  >;
  created?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  model?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  object?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  usage?: Resolver<ResolversTypes['RhinoRawAPIResponseUsage'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RhinoRawApiResponseChoiceResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RhinoRawAPIResponseChoice'] = ResolversParentTypes['RhinoRawAPIResponseChoice'],
> = {
  finishReason?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  index?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['SironaCustomLLMResponseMessage'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RhinoRawApiResponseUsageResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RhinoRawAPIResponseUsage'] = ResolversParentTypes['RhinoRawAPIResponseUsage'],
> = {
  completionTokens?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  promptTokens?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTokens?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Role'] = ResolversParentTypes['Role'],
> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  permissions?: Resolver<ReadonlyArray<ResolversTypes['Permission']>, ParentType, ContextType>;
  scope?: Resolver<ResolversTypes['Scope'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SrGeneralInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SRGeneralInfo'] = ResolversParentTypes['SRGeneralInfo'],
> = {
  completion_flag?: Resolver<Maybe<ResolversTypes['CompletionFlag']>, ParentType, ContextType>;
  completion_flag_description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  date?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  instance_number?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  preliminary_flag?: Resolver<Maybe<ResolversTypes['PreliminaryFlag']>, ParentType, ContextType>;
  time?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  verification_status?: Resolver<
    Maybe<ResolversTypes['VerificationStatus']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SrInstanceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SRInstance'] = ResolversParentTypes['SRInstance'],
> = {
  number?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  uid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SrObservationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SRObservation'] = ResolversParentTypes['SRObservation'],
> = {
  datetime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SrPatientResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SRPatient'] = ResolversParentTypes['SRPatient'],
> = {
  age?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sex?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SrsopClassResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SRSOPClass'] = ResolversParentTypes['SRSOPClass'],
> = {
  uid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SrSeriesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SRSeries'] = ResolversParentTypes['SRSeries'],
> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  number?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  uid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SrStudyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SRStudy'] = ResolversParentTypes['SRStudy'],
> = {
  date?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  time?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SavedSortingPreferenceResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SavedSortingPreference'] = ResolversParentTypes['SavedSortingPreference'],
> = {
  searchParams?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  surface?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  worklistViewID?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SavedWorklistViewResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SavedWorklistView'] = ResolversParentTypes['SavedWorklistView'],
> = {
  surface?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  views?: Resolver<ReadonlyArray<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScopeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Scope'] = ResolversParentTypes['Scope'],
> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  object_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScreenShareSettingResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ScreenShareSettingResponse'] = ResolversParentTypes['ScreenShareSettingResponse'],
> = {
  screenShareEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SecuritySettingsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SecuritySettings'] = ResolversParentTypes['SecuritySettings'],
> = {
  multiFactorAuth?: Resolver<ResolversTypes['MultiFactorAuth'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SeriesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Series'] = ResolversParentTypes['Series'],
> = {
  SOPClassUID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  classifications?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['SeriesClassification']>>>,
    ParentType,
    ContextType
  >;
  colorLevel?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  columns?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dicomTags?: Resolver<
    ReadonlyArray<ResolversTypes['DicomTag']>,
    ParentType,
    ContextType,
    Partial<SeriesDicomTagsArgs>
  >;
  frameOfReferenceUID?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  imageRegistrations?: Resolver<
    ReadonlyArray<ResolversTypes['ImageRegistration']>,
    ParentType,
    ContextType
  >;
  instances?: Resolver<ReadonlyArray<ResolversTypes['DicomInstance']>, ParentType, ContextType>;
  is3Dable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  pixelRepresentation?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pixelSpacing?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['Float']>>>,
    ParentType,
    ContextType
  >;
  rescaleIntercept?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rescaleSlope?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  seriesDateTimeLocal?: Resolver<Maybe<ResolversTypes['DateTimeLocal']>, ParentType, ContextType>;
  seriesInstanceUID?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  seriesNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sliceThickness?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  smid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  study?: Resolver<ResolversTypes['Study'], ParentType, ContextType>;
  windowLevel?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SeriesClassificationResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SeriesClassification'] = ResolversParentTypes['SeriesClassification'],
> = {
  seriesCriteria?: Resolver<Maybe<ResolversTypes['SeriesCriteria']>, ParentType, ContextType>;
  seriesDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  seriesDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  seriesNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  seriesSmid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  seriesTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  studySmid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SeriesCriteriaResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SeriesCriteria'] = ResolversParentTypes['SeriesCriteria'],
> = {
  anatomic_focus?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['ClassificationSummary']>>>,
    ParentType,
    ContextType
  >;
  contrast_status?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['ClassificationSummary']>>>,
    ParentType,
    ContextType
  >;
  ct_kernel?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['ClassificationSummary']>>>,
    ParentType,
    ContextType
  >;
  dimensionality?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['ClassificationSummary']>>>,
    ParentType,
    ContextType
  >;
  document_type?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['ClassificationSummary']>>>,
    ParentType,
    ContextType
  >;
  field_of_view?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['ClassificationSummary']>>>,
    ParentType,
    ContextType
  >;
  localizer?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['ClassificationSummary']>>>,
    ParentType,
    ContextType
  >;
  modality?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['ClassificationSummary']>>>,
    ParentType,
    ContextType
  >;
  mr_relative_phase?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['ClassificationSummary']>>>,
    ParentType,
    ContextType
  >;
  patient_position?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['ClassificationSummary']>>>,
    ParentType,
    ContextType
  >;
  post_processing?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['ClassificationSummary']>>>,
    ParentType,
    ContextType
  >;
  tissue_selection?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['ClassificationSummary']>>>,
    ParentType,
    ContextType
  >;
  view_count?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['ClassificationSummary']>>>,
    ParentType,
    ContextType
  >;
  view_orientation?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['ClassificationSummary']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SeriesSkipSettingsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SeriesSkipSettings'] = ResolversParentTypes['SeriesSkipSettings'],
> = {
  amount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  keepPreviousWhenAvailable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SeriesSkipSettingsResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SeriesSkipSettingsResponse'] = ResolversParentTypes['SeriesSkipSettingsResponse'],
> = {
  seriesSkipSettings?: Resolver<
    Maybe<ResolversTypes['SeriesSkipSettings']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SetWwwcDragDirectionResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SetWwwcDragDirectionResponse'] = ResolversParentTypes['SetWwwcDragDirectionResponse'],
> = {
  invertDragDirection?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShortcutResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Shortcut'] = ResolversParentTypes['Shortcut'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  interactionId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShowIconLabelPreferencesResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ShowIconLabelPreferences'] = ResolversParentTypes['ShowIconLabelPreferences'],
> = {
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SignedUrlResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SignedUrl'] = ResolversParentTypes['SignedUrl'],
> = {
  expirationTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SingleLayerStackResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SingleLayerStack'] = ResolversParentTypes['SingleLayerStack'],
> = {
  frames?: Resolver<ReadonlyArray<ResolversTypes['FrameScalar']>, ParentType, ContextType>;
  image?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  imageParams?: Resolver<ResolversTypes['ImageParams'], ParentType, ContextType>;
  is3Dable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  series?: Resolver<ResolversTypes['Series'], ParentType, ContextType>;
  seriesNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  smid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  study?: Resolver<ResolversTypes['Study'], ParentType, ContextType>;
  supportedRenderers?: Resolver<ResolversTypes['SupportedRenderers'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SironaCustomLlmResponseMessageResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SironaCustomLLMResponseMessage'] = ResolversParentTypes['SironaCustomLLMResponseMessage'],
> = {
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  role?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SironaCustomLlmResponseModelResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SironaCustomLLMResponseModel'] = ResolversParentTypes['SironaCustomLLMResponseModel'],
> = {
  message?: Resolver<ResolversTypes['SironaCustomLLMResponseMessage'], ParentType, ContextType>;
  usage?: Resolver<ResolversTypes['SironaCustomLLMResponseUsage'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SironaCustomLlmResponseUsageResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SironaCustomLLMResponseUsage'] = ResolversParentTypes['SironaCustomLLMResponseUsage'],
> = {
  completionTokens?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  promptTokens?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalTokens?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SlimUserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SlimUser'] = ResolversParentTypes['SlimUser'],
> = {
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SourceImageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SourceImage'] = ResolversParentTypes['SourceImage'],
> = {
  patientOrientation?: Resolver<
    Maybe<ResolversTypes['PatientOrientation']>,
    ParentType,
    ContextType
  >;
  referencedFrameNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  referencedSOPClassUID?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  referencedSOPInstanceUID?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  spatialLocationsPreserved?: Resolver<
    Maybe<ResolversTypes['SpatialLocationsPreserved']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StackedScrollingModalitiesResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['StackedScrollingModalitiesResponse'] = ResolversParentTypes['StackedScrollingModalitiesResponse'],
> = {
  stackedModalities?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StackedScrollingResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['StackedScrollingResponse'] = ResolversParentTypes['StackedScrollingResponse'],
> = {
  stackInstancesByType?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface StringOrBooleanOrDateScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['StringOrBooleanOrDate'], any> {
  name: 'StringOrBooleanOrDate';
}

export type StructuredReportResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['StructuredReport'] = ResolversParentTypes['StructuredReport'],
> = {
  content?: Resolver<ResolversTypes['ContentSequence'], ParentType, ContextType>;
  general?: Resolver<ResolversTypes['SRGeneralInfo'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  instance?: Resolver<ResolversTypes['SRInstance'], ParentType, ContextType>;
  modalities?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  observation?: Resolver<ResolversTypes['SRObservation'], ParentType, ContextType>;
  patient?: Resolver<ResolversTypes['SRPatient'], ParentType, ContextType>;
  series?: Resolver<ResolversTypes['SRSeries'], ParentType, ContextType>;
  sop_class?: Resolver<ResolversTypes['SRSOPClass'], ParentType, ContextType>;
  study?: Resolver<ResolversTypes['SRStudy'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StudyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Study'] = ResolversParentTypes['Study'],
> = {
  accessionNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bodyPart?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  classifications?: Resolver<
    ResolversTypes['Classifications'],
    ParentType,
    ContextType,
    Partial<StudyClassificationsArgs>
  >;
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hl7Order?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  instanceUID?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  laterality?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modality?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mrn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  patientAge?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  patientDob?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  patientName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  patientSex?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  patientWeight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  provenanceData?: Resolver<Maybe<ResolversTypes['ProvenanceData']>, ParentType, ContextType>;
  seriesCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  seriesList?: Resolver<ReadonlyArray<ResolversTypes['Series']>, ParentType, ContextType>;
  site?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  smid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  stackedFrames?: Resolver<
    ReadonlyArray<ResolversTypes['FrameStack']>,
    ParentType,
    ContextType,
    RequireFields<StudyStackedFramesArgs, 'groupByType'>
  >;
  studyDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StudyClassificationSummaryResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['StudyClassificationSummary'] = ResolversParentTypes['StudyClassificationSummary'],
> = {
  study_classification?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  study_description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  study_smid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StudyDescriptionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['StudyDescription'] = ResolversParentTypes['StudyDescription'],
> = {
  autoloadTemplate?: Resolver<Maybe<ResolversTypes['ReportTemplate']>, ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  smid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StudyDescriptionsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['StudyDescriptions'] = ResolversParentTypes['StudyDescriptions'],
> = {
  cursor?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  items?: Resolver<ReadonlyArray<ResolversTypes['StudyDescription']>, ParentType, ContextType>;
  next?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  previous?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StudyItemsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StudyItems'] = ResolversParentTypes['StudyItems'],
> = {
  cursor?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  items?: Resolver<ReadonlyArray<ResolversTypes['Study']>, ParentType, ContextType>;
  next?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  previous?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StylesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Styles'] = ResolversParentTypes['Styles'],
> = {
  bodyStyle?: Resolver<ResolversTypes['TextStyle'], ParentType, ContextType>;
  headingStyles?: Resolver<ReadonlyArray<ResolversTypes['HeadingStyle']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubmitReportInvalidInputResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SubmitReportInvalidInput'] = ResolversParentTypes['SubmitReportInvalidInput'],
> = {
  field?: Resolver<ResolversTypes['SubmitReportInvalidInputField'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubmitReportPayloadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SubmitReportPayload'] = ResolversParentTypes['SubmitReportPayload'],
> = {
  report?: Resolver<ResolversTypes['Report'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubmitReportProblemResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SubmitReportProblem'] = ResolversParentTypes['SubmitReportProblem'],
> = {
  invalidInputs?: Resolver<
    ReadonlyArray<Maybe<ResolversTypes['SubmitReportInvalidInput']>>,
    ParentType,
    ContextType
  >;
  unknownProblem?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubmitReportResultResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SubmitReportResult'] = ResolversParentTypes['SubmitReportResult'],
> = {
  __resolveType: TypeResolveFn<
    'SubmitReportPayload' | 'SubmitReportProblem',
    ParentType,
    ContextType
  >;
};

export type SubscriptionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Subscription'] = ResolversParentTypes['Subscription'],
> = {
  counter?: SubscriptionResolver<ResolversTypes['Counter'], 'counter', ParentType, ContextType>;
};

export type SupportedRenderersResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SupportedRenderers'] = ResolversParentTypes['SupportedRenderers'],
> = {
  vtk?: Resolver<ResolversTypes['RendererSupport'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface TemplatePredictionScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['TemplatePrediction'], any> {
  name: 'TemplatePrediction';
}

export type TextDecorationResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TextDecoration'] = ResolversParentTypes['TextDecoration'],
> = {
  isBold?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isItalic?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isUnderline?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TextStyleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TextStyle'] = ResolversParentTypes['TextStyle'],
> = {
  fontFamily?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fontSize?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  textDecoration?: Resolver<Maybe<ResolversTypes['TextDecoration']>, ParentType, ContextType>;
  textTransform?: Resolver<Maybe<ResolversTypes['TextTransform']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Tool'] = ResolversParentTypes['Tool'],
> = {
  category?: Resolver<ResolversTypes['ToolCategory'], ParentType, ContextType>;
  customAnnotationsLogic?: Resolver<
    ReadonlyArray<ResolversTypes['CustomAnnotationLogic']>,
    ParentType,
    ContextType
  >;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  icon?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  interactions?: Resolver<
    ReadonlyArray<ResolversTypes['ToolInteractionUnion']>,
    ParentType,
    ContextType
  >;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  payloadCapabilities?: Resolver<
    ReadonlyArray<ResolversTypes['ToolPayloadCapability']>,
    ParentType,
    ContextType
  >;
  splitFlag?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolAnnotationInteractionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ToolAnnotationInteraction'] = ResolversParentTypes['ToolAnnotationInteraction'],
> = {
  action?: Resolver<ResolversTypes['MouseAction'], ParentType, ContextType>;
  button?: Resolver<ResolversTypes['MouseButton'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  maxConcurrentRequests?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  request?: Resolver<ResolversTypes['ToolInteractionRequest'], ParentType, ContextType>;
  splitFlag?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  targetAnnotationGroupId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  targetAnnotationId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ToolInteractionType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolContextMenuOptionInteractionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ToolContextMenuOptionInteraction'] = ResolversParentTypes['ToolContextMenuOptionInteraction'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  maxConcurrentRequests?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  request?: Resolver<ResolversTypes['ToolInteractionRequest'], ParentType, ContextType>;
  splitFlag?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ToolInteractionType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolIconButtonClickInteractionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ToolIconButtonClickInteraction'] = ResolversParentTypes['ToolIconButtonClickInteraction'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  maxConcurrentRequests?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  request?: Resolver<ResolversTypes['ToolInteractionRequest'], ParentType, ContextType>;
  splitFlag?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ToolInteractionType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolInteractionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ToolInteraction'] = ResolversParentTypes['ToolInteraction'],
> = {
  __resolveType: TypeResolveFn<
    | 'ToolAnnotationInteraction'
    | 'ToolContextMenuOptionInteraction'
    | 'ToolIconButtonClickInteraction'
    | 'ToolKeyboardShortcutInteraction'
    | 'ToolMergeFieldInteraction'
    | 'ToolViewportClickInteraction'
    | 'ToolVoiceCommandInteraction',
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  maxConcurrentRequests?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  request?: Resolver<ResolversTypes['ToolInteractionRequest'], ParentType, ContextType>;
  splitFlag?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ToolInteractionType'], ParentType, ContextType>;
};

export type ToolInteractionAddInteractionResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ToolInteractionAddInteractionResponse'] = ResolversParentTypes['ToolInteractionAddInteractionResponse'],
> = {
  interactions?: Resolver<
    ReadonlyArray<ResolversTypes['ToolInteraction']>,
    ParentType,
    ContextType
  >;
  type?: Resolver<ResolversTypes['ToolInteractionResponseType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolInteractionAnnotationDataResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ToolInteractionAnnotationData'] = ResolversParentTypes['ToolInteractionAnnotationData'],
> = {
  allowedTransformations?: Resolver<
    Maybe<ResolversTypes['AnnotationEditableOptions']>,
    ParentType,
    ContextType
  >;
  customLogic?: Resolver<Maybe<ResolversTypes['CustomAnnotationLogic']>, ParentType, ContextType>;
  groupId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  segments?: Resolver<ReadonlyArray<ResolversTypes['AnnotationSegment']>, ParentType, ContextType>;
  style?: Resolver<Maybe<ResolversTypes['AnnotationStyle']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['AnnotationTypes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolInteractionAnnotationResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ToolInteractionAnnotationResponse'] = ResolversParentTypes['ToolInteractionAnnotationResponse'],
> = {
  annotation?: Resolver<ResolversTypes['ToolInteractionAnnotationData'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ToolInteractionResponseType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolInteractionInsertTextResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ToolInteractionInsertTextResponse'] = ResolversParentTypes['ToolInteractionInsertTextResponse'],
> = {
  content?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ToolInteractionResponseType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolInteractionKeyboardShortcutResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ToolInteractionKeyboardShortcutResponse'] = ResolversParentTypes['ToolInteractionKeyboardShortcutResponse'],
> = {
  type?: Resolver<ResolversTypes['ToolInteractionResponseType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolInteractionMessageResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ToolInteractionMessageResponse'] = ResolversParentTypes['ToolInteractionMessageResponse'],
> = {
  display?: Resolver<ResolversTypes['MessageDisplay'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  severity?: Resolver<ResolversTypes['MessageSeverity'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ToolInteractionResponseType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolInteractionRequestResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ToolInteractionRequest'] = ResolversParentTypes['ToolInteractionRequest'],
> = {
  method?: Resolver<ResolversTypes['ToolInteractionRequestMethod'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolInteractionResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ToolInteractionResponse'] = ResolversParentTypes['ToolInteractionResponse'],
> = {
  __resolveType: TypeResolveFn<
    | 'ToolInteractionAddInteractionResponse'
    | 'ToolInteractionAnnotationResponse'
    | 'ToolInteractionInsertTextResponse'
    | 'ToolInteractionKeyboardShortcutResponse'
    | 'ToolInteractionMessageResponse'
    | 'ToolInteractionStartAnnotationResponse'
    | 'ToolInteractionUpdateToolActiveStateResponse'
    | 'ToolInteractionUpdateToolbarButtonIconResponse'
    | 'ToolInteractionUpdateViewportPresentationStateResponse'
    | 'ToolInteractionUpdateViewportSliceResponse'
    | 'ToolInteractionWidgetResponse',
    ParentType,
    ContextType
  >;
  type?: Resolver<ResolversTypes['ToolInteractionResponseType'], ParentType, ContextType>;
};

export type ToolInteractionResponseUnionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ToolInteractionResponseUnion'] = ResolversParentTypes['ToolInteractionResponseUnion'],
> = {
  __resolveType: TypeResolveFn<
    | 'ToolInteractionAddInteractionResponse'
    | 'ToolInteractionAnnotationResponse'
    | 'ToolInteractionInsertTextResponse'
    | 'ToolInteractionKeyboardShortcutResponse'
    | 'ToolInteractionMessageResponse'
    | 'ToolInteractionStartAnnotationResponse'
    | 'ToolInteractionUpdateToolActiveStateResponse'
    | 'ToolInteractionUpdateToolbarButtonIconResponse'
    | 'ToolInteractionUpdateViewportPresentationStateResponse'
    | 'ToolInteractionUpdateViewportSliceResponse'
    | 'ToolInteractionWidgetResponse',
    ParentType,
    ContextType
  >;
};

export type ToolInteractionStartAnnotationResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ToolInteractionStartAnnotationResponse'] = ResolversParentTypes['ToolInteractionStartAnnotationResponse'],
> = {
  annotationType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ToolInteractionResponseType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolInteractionUnionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ToolInteractionUnion'] = ResolversParentTypes['ToolInteractionUnion'],
> = {
  __resolveType: TypeResolveFn<
    | 'ToolAnnotationInteraction'
    | 'ToolContextMenuOptionInteraction'
    | 'ToolIconButtonClickInteraction'
    | 'ToolKeyboardShortcutInteraction'
    | 'ToolMergeFieldInteraction'
    | 'ToolViewportClickInteraction'
    | 'ToolVoiceCommandInteraction',
    ParentType,
    ContextType
  >;
};

export type ToolInteractionUpdateToolActiveStateResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ToolInteractionUpdateToolActiveStateResponse'] = ResolversParentTypes['ToolInteractionUpdateToolActiveStateResponse'],
> = {
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  toolId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ToolInteractionResponseType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolInteractionUpdateToolbarButtonIconResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ToolInteractionUpdateToolbarButtonIconResponse'] = ResolversParentTypes['ToolInteractionUpdateToolbarButtonIconResponse'],
> = {
  icon?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ToolInteractionResponseType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolInteractionUpdateViewportPresentationStateResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ToolInteractionUpdateViewportPresentationStateResponse'] = ResolversParentTypes['ToolInteractionUpdateViewportPresentationStateResponse'],
> = {
  level?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rotate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ToolInteractionResponseType'], ParentType, ContextType>;
  viewportId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  window?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  zoom?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolInteractionUpdateViewportSliceResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ToolInteractionUpdateViewportSliceResponse'] = ResolversParentTypes['ToolInteractionUpdateViewportSliceResponse'],
> = {
  slice?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ToolInteractionResponseType'], ParentType, ContextType>;
  viewportId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolInteractionWidgetResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ToolInteractionWidgetResponse'] = ResolversParentTypes['ToolInteractionWidgetResponse'],
> = {
  type?: Resolver<ResolversTypes['ToolInteractionResponseType'], ParentType, ContextType>;
  widget?: Resolver<ResolversTypes['WidgetConfiguration'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolKeyboardShortcutInteractionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ToolKeyboardShortcutInteraction'] = ResolversParentTypes['ToolKeyboardShortcutInteraction'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  keyboardShortcut?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  maxConcurrentRequests?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  request?: Resolver<ResolversTypes['ToolInteractionRequest'], ParentType, ContextType>;
  splitFlag?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ToolInteractionType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolMergeFieldInteractionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ToolMergeFieldInteraction'] = ResolversParentTypes['ToolMergeFieldInteraction'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  maxConcurrentRequests?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  request?: Resolver<ResolversTypes['ToolInteractionRequest'], ParentType, ContextType>;
  splitFlag?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ToolInteractionType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolPreferencesResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ToolPreferences'] = ResolversParentTypes['ToolPreferences'],
> = {
  bar?: Resolver<ResolversTypes['BarPreferences'], ParentType, ContextType>;
  dictaphone?: Resolver<ResolversTypes['DictaphonePreferences'], ParentType, ContextType>;
  fastScroll?: Resolver<ResolversTypes['FastScrollPreferences'], ParentType, ContextType>;
  fineScroll?: Resolver<ResolversTypes['FineScrollPreferences'], ParentType, ContextType>;
  invertScroll?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  keyboard?: Resolver<ResolversTypes['KeyboardPreferences'], ParentType, ContextType>;
  mip?: Resolver<ResolversTypes['MipPreferences'], ParentType, ContextType>;
  mirrorViewerLayouts?: Resolver<
    ResolversTypes['MirrorViewerLayoutsPreferences'],
    ParentType,
    ContextType
  >;
  mouse?: Resolver<ResolversTypes['MousePreferences'], ParentType, ContextType>;
  regularScroll?: Resolver<ResolversTypes['RegularScrollPreferences'], ParentType, ContextType>;
  resetToolOnScroll?: Resolver<
    ResolversTypes['ResetToolOnScrollPreferences'],
    ParentType,
    ContextType
  >;
  showIconLabel?: Resolver<ResolversTypes['ShowIconLabelPreferences'], ParentType, ContextType>;
  wheel?: Resolver<ResolversTypes['WheelPreferences'], ParentType, ContextType>;
  workspaces?: Resolver<ResolversTypes['WorkspacePreferences'], ParentType, ContextType>;
  wwwc?: Resolver<ResolversTypes['WwwcToolPreferences'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolViewportClickInteractionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ToolViewportClickInteraction'] = ResolversParentTypes['ToolViewportClickInteraction'],
> = {
  button?: Resolver<ResolversTypes['MouseButton'], ParentType, ContextType>;
  cursorIcon?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  maxConcurrentRequests?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  request?: Resolver<ResolversTypes['ToolInteractionRequest'], ParentType, ContextType>;
  splitFlag?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ToolInteractionType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolVoiceCommandInteractionResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ToolVoiceCommandInteraction'] = ResolversParentTypes['ToolVoiceCommandInteraction'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  maxConcurrentRequests?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  request?: Resolver<ResolversTypes['ToolInteractionRequest'], ParentType, ContextType>;
  splitFlag?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  triggerPhrase?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ToolInteractionType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface TransformMatrixScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['TransformMatrix'], any> {
  name: 'TransformMatrix';
}

export type UgcAnnotationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UgcAnnotation'] = ResolversParentTypes['UgcAnnotation'],
> = {
  aiAnnotationSmid?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  customLogic?: Resolver<Maybe<ResolversTypes['CustomAnnotationLogic']>, ParentType, ContextType>;
  data?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  owner?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  pixel_space_representation?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  seriesSmid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  smid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  source?: Resolver<ResolversTypes['AnnotationSource'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['AnnotationTypes'], ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateAutoCorrectEntryResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateAutoCorrectEntryResponse'] = ResolversParentTypes['UpdateAutoCorrectEntryResponse'],
> = {
  entry?: Resolver<Maybe<ResolversTypes['AutoCorrectEntry']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User'],
> = {
  anatomicNavigatorEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  autoCorrect?: Resolver<
    ReadonlyArray<ResolversTypes['AutoCorrectEntry']>,
    ParentType,
    ContextType
  >;
  clinic?: Resolver<Maybe<ResolversTypes['Clinic']>, ParentType, ContextType>;
  defaultAutoloadTemplate?: Resolver<
    Maybe<ResolversTypes['ReportTemplate']>,
    ParentType,
    ContextType
  >;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  intercomHash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastViewedWorklists?: Resolver<
    ReadonlyArray<ResolversTypes['WorklistItem']>,
    ParentType,
    ContextType
  >;
  nvoqCredentials?: Resolver<ResolversTypes['NvoqCredentials'], ParentType, ContextType>;
  physicianId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  preferredWorkLocation?: Resolver<
    ResolversTypes['PreferredWorkLocation'],
    ParentType,
    ContextType
  >;
  reporterSettings?: Resolver<ResolversTypes['ReporterSettings'], ParentType, ContextType>;
  roles?: Resolver<ReadonlyArray<ResolversTypes['Role']>, ParentType, ContextType>;
  screenShareEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  securitySettings?: Resolver<ResolversTypes['SecuritySettings'], ParentType, ContextType>;
  sessionTimeoutInMinutes?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  stackInstancesByType?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  stackedModalities?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>;
  toolPreferences?: Resolver<ResolversTypes['ToolPreferences'], ParentType, ContextType>;
  viewerSettings?: Resolver<ResolversTypes['ViewerSettings'], ParentType, ContextType>;
  worklistSettings?: Resolver<ResolversTypes['WorklistSettings'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Users'] = ResolversParentTypes['Users'],
> = {
  items?: Resolver<ReadonlyArray<ResolversTypes['LimitedUser']>, ParentType, ContextType>;
  next?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface Vector2ScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Vector2'], any> {
  name: 'Vector2';
}

export interface Vector3ScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Vector3'], any> {
  name: 'Vector3';
}

export interface Vector4ScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Vector4'], any> {
  name: 'Vector4';
}

export interface Vector6ScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Vector6'], any> {
  name: 'Vector6';
}

export type ViewerSettingsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ViewerSettings'] = ResolversParentTypes['ViewerSettings'],
> = {
  absoluteScroll?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  customDicomTags?: Resolver<
    ReadonlyArray<ResolversTypes['ModalityDicomTags']>,
    ParentType,
    ContextType
  >;
  defaultThumbnailSizePx?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  eraseAllToolScope?: Resolver<Maybe<ResolversTypes['EraseAllToolScope']>, ParentType, ContextType>;
  seriesSkipSettings?: Resolver<
    Maybe<ResolversTypes['SeriesSkipSettings']>,
    ParentType,
    ContextType
  >;
  showManualLocalizerLines?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  virtualMonitorSplit?: Resolver<
    Maybe<ResolversTypes['VirtualMonitorSplit']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ViewportConfigurationResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ViewportConfiguration'] = ResolversParentTypes['ViewportConfiguration'],
> = {
  configuration?: Resolver<ResolversTypes['ViewportConfigurationData'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ViewportConfigurationDataResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ViewportConfigurationData'] = ResolversParentTypes['ViewportConfigurationData'],
> = {
  comparisonIndex?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  series?: Resolver<Maybe<ResolversTypes['DehydratedSeries']>, ParentType, ContextType>;
  seriesCriteria?: Resolver<Maybe<ResolversTypes['SeriesCriteria']>, ParentType, ContextType>;
  seriesDescriptions?: Resolver<ReadonlyArray<ResolversTypes['String']>, ParentType, ContextType>;
  seriesId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  stack?: Resolver<Maybe<ResolversTypes['DehydratedStack']>, ParentType, ContextType>;
  study?: Resolver<Maybe<ResolversTypes['DehydratedStudy']>, ParentType, ContextType>;
  viewType?: Resolver<Maybe<ResolversTypes['ViewType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VirtualMonitorSplitResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['VirtualMonitorSplitResponse'] = ResolversParentTypes['VirtualMonitorSplitResponse'],
> = {
  virtualMonitorSplit?: Resolver<
    Maybe<ResolversTypes['VirtualMonitorSplit']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VocabEntryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VocabEntry'] = ResolversParentTypes['VocabEntry'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  phrase?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface VoiLutDescriptorScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['VoiLutDescriptor'], any> {
  name: 'VoiLutDescriptor';
}

export type VoiLutModuleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VoiLutModule'] = ResolversParentTypes['VoiLutModule'],
> = {
  voiLutFunction?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  voiLutSequence?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['VoiLutSequence']>>>,
    ParentType,
    ContextType
  >;
  windowCenter?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['String']>>>,
    ParentType,
    ContextType
  >;
  windowCenterWidthExplanation?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['String']>>>,
    ParentType,
    ContextType
  >;
  windowWidth?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['String']>>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VoiLutSequenceResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['VoiLutSequence'] = ResolversParentTypes['VoiLutSequence'],
> = {
  lutData?: Resolver<ReadonlyArray<ResolversTypes['Int']>, ParentType, ContextType>;
  lutDescriptor?: Resolver<ResolversTypes['VoiLutDescriptor'], ParentType, ContextType>;
  lutExplanation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WheelPreferencesResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['WheelPreferences'] = ResolversParentTypes['WheelPreferences'],
> = {
  toolIds?: Resolver<ReadonlyArray<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WidgetConfigurationResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['WidgetConfiguration'] = ResolversParentTypes['WidgetConfiguration'],
> = {
  data?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  dismissActions?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['WidgetDismissAction']>>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  placement?: Resolver<Maybe<ResolversTypes['WidgetPlacement']>, ParentType, ContextType>;
  position?: Resolver<Maybe<ResolversTypes['ExtendedCoordinates']>, ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['WidgetType'], ParentType, ContextType>;
  urlPath?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WindowResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Window'] = ResolversParentTypes['Window'],
> = {
  height?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  left?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  screen?: Resolver<ResolversTypes['ScreenName'], ParentType, ContextType>;
  state?: Resolver<ResolversTypes['WindowState'], ParentType, ContextType>;
  top?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['WindowType'], ParentType, ContextType>;
  width?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorklistAutoLoadResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['WorklistAutoLoad'] = ResolversParentTypes['WorklistAutoLoad'],
> = {
  surface?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorklistColumnResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['WorklistColumn'] = ResolversParentTypes['WorklistColumn'],
> = {
  enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['WorklistColumnID'], ParentType, ContextType>;
  width?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorklistColumnsBySurfaceResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['WorklistColumnsBySurface'] = ResolversParentTypes['WorklistColumnsBySurface'],
> = {
  columns?: Resolver<ReadonlyArray<ResolversTypes['WorklistColumn']>, ParentType, ContextType>;
  surface?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorklistCustomStatusResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['WorklistCustomStatus'] = ResolversParentTypes['WorklistCustomStatus'],
> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  smid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorklistFilterResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['WorklistFilter'] = ResolversParentTypes['WorklistFilter'],
> = {
  key?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['WorklistFilterType'], ParentType, ContextType>;
  values?: Resolver<ReadonlyArray<ResolversTypes['WorklistFilterValue']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorklistFilterDropdownItemResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['WorklistFilterDropdownItem'] = ResolversParentTypes['WorklistFilterDropdownItem'],
> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorklistFilterDropdownItemsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['WorklistFilterDropdownItems'] = ResolversParentTypes['WorklistFilterDropdownItems'],
> = {
  items?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['WorklistFilterDropdownItem']>>,
    ParentType,
    ContextType
  >;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorklistFilterValueResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['WorklistFilterValue'] = ResolversParentTypes['WorklistFilterValue'],
> = {
  key?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['StringOrBooleanOrDate'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorklistItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['WorklistItem'] = ResolversParentTypes['WorklistItem'],
> = {
  accessionNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  accessionNumberPartial?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  allAutoloadedPriors?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['DehydratedStudy']>>,
    ParentType,
    ContextType
  >;
  autoloadedPriors?: Resolver<
    ReadonlyArray<ResolversTypes['Study']>,
    ParentType,
    ContextType,
    Partial<WorklistItemAutoloadedPriorsArgs>
  >;
  bodyPart?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  claimExpiration?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  claimedBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  created?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  customMergeFields?: Resolver<
    ReadonlyArray<ResolversTypes['CustomMergeField']>,
    ParentType,
    ContextType
  >;
  customStatus?: Resolver<Maybe<ResolversTypes['WorklistCustomStatus']>, ParentType, ContextType>;
  date?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dicomInstanceCount?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dosage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  examCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  firstVerifiedStatusDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  groupId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  hasDraftReport?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasQcComments?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isStat?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  modality?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mrn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mrnPartial?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  patientAge?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  patientDob?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  patientName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  patientSex?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  patientType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  priority?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  procedure?: Resolver<Maybe<ResolversTypes['Procedure']>, ParentType, ContextType>;
  referringPhysician?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  referringPhysicianFirstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  referringPhysicianLastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  referringPhysicianMiddleName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  report?: Resolver<Maybe<ResolversTypes['Report']>, ParentType, ContextType>;
  site?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  smid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['WorklistItemStatus'], ParentType, ContextType>;
  studies?: Resolver<ReadonlyArray<ResolversTypes['Study']>, ParentType, ContextType>;
  studyDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  studyDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  studyReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  submittedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  summary?: Resolver<Maybe<ResolversTypes['WorklistItemSummary']>, ParentType, ContextType>;
  techNotes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorklistItemAuditLogItemResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['WorklistItemAuditLogItem'] = ResolversParentTypes['WorklistItemAuditLogItem'],
> = {
  actor?: Resolver<Maybe<ResolversTypes['WorklistItemAuditLogUser']>, ParentType, ContextType>;
  claimedBy?: Resolver<Maybe<ResolversTypes['WorklistItemAuditLogUser']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  customStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  groupId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorklistItemAuditLogUserResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['WorklistItemAuditLogUser'] = ResolversParentTypes['WorklistItemAuditLogUser'],
> = {
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorklistItemSummaryResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['WorklistItemSummary'] = ResolversParentTypes['WorklistItemSummary'],
> = {
  patientHistory?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  relevantFindings?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorklistItemsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['WorklistItems'] = ResolversParentTypes['WorklistItems'],
> = {
  cursor?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  filteredCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  items?: Resolver<ReadonlyArray<ResolversTypes['WorklistItem']>, ParentType, ContextType>;
  next?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  previous?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorklistSettingsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['WorklistSettings'] = ResolversParentTypes['WorklistSettings'],
> = {
  columns?: Resolver<ReadonlyArray<ResolversTypes['WorklistColumn']>, ParentType, ContextType>;
  columnsBySurface?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['WorklistColumnsBySurface']>>,
    ParentType,
    ContextType
  >;
  defaultCustomWorklistsSettings?: Resolver<
    Maybe<ResolversTypes['DefaultCustomWorklistsSetting']>,
    ParentType,
    ContextType
  >;
  favoritedWorklistViews?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['ID']>>,
    ParentType,
    ContextType
  >;
  isPatientJacketDocked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lastViewedCustomWorklists?: Resolver<
    Maybe<ReadonlyArray<Maybe<ResolversTypes['DefaultCustomWorklist']>>>,
    ParentType,
    ContextType
  >;
  openPatientJacketOnRead?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  patientJacketLayout?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  savedSortingPreferences?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['SavedSortingPreference']>>,
    ParentType,
    ContextType
  >;
  savedWorklistViews?: Resolver<
    Maybe<ReadonlyArray<ResolversTypes['SavedWorklistView']>>,
    ParentType,
    ContextType
  >;
  settingsVersion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  worklistAutoLoad?: Resolver<Maybe<ResolversTypes['WorklistAutoLoad']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorklistStudiesResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['WorklistStudies'] = ResolversParentTypes['WorklistStudies'],
> = {
  cursor?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  items?: Resolver<ReadonlyArray<ResolversTypes['WorklistStudy']>, ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorklistStudyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['WorklistStudy'] = ResolversParentTypes['WorklistStudy'],
> = {
  primary?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  study?: Resolver<ResolversTypes['Study'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorklistViewResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['WorklistView'] = ResolversParentTypes['WorklistView'],
> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  smid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorklistViewDetailsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['WorklistViewDetails'] = ResolversParentTypes['WorklistViewDetails'],
> = {
  filters?: Resolver<ReadonlyArray<ResolversTypes['WorklistViewFilters']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  smid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorklistViewFilterResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['WorklistViewFilter'] = ResolversParentTypes['WorklistViewFilter'],
> = {
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorklistViewFiltersResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['WorklistViewFilters'] = ResolversParentTypes['WorklistViewFilters'],
> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  options?: Resolver<ReadonlyArray<ResolversTypes['WorklistViewFilter']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkspacePreferencesResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['WorkspacePreferences'] = ResolversParentTypes['WorkspacePreferences'],
> = {
  autoApply?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  presets?: Resolver<ReadonlyArray<ResolversTypes['WorkspacePreset']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorkspacePresetResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['WorkspacePreset'] = ResolversParentTypes['WorkspacePreset'],
> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  instanceId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  smid?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  windows?: Resolver<ReadonlyArray<ResolversTypes['Window']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WorldCoordinatesResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['WorldCoordinates'] = ResolversParentTypes['WorldCoordinates'],
> = {
  worldSpace?: Resolver<ResolversTypes['Vector3'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WwcPresetDeleteResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['WwcPresetDeleteResponse'] = ResolversParentTypes['WwcPresetDeleteResponse'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WwwcPresetResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['WwwcPreset'] = ResolversParentTypes['WwwcPreset'],
> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  level?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shortcut?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  width?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WwwcToolPreferencesResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['WwwcToolPreferences'] = ResolversParentTypes['WwwcToolPreferences'],
> = {
  invertDragDirection?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  presets?: Resolver<ReadonlyArray<ResolversTypes['WwwcPreset']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  AIModeSettings?: AiModeSettingsResolvers<ContextType>;
  AbsoluteScrollingResponse?: AbsoluteScrollingResponseResolvers<ContextType>;
  AcceptAiAnnotationResponse?: AcceptAiAnnotationResponseResolvers<ContextType>;
  Addendum?: AddendumResolvers<ContextType>;
  AddendumContent?: AddendumContentResolvers<ContextType>;
  AddendumSironaSlate?: AddendumSironaSlateResolvers<ContextType>;
  AddendumTextBlob?: AddendumTextBlobResolvers<ContextType>;
  AiAnnotation?: AiAnnotationResolvers<ContextType>;
  AiUgcAnnotation?: AiUgcAnnotationResolvers<ContextType>;
  AnatomicNavigatorResponse?: AnatomicNavigatorResponseResolvers<ContextType>;
  Annotation?: AnnotationResolvers<ContextType>;
  AnnotationEditableOptions?: AnnotationEditableOptionsResolvers<ContextType>;
  AnnotationLogResponse?: AnnotationLogResponseResolvers<ContextType>;
  AnnotationSegment?: AnnotationSegmentResolvers<ContextType>;
  AnnotationStyle?: AnnotationStyleResolvers<ContextType>;
  AssignDefaultAutoloadTemplatePayload?: AssignDefaultAutoloadTemplatePayloadResolvers<ContextType>;
  AssignExamsToUserResponse?: AssignExamsToUserResponseResolvers<ContextType>;
  AuthenticateResponse?: AuthenticateResponseResolvers<ContextType>;
  AutoCorrectEntry?: AutoCorrectEntryResolvers<ContextType>;
  AutoFillComparison?: AutoFillComparisonResolvers<ContextType>;
  BarPreferences?: BarPreferencesResolvers<ContextType>;
  BaseStack?: BaseStackResolvers<ContextType>;
  BatchClaimResult?: BatchClaimResultResolvers<ContextType>;
  BatchUnclaimResult?: BatchUnclaimResultResolvers<ContextType>;
  BodyPart?: BodyPartResolvers<ContextType>;
  CadAnnotation?: CadAnnotationResolvers<ContextType>;
  CineModule?: CineModuleResolvers<ContextType>;
  ClassificationItem?: ClassificationItemResolvers<ContextType>;
  ClassificationSummary?: ClassificationSummaryResolvers<ContextType>;
  Classifications?: ClassificationsResolvers<ContextType>;
  Clinic?: ClinicResolvers<ContextType>;
  ConfirmDeleteWorkspacePreset?: ConfirmDeleteWorkspacePresetResolvers<ContextType>;
  ConfirmResponse?: ConfirmResponseResolvers<ContextType>;
  ContentSequence?: GraphQLScalarType;
  Counter?: CounterResolvers<ContextType>;
  CreateAutoCorrectEntryResponse?: CreateAutoCorrectEntryResponseResolvers<ContextType>;
  CreateVocabEntryResponse?: CreateVocabEntryResponseResolvers<ContextType>;
  CursorStyle?: CursorStyleResolvers<ContextType>;
  CustomAnnotationLogic?: CustomAnnotationLogicResolvers<ContextType>;
  CustomDicomTagsResponse?: CustomDicomTagsResponseResolvers<ContextType>;
  CustomMergeField?: CustomMergeFieldResolvers<ContextType>;
  Date?: GraphQLScalarType;
  DateTime?: GraphQLScalarType;
  DateTimeLocal?: GraphQLScalarType;
  DefaultCustomWorklist?: DefaultCustomWorklistResolvers<ContextType>;
  DefaultCustomWorklistsSetting?: DefaultCustomWorklistsSettingResolvers<ContextType>;
  DefaultThumbnailSizeResponse?: DefaultThumbnailSizeResponseResolvers<ContextType>;
  DehydratedSeries?: DehydratedSeriesResolvers<ContextType>;
  DehydratedStack?: DehydratedStackResolvers<ContextType>;
  DehydratedStudy?: DehydratedStudyResolvers<ContextType>;
  DeleteAnnotationsItem?: DeleteAnnotationsItemResolvers<ContextType>;
  DeleteAnnotationsResponse?: DeleteAnnotationsResponseResolvers<ContextType>;
  DeleteAutoCorrectEntryResponse?: DeleteAutoCorrectEntryResponseResolvers<ContextType>;
  DeleteMacroResponse?: DeleteMacroResponseResolvers<ContextType>;
  DeleteTemplateResponse?: DeleteTemplateResponseResolvers<ContextType>;
  DeleteVocabEntryResponse?: DeleteVocabEntryResponseResolvers<ContextType>;
  DicomInstance?: DicomInstanceResolvers<ContextType>;
  DicomTag?: DicomTagResolvers<ContextType>;
  DicomTagRenderInfo?: DicomTagRenderInfoResolvers<ContextType>;
  DicomTagValue?: DicomTagValueResolvers<ContextType>;
  DictaphoneKey?: DictaphoneKeyResolvers<ContextType>;
  DictaphonePreferences?: DictaphonePreferencesResolvers<ContextType>;
  EraseAllToolScopeResponse?: EraseAllToolScopeResponseResolvers<ContextType>;
  ExtendedCoordinates?: ExtendedCoordinatesResolvers<ContextType>;
  FailedAssignExamSmid?: FailedAssignExamSmidResolvers<ContextType>;
  FastScrollPreferences?: FastScrollPreferencesResolvers<ContextType>;
  FineScrollPreferences?: FineScrollPreferencesResolvers<ContextType>;
  FocusMode?: FocusModeResolvers<ContextType>;
  Frame?: FrameResolvers<ContextType>;
  FrameDirection?: FrameDirectionResolvers<ContextType>;
  FrameScalar?: GraphQLScalarType;
  FrameStack?: FrameStackResolvers<ContextType>;
  GeneralImageModule?: GeneralImageModuleResolvers<ContextType>;
  GeneralReferenceModule?: GeneralReferenceModuleResolvers<ContextType>;
  GenerateImpressionResponse?: GenerateImpressionResponseResolvers<ContextType>;
  GeneratePriorsSummaryResponse?: GeneratePriorsSummaryResponseResolvers<ContextType>;
  GspsAnnotation?: GspsAnnotationResolvers<ContextType>;
  GspsOwner?: GspsOwnerResolvers<ContextType>;
  GspsPresentationState?: GspsPresentationStateResolvers<ContextType>;
  GspsPresentationStateError?: GspsPresentationStateErrorResolvers<ContextType>;
  GspsPresentationStateResponse?: GspsPresentationStateResponseResolvers<ContextType>;
  HangingProtocol?: HangingProtocolResolvers<ContextType>;
  HangingProtocolData?: HangingProtocolDataResolvers<ContextType>;
  HangingProtocolDeleted?: HangingProtocolDeletedResolvers<ContextType>;
  HangingProtocolLayout?: HangingProtocolLayoutResolvers<ContextType>;
  HangingProtocols?: HangingProtocolsResolvers<ContextType>;
  HeadingKeywords?: HeadingKeywordsResolvers<ContextType>;
  HeadingStyle?: HeadingStyleResolvers<ContextType>;
  ImageParams?: ImageParamsResolvers<ContextType>;
  ImageRegistration?: ImageRegistrationResolvers<ContextType>;
  ImpressionGenerator?: ImpressionGeneratorResolvers<ContextType>;
  IndexCoordinates?: IndexCoordinatesResolvers<ContextType>;
  JSON?: GraphQLScalarType;
  JSONObject?: GraphQLScalarType;
  KeyboardPreferences?: KeyboardPreferencesResolvers<ContextType>;
  Layer?: LayerResolvers<ContextType>;
  LayeredStack?: LayeredStackResolvers<ContextType>;
  LimitedUser?: LimitedUserResolvers<ContextType>;
  LineIndicator?: LineIndicatorResolvers<ContextType>;
  LinkAuthenticatorResponse?: LinkAuthenticatorResponseResolvers<ContextType>;
  List?: ListResolvers<ContextType>;
  MFARequired?: MfaRequiredResolvers<ContextType>;
  Macro?: MacroResolvers<ContextType>;
  Macros?: MacrosResolvers<ContextType>;
  ManualLocalizerLinesResponse?: ManualLocalizerLinesResponseResolvers<ContextType>;
  Matrix3x3?: GraphQLScalarType;
  MergeFieldsSettings?: MergeFieldsSettingsResolvers<ContextType>;
  MipPreferences?: MipPreferencesResolvers<ContextType>;
  MirrorViewerLayoutsPreferences?: MirrorViewerLayoutsPreferencesResolvers<ContextType>;
  Modality?: ModalityResolvers<ContextType>;
  ModalityDicomTags?: ModalityDicomTagsResolvers<ContextType>;
  ModalityLutDescriptor?: GraphQLScalarType;
  ModalityLutModule?: ModalityLutModuleResolvers<ContextType>;
  ModalityLutSequence?: ModalityLutSequenceResolvers<ContextType>;
  Modules?: ModulesResolvers<ContextType>;
  MousePreferences?: MousePreferencesResolvers<ContextType>;
  MultiFactorAuth?: MultiFactorAuthResolvers<ContextType>;
  MultiFactorAuthResponse?: MultiFactorAuthResponseResolvers<ContextType>;
  MultiFactorLinkResponse?: MultiFactorLinkResponseResolvers<ContextType>;
  MultiFrameModule?: MultiFrameModuleResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  NvoqCredentials?: NvoqCredentialsResolvers<ContextType>;
  Owner?: OwnerResolvers<ContextType>;
  PatientOrientation?: PatientOrientationResolvers<ContextType>;
  PatientStudyModule?: PatientStudyModuleResolvers<ContextType>;
  Permission?: PermissionResolvers<ContextType>;
  PetImageModule?: PetImageModuleResolvers<ContextType>;
  PixelURL?: PixelUrlResolvers<ContextType>;
  PredictTemplateResponse?: PredictTemplateResponseResolvers<ContextType>;
  PreferredWorkLocation?: PreferredWorkLocationResolvers<ContextType>;
  PresentationState?: PresentationStateResolvers<ContextType>;
  Priority?: PriorityResolvers<ContextType>;
  Procedure?: ProcedureResolvers<ContextType>;
  ProcedureReference?: ProcedureReferenceResolvers<ContextType>;
  Procedures?: ProceduresResolvers<ContextType>;
  ProvenanceData?: ProvenanceDataResolvers<ContextType>;
  QualityControlComment?: QualityControlCommentResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  RadioPharmaceuticalInformation?: RadioPharmaceuticalInformationResolvers<ContextType>;
  ReferencedImage?: ReferencedImageResolvers<ContextType>;
  Region?: RegionResolvers<ContextType>;
  RegionCalibrationModule?: RegionCalibrationModuleResolvers<ContextType>;
  RegionFlags?: RegionFlagsResolvers<ContextType>;
  RegularScrollPreferences?: RegularScrollPreferencesResolvers<ContextType>;
  RejectAiAnnotationLog?: RejectAiAnnotationLogResolvers<ContextType>;
  Report?: ReportResolvers<ContextType>;
  ReportPicklist?: ReportPicklistResolvers<ContextType>;
  ReportPicklistOption?: ReportPicklistOptionResolvers<ContextType>;
  ReportTemplate?: ReportTemplateResolvers<ContextType>;
  ReportTemplates?: ReportTemplatesResolvers<ContextType>;
  ReporterSettings?: ReporterSettingsResolvers<ContextType>;
  ResetToolOnScrollPreferences?: ResetToolOnScrollPreferencesResolvers<ContextType>;
  Response?: ResponseResolvers<ContextType>;
  RhinoFeedbackResponse?: RhinoFeedbackResponseResolvers<ContextType>;
  RhinoMedCheckResponse?: RhinoMedCheckResponseResolvers<ContextType>;
  RhinoModel?: RhinoModelResolvers<ContextType>;
  RhinoModelConfig?: RhinoModelConfigResolvers<ContextType>;
  RhinoPrompt?: RhinoPromptResolvers<ContextType>;
  RhinoQueryMessage?: RhinoQueryMessageResolvers<ContextType>;
  RhinoQueryResponse?: RhinoQueryResponseResolvers<ContextType>;
  RhinoRawAPIResponse?: RhinoRawApiResponseResolvers<ContextType>;
  RhinoRawAPIResponseChoice?: RhinoRawApiResponseChoiceResolvers<ContextType>;
  RhinoRawAPIResponseUsage?: RhinoRawApiResponseUsageResolvers<ContextType>;
  Role?: RoleResolvers<ContextType>;
  SRGeneralInfo?: SrGeneralInfoResolvers<ContextType>;
  SRInstance?: SrInstanceResolvers<ContextType>;
  SRObservation?: SrObservationResolvers<ContextType>;
  SRPatient?: SrPatientResolvers<ContextType>;
  SRSOPClass?: SrsopClassResolvers<ContextType>;
  SRSeries?: SrSeriesResolvers<ContextType>;
  SRStudy?: SrStudyResolvers<ContextType>;
  SavedSortingPreference?: SavedSortingPreferenceResolvers<ContextType>;
  SavedWorklistView?: SavedWorklistViewResolvers<ContextType>;
  Scope?: ScopeResolvers<ContextType>;
  ScreenShareSettingResponse?: ScreenShareSettingResponseResolvers<ContextType>;
  SecuritySettings?: SecuritySettingsResolvers<ContextType>;
  Series?: SeriesResolvers<ContextType>;
  SeriesClassification?: SeriesClassificationResolvers<ContextType>;
  SeriesCriteria?: SeriesCriteriaResolvers<ContextType>;
  SeriesSkipSettings?: SeriesSkipSettingsResolvers<ContextType>;
  SeriesSkipSettingsResponse?: SeriesSkipSettingsResponseResolvers<ContextType>;
  SetWwwcDragDirectionResponse?: SetWwwcDragDirectionResponseResolvers<ContextType>;
  Shortcut?: ShortcutResolvers<ContextType>;
  ShowIconLabelPreferences?: ShowIconLabelPreferencesResolvers<ContextType>;
  SignedUrl?: SignedUrlResolvers<ContextType>;
  SingleLayerStack?: SingleLayerStackResolvers<ContextType>;
  SironaCustomLLMResponseMessage?: SironaCustomLlmResponseMessageResolvers<ContextType>;
  SironaCustomLLMResponseModel?: SironaCustomLlmResponseModelResolvers<ContextType>;
  SironaCustomLLMResponseUsage?: SironaCustomLlmResponseUsageResolvers<ContextType>;
  SlimUser?: SlimUserResolvers<ContextType>;
  SourceImage?: SourceImageResolvers<ContextType>;
  StackedScrollingModalitiesResponse?: StackedScrollingModalitiesResponseResolvers<ContextType>;
  StackedScrollingResponse?: StackedScrollingResponseResolvers<ContextType>;
  StringOrBooleanOrDate?: GraphQLScalarType;
  StructuredReport?: StructuredReportResolvers<ContextType>;
  Study?: StudyResolvers<ContextType>;
  StudyClassificationSummary?: StudyClassificationSummaryResolvers<ContextType>;
  StudyDescription?: StudyDescriptionResolvers<ContextType>;
  StudyDescriptions?: StudyDescriptionsResolvers<ContextType>;
  StudyItems?: StudyItemsResolvers<ContextType>;
  Styles?: StylesResolvers<ContextType>;
  SubmitReportInvalidInput?: SubmitReportInvalidInputResolvers<ContextType>;
  SubmitReportPayload?: SubmitReportPayloadResolvers<ContextType>;
  SubmitReportProblem?: SubmitReportProblemResolvers<ContextType>;
  SubmitReportResult?: SubmitReportResultResolvers<ContextType>;
  Subscription?: SubscriptionResolvers<ContextType>;
  SupportedRenderers?: SupportedRenderersResolvers<ContextType>;
  TemplatePrediction?: GraphQLScalarType;
  TextDecoration?: TextDecorationResolvers<ContextType>;
  TextStyle?: TextStyleResolvers<ContextType>;
  Tool?: ToolResolvers<ContextType>;
  ToolAnnotationInteraction?: ToolAnnotationInteractionResolvers<ContextType>;
  ToolContextMenuOptionInteraction?: ToolContextMenuOptionInteractionResolvers<ContextType>;
  ToolIconButtonClickInteraction?: ToolIconButtonClickInteractionResolvers<ContextType>;
  ToolInteraction?: ToolInteractionResolvers<ContextType>;
  ToolInteractionAddInteractionResponse?: ToolInteractionAddInteractionResponseResolvers<ContextType>;
  ToolInteractionAnnotationData?: ToolInteractionAnnotationDataResolvers<ContextType>;
  ToolInteractionAnnotationResponse?: ToolInteractionAnnotationResponseResolvers<ContextType>;
  ToolInteractionInsertTextResponse?: ToolInteractionInsertTextResponseResolvers<ContextType>;
  ToolInteractionKeyboardShortcutResponse?: ToolInteractionKeyboardShortcutResponseResolvers<ContextType>;
  ToolInteractionMessageResponse?: ToolInteractionMessageResponseResolvers<ContextType>;
  ToolInteractionRequest?: ToolInteractionRequestResolvers<ContextType>;
  ToolInteractionResponse?: ToolInteractionResponseResolvers<ContextType>;
  ToolInteractionResponseUnion?: ToolInteractionResponseUnionResolvers<ContextType>;
  ToolInteractionStartAnnotationResponse?: ToolInteractionStartAnnotationResponseResolvers<ContextType>;
  ToolInteractionUnion?: ToolInteractionUnionResolvers<ContextType>;
  ToolInteractionUpdateToolActiveStateResponse?: ToolInteractionUpdateToolActiveStateResponseResolvers<ContextType>;
  ToolInteractionUpdateToolbarButtonIconResponse?: ToolInteractionUpdateToolbarButtonIconResponseResolvers<ContextType>;
  ToolInteractionUpdateViewportPresentationStateResponse?: ToolInteractionUpdateViewportPresentationStateResponseResolvers<ContextType>;
  ToolInteractionUpdateViewportSliceResponse?: ToolInteractionUpdateViewportSliceResponseResolvers<ContextType>;
  ToolInteractionWidgetResponse?: ToolInteractionWidgetResponseResolvers<ContextType>;
  ToolKeyboardShortcutInteraction?: ToolKeyboardShortcutInteractionResolvers<ContextType>;
  ToolMergeFieldInteraction?: ToolMergeFieldInteractionResolvers<ContextType>;
  ToolPreferences?: ToolPreferencesResolvers<ContextType>;
  ToolViewportClickInteraction?: ToolViewportClickInteractionResolvers<ContextType>;
  ToolVoiceCommandInteraction?: ToolVoiceCommandInteractionResolvers<ContextType>;
  TransformMatrix?: GraphQLScalarType;
  UgcAnnotation?: UgcAnnotationResolvers<ContextType>;
  UpdateAutoCorrectEntryResponse?: UpdateAutoCorrectEntryResponseResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  Users?: UsersResolvers<ContextType>;
  Vector2?: GraphQLScalarType;
  Vector3?: GraphQLScalarType;
  Vector4?: GraphQLScalarType;
  Vector6?: GraphQLScalarType;
  ViewerSettings?: ViewerSettingsResolvers<ContextType>;
  ViewportConfiguration?: ViewportConfigurationResolvers<ContextType>;
  ViewportConfigurationData?: ViewportConfigurationDataResolvers<ContextType>;
  VirtualMonitorSplitResponse?: VirtualMonitorSplitResponseResolvers<ContextType>;
  VocabEntry?: VocabEntryResolvers<ContextType>;
  VoiLutDescriptor?: GraphQLScalarType;
  VoiLutModule?: VoiLutModuleResolvers<ContextType>;
  VoiLutSequence?: VoiLutSequenceResolvers<ContextType>;
  WheelPreferences?: WheelPreferencesResolvers<ContextType>;
  WidgetConfiguration?: WidgetConfigurationResolvers<ContextType>;
  Window?: WindowResolvers<ContextType>;
  WorklistAutoLoad?: WorklistAutoLoadResolvers<ContextType>;
  WorklistColumn?: WorklistColumnResolvers<ContextType>;
  WorklistColumnsBySurface?: WorklistColumnsBySurfaceResolvers<ContextType>;
  WorklistCustomStatus?: WorklistCustomStatusResolvers<ContextType>;
  WorklistFilter?: WorklistFilterResolvers<ContextType>;
  WorklistFilterDropdownItem?: WorklistFilterDropdownItemResolvers<ContextType>;
  WorklistFilterDropdownItems?: WorklistFilterDropdownItemsResolvers<ContextType>;
  WorklistFilterValue?: WorklistFilterValueResolvers<ContextType>;
  WorklistItem?: WorklistItemResolvers<ContextType>;
  WorklistItemAuditLogItem?: WorklistItemAuditLogItemResolvers<ContextType>;
  WorklistItemAuditLogUser?: WorklistItemAuditLogUserResolvers<ContextType>;
  WorklistItemSummary?: WorklistItemSummaryResolvers<ContextType>;
  WorklistItems?: WorklistItemsResolvers<ContextType>;
  WorklistSettings?: WorklistSettingsResolvers<ContextType>;
  WorklistStudies?: WorklistStudiesResolvers<ContextType>;
  WorklistStudy?: WorklistStudyResolvers<ContextType>;
  WorklistView?: WorklistViewResolvers<ContextType>;
  WorklistViewDetails?: WorklistViewDetailsResolvers<ContextType>;
  WorklistViewFilter?: WorklistViewFilterResolvers<ContextType>;
  WorklistViewFilters?: WorklistViewFiltersResolvers<ContextType>;
  WorkspacePreferences?: WorkspacePreferencesResolvers<ContextType>;
  WorkspacePreset?: WorkspacePresetResolvers<ContextType>;
  WorldCoordinates?: WorldCoordinatesResolvers<ContextType>;
  WwcPresetDeleteResponse?: WwcPresetDeleteResponseResolvers<ContextType>;
  WwwcPreset?: WwwcPresetResolvers<ContextType>;
  WwwcToolPreferences?: WwwcToolPreferencesResolvers<ContextType>;
};

export type DirectiveResolvers<ContextType = any> = {
  auth?: AuthDirectiveResolver<any, any, ContextType>;
};
