import { atom, useRecoilCallback, useRecoilValue } from 'recoil';
import {
  broadcastChannelSynchronizerEffect,
  localStoragePersisterEffect,
} from 'utils/recoilEffects';
import { validateAll } from './validators';

import type { RecoilState } from 'recoil';
import type { SysChkValidatorTypes, SystemCheckValidatorsMap } from './validators';

export type ValidationResult = {
  id: SysChkValidatorTypes;
  result: boolean;
  reason: string | null | undefined;
  metadata?: any;
};

export type SystemCheckPreferenceState = {
  shouldBypassSystemCheck: boolean;
};

export const systemCheckPreferenceState: RecoilState<SystemCheckPreferenceState> = atom({
  key: 'systemCheckPreferenceState',
  default: {
    shouldBypassSystemCheck: false,
  },
  effects: [localStoragePersisterEffect(), broadcastChannelSynchronizerEffect()],
});

export type SystemCheckState = {
  isSystemValid: boolean;
  validationResults: ValidationResult[];
  /** Flag that controls the display of the system check screen */
  displaySystemCheckModal: boolean;
  /** Timestamp of when the last system check was executed */
  lastCheckTimestamp: number;
};

/** Merge function to always accept only the most recent system check results */
const systemCheckMergeFn = (
  oldValue: SystemCheckState | null | undefined,
  newValue: SystemCheckState
): SystemCheckState => {
  if (oldValue == null) {
    return newValue;
  } else if (newValue.lastCheckTimestamp >= oldValue?.lastCheckTimestamp) {
    return newValue;
  }
  return oldValue;
};

export const systemCheckState: RecoilState<SystemCheckState> = atom({
  key: 'systemCheckState',
  default: {
    isSystemValid: false,
    validationResults: [],
    displaySystemCheckModal: false,
    lastCheckTimestamp: 0,
  },
  effects: [broadcastChannelSynchronizerEffect({ mergeFn: systemCheckMergeFn })],
});

/** Hook to initiate system checks and for returning the current state of validations */
export const useSystemCheck = (
  systemCheckValidators: SystemCheckValidatorsMap
): {
  /** Runs all enabled validators to produce a single result */
  validate: () => {
    isSystemValid: boolean;
  };
  /** Resets the validation results */
  reset: () => void;
  setBypassSystemCheck: (shouldBypass: boolean) => void;
  isSystemValid: boolean;
  shouldBypassSystemCheck: boolean;
  validationResults: ValidationResult[];
} => {
  const { isSystemValid, validationResults } = useRecoilValue(systemCheckState);
  const { shouldBypassSystemCheck } = useRecoilValue(systemCheckPreferenceState);

  const setBypassSystemCheck = useRecoilCallback(
    ({ set }) =>
      (shouldBypassSystemCheck: boolean) => {
        set(systemCheckPreferenceState, (prevState) => ({ ...prevState, shouldBypassSystemCheck }));
      }
  );

  const validate = useRecoilCallback<
    [],
    {
      isSystemValid: boolean;
    }
  >(({ set }) => () => {
    const { result, validators } = validateAll();
    set(systemCheckState, (state) => ({
      ...state,
      isSystemValid: result,
      validationResults: validators,
      lastCheckTimestamp: Date.now(),
    }));

    return { isSystemValid: result };
  });

  const reset = useRecoilCallback(({ set }) => () => {
    set(systemCheckState, (state) => ({
      ...state,
      isSystemValid: false,
      validationResults: [],
    }));
  });

  return {
    validate,
    setBypassSystemCheck,
    isSystemValid,
    shouldBypassSystemCheck,
    validationResults,
    reset,
  };
};

/** Hook for managing the visibility of the system check */
export const useDisplaySystemCheck = (): {
  /** Default handler for the System Check component; hides the modal */
  handleOnContinue: () => void;
  shouldShowSystemCheck: boolean;
  showSystemCheckScreen: () => void;
} => {
  const { displaySystemCheckModal } = useRecoilValue(systemCheckState);

  const handleOnContinue = useRecoilCallback(({ set }) => () => {
    set(systemCheckState, (prevState) => ({ ...prevState, displaySystemCheckModal: false }));
  });

  const showSystemCheckScreen = useRecoilCallback(({ set }) => () => {
    set(systemCheckState, (prevState) => ({ ...prevState, displaySystemCheckModal: true }));
  });

  return {
    shouldShowSystemCheck: displaySystemCheckModal,
    handleOnContinue,
    showSystemCheckScreen,
  };
};
