import { nanoid } from 'nanoid';
import type { FullSingleLayerStack } from 'domains/viewer/ViewportsConfigurations/types';
import { broadcastChannelAnalytics } from 'modules/analytics/broadcastChannelAnalytics';
import Deferred from 'utils/deferred';
import type { TransferType } from '../PixelDataLoader';
import { logger } from 'modules/logger';
import { BaseImageLoader } from './BaseImageLoader';
import type {
  FrameDataMessage,
  MessageReceivedCallback,
  OrderedFrame,
  TransferCompleteMessage,
  TransferStartingMessage,
} from './BaseImageLoader';
import type { SupportedTextureTypes, SupportedTexturesMap } from 'utils/textureUtils';
import { mapToOrderedFrame } from 'utils/frameMapper';

export type FrameData = {
  frameSmid: string;
  pixels: SupportedTextureTypes;
  range: [number, number];
  fromCache: boolean;
  volume?: SupportedTextureTypes;
};

type TransferErrorIncomingMessage = {
  event: 'transfer-error';
  requestId: string;
  error: string;
};
type TransferCancelledMessage = {
  event: 'transfer-cancelled';
  requestIds: string[];
};

export type IncomingMessage =
  | TransferStartingMessage
  | TransferErrorIncomingMessage
  | TransferCompleteMessage
  | TransferCancelledMessage
  | FrameDataMessage;

export type FrameMap = Map<string, OrderedFrame>;
export type HandleDataMessageCallback = (data: unknown) => Promise<void>;
type RequestInfo = {
  requestId: string;
  focusIndex: number;
  frameMap: FrameMap;
  messageReceivedCallback: MessageReceivedCallback;
  completeDeferred: Deferred<undefined>;
  framesTransfered?: number;
  framesDownloaded: number;
  priority: number;
};

type RequestMap = Map<string, RequestInfo>;

const MAX_ATTEMPTS = 50;
const INTERVAL_TIMEOUT = 100;

/**
 * A thin wrapper class to establish a secure, authorized WebSocket
 * connection
 */
export class BaseWebSocketImageLoader extends BaseImageLoader {
  /* Browser native {WebSocket} connection */
  ws: null | WebSocket;
  /* JWT used for authentication / authorization for the web socket */
  jwt: string;
  /* Buffered messages to send to server */
  outbox: string[];
  /** Map of request ID -> requests for frame data */
  requestMap: RequestMap;
  #wsUrl: string;
  #wsConnectionPromise: null | Promise<undefined>;

  /**
   * Create an WebSocket connection with the given {wsUrl}
   */
  constructor(wsUrl: string, SUPPORTED_TEXTURES: SupportedTexturesMap) {
    super(SUPPORTED_TEXTURES);
    this.outbox = [];
    this.#wsUrl = wsUrl;
    this.ws = null;
    this.requestMap = new Map<string, RequestInfo>();
  }

  /******************************************************************
   * Public API
   ******************************************************************/

  /**
   * Returns {true} if the WebSocket connection is established and
   * its {readyState} is set to open.
   */
  isWsActive(): boolean {
    return this.ws?.readyState === WebSocket.OPEN;
  }

  /**
   * Returns {true} if the WebSocket connection is either closed or closing.
   */
  isWsClosed(): boolean {
    // @ts-expect-error [EN-7967] - TS2345 - Argument of type 'number' is not assignable to parameter of type '3 | 2'.
    return [WebSocket.CLOSED, WebSocket.CLOSING].includes(this.ws?.readyState);
  }

  updatePriority({
    stack,
    focusFrameIndex,
    stackPriority,
  }: {
    stack: FullSingleLayerStack;
    focusFrameIndex: number;
    stackPriority: number;
  }): void {
    // requests may have frames from multiple stacks
    // such as the request for the initial frames of all hanged stacks
    const matchingRequests = Array.from(this.requestMap.values()).filter((request) =>
      Array.from(request.frameMap.values()).some((frame) => frame.stackSmid === stack.smid)
    );

    matchingRequests.forEach((request) => {
      request.priority = stackPriority;
      request.focusIndex = focusFrameIndex;
      this._sendJson({
        requestId: request.requestId,
        focus: focusFrameIndex,
        priority: stackPriority,
        event: 'update-priority',
      });
    });
  }

  /**
   * Close the connection to the WebSocket.
   */
  close(code?: number, reason?: string) {
    if (this.isWsActive()) {
      this.ws?.close(code, reason);
    }
  }

  #getRequestsForStackSmid(stackSmid?: string): Array<RequestInfo> {
    if (stackSmid == null) {
      return Array.from(this.requestMap.values());
    }
    return Array.from(this.requestMap.values()).filter((request) =>
      Array.from(request.frameMap.values()).some((frame) => frame.stackSmid === stackSmid)
    );
  }

  /**
   * Cancels in-flight requests. If given a stack smid only cancels that id and removes from map.
   *
   */
  cancel(stackSmid?: string) {
    if (this.isWsActive()) {
      const requests = this.#getRequestsForStackSmid(stackSmid);

      this._sendJson({
        event: 'cancel-transfer',
        requestIds: requests.map((r) => r.requestId),
      });
    }
  }

  /******************************************************************
   * Protected API - Call only from this class or extending classes
   ******************************************************************/

  /**
   * If needed, connect to the WebSocket located at the URL provided when creating this object.
   *
   * Attempts to retry up to {MAX_ATTEMPTS} times, waiting a small amount of time between each try.
   *
   * If already connected, this is a noop.
   */
  async _connect(): Promise<void> {
    if (this.#wsConnectionPromise != null) return this.#wsConnectionPromise;

    if (this.ws === null || this.isWsClosed()) {
      this._log(`Creating new WS: ${this.#wsUrl}`);
      const ws = new WebSocket(this.#wsUrl);
      this.ws = ws;

      ws.binaryType = 'arraybuffer';
      ws.onmessage = async (event: MessageEvent) => {
        try {
          const data = event.data;
          if (data instanceof ArrayBuffer) {
            await this._handleDataMessage(data);
          } else if (typeof data === 'string') {
            const message: IncomingMessage = JSON.parse(data);
            this._log(message.event, data);

            switch (message.event) {
              case 'transfer-starting':
                this.#transferStarting(message.requestId);
                break;
              case 'transfer-complete':
                await this._transferComplete(message.requestId, message.framesTransfered);
                break;
              case 'transfer-error':
                this.#transferError(message.requestId, message.error);
                break;
              case 'frame-data':
                await this._handleDataMessage(data);
                break;
              case 'transfer-cancelled':
                this.#transferCancelled(message.requestIds);
                break;
              default:
                throw new Error(`Unexpected WS message received: ${JSON.stringify(message)}`);
            }
          }
        } catch (error: any) {
          // unknown errors, try to parse a request to match them, and float to the viewport error boundary
          let requestId = '';
          try {
            if (typeof event.data === 'string') {
              const message: IncomingMessage = JSON.parse(event.data);
              // @ts-expect-error [EN-7967] - TS2339 - Property 'requestId' does not exist on type 'IncomingMessage'.
              if (message.requestId != null) {
                // @ts-expect-error [EN-7967] - TS2339 - Property 'requestId' does not exist on type 'IncomingMessage'.
                requestId = message.requestId;
              }
            }
          } catch (e: any) {}
          logger.error('WS message error:', error);
          this.#transferError(requestId, error.message);
        }
      };

      ws.onclose = (event: CloseEvent) => {
        if (event.code !== 1000) {
          logger.error(
            `WebSocket closed: Code: ${event.code} Reason: ${
              event.reason.length > 0 ? event.reason : 'No Reason Given.'
            }`
          );
          this.#throwErrorsForAllRequests('early ws termination');
        }
      };

      ws.onerror = (event: any) => {
        logger.error(
          `WebSocket Error: Code: ${event.code} Reason: ${
            event.reason.length > 0 ? event.reason : 'No Reason Given.'
          }`
        );
        this.#throwErrorsForAllRequests('ws error');
      };

      let attempts = 0;

      this.#wsConnectionPromise = new Promise(
        (
          resolve: (result: Promise<undefined> | undefined) => void,
          reject: (error?: any) => void
        ) => {
          const cleanup = () => {
            clearInterval(interval);
            this.#wsConnectionPromise = null;
          };
          const interval = setInterval(() => {
            if (attempts >= MAX_ATTEMPTS) {
              cleanup();
              reject(
                new Error(`Failed to connect to websocket server after ${attempts} attempts.`)
              );

              return;
            } else if (this.ws?.readyState === WebSocket.OPEN) {
              cleanup();
              this.#processOutbox();
              // @ts-expect-error [EN-7967] - TS2794 - Expected 1 arguments, but got 0. Did you forget to include 'void' in your type argument to 'Promise'?
              resolve();

              return;
            }

            attempts++;
          }, INTERVAL_TIMEOUT);
        }
      );

      return this.#wsConnectionPromise;
    }
  }

  /**
   * Given a {stack}, load all the {frames} in the stack via WebSocket, where each
   * individual frame data is delivered according to the specified {transferType}.
   */
  _loadStack({
    stack,
    transferType,
    initialFocus,
    stackPriority,
    isInitialFrame,
    isDropped,
    messageReceivedCallback,
  }: {
    stack: FullSingleLayerStack;
    transferType: TransferType;
    initialFocus: number;
    stackPriority: number;
    isInitialFrame: boolean;
    isDropped: boolean;
    messageReceivedCallback: MessageReceivedCallback;
  }) {
    const orderedFrames = stack.frames.map((frame, index) => {
      return mapToOrderedFrame(frame, index, stack);
    });

    this._loadFrames({
      orderedFrames,
      transferType,
      initialFocus,
      isInitialFrame,
      stackPriority,
      isDropped,
      messageReceivedCallback,
    });
  }

  /**
   * Given a list of frames to load, load them via WebSocket, where each
   * individual frame data is delivered according to the specified {transferType}.
   */
  _loadFrames({
    orderedFrames,
    transferType,
    stackPriority,
    initialFocus,
    isInitialFrame,
    isDropped,
    messageReceivedCallback,
  }: {
    orderedFrames: OrderedFrame[];
    transferType: TransferType;
    stackPriority: number;
    initialFocus: number;
    isInitialFrame: boolean;
    isDropped: boolean;
    messageReceivedCallback: MessageReceivedCallback;
  }) {
    const requestId = nanoid();
    const requestInfo = {
      requestId,
      focusIndex: initialFocus,
      frameMap: new Map<string, OrderedFrame>(),
      messageReceivedCallback,
      completeDeferred: new Deferred(),
      framesDownloaded: 0,
      priority: stackPriority,
    } as const;

    orderedFrames.forEach((frame: OrderedFrame) => {
      requestInfo.frameMap.set(frame.frameSmid, frame);
    });

    // @ts-expect-error [EN-7967] - TS2345 - Argument of type '{ readonly requestId: string; readonly focusIndex: number; readonly frameMap: Map<string, OrderedFrame>; readonly messageReceivedCallback: MessageReceivedCallback; readonly completeDeferred: Deferred<...>; readonly framesDownloaded: 0; readonly priority: number; }' is not assignable to parameter of type 'RequestInfo'.
    this.requestMap.set(requestId, requestInfo);
    this._sendJson({
      requestId,
      transferType,
      focus: initialFocus,
      frames: orderedFrames,
      isInitialFrame,
      isDropped,
      requestPriority: stackPriority,
      event: 'request-transfer',
    });
  }

  /**
   * Implemented by the extending class process a message containing
   * frame data. The exact format of the data is picked by the
   * extending class (DICOM vs pixels)
   */
  _handleDataMessage: HandleDataMessageCallback = async (data) => {
    throw new Error('Not yet implemented');
  };

  _resolveDeferredPromise(requestId: string) {
    const requestInfo = this.requestMap.get(requestId);
    if (requestInfo == null) return;

    this.requestMap.set(requestId, {
      ...requestInfo,
      framesDownloaded: requestInfo.framesDownloaded + 1,
    });

    if (requestInfo.framesDownloaded + 1 === requestInfo.framesTransfered) {
      this._log('#resolveDeferredPromise', requestId);
      // @ts-expect-error [EN-7967] - TS2554 - Expected 1 arguments, but got 0.
      requestInfo.completeDeferred.resolve();
    }
  }

  /**
   * Send a JSON object message to the websocket server.
   * @param message - any value that can be sent through {JSON.stringify}
   */
  _sendJson(message: any): void {
    this._connect();
    const messageString = JSON.stringify({
      ...message,
      analytics: {
        readSession: broadcastChannelAnalytics.readSession,
      },
    });
    if (this.isWsActive()) {
      this.ws?.send(messageString);
    } else {
      this.outbox.push(messageString);
    }
  }

  async _transferComplete(requestId: string, framesTransfered: number) {
    const requestInfo = this.requestMap.get(requestId);
    if (requestInfo == null) return;

    // if the number of frames downloaded matches the number of frames transfered, resolve the completeDeferred
    // so that the rest of the logic below can instantly execute
    if (requestInfo.framesDownloaded === framesTransfered) {
      this._log('#transfer-complete', requestId, 'all frames transfered');
      // @ts-expect-error [EN-7967] - TS2554 - Expected 1 arguments, but got 0.
      requestInfo.completeDeferred.resolve();
    } else {
      this._log(
        '#transfer-complete',
        requestId,
        'some frames still inflight, will wait for them to finish'
      );
      this.requestMap.set(requestId, {
        ...requestInfo,
        framesTransfered,
      });
    }

    await requestInfo.completeDeferred.promise;

    this._log('#transfer-complete', requestId, 'all set');
    requestInfo.messageReceivedCallback({
      requestId,
      event: 'transfer-complete',
      framesTransfered,
      endingPriority: requestInfo.priority,
      endingFocus: requestInfo.focusIndex,
    });

    this.requestMap.delete(requestId);
  }

  /******************************************************************
   * Private API
   ******************************************************************/

  #processOutbox() {
    if (this.isWsActive()) {
      this.outbox.forEach((message) => this.ws?.send(message));
      this.outbox = [];
    }
  }

  #transferStarting(requestId: string) {
    const requestInfo = this.requestMap.get(requestId);
    if (requestInfo == null) return;
    const firstFrameIndex = requestInfo.frameMap.keys().next().value;
    if (firstFrameIndex == null) return;

    const frame = requestInfo.frameMap.get(firstFrameIndex);
    if (frame == null) return;

    requestInfo.messageReceivedCallback({ requestId, event: 'transfer-starting' });
  }

  #transferError(requestId: string, error: string) {
    const requestInfo = this.requestMap.get(requestId);
    logger.error('#transfer-error', requestId, error);
    if (requestInfo != null) {
      const stackSmids = new Set<string>();
      requestInfo.frameMap.forEach((orderedFrame) => {
        stackSmids.add(orderedFrame.stackSmid);
      });
      requestInfo.messageReceivedCallback({
        stacks: Array.from(stackSmids),
        event: 'transfer-error',
        error,
      });
    }

    // TODO - validate no frames for the request remain in the frameMap
    this.requestMap.delete(requestId);
  }

  #transferCancelled(requestIds: string[]) {
    requestIds.forEach((id) => {
      this.requestMap.delete(id);
    });

    if (this.requestMap.size === 0) {
      // send json will reopen a new connection if required
      this.close(1000, 'All transfers cancelled');
    }
  }

  #throwErrorsForAllRequests(errorMessage: string) {
    const stackSmids = new Set<string>();
    let messageReceivedCallback = null;
    for (const requestInfo of this.requestMap.values()) {
      requestInfo.frameMap.forEach((orderedFrame) => {
        stackSmids.add(orderedFrame.stackSmid);
      });
      if (messageReceivedCallback == null) {
        messageReceivedCallback = requestInfo.messageReceivedCallback;
      }
    }
    this.requestMap.clear();
    messageReceivedCallback?.({
      stacks: Array.from(stackSmids),
      event: 'transfer-error',
      error: errorMessage,
    });
  }
}
