import { PIXEL_LOADER_WS_URL, GRAPHQL_URL } from 'config';
import type { FullSingleLayerStack } from 'domains/viewer/ViewportsConfigurations/types';
import Deferred from 'utils/deferred';
import { workerAnalytics } from 'modules/analytics/workerAnalytics';
import { globalContext } from 'modules/analytics/constants';

import { BaseWebSocketImageLoader } from './BaseWebSocketImageLoader';
import type { HandleDataMessageCallback } from './BaseWebSocketImageLoader';
import { env } from 'config/env';
import { PriorityQueue } from '@datastructures-js/priority-queue';
import { fetchWithRetry } from './fetchWithRetry';
import { logger } from '../../logger';
import type { MessageReceivedCallback, OrderedFrame } from './BaseImageLoader';
import { parsePixelData } from './imageLoaderUtils';
import OpenJPHModuleLoader from '@cornerstonejs/codec-openjph';
import type { HTJ2KDecoderType } from '@cornerstonejs/codec-openjph';
import type { SupportedTexturesMap } from 'utils/textureUtils';

export const MAX_PARALLEL_REQUESTS: number =
  env.NODE_ENV === 'test' ? 2 : Number(env.MAX_PARALLEL_PIXEL_WEBSOCKET_CONCURRENCY ?? 40);

type OriginCacheTypes = 'edge-pixel-cache' | 'pixel-cache' | 'no-cache';
type OriginService = 'dcmdata' | 'cloudfront';
type MixedOriginCacheType = 'mixed';

type QueuedFrame = {
  requestId: string;
  url: string;
  includeCredentials: boolean;
  frameSmid: string;
  frameIndex: number;
  deferred: Deferred<ArrayBuffer | null>;
  priority: number;
};

let globalCacheStatus: OriginCacheTypes | MixedOriginCacheType | null = null;
let globalCacheService: OriginService | MixedOriginCacheType | null = null;

export function trackCacheStatus(url: string, response: Response) {
  let type: OriginCacheTypes | MixedOriginCacheType;
  let serviceType: OriginService | MixedOriginCacheType;

  if (response.headers.get('X-Cache')?.includes('Hit')) {
    type = 'edge-pixel-cache';
    serviceType = 'cloudfront';
  } else if (response.headers.get('X-Cache')?.includes('Miss')) {
    type = 'pixel-cache';
    serviceType = 'cloudfront';
  } else {
    type = 'no-cache';
    serviceType = 'dcmdata';
  }

  if (globalCacheStatus == null) {
    globalCacheStatus = type;
  } else if (globalCacheStatus !== type) {
    globalCacheStatus = 'mixed';
  }

  if (globalCacheService == null) {
    globalCacheService = serviceType;
  } else if (globalCacheService !== serviceType) {
    globalCacheService = 'mixed';
  }

  workerAnalytics.addContext(
    { type: 'viewer', windowId: '0' },
    globalContext.viewer.urlPixelLoaderOriginCacheStatus,
    globalCacheStatus
  );
  workerAnalytics.addContext(
    { type: 'viewer', windowId: '0' },
    globalContext.viewer.urlPixelLoaderOriginCacheService,
    globalCacheService
  );
}

export class ParallelPixelWebSocketImageLoader extends BaseWebSocketImageLoader {
  #fetchQueue: PriorityQueue<QueuedFrame> = new PriorityQueue<QueuedFrame>(
    (a: QueuedFrame, b: QueuedFrame) => a.priority - b.priority
  );

  #inflightFrames: {
    frameSmid: string;
    requestId: string;
  }[] = [];

  #abortController: AbortController;
  #openJPHModuleLoaderPromise: Promise<{
    HTJ2KDecoder: HTJ2KDecoderType;
  }> = OpenJPHModuleLoader();

  constructor(SUPPORTED_TEXTURES: SupportedTexturesMap) {
    super(PIXEL_LOADER_WS_URL, SUPPORTED_TEXTURES);

    this.#abortController = new AbortController();
  }

  /******************************************************************
   * Public API
   ******************************************************************/

  loadStack({
    stack,
    initialFocus,
    isInitialFrame,
    stackPriority,
    isDropped,
    messageReceivedCallback,
  }: {
    stack: FullSingleLayerStack;
    initialFocus: number;
    isInitialFrame: boolean;
    stackPriority: number;
    isDropped: boolean;
    messageReceivedCallback: MessageReceivedCallback;
  }): void {
    this._loadStack({
      stack,
      transferType: 'url-pixels',
      initialFocus,
      isInitialFrame: false,
      stackPriority,
      isDropped: false,
      messageReceivedCallback,
    });
  }

  async loadFrames({
    orderedFrames,
    initialFocus,
    isInitialFrame,
    stackPriority,
    isDropped,
    messageReceivedCallback,
  }: {
    orderedFrames: OrderedFrame[];
    initialFocus: number;
    stackPriority: number;
    isInitialFrame: boolean;
    isDropped: boolean;
    messageReceivedCallback: MessageReceivedCallback;
  }) {
    this._loadFrames({
      orderedFrames,
      transferType: 'url-pixels',
      initialFocus,
      isInitialFrame,
      stackPriority,
      isDropped,
      messageReceivedCallback,
    });
  }

  updatePriority({
    stack,
    focusFrameIndex,
    stackPriority,
  }: {
    stack: FullSingleLayerStack;
    focusFrameIndex: number;
    stackPriority: number;
  }): void {
    // requests may have frames from multiple stacks
    // such as the request for the initial frames of all hanged stacks
    const matchingRequests = Array.from(this.requestMap.values()).filter((request) =>
      Array.from(request.frameMap.values()).some((frame) => frame.stackSmid === stack.smid)
    );
    matchingRequests.forEach((request) => {
      request.priority = stackPriority;
      request.focusIndex = focusFrameIndex;

      this._sendJson({
        requestId: request.requestId,
        focus: focusFrameIndex,
        priority: stackPriority,
        event: 'update-priority',
      });

      // also update the url-pixels queue for any frames already returned by the websocket
      const matchingFrames = this.#fetchQueue.remove((f) => f.requestId === request.requestId);
      matchingFrames.forEach((f) => {
        const frameDistance = Math.abs(focusFrameIndex - f.frameIndex);
        f.priority = stackPriority + (f.frameIndex % 5 === 0 ? frameDistance / 5 : frameDistance);
        this.#fetchQueue.enqueue(f);
      });
    });
  }

  /******************************************************************
   * Protected API
   ******************************************************************/

  _handleDataMessage: HandleDataMessageCallback = async (data) => {
    try {
      if (typeof data !== 'string') {
        throw new Error('Expected data to be a stringified JSON object');
      }

      const { requestId, frameSmid, signedPixelURL, fallbackPixelURL, priority } = JSON.parse(data);
      this._log('#receiveFrame', requestId);

      const requestInfo = this.requestMap.get(requestId);

      if (requestInfo == null) return;

      let dataBuffer = null;

      if (signedPixelURL != null) {
        try {
          // cached URLs are pre-signed so we don't need to include credentials
          dataBuffer = await this.#fetchPixelData(
            signedPixelURL,
            false,
            frameSmid,
            requestId,
            priority
          );
        } catch (err: any) {
          this._log(`Failed to fetch pixel data from cache frameSmid: ${frameSmid}`);
        }
      }

      if (dataBuffer == null && fallbackPixelURL != null) {
        try {
          // non-cached URLs are not pre-signed so we need to include credentials
          dataBuffer = await this.#fetchPixelData(
            fallbackPixelURL,
            true,
            frameSmid,
            requestId,
            priority
          );
        } catch (err: any) {
          this._log(`Failed to fetch pixel data from DCMData frameSmid: ${frameSmid}`);
        }
      }

      if (dataBuffer == null) {
        throw new Error(`Failed to fetch pixel data from cache or DCMData frameSmid: ${frameSmid}`);
      }

      const pixels = await parsePixelData(
        dataBuffer,
        this.#openJPHModuleLoaderPromise,
        this._SUPPORTED_TEXTURES
      );

      if (pixels != null) {
        this._log('_handleDataMessage messageReceivedCallback', requestId);
        requestInfo.messageReceivedCallback({
          pixels,
          frameSmid,
          event: 'data-received',
        });
        this._resolveDeferredPromise(requestId);
      } else {
        throw new Error('Failed to create pixel array');
      }
    } catch (error: any) {
      logger.error('frame error', error);
    }
  };

  cancel() {
    super.cancel();

    this.#fetchQueue = new PriorityQueue<QueuedFrame>(
      (a: QueuedFrame, b: QueuedFrame) => a.priority - b.priority
    );
    this.#abortController.abort('Cancelled');
    this.#inflightFrames = [];
  }

  /******************************************************************
   * Private API
   ******************************************************************/

  async _transferComplete(requestId: string, transferedFrames: number): Promise<void> {
    const requestInfo = this.requestMap.get(requestId);

    if (requestInfo == null) {
      throw new Error(`Request info not found for request ID: ${requestId}`);
    }

    const framesMap = requestInfo.frameMap;
    const requestedFrames = Array.from(framesMap.values()).map((item) => item.frameSmid);
    const loadingFrames = this.#fetchQueue
      .toArray()
      .filter((item) => requestedFrames.includes(item.frameSmid));

    // wait for all the frames for this request to finish loading before calling super._transferComplete
    await Promise.all(loadingFrames.map((item) => item.deferred.promise));
    super._transferComplete(requestId, transferedFrames);
  }

  async #processFetchQueue(): Promise<undefined> {
    // Check if the counter for parallel requests is less than the configured limit from ENV
    while (this.#fetchQueue.size() > 0) {
      const item = this.#fetchQueue.dequeue();

      if (item == null) return;

      const { url, includeCredentials, deferred, requestId } = item;

      if (url) {
        this.#inflightFrames.push({ frameSmid: item.frameSmid, requestId: item.requestId });

        // Initiate the fetch request
        try {
          const response = await fetchWithRetry(new URL(url.toString(), GRAPHQL_URL), {
            credentials: includeCredentials ? 'include' : 'omit',
            signal: this.#abortController.signal,
          });
          trackCacheStatus(url, response);

          // Remove the frame from the inflight list
          this.#inflightFrames.splice(
            this.#inflightFrames.findIndex((i) => requestId === i.requestId), // TODO EN-7795 this should be frameSmid, not requestId
            1
          );

          await response.arrayBuffer().then((arrayBuffer) => {
            // Resolve the promise
            deferred.resolve(arrayBuffer);
          });
        } catch (error: any) {
          // rejecting within async code sometimes escapes try-catch blocks
          // specifically, a single-frame-load error should not float to the viewport
          // (only process errors like signed urls or WebSocket errors)
          // instead we can just resolve null to represent the data
          // as long as we log the error ourselves
          logger.error(`Error fetching URL: ${url}`, error);
          deferred.resolve(null);

          // Remove the frame from the inflight list
          this.#inflightFrames.splice(
            this.#inflightFrames.findIndex((i) => requestId === i.requestId), // TODO this should be frameSmid, not requestId
            1
          );
        }
      }
    }
  }

  #fetchPixelData(
    url: string,
    includeCredentials: boolean,
    frameSmid: string,
    requestId: string,
    priority: number
  ): Promise<ArrayBuffer | null> {
    // Create a deferred object
    const deferred = new Deferred<ArrayBuffer | null>();

    const sortIndex = this.requestMap.get(requestId)?.frameMap.get(frameSmid)?.sortIndex ?? 0;
    // Add the URL and deferred object to the queue
    this.#fetchQueue.enqueue({
      requestId,
      url,
      frameSmid,
      frameIndex: sortIndex,
      includeCredentials,
      deferred,
      priority,
    });

    if (MAX_PARALLEL_REQUESTS > this.#inflightFrames.length) {
      this.#processFetchQueue();
    }

    return deferred.promise;
  }
}
